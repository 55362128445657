import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import {
  OppfølgingsFormType,
  ValidateFormTypes,
} from 'features/deltaker/details/components/Henvendelser/HenvendelseDialog/Oppfølgingspunkt/interface';
import { displayNotificationAndLogError } from 'features/errorHandling/errorHandlingUtils';
import { getServertidspunktOrDefaultToClientTime } from 'features/system/systemAPI';
import { BrukerService, DeltakerService, OppfølgingService } from 'services';
import { OppfølgingMedHenvendelseDto } from 'services/oppfølging/dto';
import { FetchResponseError, TraceableError } from 'utils/errors/customError';

import { addNotification } from '../../../notification/notificationSlice';
import { setShouldRefetchLogg } from '../logg/slice';

const typeName = 'deltaker/oppfølgingspunkt';

export const initializeForm = createAsyncThunk<
  { fristDate: string; type: OppfølgingsFormType },
  OppfølgingsFormType
>(`${typeName}/initializeForm`, async (formType) => {
  const dt = await getServertidspunktOrDefaultToClientTime();

  return { fristDate: dt.plus({ week: 1 }).toISODate()!, type: formType };
});

export const getOppfølgingById = createAsyncThunk<
  OppfølgingMedHenvendelseDto,
  number,
  { rejectValue: string; state: RootState }
>(
  `${typeName}/getOppfølgingById`,
  async (oppfølgingId, { rejectWithValue, dispatch, getState }) => {
    const {
      deltaker: {
        detaljer: { data },
      },
    } = getState();

    try {
      const result = await OppfølgingService.getOppfølgingById(oppfølgingId);

      const deltakerIdIsEqual = result.deltakerId === data?.deltakerId;

      if (deltakerIdIsEqual) return result;
      else
        throw new Error(
          `DeltakerId ${result.deltakerId} er ikke tilknyttet oppfolgingId ${oppfølgingId}`
        );
    } catch (error) {
      const err = error as TraceableError;
      displayNotificationAndLogError(dispatch, err);

      return rejectWithValue(err.toString());
    }
  }
);

export const autoFillUtførtTidspunkt = createAsyncThunk<string, undefined>(
  `${typeName}/autoFillUtførtTidspunkt`,
  async () => {
    const dt = await getServertidspunktOrDefaultToClientTime();

    return dt.toISO({ includeOffset: false })!;
  }
);

export const setBrukerIdFromNavn = createAsyncThunk<
  { brukernavn: string; brukerId?: number },
  string,
  {
    rejectValue: {
      brukernavn: string;
      brukerId: undefined;
      errorMessage: string;
    };
  }
>(
  `${typeName}/setBrukerIdFromNavn`,
  async (brukernavn, { rejectWithValue }) => {
    if (brukernavn.length >= 4 && brukernavn.length <= 8) {
      try {
        const response = await BrukerService.getBrukerInfoByBrukernavn(
          brukernavn
        );

        return { brukernavn, brukerId: response.id };
      } catch (error) {
        const err = error as FetchResponseError;
        return rejectWithValue({
          brukernavn,
          brukerId: undefined,
          errorMessage: err.toString(),
        });
      }
    } else {
      return rejectWithValue({
        brukernavn,
        brukerId: undefined,
        errorMessage: `Brukernavn inneholder for ${
          brukernavn.length < 4 ? 'få' : 'mange'
        } tegn`,
      });
    }
  }
);

export const sendOppfølging = createAsyncThunk<
  void,
  string | null,
  { rejectValue: string; state: RootState }
>(
  `${typeName}/sendOppfølging`,
  async (oppfølgingId, { dispatch, rejectWithValue, getState }) => {
    const {
      deltaker: { detaljer, oppfølgingspunkt },
    } = getState();
    const { formValues, invalidFields, isUtførtSelected } = oppfølgingspunkt;

    const allRequiredFieldsOk = !Object.keys(invalidFields).some(
      (key) => invalidFields[key as keyof ValidateFormTypes]
    );

    const dto = {
      ...formValues,
      ...(!isUtførtSelected && {
        utførtAvBrukerId: undefined,
        utførtTidspunkt: undefined,
      }),
    };

    if (allRequiredFieldsOk) {
      try {
        if (oppfølgingId) {
          await OppfølgingService.putDeltakeroppfølging(
            Number(oppfølgingId),
            dto
          );
        } else {
          const deltakerId = detaljer.data?.deltakerId;

          if (!deltakerId) throw new Error('Ukjent deltakerId');

          await DeltakerService.postDeltakeroppfølging(deltakerId, dto);
        }
      } catch (error) {
        const err = error as FetchResponseError;
        return rejectWithValue(err.message);
      }
    } else {
      return rejectWithValue('Kunne ikke registrere da det er ugyldige felt');
    }

    dispatch(
      addNotification({
        message: {
          title: 'Oppdatert',
          text: `Oppfølgingspunkt ble oppdatert`,
        },
        variant: 'success',
      })
    );
    dispatch(setShouldRefetchLogg(true));
  }
);
