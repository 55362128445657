import { createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus, loading, loadingComplete } from 'features/utils';

import { KanalerState } from './interface';
import { fetchHenvendelseKanalIdsByHenvendelseType } from './thunks';

export const initialState: KanalerState = {};

const slice = createSlice({
  name: 'kanaler',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchHenvendelseKanalIdsByHenvendelseType.fulfilled,
      (state, { payload: { henvendelseTypeId, data } }) => ({
        ...state,
        [henvendelseTypeId]: {
          ...state[henvendelseTypeId],
          data: data,
          status: loadingComplete,
        },
      })
    );
    builder.addCase(
      fetchHenvendelseKanalIdsByHenvendelseType.pending,
      (state, { meta: { arg } }) => ({
        ...state,
        [arg]: {
          ...state[arg],
          status: loading,
        },
      })
    );
    builder.addCase(
      fetchHenvendelseKanalIdsByHenvendelseType.rejected,
      (state, { meta: { arg }, payload }) => ({
        ...state,
        [arg]: {
          status: {
            ...initialFetchStatus,
            errorMessage: payload,
          },
        },
      })
    );
  },
});

export default slice.reducer;
