import { createSelector } from '@reduxjs/toolkit';
import { RoutePathAccess } from 'features/currentBruker/state/allowedRoutes/interface';
import {
  getAllEnheter,
  getAllowedRoutes,
  getAuth,
  getEnheterStatus,
  getIsAuthorized,
  getRettigheter,
} from 'features/currentBruker/state/selectors';
import { getInfoDemaskert } from 'features/deltaker/state/deltakerSelectors';
import { EnhetDto } from 'services/enheter/dto';

type UserData = {
  isDisabled: boolean;
  isLimitedAccess: boolean;
  hasId: boolean;
};
export type DemaskertSelector = { dialogOpen: boolean; deltakerId?: number };
export type GetRouteInfo = {
  enheter: EnhetDto[];
  allowedRoutes: RoutePathAccess[];
  userData: UserData;
  isAuthorized: boolean;
  isLoading: boolean;
  currentEnhetId?: number;
};

export type GetAppInfo = {
  hasHelseIdToken: boolean;
  isLoading: boolean;
  loadingComplete: boolean;
  enheter: EnhetDto[];
  currentEnhetId: number | undefined;
};

const userDataSelector = createSelector([getAuth], ({ data }): UserData => {
  return {
    hasId: !!data?.id,
    isLimitedAccess: data?.authority === 'ROLE_LIMITED_ACCESS',
    isDisabled: data?.authority === 'ROLE_DISABLED',
  };
});

const isLoadingSelector = createSelector(
  [getAuth, getEnheterStatus, getRettigheter],
  (auth, enheterStatus, rettigheter) =>
    enheterStatus.loading || auth.status.loading || rettigheter.status.loading
);

const getRouteInfo = createSelector(
  [
    getAllEnheter,
    getAllowedRoutes,
    getIsAuthorized,
    userDataSelector,
    isLoadingSelector,
    getAuth,
  ],
  (
    alleEnheter,
    allowedRoutes,
    isAuthorized,
    userData,
    isLoading,
    auth
  ): GetRouteInfo => {
    return {
      isLoading: isLoading,
      enheter: alleEnheter,
      allowedRoutes,
      userData: userData,
      isAuthorized: isAuthorized,
      currentEnhetId: auth.currentEnhetId,
    };
  }
);

const getAppInfo = createSelector(
  [getAuth, isLoadingSelector, getEnheterStatus, getAllEnheter, getRettigheter],
  (auth, isLoaded, enheterStatus, enheter, rettigheter): GetAppInfo => {
    return {
      hasHelseIdToken: !!auth.data?.id,
      isLoading: isLoaded,
      loadingComplete:
        !!auth.status.loadingComplete ||
        !!enheterStatus.loadingComplete ||
        !!rettigheter.status.loadingComplete,
      enheter,
      currentEnhetId: auth.currentEnhetId,
    };
  }
);
export const demaskertSelector = createSelector(
  [getInfoDemaskert],
  ({ dialogOpen, deltakerToDemask }): DemaskertSelector => {
    return {
      dialogOpen,
      deltakerId: deltakerToDemask,
    };
  }
);

export { getRouteInfo, getAppInfo };
