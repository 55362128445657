import { ManuellHenvisningState } from '../interface';

export const getManuellHenvisningStatus = (
  state: ManuellHenvisningState
): ManuellHenvisningState => state;

const selectors = {
  getHenvisningSendStatus: getManuellHenvisningStatus,
};

export default selectors;
