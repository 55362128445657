import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/rootReducer';
import { LoadingInputComponent } from 'components';
import { getkanalerByHenvendelsestypeId } from 'features/deltaker/state/deltakerSelectors';
import { fetchHenvendelseKanalIdsByHenvendelseType } from 'features/deltaker/state/henvendelse/kanaler/thunks';
import { putHenvendelse } from 'features/deltaker/state/henvendelse/sending/thunks';
import { useSysProperties } from 'features/sys';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  GetHenvendelseDto,
  HenvendelseTypeDto,
} from 'services/henvendelser/dto';
import { getHenvendelse } from 'services/henvendelser/henvendelerService';
import {
  PickerFormat,
  PickerProps,
  formatPickerToOutput,
  fromISOToMillis,
} from 'utils/date';
import { useFetchData } from 'utils/hooks';

import { DialogContentWrapper, DialogFormButtons } from '../components';
import { Henvendelsekategorier } from '../components/Henvendelsekategorier';
import { henvendelseDialogSelector } from '../selectors';
import { initialFormState, initialFormStateInvalid } from '../utils';
import { HenvendelseDialogFormProps } from './interface';

const RedigerHenvendelseDialogForm: FC<HenvendelseDialogFormProps> = ({
  henvendelseTypeId,
  close,
  log,
}) => {
  const dispatch = useAppDispatch();

  const fetcher = useCallback(
    () => getHenvendelse(log.primærnøkkelId as number),
    [log.primærnøkkelId]
  );
  const { status, data } = useFetchData<GetHenvendelseDto>(fetcher);

  const sysHenvendelseTyper = useSysProperties('henvendelseTyper');
  const sysHenvendelseKanaler = useSysProperties('henvendelseKanaler');
  const { isSubmitting, hasError } = useAppSelector(henvendelseDialogSelector);

  const [formState, setFormState] = useState(initialFormState);
  const [formStateInvalid, setFormStateInvalid] = useState(
    initialFormStateInvalid
  );
  const [mottattTidspunkt, setMottattTidspunkt] = useState<PickerProps>({
    value: null,
    format: PickerFormat.DATE_TO_DATE_TIME,
  });

  useEffect(() => {
    if (henvendelseTypeId) {
      dispatch(fetchHenvendelseKanalIdsByHenvendelseType(henvendelseTypeId));
    }
  }, [dispatch, henvendelseTypeId]);

  useEffect(() => {
    const mottatt = fromISOToMillis(data?.mottattTidspunkt);
    if (mottatt) {
      setMottattTidspunkt({
        value: DateTime.fromMillis(mottatt),
        format: PickerFormat.DATE_TO_DATE_TIME,
      });
    }
    setFormState((f) => ({
      ...f,
      henvendelseKanalId: data?.henvendelseKanalId,
      henvendelseKategorier: data?.henvendelseKategoriIder || [],
      tekst: data?.tekst || '',
    }));
  }, [fetcher, data]);

  const henvendelsesKanaler = useAppSelector((state: RootState) =>
    getkanalerByHenvendelsestypeId(state, henvendelseTypeId)
  );

  const isAllLoaded =
    sysHenvendelseTyper.isLoaded &&
    sysHenvendelseKanaler.isLoaded &&
    status.loadingComplete;

  if (!isAllLoaded) return null;

  const henvendelseType = sysHenvendelseTyper.map(
    henvendelseTypeId
  ) as HenvendelseTypeDto;

  const handleChange = (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string | number | number[]>
  ) => {
    const { name, value } = event.target;
    if (name) {
      setFormState({ ...formState, [name]: value });
      setFormStateInvalid({ ...formStateInvalid, [name]: false });
    }
  };

  const handleInvalid = (event: React.FormEvent) => {
    event.preventDefault();

    const { name } = event.target as HTMLInputElement;
    setFormStateInvalid({ ...formStateInvalid, [name]: true });
  };

  const handleMottattTidspunktChange = (date: DateTime | null) => {
    setMottattTidspunkt({
      ...mottattTidspunkt,
      value: date,
    });
    setFormStateInvalid({ ...formStateInvalid, mottattTidspunkt: false });
  };

  const handleMottattTidspunktError = (error: DateValidationError) => {
    let message;
    switch (error) {
      case 'invalidDate':
        message = 'Mottatt dato må være på formatet dd.mm.åååå';
        break;
      case 'disableFuture':
        message = 'Mottatt dato kan ikke være i fremtiden';
        break;
      case null:
        break;
      default:
        message = 'Ugyldig dato';
    }
    setMottattTidspunkt({
      ...mottattTidspunkt,
      error: message,
      displayError: false,
    });
  };

  const displayMottattTidspunktError = () => {
    if (mottattTidspunkt.error) {
      setMottattTidspunkt({
        ...mottattTidspunkt,
        displayError: true,
      });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const henvendelse = {
      ...formState,
      henvendelseKanalId: formState.henvendelseKanalId!,
      mottattTidspunkt: formatPickerToOutput(mottattTidspunkt),
      henvendelseTypeId: henvendelseTypeId!,
    };

    dispatch(
      putHenvendelse({
        henvendelseId: data?.id || 0,
        henvendelseUpdate: henvendelse,
      })
    );
  };

  const errorMessage = hasError
    ? {
        title: 'Noe gikk galt ved lagring',
        message: 'Vennligst prøv igjen',
      }
    : undefined;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <DialogContentWrapper
        hideDeltakerAdresse={false}
        showDeltakerInfo
        tekstOverskrift={`Rediger hendvendelse av type: ${henvendelseType.tekstOverskrift}`}
        errorMessage={errorMessage}>
        <LoadingInputComponent showLoading={!isAllLoaded} marginBottom={2}>
          <DatePicker
            label="Dato mottatt"
            value={mottattTidspunkt.value}
            format={mottattTidspunkt.format.inputFormat}
            disableFuture
            onChange={handleMottattTidspunktChange}
            onError={handleMottattTidspunktError}
            slotProps={{
              textField: {
                id: 'mottattTidspunkt',
                name: 'mottattTidspunkt',
                required: true,
                error:
                  mottattTidspunkt.displayError ||
                  formStateInvalid.mottattTidspunkt,
                helperText: mottattTidspunkt.displayError
                  ? mottattTidspunkt.error
                  : '',
                onBlur: displayMottattTidspunktError,
                onInvalid: handleInvalid,
                sx: { mb: 2 },
              },
            }}
          />
        </LoadingInputComponent>
        <FormControl
          variant="filled"
          required
          fullWidth
          error={formStateInvalid.henvendelseKanalId}
          sx={{ mb: 2 }}>
          <LoadingInputComponent showLoading={!sysHenvendelseKanaler.isLoaded}>
            <InputLabel id="henvendelse-kanal-select">Velg kanal</InputLabel>
            <Select
              labelId="henvendelse-kanal-select"
              name="henvendelseKanalId"
              value={formState.henvendelseKanalId ?? ''}
              onInvalid={handleInvalid}
              onChange={handleChange}>
              {henvendelsesKanaler?.data?.map((henvendelsesKanalId: number) => (
                <MenuItem key={henvendelsesKanalId} value={henvendelsesKanalId}>
                  {sysHenvendelseKanaler.mapBeskrivelse(henvendelsesKanalId)}
                </MenuItem>
              ))}
            </Select>
          </LoadingInputComponent>
        </FormControl>
        <Henvendelsekategorier
          handleInvalid={handleInvalid}
          handleChange={handleChange}
          selectedValues={formState.henvendelseKategorier}
        />
        <TextField
          id="henvendelse-tekst"
          label="Vennligst utdyp nærmere..."
          name="tekst"
          value={formState.tekst ?? ''}
          fullWidth
          multiline
          spellCheck={false}
          rows={4}
          InputLabelProps={{ shrink: true }}
          onChange={handleChange}
        />
      </DialogContentWrapper>

      <DialogFormButtons
        disabled={isSubmitting}
        isLoading={isSubmitting}
        close={close}
        tekstHandling="Lagre henvendelse"
      />
    </form>
  );
};

export default RedigerHenvendelseDialogForm;
