import { Box } from '@mui/material';

import Props from './interface';

const Heading = ({
  id,
  className,
  componentVariant = 'h3',
  text,
  center = false,
  noMargin,
  inline,
  xs,
}: Props): React.JSX.Element => {
  return (
    <Box
      id={id}
      className={className}
      component={componentVariant}
      sx={{
        color: 'primary.main',
        fontSize: 16,
        fontWeight: 'bold',
        my: noMargin ? 0 : 2,
        display: inline ? 'inline' : 'block',
        ...(center && { textAlign: 'center' }),
        ...xs,
      }}>
      {text}
    </Box>
  );
};

export default Heading;
