import { Theme } from '@mui/material';

import { AntallData } from './interface';

export const sum = (data: AntallData[]): number => {
  return data.reduce(
    (acc, current) => acc + (current.selected ? current.antall : 0),
    0
  );
};

export const sumKorrigert = (data: AntallData[]): number => {
  return data.reduce(
    (acc, current) =>
      acc + (current.selected ? current.korrigertAntall ?? current.antall : 0),
    0
  );
};

export const getStrikeThrough = (korrigert: boolean) => {
  if (korrigert) {
    return {
      textDecorationLine: 'line-through',
      textDecorationColor: (theme: Theme) => theme.palette.scr.red700,
    };
  }
  return {};
};
