import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RootState } from 'app/rootReducer';
import { reset } from 'features/currentBruker/state/auth/slice';

const UnauthrizedExample: React.FC = () => {
  const dispatch = useAppDispatch();

  const resetState = () => {
    dispatch(reset());
  };

  const userId = useAppSelector(
    (state: RootState) => state.currentBruker.auth.data?.id
  );

  return (
    <>
      <pre
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: 800,
        }}>
        BrukerId: {userId}
      </pre>
      <Button variant="contained" color="secondary" onClick={resetState}>
        Reset brukerstate
      </Button>
    </>
  );
};

export default UnauthrizedExample;
