import { CssBaseline, ThemeProvider } from '@mui/material';

import {
  ErrorBoundary,
  TopLevelErrorBoundary,
} from 'components/Error';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from './app/store';
import reportWebVitals from './reportWebVitals';
import screenitTheme from './theme';
import App from './app/App';

const render = () => {

  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(
    <TopLevelErrorBoundary>
      <ThemeProvider theme={screenitTheme}>
        <CssBaseline />
        <Provider store={store}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    </TopLevelErrorBoundary>
  );
};

render();

/*if (process.env.NODE_ENV === 'development' && import.meta.hot) {
  import.meta.hot.accept('./app/App', render);

  // expose store to Cypress
  // @ts-ignore // TODO: ts2339. find solution and remove
  if (window.Cypress) window.store = store;
}*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
