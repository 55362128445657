import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpPost } from 'utils/http';

import { DeltakerNotatService, PostDeltakerNotat } from './interface';
import { DeltakerNotatDto } from './dto';

const basePath = 'notat';

const postDeltakerNotat: PostDeltakerNotat = async (
  henvisning: DeltakerNotatDto
) => {
  const path = buildPath(basePath);
  return httpPost(path, henvisning).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Klarte ikke lagre deltaker notat. Detaljer: ${
        (error as FetchResponseError).message
      }`
    );
  });
};

export default {
  postDeltakerNotat,
} as DeltakerNotatService;
