import { createSelector } from '@reduxjs/toolkit';
import { DisplayNotification } from 'features/notification/interface';
import { getAllNotifications } from 'features/notification/selectors';

export const getCurrentNotification = createSelector(
  [getAllNotifications],
  (notifications): DisplayNotification | undefined =>
    notifications.find(
      (notification) =>
        !notification.dismissed && notification.options.variant !== 'error'
    )
);

export const getCurrentError = createSelector(
  [getAllNotifications],
  (notifications): DisplayNotification | undefined =>
    notifications.find(
      (notification) =>
        !notification.dismissed && notification.options.variant === 'error'
    )
);
