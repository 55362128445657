import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { getAlleDokumenterByDokumenttype } from 'services/dokument/dokumentService';
import { Dokument } from 'services/dokument/dto';
import { FetchResponseError } from 'utils/errors/customError';

const typeName = 'dokument';
export const fetchDokumenter = createAsyncThunk<
  Dokument[],
  { dokumenttypeId: number; historikk: boolean },
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}/fetchDokumenter`,
  async (
    { dokumenttypeId, historikk },
    { dispatch, rejectWithValue }
  ) => {
    try {
      return await getAlleDokumenterByDokumenttype(
        dokumenttypeId,
          historikk
      );
    } catch (error) {
      const err = error as FetchResponseError;

      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    }
  }
);
