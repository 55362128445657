import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';

export const getAllErrors = (state: RootState) => state.errors.list;

export const getLastError = createSelector([getAllErrors], (errors) =>
  errors.reduce((a, b) =>
    Number(a.error.timestamp) > Number(b.error.timestamp) ? a : b
  )
);

export const getErrorById = (id: string) =>
  createSelector([getAllErrors], (errors) =>
    errors.find((error) => error.id === id)
  );

export const getErrorByTraceId = (traceId: string) =>
  createSelector([getAllErrors], (errors) =>
    errors.filter((error) => error.error.traceId === traceId)
  );
