import { combineReducers } from '@reduxjs/toolkit';
import brukerEditorReducer from 'features/bruker/editor/brukerEditorSlice';
import skopørKvalitetskravReducer from 'features/skopør/kvalitetskrav/skopørKvalitetskravSlice';
// Unable to import these from features directly. See features/index.ts for explanation
import { currentBrukerReducer } from 'features/currentBruker';
import { deltakerReducer } from 'features/deltaker';
import { dokumentReducer } from 'features/brevmaladmin/dokument/state';
import { enheterReducer } from 'features/enhet';
import { errorHandlingReducer } from 'features/errorHandling';
import { notificationReducer } from 'features/notification';
import { senteradminReducer } from 'features/senteradmin/state';
import { sysReducer } from 'features/sys';
import { utsendingReducer } from 'features/brevmaladmin/utsending/state';
import { dashboardReducer } from 'features/dashboard/state';

const rootReducer = combineReducers({
  brukerEditor: brukerEditorReducer,
  skopørKvalitetskrav: skopørKvalitetskravReducer,
  currentBruker: currentBrukerReducer,
  dokument: dokumentReducer,
  utsending: utsendingReducer,
  enheter: enheterReducer,
  notification: notificationReducer,
  sys: sysReducer,
  errors: errorHandlingReducer,
  deltaker: deltakerReducer,
  senteradmin: senteradminReducer,
  dashboard: dashboardReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
