import { HvemOgTidspunktDto } from 'utils/dto';

export type PostDeltakerReturnDto = {
  deltakerId?: number;
};

export type DeltakerDetaljerDto = {
  deltakerId: number;
  personStatusId: number;
  alder: number;
  kommuneNr: string;
  kommuneNavn: string;
  bydelNr?: string;
  bydelNavn?: string;
  antallAdresser: number;
  helseforetak: string;
  screeningsenter: string;
  inklusjonsdato: string;
  screeningMetode: string;
  status: string;
  invitasjonsStopp?: string;
  reservertMotLagring?: string;
  sistFormidlingskanal?: string;
};

export type HenvisningDto = {
  id: number;
  status: string;
  henvistDato: string;
  årsak: string;
  henvistTilEnhetId: number;

  viderehenvistTilEnhetId?: number;
  statusDato: string;
};
export type HenvisningTypeDto = {
  id: number;
  beskrivelse: string;
  henvisningstekst: string;
  tekstStatusvisningHelsenorge: string;
};

export enum IfobtRundeHendelseType {
  ANALYSERT = 'Analysert',
  PÅMINNELSE = 'Påminnelse',
  PRØVESETT = 'Prøvesett',
}

export type DeltakerIfobtRundeInfoHendelserDto = {
  ifobtRundeHendelseType: IfobtRundeHendelseType;
  tidspunkt: string;
  løpenummer?: number;
  utløpt?: boolean;
  prøvesvarStatusId?: number;
  prøvesvarKonklusjon?: string;
  gjeldenePrøvesvar?: boolean;
  deltakerUtsendingStatusId?: number;
};

export type DeltakerIfobtRundeInfoDto = {
  rundeKonklusjon: string;
  screeningintensjonId: number;
  runde: number;
  hendelser: DeltakerIfobtRundeInfoHendelserDto[];
};
export enum prvesvarStatusBeskrivelseEnum {
  Kansellering = 'Kansellering',
  Ny = 'Ny',
  Erstatning = 'Erstatning',
  Erstattet = 'Erstattet',
  Kansellert = 'Kansellert',
}

export type GetDeltakerDto = {
  fødselsnr: string;
};

export type KapasitetForÅrDtoList = {
  år: number;
  koloskopikapasitet: number;
};

export type KoloskopiKapasitetDto = {
  enhetId: number;
  kapasitetForÅrDtoList: KapasitetForÅrDtoList[];
  registrertAvBrukerId: number;
  registrertTidspunkt: string;
};

export type DeltakerAdresseDto = {
  adresseType: number;
  adresseInnholdstype: number;
  adresse: string;
  postnr?: string;
  poststed?: string;
  bydel?: string;
  bydelnr?: string;
  bydelkode?: string;
  kommunenr?: string;
  kommunenavn?: string;
  effektivPostAdresse?: boolean;
  gyldigFra: string;
  gyldigTil?: string;
  idKryptert: string;
};

export type DeltakerKKRDto = {
  status: string;
  telefon?: string;
  telefonOppdatert?: string;
  telefonSistVerifisert?: string;
  reservasjon?: boolean;
  digitalPostkasse?: boolean;
};

export type DeltakerInfoDemaskertDto = {
  deltakerId: number;
  fødselsnummer: string;
  fornavn: string;
  mellomnavn?: string;
  etternavn: string;
  adresse?: DeltakerAdresseDto[];
};

export type DeltakerLogDto = {
  rowIndex?: number;
  primærnøkkelId?: number;
  loggradTypeId: number;
  henvendelseKanalIkonId?: number;
  henvendelseKanalId?: number;
  loggradTypeIkonId?: number;
  henvendelseTypeId?: number;
  registrertAvBrukernavn: string;
  tidspunkt: string;
  loggtekst: string;
  hovertekst?: string;
  deltakerUtsendingStatusGruppeId?: number;
  henvisningStatusId?: number;
  ifobtPrøvesvarStatusId?: number;
  utløptPrøvesett?: boolean;
};
export type DeltakerInfo = {
  deltakerId: number;
  personStatusId: number;
  fornavn: string;
  mellomnavn?: string;
  etternavn: string;
  fødselsdato?: string;
  adresse?: string;
  kommuneNr?: string;
  kommuneNavn?: string;
  postSted?: string;
  postnr?: string;
};

export type DeltakerInfoDto =
  | DeltakerInfo
  | (DeltakerInfo & HvemOgTidspunktDto);

export type OppfølgingCreateDto = {
  oppfølgingTypeId: number;
  knyttetTilHenvendelseId: number;
  fristDato: string;
  tekst: string;
  oppfølgingStatusId: number;
  utførtAvBrukerId: number;
  utførtTidspunkt: string;
  id?: number;
};

export enum DemaskerTypeEnum {
  alle = 'alle',
  fodselsnummer = 'fodselsnummer',
  navn = 'navn',
  adresse = 'adresse',
}

export type PostKoloskopirapportDto = {
  undersøkelsesDato: string;
  hprNr: string | null;
  krgGuid: string;
  mottattTidspunkt: string;
  enhetId: number;
};
