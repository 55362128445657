import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadingInputComponent, ScrWrapper } from 'components';
import { useSysProperties } from 'features/sys';
import { useState } from 'react';
import { overlayDialogWidth } from 'utils/styles';

import { DialogTypesEnum } from '../state/infoDemaskert/interface';
import { setTypeToDemask } from '../state/infoDemaskert/slice';
import { DemaskerÅrsakSelector, demaskerÅrsakSelector } from './selectors';

type Props = {
  open: boolean;
  close: (årsaksId?: number, deltakerId?: number) => void;
};

const DemaskerÅrsakDialog: React.FC<Props> = ({ open, close }: Props) => {
  const dispatch = useAppDispatch();
  const visningsgrunn = useSysProperties('visningsgrunn');
  const [selectedId, setSelectedId] = useState<number>();

  const { deltakerToDemask } = useAppSelector<DemaskerÅrsakSelector>(
    demaskerÅrsakSelector
  );
  const handleChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedId(event.target.value as number);
  };

  const handleClose = (årsakProvided: boolean) => {
    if (årsakProvided && selectedId) {
      close(selectedId, deltakerToDemask);
      setSelectedId(undefined);
    } else {
      close();
      dispatch(setTypeToDemask({ typeToOpenOnSuccess: DialogTypesEnum.INGEN }));
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="årsak-dialog-title"
      PaperProps={{ sx: { ...overlayDialogWidth, px: 3 } }}>
      <DialogTitle id="årsak-dialog-title">
        Oppgi grunn for oppslag/demaskering
      </DialogTitle>
      <DialogContent>
        <ScrWrapper variant="dialog" sx={{ p: 3 }}>
          <LoadingInputComponent showLoading={!visningsgrunn.isLoaded}>
            <FormControl variant="filled" required fullWidth>
              <InputLabel id="årsak-select">Velg årsak</InputLabel>
              <Select
                labelId="årsak-select"
                name="henvendelseKanalId"
                value={selectedId ?? ''}
                onChange={handleChange}>
                {visningsgrunn.data?.map((grunn) => (
                  <MenuItem key={'årsakid_' + grunn.id} value={grunn.id}>
                    {grunn.beskrivelse}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </LoadingInputComponent>
        </ScrWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Avbryt</Button>
        <Button
          disabled={!visningsgrunn.isLoaded || !selectedId}
          variant="contained"
          onClick={() => handleClose(true)}>
          Demasker person
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemaskerÅrsakDialog;
