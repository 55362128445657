import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { TraceableError } from 'utils/errors/customError';

export type Errors = {
  id: string;
  error: TraceableError;
};

export type State = {
  list: Errors[];
};
export type Dispatcher = ThunkDispatch<unknown, unknown, AnyAction>;

export const AddError = 'errors/addError';
export const RemoveErrorById = 'errors/removeErrorById';
export const RemoveErrorByTraceId = 'errors/removeErrorByTraceId';
export const ClearErrors = 'errors/clearErrors';

export type AddErrorType = typeof AddError;
export type RemoveErrorByIdType = typeof RemoveErrorById;
export type RemoveErrorByTraceIdType = typeof RemoveErrorByTraceId;
export type ClearErrorsType = typeof ClearErrors;

export type ErrorTypes =
  | AddErrorType
  | RemoveErrorByIdType
  | RemoveErrorByTraceIdType
  | ClearErrorsType;
