import { DeltakerLoggState } from './interface';

const getDeltakerLog = (state: DeltakerLoggState): DeltakerLoggState => state;
const getShouldRefetch = (state: DeltakerLoggState): boolean =>
  state.shouldRefetchLogg;

const selectors = {
  getDeltakerLog,
  getShouldRefetch,
};

export default selectors;
