import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { FetchStatus } from 'features/utils';
import { EnhetDto } from 'services/enheter/dto';
import { LoggedInBruker } from 'services/loggedInnBruker/dto';

import { RoutePathAccess } from './allowedRoutes/interface';
import allowedRoutesSelectors from './allowedRoutes/selectors';
import { AuthState } from './auth/interface';
import authSelectors from './auth/selectors';
import brukeradminenhetSelectors from './brukeradminEnheter/selectors';
import enhetSelectors from './enheter/selectors';
import { RettigheterState } from './rettigheter/interface';
import rettigheterSelectors from './rettigheter/selectors';

const getEnhetById = (id: EnhetDto['id']) => enhetSelectors.getEnhetById(id);

const getAllEnheter = (): EnhetDto[] => enhetSelectors.getAllEnheter();

const getEnheterStatus = (state: RootState): FetchStatus =>
  enhetSelectors.getEnheterStatus(state.currentBruker.enheter);

const getBrukeradminEnhetById = (
  state: RootState,
  id: EnhetDto['id']
): EnhetDto | undefined =>
  brukeradminenhetSelectors.getBrukeradminEnhetById(state, id);

const getBrukeradminEnheter = (state: RootState): EnhetDto[] =>
  brukeradminenhetSelectors.getBrukeradminEnheter(state);

const getBrukeradminEnhetStatus = (state: RootState): FetchStatus =>
  brukeradminenhetSelectors.getBrukeradminEnhetStatus(
    state.currentBruker.brukeradminEnheter
  );

const getIsSingleEnhetAdmin = (state: RootState): boolean =>
  getBrukeradminEnheter(state).length === 1;

const getAuth = (state: RootState): AuthState =>
  authSelectors.getAuth(state.currentBruker.auth);

const getCurrentBrukerId = (
  state: RootState
): LoggedInBruker['id'] | undefined =>
  authSelectors.getCurrentBrukerId(state.currentBruker.auth);

const getCurrentUserName = (
  state: RootState
): LoggedInBruker['userName'] | undefined =>
  authSelectors.getCurrentUserName(state.currentBruker.auth);

const getIsAuthorized = (state: RootState): boolean =>
  authSelectors.getIsAuthorized(state.currentBruker.auth);

const getRettigheter = (state: RootState): RettigheterState =>
  rettigheterSelectors.getRettigheter(state.currentBruker.rettigheter);

const hasRettigheterByEnhet = (
  state: RootState,
  enhetId: EnhetDto['id'],
  rettigheter: number[] | number
) =>
  rettigheterSelectors.hasRettigheterByEnhet(
    state.currentBruker.rettigheter,
    enhetId,
    rettigheter
  );

const getAllowedRoutes = (state: RootState): RoutePathAccess[] =>
  allowedRoutesSelectors.getAllowedRoutes(state.currentBruker.allowedRoutes);

const hasRettigheterByCurrentSelectedEnhet = createSelector(
  [
    (state: RootState) => state.currentBruker.auth.currentEnhetId,
    (state: RootState) => state,
    (_: RootState, rettighetId) => rettighetId,
  ],
  (currentSelectedEnhet, state, rettighetId) => {
    return currentSelectedEnhet
      ? !!hasRettigheterByEnhet(state, currentSelectedEnhet, rettighetId)[0]?.id
      : false;
  }
);

export {
  getEnhetById,
  getAllEnheter,
  getEnheterStatus,
  getBrukeradminEnhetById,
  getBrukeradminEnheter,
  getBrukeradminEnhetStatus,
  getIsSingleEnhetAdmin,
  getAuth,
  getCurrentBrukerId,
  getAllowedRoutes,
  getRettigheter,
  hasRettigheterByEnhet,
  getIsAuthorized,
  getCurrentUserName,
  hasRettigheterByCurrentSelectedEnhet,
};
