import { Box, Button, IconButton } from '@mui/material';
import { ScrIcon } from 'components';

import { AppendedContentButtonProps } from './interface';

const AppendedContentButton: React.FC<AppendedContentButtonProps> = ({
  showContent,
  text,
  buttonType,
}) =>
  buttonType === 'open' ? (
    <Button
      variant="text"
      startIcon={<ScrIcon iconId={62} />}
      onClick={() => showContent(true)}>
      {text}
    </Button>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
      <IconButton
        aria-label={text}
        size="small"
        onClick={() => showContent(false)}>
        <ScrIcon iconId={63} size="small" />
      </IconButton>
    </Box>
  );

export default AppendedContentButton;
