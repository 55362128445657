import { nanoid } from '@reduxjs/toolkit';
import { TraceableError } from 'utils/errors/customError';

import { iconMap } from './iconMap';
import { GetIconData } from './interface';

export const getIconData: GetIconData = (id) => {
  if (!iconMap.has(id)) {
    const message = `Klarte ikke å finne ikon ${id} i ikonlisten`;
    // eslint-disable-next-line no-console
    console.warn(message);
    throw new TraceableError(message, nanoid(), Date.now.toString());
  }

  return iconMap.get(id);
};
