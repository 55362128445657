import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import React from 'react';

import { AntallsbegrensningProps } from './interface';

const Antallsbegrensning: React.FC<
  AntallsbegrensningProps & { invalid: boolean }
> = ({ active, toggle, text, invalid }) => {
  return (
    <>
      <Grid container item xs={12} justifyContent="flex-end">
        <FormControlLabel
          label="Aktiver antallsbegrensning"
          labelPlacement="start"
          control={<Switch checked={active} onClick={toggle} />}
        />
      </Grid>
      <Grid container item xs={12} justifyContent="flex-end">
        <Typography
          variant="caption"
          sx={{
            color: invalid && active ? 'error.main' : 'primary.main',
            fontStyle: 'italic',
          }}>
          {text}
        </Typography>
      </Grid>
    </>
  );
};

export default Antallsbegrensning;
