import { HenvendelseTyperState } from './interface';

export const getHenvendelseTyper = (
  state: HenvendelseTyperState
): HenvendelseTyperState => state;

const selectors = {
  getHenvendelseTyper,
};

export default selectors;
