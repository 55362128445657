import { RootState } from 'app/rootReducer';
import { SysProperties } from 'services/sys/dto';

import {
  GetSysStateObjectBySysKeyWithKey,
  GetSysStateObjectBySyskey,
  sysAdapter,
} from './interface';

export const getSysStateObjectBySyskey = (
  state: RootState,
  sysKey: keyof SysProperties
): GetSysStateObjectBySyskey => {
  return {
    data: {
      ids: state.sys[sysKey].ids,
      entities: state.sys[sysKey].entities,
    },
    status: state.sys[sysKey].status,
  };
};

export const getSysStateObjectBySyskeys = (
  state: RootState,
  sysKeys: Array<keyof SysProperties>
): Array<GetSysStateObjectBySysKeyWithKey> => {
  const sysData: Array<GetSysStateObjectBySysKeyWithKey> = [];
  for (let index = 0; index < sysKeys.length; index++) {
    sysData.push({
      ...getSysStateObjectBySyskey(state, sysKeys[index]),
      key: sysKeys[index],
    });
  }
  return sysData;
};
export const getSysObjectByKeyAndId = (
  state: RootState,
  sysKey: keyof SysProperties,
  id: number
) => {
  return sysAdapter.getSelectors().selectById(state.sys[sysKey], id);
};
