import { Box } from '@mui/material';
import { IconType } from 'react-icons';

import { Icon, IconAvatar } from './components';
import ScrIconProps, { IconData } from './interface';
import { getIconData } from './utils';

const ScrIcon: React.FC<ScrIconProps> = ({
  iconId,
  size = 'medium',
  color,
  bgcolor,
  borderColor,
  label,
}: ScrIconProps) => {
  let iconData: IconData | undefined;
  try {
    iconData = getIconData(iconId);
    // eslint-disable-next-line no-empty
  } catch {}

  if (!iconData) return <Box />;

  const { content, color: iconColor, type } = iconData;

  return type === 'avatar' ? (
    <IconAvatar size={size} text={content as string} label={label} />
  ) : (
    <Icon
      size={size}
      Icon={content as IconType}
      color={color ?? iconColor}
      bgcolor={bgcolor}
      borderColor={borderColor}
      label={label}
    />
  );
};

export default ScrIcon;
