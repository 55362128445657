import { nanoid } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

export class TraceableError extends Error {
  public name = 'TraceableError';
  constructor(
    public message: string,
    public traceId: string,
    public timestamp: string,
    public stack?: string
  ) {
    super(message);
  }
}

export class FetchResponseError extends TraceableError {
  public name = 'FetchResponseError';
  constructor(
    public message: string,
    public traceId: string,
    public statuscode: number,
    public timestamp: string,
    public stack?: string,
    public instance?: string,
    public isAbortError?: boolean
  ) {
    super(message, traceId, timestamp, stack);
  }
}

export const createNewTraceableError = (error: Error) =>
  new TraceableError(
    error.message,
    nanoid(),
    DateTime.now().toISO({ includeOffset: false })!,
    error.stack
  );

export const addMessageToExsistingFetchError = (
  error: FetchResponseError,
  message: string
) => {
  return new FetchResponseError(
    message,
    error.traceId,
    error.statuscode,
    error.timestamp,
    error.stack,
    error.instance,
    error.isAbortError
  );
};
