import { PayloadAction, createSlice, nanoid } from '@reduxjs/toolkit';

import { DisplayNotification, Notification } from './interface';

export const initialState: DisplayNotification[] = [];

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: {
      reducer(state, payloadAction: PayloadAction<DisplayNotification>) {
        const { message, options, dismissed, action } = payloadAction.payload;
        state.push({ message, options, dismissed, action });
      },
      prepare({ message, variant, action, timeout }: Notification) {
        return {
          payload: {
            message,
            options: {
              key: nanoid(),
              variant,
              timeout: timeout ?? 5000,
            },
            dismissed: false,
            action,
          },
        };
      },
    },
    dismissNotification: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;

      const index = state.findIndex(
        (notification) => notification.options.key === key
      );

      if (index === -1)
        throw new Error(`${key} not found in notification state`);

      state[index].dismissed = true;
    },
    dismissAllNotifications: (state) => {
      state.forEach((notification) => (notification.dismissed = true));
    },
    removeNotification: (state, action: PayloadAction<{ key: string }>) => {
      const { key } = action.payload;

      return state.filter((notification) => notification.options.key !== key);
    },
    resetNotifications: (state) => {
      return initialState;
    },
  },
});

export const {
  addNotification,
  dismissAllNotifications,
  dismissNotification,
  removeNotification,
  resetNotifications,
} = slice.actions;

export default slice.reducer;
