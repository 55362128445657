import { DialogFormState, DialogFormStateInvalid } from './interface';
import { DateTime } from 'luxon';
import { DeltakerNotatDto } from 'services/notat/dto';

export const initialFormState: DialogFormState = Object.freeze({
  loggtekst: '',
  tekst: '',
  tidspunkt: DateTime.now(),
});

export const initialFormStateInvalid: DialogFormStateInvalid = Object.freeze({
  notatTypeId: false,
  loggtekst: false,
  tekst: false,
  tidspunkt: false,
});

export const createPostData = (
  deltakerId: number,
  form: DialogFormState
): DeltakerNotatDto => {
  return {
    deltakerId: deltakerId,
    notatTypeId: form.notatTypeId || 0,
    tidspunkt: buildDate(form.tidspunkt),
    loggtekst: form.loggtekst,
    tekst: form.tekst,
  };
};

export const buildDate = (datetime: DateTime | null) => {
  return datetime != null
    ? datetime
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // dato lagres som tidspunkt 00:00
        .toISO({ includeOffset: false })
    : undefined;
};
