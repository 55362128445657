export type FetchStatus = {
  loading: boolean;
  loadingComplete?: boolean;
  errorMessage?: string;
};

export type SendStatus = {
  loading: boolean;
  loadingComplete?: boolean;
  success?: boolean;
  errorMessage?: string;
};

export const initialFetchStatus: FetchStatus = {
  loading: false,
};
export const initialSendStatus: SendStatus = {
  loading: false,
};
export const loading: FetchStatus = { ...initialFetchStatus, loading: true };

export const loadingComplete: FetchStatus = {
  ...initialFetchStatus,
  loadingComplete: true,
};

export const rejectedStatus = (errorMessage?: string) => {
  return {
    ...loadingComplete,
    errorMessage,
  };
};
