import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus } from 'features/utils';
import { SystemtabellerMetaDto } from 'services/sys/dto';

import { SysMetaState } from './interface';
import { fetchSystemTableMetaData } from './thunks';

export const initialState: SysMetaState = {
  status: initialFetchStatus,
  systemtableMetaData: [],
};

const slice = createSlice({
  name: 'sysMeta',
  initialState,
  reducers: {
    reset: () => initialState,
    updateSelectedMetaTable: (
      state,
      { payload }: PayloadAction<SystemtabellerMetaDto['tabellnavn']>
    ) => {
      state.valgtSystemTableMeta = state.systemtableMetaData.find(
        (metaTabell) => metaTabell.tabellnavn === payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemTableMetaData.fulfilled, (state, { payload }) => {
        state.systemtableMetaData = payload;
        state.status = { loading: false, loadingComplete: true };
      })
      .addCase(fetchSystemTableMetaData.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(
        fetchSystemTableMetaData.rejected,
        (state, { error: { message } }) => {
          state.systemtableMetaData = [];
          state.status = {
            loading: false,
            errorMessage: message,
            loadingComplete: true,
          };
        }
      );
  },
});

export const { reset, updateSelectedMetaTable } = slice.actions;

export default slice.reducer;
