import { DialogTypesEnum } from 'features/deltaker/state/infoDemaskert/interface';
import { HenvendelseDeltakerDto } from 'services/henvendelser/dto';

export type HenvendelselserSelector = {
  loading: boolean;
  errorMessage?: string;
  availableHenvendelseTyper?: HenvendelseDeltakerDto[];
  deltakerHasBeenDemaskert: boolean;
  deltakerId?: number;
  typeToOpenOnSuccess: DialogTypesEnum;
  kanHenvises?: boolean;
  hasAccessToManuellHenvisning: boolean;
  shouldRefetch: boolean;
};

export enum SpecialHenvendelsetypeEnum {
  NY_FRITEKSTBREV = 'NY-FRITEKSTBREV',
  OPPFØLGINGSPUNKT = 'OPPFØLGINGSPUNKT',
  MANUELL_HENVISNING = 'MANUELL-HENVISNING',
  NOTAT = 'NOTAT',
}

export type SpecialHenvendelsetype =
  | SpecialHenvendelsetypeEnum.NY_FRITEKSTBREV
  | SpecialHenvendelsetypeEnum.OPPFØLGINGSPUNKT
  | SpecialHenvendelsetypeEnum.MANUELL_HENVISNING
  | SpecialHenvendelsetypeEnum.NOTAT;
