import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { screenReaderOnly } from 'utils/styles';

import { AcceptableRowValues, Data, HeadCell, Order } from './interfaces';

interface Props {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data<AcceptableRowValues>
  ) => void;
  order: Order;
  orderBy: AcceptableRowValues;
  headCells: HeadCell<AcceptableRowValues>[];
  sortable: boolean;
}

const EnhancedTableHead: React.FC<Props> = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  sortable,
}: Props) => {
  // TODO: SCREENIT-1978 sortering må legges til
  // const createSortHandler = (property: keyof Data<AcceptableRowValues>) => (
  //   event: React.MouseEvent<unknown>
  // ) => {
  //   onRequestSort(event, property);
  // };

  const label = (headCell: HeadCell<AcceptableRowValues>) => {
    return headCell.hidden ? (
      <Box sx={screenReaderOnly}>{headCell.label}</Box>
    ) : (
      headCell.label
    );
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                // onClick={createSortHandler(headCell.id)}
              >
                {label(headCell)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={screenReaderOnly}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              label(headCell)
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
