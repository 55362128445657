import { createSlice } from '@reduxjs/toolkit';
import {
  ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH,
  ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH,
  ADMINISTRASJON_LANDINGSIDE,
  ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH,
  BRUKER_PATH,
  BRUKERE_PATH,
  DOKUMENTER_PATH,
  EKSTERNT_PERSONSOK_PATH,
  ENHETER_PATH,
  HELSEPERSONELL_LANDINGSIDE,
  HELSEPERSONELL_ROLLER,
  HENDELSEKONTROLL_HENDELSELOGG_PATH,
  HENDELSEKONTROLL_KVITTERING_PATH,
  HENDELSEKONTROLL_LANDINGSIDE_PATH,
  HENDELSEKONTROLL_LOGGKONTROLL_MELDING_PATH,
  HENDELSEKONTROLL_LOGGKONTROLL_PATH,
  INVITASJON_OG_INKLUSJON_INKLUSJON_PATH,
  INVITASJON_OG_INKLUSJON_KVITTERING_PATH,
  INVITASJON_OG_INKLUSJON_PATH,
  INVITASJON_OG_INKLUSJON_REDIGER_PATH,
  KAPASITET_PATH,
  OPPFOLGING,
  OPPRETT_ENHET_PATH,
  OPPRETT_HELSEPERSONELL_PATH,
  PERSON_PATH,
  PERSONOPPSLAG_PATH,
  PROFIL_PATH,
  REDIGER_ENHET_PATH,
  REDIGER_HELSEPERSONELL_PATH,
  SENTERTILKNYTNING_PATH,
  SENTRALADMIN_EKSTERNE_MELDINGER,
  SENTRALADMIN_HELSEMELDINGER,
  SENTRALADMIN_LOGISITKKPARTNER,
  SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH,
  SENTRALADMINSOK_UTSENDINGER_PATH,
  SENTRALADMINSØK_DELTAKER_PATH,
  SENTRALADMINSØK_INTERVALL_PATH,
  SENTRALADMINSØK_ROUTE,
  SENTRALADMINSØK_SENTER_PATH,
  SKOPØR_INFORMASJON,
  SKOPØR_RESERVASJON,
  SKOPØR_RESERVERTE,
  SYSTEMINFORMASJON_PATH,
  SYSTEMTABELLER_PATH,
  UTSENDINGSPAUSE_OPPRETT_PATH,
  UTSENDINGSPAUSE_PAGE_PATH,
  UTSENDINGSPAUSE_REDIGER_PATH,
  UTSENDINGTYPE_PATH,
} from 'app/routes/routeConstants';

import { AllowedRoutesState, RoutePathAccess } from './interface';
import { updateAllowedRoutesWithNewEnhet } from './utils';

export const initialRoutePathAccess: RoutePathAccess[] = [
  { path: '/', exact: true, userEnhetAccessToPath: [] },
  { path: `/${PROFIL_PATH}`, exact: true, userEnhetAccessToPath: [] },
  { path: `/${BRUKERE_PATH}`, exact: true, userEnhetAccessToPath: [] },
  { path: `/${BRUKER_PATH}`, exact: true, userEnhetAccessToPath: [] },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}/${SKOPØR_INFORMASJON}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}/${SKOPØR_RESERVASJON}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}/${SKOPØR_RESERVERTE}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}/${HELSEPERSONELL_ROLLER}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}/${OPPRETT_HELSEPERSONELL_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HELSEPERSONELL_LANDINGSIDE}/${REDIGER_HELSEPERSONELL_PATH}`,
    exact: false,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${ADMINISTRASJON_LANDINGSIDE}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${ADMINISTRASJON_LANDINGSIDE}/${ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${ADMINISTRASJON_LANDINGSIDE}/${ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${ADMINISTRASJON_LANDINGSIDE}/${ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SENTERTILKNYTNING_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  { path: `/${PERSONOPPSLAG_PATH}`, exact: true, userEnhetAccessToPath: [] },
  { path: `/${PERSON_PATH}`, exact: false, userEnhetAccessToPath: [] },
  { path: `/${ENHETER_PATH}`, exact: false, userEnhetAccessToPath: [] },
  {
    path: `/${ENHETER_PATH}/${OPPRETT_ENHET_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${ENHETER_PATH}/${REDIGER_ENHET_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  { path: `/${KAPASITET_PATH}`, exact: true, userEnhetAccessToPath: [] },
  { path: `/${OPPFOLGING}`, exact: true, userEnhetAccessToPath: [] },
  {
    path: `/${INVITASJON_OG_INKLUSJON_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HENDELSEKONTROLL_LANDINGSIDE_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HENDELSEKONTROLL_HENDELSELOGG_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HENDELSEKONTROLL_LOGGKONTROLL_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HENDELSEKONTROLL_LOGGKONTROLL_MELDING_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${HENDELSEKONTROLL_KVITTERING_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  { path: `/${SENTRALADMINSØK_ROUTE}`, exact: true, userEnhetAccessToPath: [] },
  {
    path: `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_SENTER_PATH}/${SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_DELTAKER_PATH}/${SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_DELTAKER_PATH}/${SENTRALADMINSOK_UTSENDINGER_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_INTERVALL_PATH}/${SENTRALADMINSOK_UTSENDINGER_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SENTRALADMIN_HELSEMELDINGER}`,
    exact: false,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SENTRALADMIN_EKSTERNE_MELDINGER}`,
    exact: false,
    userEnhetAccessToPath: [],
  },

  {
    path: `/${SENTRALADMIN_LOGISITKKPARTNER}`,
    exact: false,
    userEnhetAccessToPath: [],
  },
  { path: `/${SYSTEMTABELLER_PATH}`, exact: true, userEnhetAccessToPath: [] },
  {
    path: `/${INVITASJON_OG_INKLUSJON_PATH}/${INVITASJON_OG_INKLUSJON_REDIGER_PATH}`,
    exact: false,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${INVITASJON_OG_INKLUSJON_PATH}/${INVITASJON_OG_INKLUSJON_INKLUSJON_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${INVITASJON_OG_INKLUSJON_PATH}/${INVITASJON_OG_INKLUSJON_KVITTERING_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${EKSTERNT_PERSONSOK_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${DOKUMENTER_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${UTSENDINGTYPE_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${SYSTEMINFORMASJON_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${UTSENDINGSPAUSE_PAGE_PATH}`,
    exact: false,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${UTSENDINGSPAUSE_PAGE_PATH}/${UTSENDINGSPAUSE_REDIGER_PATH}`,
    exact: false,
    userEnhetAccessToPath: [],
  },
  {
    path: `/${UTSENDINGSPAUSE_PAGE_PATH}/${UTSENDINGSPAUSE_OPPRETT_PATH}`,
    exact: true,
    userEnhetAccessToPath: [],
  },
];

export const initialState: AllowedRoutesState = initialRoutePathAccess;

export const slice = createSlice({
  name: 'allowedRoutes',
  initialState,
  reducers: {
    reset: () => initialState,
    updateAllowedRoutes: (state, { payload: enhetsrettigheter }) => {
      return [...updateAllowedRoutesWithNewEnhet(state, enhetsrettigheter)];
    },
  },
});

export const { reset, updateAllowedRoutes } = slice.actions;

export default slice.reducer;
