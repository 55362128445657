import { RootState } from 'app/rootReducer';
import { FetchStatus } from 'features/utils';
import { EnhetDto } from 'services/enheter/dto';

import { BrukerAdminEnheterState } from './interface';
import { enheterAdapter } from './slice';

const brukeradminEnheterNormalizedSelectors = enheterAdapter.getSelectors<RootState>(
  (state) => state.currentBruker.brukeradminEnheter
);

export const getBrukeradminEnhetById = (
  state: RootState,
  id: number
): EnhetDto | undefined =>
  brukeradminEnheterNormalizedSelectors.selectById(state, id);

export const getBrukeradminEnheter = (state: RootState): EnhetDto[] =>
  brukeradminEnheterNormalizedSelectors.selectAll(state);

export const getIsSingleEnhetAdmin = (state: RootState): boolean =>
  getBrukeradminEnheter(state).length === 1;

export const getBrukeradminEnhetStatus = (
  state: BrukerAdminEnheterState
): FetchStatus => state.status;

const selectors = {
  getBrukeradminEnhetById,
  getBrukeradminEnheter,
  getIsSingleEnhetAdmin,
  getBrukeradminEnhetStatus,
};

export default selectors;
