import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost, httpPut } from 'utils/http';

import { BrukerDto } from './dto';
import {
  BrukerService,
  CreateBruker,
  GenerateBrukernavn,
  GetAktivRettighetEnheter,
  GetBrukerByAdvancedSearch,
  GetByBrukerForHendelseLogg,
  GetBrukerByFødselsnummer,
  GetBrukerById,
  GetBrukerInfoByBrukernavn,
  GetBrukerWithoutRollerByFødselsnummer,
  GetEnheterForBrukeradmin,
  UpdateBruker,
  ValidateBrukernavn,
} from './interface';

const basePath = 'brukere';

export const getBrukerById: GetBrukerById = async (
  id,
  detailParams?,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${id}`, detailParams);
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av bruker id ${id}: ${error.message}`
    );
  });
};

export const getBrukerByFødselsnummer: GetBrukerByFødselsnummer = async (
  data,
  detailParams?
) => {
  const fullPath = buildPath(`${basePath}/fodselsnr`, detailParams);
  return httpPost(fullPath, data).catch((error: FetchResponseError) => {
    if (error.statuscode === 404) {
      return null;
    }
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av bruker på fødselsnummer ${data.fødselsnr}: ${error.message}`
    );
  });
};

export const getBrukerWithoutRollerByFødselsnummer: GetBrukerWithoutRollerByFødselsnummer =
  async (data) => {
    const fullPath = buildPath(`${basePath}/fodselsnr/uten-roller`);
    return httpPost(fullPath, data).catch((error: FetchResponseError) => {
      if (error.statuscode === 404) {
        return null;
      }
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved henting av bruker på fødselsnummer ${data.fødselsnr}: ${error.message}`
      );
    });
  };

export const getBrukerByAdvancedSearch: GetBrukerByAdvancedSearch = async (
  searchParams,
  detailParams?,
  signal?
): Promise<BrukerDto[]> => {
  const fullPath = buildPath(`${basePath}/avansert-sok`, {
    ...searchParams,
    ...detailParams,
  });

  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      'En feil oppsto ved søk på brukere'
    );
  });
};

export const createBruker: CreateBruker = async (bruker) => {
  const fullPath = buildPath(basePath);
  return httpPost(fullPath, bruker).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved lagring av bruker: ${error.message}`
    );
  });
};

export const updateBruker: UpdateBruker = async (bruker, detailParams?) => {
  const fullPath = buildPath(`${basePath}/${bruker.id}`, detailParams);
  return httpPut(fullPath, bruker).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved lagring av bruker id ${bruker.id}: ${error.message}`
    );
  });
};

export const generateBrukernavn: GenerateBrukernavn = async (
  fornavn,
  etternavn,
  signal
) => {
  const fullPath = buildPath(`${basePath}/brukernavn`, { fornavn, etternavn });
  return httpGet(fullPath, signal)
    .then((response) => response.brukernavn)
    .catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved generering av brukernavn for ${fornavn} ${etternavn}: ${error.message}`
      );
    });
};

export const validateBrukernavn: ValidateBrukernavn = async (
  brukernavn,
  signal
) => {
  const fullPath = buildPath(
    `${basePath}/brukernavn/${brukernavn}/tillatt-og-tilgjengelig`
  );
  return httpGet(fullPath, signal)
    .then((response) => response.status)
    .catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved validering av brukernavn ${brukernavn}: ${error.message}`
      );
    });
};

export const getEnheterForBrukeradmin: GetEnheterForBrukeradmin = async (
  brukerId,
  signal?
) => {
  const fullPath = buildPath(
    `${basePath}/${brukerId}/brukeradministrator-rettighet/enheter`
  );
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av enheter for brukeradministrator med id ${brukerId}: ${error.message}`
    );
  });
};

export const getByBrukerForHendelseLogg: GetByBrukerForHendelseLogg = async (
  signal?
) => {
  const fullPath = buildPath(`${basePath}/hendelse-logg`);
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av brukere: ${error.message}`
    );
  });
};

export const getAktivRettighetEnheter: GetAktivRettighetEnheter = async (
  brukerId,
  detailParams?,
  signal?
) => {
  const fullPath = buildPath(
    `${basePath}/${brukerId}/aktiv-rettighet/enheter`,
    detailParams
  );
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av aktive enheter til bruker med id ${brukerId}: ${error.message}`
    );
  });
};

export const getBrukerInfoByBrukernavn: GetBrukerInfoByBrukernavn = async (
  brukernavn,
  signal
) => {
  if (!brukernavn) throw new Error('Ingen brukernavn oppgitt');
  const fullPath = buildPath(`${basePath}/brukernavn/${brukernavn}`);
  try {
    return await httpGet(fullPath, signal);
  } catch (err) {
    const error = err as FetchResponseError;
    if (error.statuscode === 404) {
      error.message = `${brukernavn} er ikke et gyldig brukernavn`;
    }
    throw error;
  }
};

export default {
  getBrukerById,
  getBrukerByFødselsnummer,
  getBrukerWithoutRollerByFødselsnummer,
  getBrukerByAdvancedSearch,
  createBruker,
  updateBruker,
  generateBrukernavn,
  validateBrukernavn,
  getEnheterForBrukeradmin,
  getByBrukerForHendelseLogg,
  getAktivRettighetEnheter,
  getBrukerInfoByBrukernavn,
} as BrukerService;
