import { Button, IconButton } from '@mui/material';
import { ScrIcon } from 'components';
import { redirectToLogout } from 'utils/redirect';

import { Size } from '../interface';

type Props = {
  size: Size;
  onPopoverOpen?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ) => void;
  onPopoverClose?: VoidFunction;
};

const height = 36;

const LogOutButton: React.FC<Props> = ({
  size,
  onPopoverOpen,
  onPopoverClose,
}: Props) => {
  const handleLogOut = () => {
    sessionStorage.clear();
    redirectToLogout();
  };

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    onPopoverOpen && onPopoverOpen(event, 'Logg ut');
  };

  const handlePopoverClose = (): void => {
    onPopoverClose && onPopoverClose();
  };

  const ariaLabel = 'logg ut';

  const logoutIcon = <ScrIcon iconId={69} size="medium" />;

  return size === Size.Small ? (
    <IconButton
      color="primary"
      onClick={handleLogOut}
      aria-label={ariaLabel}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 1,
        borderColor: 'primary.main',
        borderRadius: '10%',
        height,
        '&:hover': { bgcolor: 'scr.blue50' },
      }}>
      {logoutIcon}
    </IconButton>
  ) : (
    <Button
      color="primary"
      variant="outlined"
      size="large"
      startIcon={logoutIcon}
      onClick={handleLogOut}
      aria-label={ariaLabel}
      sx={{
        height,
        width: 126,
        '&:hover': { bgcolor: 'scr.blue50' },
      }}>
      Logg ut
    </Button>
  );
};

export default LogOutButton;
