import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpDelete, httpGet, httpPut } from 'utils/http';

import {
  DeleteDeltakeroppfølging,
  GetOppfølgingById,
  GetOppfølginger,
  OppfølgingFilter,
  OppfølgingFilterParams,
  OppfølgingService,
  PutDeltakeroppfølging,
} from './interface';

const basePath = 'oppfolginger';

const convertDateToStringInFilterParams = (
  params?: OppfølgingFilter
): OppfølgingFilterParams | undefined => {
  return params
    ? {
        ...params,
        fristDatoFraOgMed: params.fristDatoFraOgMed?.toISODate() || undefined,
        fristDatoTilOgMed: params.fristDatoTilOgMed?.toISODate() || undefined,
      }
    : undefined;
};

const getOppfølginger: GetOppfølginger = async (filterParams, signal) => {
  const fullPath = buildPath(
    `${basePath}`,
    convertDateToStringInFilterParams(filterParams)
  );

  try {
    return await httpGet(`${fullPath}&hvemogtidspunkt=detaljer`, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av oppfølginger feilet ${(error as FetchResponseError).message}`
    );
  }
};

const getOppfølgingById: GetOppfølgingById = async (id, signal) => {
  const fullPath = buildPath(`${basePath}/${id}`);

  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av oppfølging med id ${id} feilet  ${
        (error as FetchResponseError).message
      }`
    );
  }
};

const putDeltakeroppfølging: PutDeltakeroppfølging = async (
  oppfølgingId,
  data
) => {
  const path = buildPath(`${basePath}/${oppfølgingId}`);
  return await httpPut(path, data).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Det oppsto en feil ved oppdatering av oppfølging: ${error.message}`
    );
  });
};
const deleteDeltakeroppfølging: DeleteDeltakeroppfølging = async (ids) => {
  const path = buildPath(`${basePath}`);
  return await httpDelete(path, { ids }).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Det oppsto en feil ved sletting av oppfølginger: ${error.message}`
    );
  });
};
export default {
  getOppfølginger,
  getOppfølgingById,
  putDeltakeroppfølging,
  deleteDeltakeroppfølging,
} as OppfølgingService;
