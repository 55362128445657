import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Dokument } from 'services/dokument/dto';

import * as thunks from './dokumentThunk';
import { DokumentState } from './interface';

export const dokumentAdapter = createEntityAdapter<Dokument, number>({
  selectId: ({ id }) => id,
});

export const initialState: DokumentState = {
  loading: false,
  errorMessage: null,
  dokumenter: dokumentAdapter.getInitialState(),
};

const slice = createSlice({
  name: 'dokument',
  initialState,
  reducers: {
    reset: (state) => {
      state.dokumenter = dokumentAdapter.getInitialState();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchDokumenter.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        thunks.fetchDokumenter.fulfilled,
        (state, { payload: dokumenter }) => {
          state.loading = false;
          dokumentAdapter.setAll(state.dokumenter, dokumenter);
        }
      )
      .addCase(
        thunks.fetchDokumenter.rejected,
        (state, { error: { message } }) => {
          return {
            ...initialState,
            errorMessage: message || null,
          };
        }
      );
  },
});

export const { reset } = slice.actions;
export default slice.reducer;
