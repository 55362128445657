import { Fab, Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ScrIcon } from 'components';

import { Size } from '../interface';

export type Props = {
  size: Size;
  onClick: (setOpen: boolean) => void;
};

const SizeToggler: React.FC<Props> = ({ size, onClick }: Props) => {
  const theme = useTheme();
  const isOpen = size !== Size.Small;

  const rightDistance = (): SxProps<Theme> | undefined => {
    switch (size) {
      case Size.Small:
        return {
          position: 'sticky',
          marginLeft: 3,
        };
      case Size.Medium:
        return {
          position: 'absolute',
        };
      default:
        return {
          position: 'absolute',
        };
    }
  };

  const handleSizeToggling = () => onClick(!isOpen);

  return (
    <Fab
      sx={{
        ...rightDistance(),
        right: 8,
        width: 32,
        height: 36,
        top: 10,
        backgroundColor: 'common.white',
        boxShadow: 'none',
        zIndex: 9999,
      }}
      size="small"
      aria-label={`gjør navbar ${isOpen ? 'liten' : 'stor'}`}
      onClick={handleSizeToggling}>
      <ScrIcon
        iconId={isOpen ? 71 : 70}
        size="large"
        color={theme.palette.scr.grey400}
        borderColor={theme.palette.scr.grey400}
      />
    </Fab>
  );
};

export default SizeToggler;
