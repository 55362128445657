import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ScrIcon } from 'components';
import { resetInfoDemaskert } from 'features/deltaker/state/infoDemaskert/slice';
import { demaskDeltaker } from 'features/deltaker/state/infoDemaskert/thunks';

import Props from './interface';
import { DemaskerButtonSelector, demaskerButtonSelector } from './selectors';

const DemaskerButton: React.FC<Props> = ({
  fullwidth = false,
  snuten = false,
}: Props) => {
  const dispatch = useAppDispatch();

  const { loading, isDemaskert, deltakerId } =
    useAppSelector<DemaskerButtonSelector>(demaskerButtonSelector);

  const handleClick = () => {
    isDemaskert
      ? dispatch(resetInfoDemaskert())
      : deltakerId && dispatch(demaskDeltaker({ deltakerId }));
  };

  return (
    <>
      <LoadingButton
        onClick={handleClick}
        startIcon={<ScrIcon iconId={isDemaskert ? 57 : 58} size="small" />}
        loading={loading}
        loadingPosition="start"
        variant="outlined"
        sx={{
          height: 36,
          width: fullwidth ? '100%' : { xs: 208, md: 125, xl: 176 },
          mt: snuten ? { md: -1, xl: -1 } : { md: 1, xl: 0 },
        }}>
        {isDemaskert ? 'masker' : 'demasker'}
      </LoadingButton>
    </>
  );
};

export default DemaskerButton;
