import {
  BRUKERE_PATH,
  BRUKER_PATH,
  DOKUMENTER_PATH,
  EKSTERNT_PERSONSOK_PATH,
  ENHETER_PATH,
  HELSEPERSONELL_LANDINGSIDE,
  HENDELSEKONTROLL_LANDINGSIDE_PATH,
  INVITASJON_OG_INKLUSJON_PATH,
  KAPASITET_PATH,
  OPPFOLGING,
  PERSONOPPSLAG_PATH,
  SENTERTILKNYTNING_PATH,
  SENTRALADMINSØK_ROUTE,
  SENTRALADMIN_HELSEMELDINGER,
  SYSTEMTABELLER_PATH,
  UTSENDINGSPAUSE_PAGE_PATH,
  UTSENDINGTYPE_PATH, ADMINISTRASJON_LANDINGSIDE,
} from 'app/routes/routeConstants';
import ScrIconProps from 'components/ScrIcon/interface';

type SidebarLink = {
  path: string;
  name: string;
  iconId: ScrIconProps['iconId'];
};

export const sidebarCategories = new Map<string, SidebarLink[]>()
  .set('personer', [
    {
      name: 'Personoppslag',
      path: `/${PERSONOPPSLAG_PATH}`,
      iconId: 23,
    },
    {
      name: 'Oppfølging',
      path: `/${OPPFOLGING}`,
      iconId: 56,
    },
    {
      name: 'Eksternt personsøk',
      path: `/${EKSTERNT_PERSONSOK_PATH}`,
      iconId: 55,
    },
  ])
  .set('brukeradmin', [
    {
      path: `/${BRUKERE_PATH}`,
      name: 'Brukere',
      iconId: 20,
    },
    {
      path: `/${BRUKER_PATH}`,
      name: 'Ny bruker',
      iconId: 21,
    },
  ])
  .set('senteradmin', [
    {
      name: 'Enheter',
      path: `/${ENHETER_PATH}`,
      iconId: 24,
    },
    {
      name: 'Sentere',
      path: `/${KAPASITET_PATH}`,
      iconId: 27,
    },
    {
      name: 'Sentertilknytning',
      path: `/${SENTERTILKNYTNING_PATH}`,
      iconId: 26,
    },
    {
      name: 'Helsepersonell',
      path: `/${HELSEPERSONELL_LANDINGSIDE}`,
      iconId: 22,
    },
    {
      name: 'Administrasjon',
      path: `/${ADMINISTRASJON_LANDINGSIDE}`,
      iconId: 29,
    },
  ])
  .set('sentraladmin', [
    {
      name: 'Inklusjon & utsending',
      path: `/${INVITASJON_OG_INKLUSJON_PATH}`,
      iconId: 28,
    },
    {
      name: 'Utsendingspauser',
      path: `/${UTSENDINGSPAUSE_PAGE_PATH}`,
      iconId: 56,
    },
    {
      name: 'Dokumenter',
      path: `/${DOKUMENTER_PATH}`,
      iconId: 43,
    },
    {
      name: 'Utsendinger',
      path: `/${UTSENDINGTYPE_PATH}`,
      iconId: 43,
    },
    {
      name: 'Søk',
      path: `/${SENTRALADMINSØK_ROUTE}`,
      iconId: 72,
    },
    {
      name: 'Eksterne meldinger',
      path: `/${SENTRALADMIN_HELSEMELDINGER}`,
      iconId: 33,
    },
    {
      name: 'Systemtabeller',
      path: `/${SYSTEMTABELLER_PATH}`,
      iconId: 30,
    },
    {
      name: 'Hendelsekontroll',
      path: `/${HENDELSEKONTROLL_LANDINGSIDE_PATH}`,
      iconId: 80,
    },
  ]);
