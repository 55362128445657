import { buildPath, httpGet } from '../../utils/http';
import {
  addMessageToExsistingFetchError,
  FetchResponseError,
} from 'utils/errors/customError';
import { Dashboard, GetDashboardData, GetDashboardProfil } from './interface';

const getDashboardData: GetDashboardData = async (signal?) => {
  const fullPath = buildPath(`dashboard`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av dashboard data feilet`
    );
  }
};

const getDashboardProfil: GetDashboardProfil = async (signal?) => {
  const fullPath = buildPath(`dashboard/profil`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av dashboard profil feilet`
    );
  }
};

export default {
  getDashboardData,
  getDashboardProfil,
} as Dashboard;
