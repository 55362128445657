import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost, httpPut } from 'utils/http';

import { SkopørKvalifikasjonskravDto, SkopørReservasjonDto } from './dto';
import {
  GetAllSkopørerWithoutKvalifikasjonskrav,
  GetSkopørHistorikk,
  GetSkopørReservasjonStatus,
  GetSkopørReservasjoner,
  PostSkopørReservasjon,
  PutUtsettKvalifikasjonskrav,
  SkopørService,
} from './interface';

const basePath = 'skoporer';

const getSkopørReservasjoner: GetSkopørReservasjoner = async () => {
  const fullPath = buildPath(`${basePath}/reservasjoner`);

  return httpGet(fullPath).catch((error: FetchResponseError) => {
    const message = `En feil oppsto ved henting av reservasjonsstatus for skopør: ${error.message}`;
    throw addMessageToExsistingFetchError(error, message);
  });
};

const getSkopørHistorikk: GetSkopørHistorikk = async (skopørId, signal?) => {
  const fullPath = buildPath(`${basePath}/reservasjon/historikk/${skopørId}`);

  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    const message = `En feil oppsto ved henting av reservasjonshistorikk for skopør: ${error.message}`;
    throw addMessageToExsistingFetchError(error, message);
  });
};

const getAllSkopørerWithoutKvalifikasjonskrav: GetAllSkopørerWithoutKvalifikasjonskrav =
  async (signal?) => {
    const fullPath = buildPath(`${basePath}/uten-kvalifikasjonskrav`);

    return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
      throw new Error(`En feil oppsto i henting av skopører: ${error.message}`);
    });
  };

const putUtsettKvalifikasjonskrav: PutUtsettKvalifikasjonskrav = async (
  skoporId,
  enhetId
) => {
  const fullPath = buildPath(
    `${basePath}/${skoporId}/${enhetId}/utsett-kvalifikasjonskrav`
  );
  try {
    return await httpPut(fullPath, null);
  } catch (error) {
    const err = error as Error;
    throw new Error(
      `En feil oppstod ved utsettelse av kvalifikasjonskrav, ${err.message}`
    );
  }
};

const postSkopørKvalifikasjonskrav = async (
  skopørKvalifikasjonskravList: SkopørKvalifikasjonskravDto[]
) => {
  const fullPath = buildPath(`${basePath}/kvalifikasjonskrav`);

  return httpPost(fullPath, skopørKvalifikasjonskravList).catch(
    (error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved registrering av kvalifikasjonskrav for skopør. Detaljer: ${error.message}`
      );
    }
  );
};

const getSkopørReservasjonStatus: GetSkopørReservasjonStatus = async (
  hprNr: string
) => {
  const fullPath = buildPath(`${basePath}/reservasjon/status/${hprNr}`);
  try {
    return await httpGet(fullPath);
  } catch (error) {
    if ((error as FetchResponseError).statuscode === 404) {
      return {
        skopørId: null,
        reservert: false,
        fraOgMedTidspunkt: undefined,
      };
    }
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `En feil oppsto ved henting av reservasjonsstatus for skopør: ${
        (error as FetchResponseError).message
      }`
    );
  }
};

const postSkopørReservasjon: PostSkopørReservasjon = async (
  dto: SkopørReservasjonDto
) => {
  const fullPath = buildPath(`${basePath}/reserver`);

  return httpPost(fullPath, dto).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved ${
        dto.reservert
          ? 'reservasjon av skopør'
          : 'oppheving av reservasjon for skopør'
      }: ${error.message}`
    );
  });
};

export default {
  getAllSkopørerWithoutKvalifikasjonskrav,
  putUtsettKvalifikasjonskrav,
  postSkopørKvalifikasjonskrav,
  getSkopørReservasjonStatus,
  postSkopørReservasjon,
  getSkopørReservasjoner,
  getSkopørHistorikk,
} as SkopørService;
