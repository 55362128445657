import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { EnhetDto } from 'services/enheter/dto';

export type EnhetListDialogProps = {
  enheter: EnhetDto[];
  open: boolean;
  onClose: (enhetId?: number) => void;
};

const EnhetListDialog: React.FC<EnhetListDialogProps> = ({
  enheter,
  onClose,
  open,
}: EnhetListDialogProps) => {
  const handleClose = () => onClose();

  const handleListItemClick = (id: number) => onClose(id);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="velg-enhet-fra-liste"
      open={open}>
      <DialogTitle id="velg-enhet-fra-liste">Velg enhet</DialogTitle>
      <DialogContent>
        <List>
          {enheter.map((enhet) => (
            <ListItem
              button
              onClick={() => handleListItemClick(enhet.id)}
              dense
              disableGutters
              key={enhet.id}>
              <ListItemText primary={enhet.navn} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default EnhetListDialog;
