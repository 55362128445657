import { Box } from '@mui/material';
import ScrIcon from 'components/ScrIcon/ScrIcon';
import React from 'react';

import { StatusProps } from './interface';

const Status: React.FC<StatusProps> = ({
  type,
  overrideText,
  showText = true,
  showIndicator = true,
  textXs,
  iconId,
}) => {
  const blankStatus = !type && !overrideText;

  const getStatusColorByType = () => {
    switch (type) {
      case 'aktiv':
        return 'scr.green900';
      case 'inaktiv':
      case 'historisk':
        return 'scr.red900';
      default:
        return undefined;
    }
  };

  const renderCircleOrIcon = () =>
    iconId ? (
      <Box sx={{ color: 'scr.green900' }}>
        <ScrIcon iconId={iconId} size="small" color="inherit" />
      </Box>
    ) : (
      <Box
        sx={{
          width: 16,
          height: 16,
          mr: 1,
          bgcolor: getStatusColorByType(),
          borderRadius: '50%',
          border: type ? 1 : 2,
          borderColor: type ? 'scr.grey600' : 'scr.green900',
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
        component="span"
      />
    );

  return (
    <Box sx={{ display: 'inline-block' }}>
      {showIndicator && renderCircleOrIcon()}
      {showText && !blankStatus && (
        <Box
          component="span"
          sx={{ textTransform: 'capitalize', fontSize: 14, ...textXs }}>
          {overrideText || type}
        </Box>
      )}
    </Box>
  );
};

export default Status;
