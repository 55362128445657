import { TextField } from '@mui/material';
import { SystemProps } from '@mui/system';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isValidFødselsnummer } from 'utils';
import React from 'react';

import {
  FØDSELSNUMMER_LENGTH,
  MAX_SCREENITID_LENGTH,
  TrackProps,
  ValueType,
} from './interface';

type Props = {
  id?: string;
  restrictToType?: ValueType;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  trackValue: (trackProps: TrackProps) => void;
  trackOn: { blur?: boolean; change?: boolean };
  errorText?: string;
  showValidStatus?: boolean;
  allowDNumber?: boolean;
  reset?: boolean;
  initialValue?: string;
  sx?: SystemProps;
  externalValidationOnblur?: (trackProps: TrackProps) => void;
  setIsEditingFødselsNr?: Dispatch<SetStateAction<boolean>>;
};

export const FødselsnummerDeltakerIdField: React.FC<Props> = ({
  id,
  label,
  restrictToType,
  required,
  disabled,
  trackValue,
  trackOn,
  showValidStatus,
  errorText,
  allowDNumber,
  reset,
  initialValue,
  sx,
  externalValidationOnblur,
  setIsEditingFødselsNr,
}: Props) => {
  const [fieldId, setFieldId] = useState(id);
  const [fieldLabel, setFieldLabel] = useState(label);
  const [maxLength, setMaxLength] = useState<number>();
  const [isInitialFieldValueSet, setIsInitialFieldValueSet] = useState(false);
  const [fieldValue, setFieldValue] = useState('');
  const [error, setError] = useState(errorText);

  //Følgende effect er for å sette initiell feltverdi om det kommer inn som parameter. Etter det er satt èn gang så skal den ikke settes igjen. Dette fordi overgangen fra brukersøk --> brukerredigering må ha fødselsnummer inn i dette feltet.
  useEffect(() => {
    if (initialValue && !isInitialFieldValueSet) {
      setFieldValue(initialValue);
      trackValue({ value: initialValue, valid: true });
      setIsInitialFieldValueSet(true);
    }
  }, [initialValue, isInitialFieldValueSet, trackValue]);

  useEffect(() => {
    if (!fieldId) {
      if (restrictToType === ValueType.Fødselsnummer)
        setFieldId('fødselsnummer-field');
      else if (restrictToType === ValueType.DeltakerID)
        setFieldId('screenitid-field');
      else setFieldId('fødselsnummer-screenitid-field');
    }
  }, [fieldId, setFieldId, restrictToType]);

  useEffect(() => {
    if (!fieldLabel) {
      if (restrictToType === ValueType.Fødselsnummer)
        setFieldLabel('Fødselsnummer');
      else if (restrictToType === ValueType.DeltakerID)
        setFieldLabel('Intern-ID');
      else setFieldLabel('Fødselsnummer/ID');
    }
  }, [fieldLabel, setFieldLabel, restrictToType]);

  useEffect(() => {
    if (!maxLength)
      setMaxLength(
        restrictToType === ValueType.DeltakerID
          ? MAX_SCREENITID_LENGTH
          : FØDSELSNUMMER_LENGTH
      );
  }, [maxLength, setMaxLength, restrictToType]);

  useEffect(() => {
    if (reset) {
      setFieldValue('');
      setError(undefined);
    }
  }, [reset]);

  useEffect(() => {
    if (errorText !== error) {
      setError(errorText);
    }
  }, [error, errorText]);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;

    if (/\D/.test(value)) return;

    setFieldValue(value);
    trackOn.change && handleValue(value);
    setIsEditingFødselsNr && setIsEditingFødselsNr(true);
  };

  const handleBlur = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    trackOn.blur && handleValue();
    const valid = isValidFødselsnummer(value, !!allowDNumber);

    externalValidationOnblur &&
      externalValidationOnblur({
        value,
        valid: valid,
        valueType: ValueType.Fødselsnummer,
      });
  };

  const handleInvalid = (e: React.FormEvent) => {
    e.preventDefault();

    if (restrictToType === ValueType.Fødselsnummer) {
      setError('Skriv inn fødselsnummer');
    }
  };

  const validateFødselsnummer = (fødselsnummer: string) => {
    const valid = isValidFødselsnummer(fødselsnummer, !!allowDNumber);

    valid ? setError(undefined) : setError('Ugyldig fødselsnummer');

    trackValue({
      value: fødselsnummer,
      valid: valid,
      valueType: ValueType.Fødselsnummer,
    });
  };

  const handleValue = (value?: string) => {
    const valueToHandle = value !== undefined ? value : fieldValue;
    switch (restrictToType) {
      case ValueType.DeltakerID:
        trackValue({
          value: valueToHandle,
          valid: true,
          valueType: ValueType.DeltakerID,
        });
        break;
      case ValueType.Fødselsnummer:
        validateFødselsnummer(valueToHandle);
        break;

      default:
        const numberOfChars = valueToHandle.length;
        const invalidNumberOfChars =
          numberOfChars > MAX_SCREENITID_LENGTH &&
          numberOfChars < FØDSELSNUMMER_LENGTH;

        if (invalidNumberOfChars) {
          setError('Ugyldig antall tegn');
          trackValue({ value: valueToHandle, valid: false });
        } else if (numberOfChars === FØDSELSNUMMER_LENGTH) {
          validateFødselsnummer(valueToHandle);
        } else {
          setError(undefined);
          trackValue({
            value: valueToHandle,
            valid: true,
            valueType: ValueType.DeltakerID,
          });
        }
        break;
    }
  };
  return (
    <TextField
      id={fieldId}
      label={fieldLabel}
      value={fieldValue}
      fullWidth
      required={required}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      onInvalid={handleInvalid}
      error={showValidStatus ? !!error : false}
      helperText={showValidStatus ? error : null}
      data-testid="fnr-did-input"
      InputProps={{
        inputProps: {
          maxLength: maxLength,
        },
      }}
      sx={sx}
    />
  );
};

export default FødselsnummerDeltakerIdField;
