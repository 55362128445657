import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpDelete, httpGet, httpPost, httpPut } from 'utils/http';

import {
  DeleteUtsending,
  DeleteUtsendingTypeDokumentType,
  GetAllUtsendingByUtsendingType,
  GetUtsendingById,
  GetUtsendingTypeById,
  GetUtsendingTypeDokumentTypeById,
  GetUtsendingTypeDokumentTypeByUtsendingTypeId,
  PostUtsending,
  PostUtsendingTypeDokumentType,
  PutUtsending,
  PutUtsendingTypeDokumentType,
  UtsendingService,
} from './interface';

const basePath = 'utsending';

export const getAllUtsendingByUtsendingType: GetAllUtsendingByUtsendingType =
  async (utsendingTypeId, historikk, signal?) => {
    const fullPath = buildPath(
      `${basePath}/utsending-type/${utsendingTypeId}`,
      {
        historikk,
        hvemogtidspunkt: 'detaljer',
      }
    );

    return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i henting av alle utsending-dokumenter: ${error.message}`
      );
    });
  };

export const getUtsendingById: GetUtsendingById = async (
  id,
  params?,
  signal?
) => {
  const fullPath = buildPath(`dokument/${id}`, params);

  return httpGet(fullPath, signal).catch((error) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto i henting av utsending id ${id}: ${error.message}`
    );
  });
};

export const putUtsending: PutUtsending = async (id, updateDto) => {
  const fullPath = buildPath(`${basePath}/${id}`);

  return httpPut(fullPath, updateDto).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved oppdatering av utsending id ${id}, følgende feilmelding ble returnert: ${error.message}`
    );
  });
};
export const postUtsending: PostUtsending = async (createDto) => {
  const fullPath = buildPath(basePath);

  return httpPost(fullPath, createDto).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved opprettelse av utsending, følgende feilmelding ble returnert: ${error.message}`
    );
  });
};
export const getUtsendingTypeById: GetUtsendingTypeById = async (
  id,
  params?,
  signal?
) => {
  const fullPath = buildPath(`utsendingstyper/${id}`, params);

  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto i henting av utsendingType id ${id}, følgende feilmelding ble returnert: ${error.message}`
    );
  });
};

export const getUtsendingTypeDokumentTypeById: GetUtsendingTypeDokumentTypeById =
  async (id, params?, signal?) => {
    const fullPath = buildPath(`utsending-type-dokument-type/${id}`, params);

    return httpGet(fullPath, signal).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i henting av utsendingTypeDokumentType id ${id}, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };

export const getUtsendingTypeDokumentTypeByUtsendingTypeId: GetUtsendingTypeDokumentTypeByUtsendingTypeId =
  async (utsendingTypeId, params?, signal?) => {
    const fullPath = buildPath(
      `utsending-type-dokument-type/utsending-type/${utsendingTypeId}`,
      params
    );

    return httpGet(fullPath, signal).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i henting av utsendingTypeDokumentType for utsendingTypeId ${utsendingTypeId}, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };

export const putUtsendingTypeDokumentType: PutUtsendingTypeDokumentType =
  async (id, model) => {
    const fullPath = buildPath(`utsending-type-dokument-type/${id}`);
    return httpPut(fullPath, model).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i oppdatering av utsendingTypeDokumentType med id ${id}, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };
export const postUtsendingTypeDokumentType: PostUtsendingTypeDokumentType =
  async (model) => {
    const fullPath = buildPath(`utsending-type-dokument-type`);
    return httpPost(fullPath, model).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i opprettelse av utsendingTypeDokumentType, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };
export const deleteUtsendingTypeDokumentType: DeleteUtsendingTypeDokumentType =
  async (id) => {
    const fullPath = buildPath(`utsending-type-dokument-type/${id}`);
    return httpDelete(fullPath).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i sletting av utsendingTypeDokumentType, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };
export const deleteUtsending: DeleteUtsending =
  async (id) => {
    const fullPath = buildPath(`utsending/${id}`);
    return httpDelete(fullPath).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i sletting av utsending, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };
export default {
  getUtsendingTypeById,
  getUtsendingById,
  putUtsending,
  getAllUtsendingByUtsendingType,
  getUtsendingTypeDokumentTypeById,
  getUtsendingTypeDokumentTypeByUtsendingTypeId,
  putUtsendingTypeDokumentType,
  postUtsendingTypeDokumentType,
  deleteUtsendingTypeDokumentType,
  postUtsending,
  deleteUtsending,
} as UtsendingService;
