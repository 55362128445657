import { Button, Grid } from '@mui/material';
import HenvendelseDialog from 'features/deltaker/details/components/Henvendelser/HenvendelseDialog';
import { useSysProperties } from 'features/sys';
import { useState } from 'react';

const HenvendelseDialogExample = () => {
  const henvendelseTyper = useSysProperties('henvendelseTyper');
  const henvendelseTypeIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14];
  const [henvendelseTypeId, setHenvendelseTypeId] = useState<number>();

  return henvendelseTyper.isLoaded ? (
    <Grid container direction="column">
      {henvendelseTypeIds.map((id) => (
        <Grid key={id} item>
          <Button
            variant="outlined"
            onClick={() => {
              setHenvendelseTypeId(id);
            }}>
            {henvendelseTyper.mapBeskrivelse(id)}
          </Button>
        </Grid>
      ))}
      <HenvendelseDialog
        open={!!henvendelseTypeId}
        henvendelseType="NY"
        henvendelseTypeId={henvendelseTypeId}
        close={() => setHenvendelseTypeId(undefined)}
      />
    </Grid>
  ) : null;
};

export default HenvendelseDialogExample;
