import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { AppDispatch } from 'app/store';
import { addError } from 'features/errorHandling/errorHandlingSlice';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { addNotification } from 'features/notification/notificationSlice';
import loggedInnBrukerService from 'services/loggedInnBruker';
import { LoggedInBruker } from 'services/loggedInnBruker/dto';
import { TraceableError } from 'utils/errors/customError';

import { fetchCurrentBrukerEnheter } from '../enheter/thunks';

export const fetchCurrentBruker = createAsyncThunk<
  LoggedInBruker,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  'currentBruker/fetchCurrentBruker',
  // eslint-disable-next-line no-shadow-restricted-names
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const response = await loggedInnBrukerService.getLoggedInBruker();
      if (response.authority === 'ROLE_ACTIVE' && response.id) {
        await dispatch(fetchCurrentBrukerEnheter(response.id));
        return response;
      } else {
        return response;
      }
    } catch (err) {
      const e = err as TraceableError;
      dispatchError(dispatch, e, addError.type);

      dispatch(
        addNotification({
          message: { text: 'En feil skjedde ved henting av innlogget bruker' },
          variant: 'error',
        })
      );

      return rejectWithValue(e.toString());
    }
  }
);
