import { createSlice } from '@reduxjs/toolkit';
import {
  SendStatus,
  initialSendStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';

import { HenvendelseSendingState } from './interface';
import * as thunk from './thunks';

export const initialState: HenvendelseSendingState = {
  postHenvendelseStatus: initialSendStatus,
  postFritekstbrevStatus: initialSendStatus,
};

export const slice = createSlice({
  name: 'sending',
  initialState,
  reducers: {
    resetHenvendelseSending: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.postHenvendelse.pending, (state) => {
        state.postHenvendelseStatus = loading;
      })
      .addCase(thunk.postHenvendelse.fulfilled, (state) => {
        state.postHenvendelseStatus = {
          ...loadingComplete,
          success: true,
        } as SendStatus;
      })
      .addCase(thunk.postHenvendelse.rejected, (state, { payload }) => {
        state.postHenvendelseStatus = rejectedStatus(payload);
      })
      .addCase(thunk.postFritekstbrev.pending, (state) => {
        state.postFritekstbrevStatus = loading;
      })
      .addCase(thunk.postFritekstbrev.fulfilled, (state) => {
        state.postFritekstbrevStatus = {
          ...loadingComplete,
          success: true,
        } as SendStatus;
      })
      .addCase(thunk.postFritekstbrev.rejected, (state, { payload }) => {
        state.postFritekstbrevStatus = rejectedStatus(payload);
      });
  },
});

export const { resetHenvendelseSending } = slice.actions;

export default slice.reducer;
