import { Divider, Table, TableContainer, TablePagination } from '@mui/material';
import { ScrWrapper } from 'components';
import { useState } from 'react';
import { scrollBar } from 'utils/styles';

import EnhancedTableBody from './EnhancedTableBody';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableHoverTekst from './EnhancedTableHoverTekst';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import {
  AcceptableRowValues,
  Data,
  HeadCell,
  Order,
  RowProps,
} from './interfaces';

type EnhancedTableProps = {
  id?: string;
  toolbarProps?: ToolbarProps;
  tableProps?: TableProps;
  dense?: boolean;
  onRowClick?: (index: number) => void;
  pagination?: boolean;
  rowsPerPage?: number;
  sortable?: boolean;
  border?: boolean;
  maxHeight?: number;
};

type ToolbarProps = {
  title?: string;
  FilterMenuComponent?: React.ReactElement<{
    closeFilter?: VoidFunction;
    buttons?: boolean;
  }> | null;
  filterDescription?: string;
  filterTableSelection?: (selected: string[]) => void;
  categoriesSelected?: number;
};

type TableProps = {
  headCells?: HeadCell<AcceptableRowValues>[];
  displayDataRows?: RowProps[];
  rawDataRows?: Data<string | number>[];
};

const EnhancedTable = ({
  id,
  toolbarProps,
  tableProps,
  dense = false,
  onRowClick,
  pagination = true,
  rowsPerPage = 10,
  sortable = true,
  border = true,
  maxHeight,
}: EnhancedTableProps) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data<AcceptableRowValues>>('');
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hovertekst, setHovertekst] = useState<string>('');

  const getTableLength = (): number => tableProps?.displayDataRows?.length || 0;

  const shouldHavePagination = (): boolean =>
    pagination && getTableLength() > rowsPerPage;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data<AcceptableRowValues>
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    hovertekst: string
  ) => {
    setAnchorEl(event.currentTarget);
    setHovertekst(hovertekst);
  };

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setPage(page);
  };

  const showToolbar = !!toolbarProps;

  return (
    <ScrWrapper id={id}>
      {showToolbar && (
        <EnhancedTableToolbar
          title={toolbarProps?.title}
          filterMenuComponentProps={{
            FilterMenuComponent: toolbarProps?.FilterMenuComponent,
            categoriesSelected: toolbarProps?.categoriesSelected,
            buttons: true,
            filterDescription: toolbarProps?.filterDescription,
          }}
        />
      )}
      <Divider />
      {tableProps && (
        <TableContainer sx={{ maxHeight, ...scrollBar }}>
          <Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            {tableProps.headCells && tableProps.headCells.length && (
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headCells={tableProps.headCells}
                sortable={sortable}
              />
            )}
            {tableProps?.displayDataRows && (
              <EnhancedTableBody
                rows={tableProps?.displayDataRows}
                order={order}
                orderBy={orderBy}
                page={page}
                dense={dense}
                onRowClick={onRowClick}
                rowsPerPage={rowsPerPage}
                pagination={shouldHavePagination()}
                border={border}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            )}
          </Table>
        </TableContainer>
      )}
      {shouldHavePagination() && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={getTableLength()}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
        />
      )}
      <EnhancedTableHoverTekst anchorEl={anchorEl} text={hovertekst} />
    </ScrWrapper>
  );
};

export default EnhancedTable;
