import { nanoid } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { stripObjectForNonValues } from 'utils/common';

import { SELECTED_ENHET_ID } from './constants';
import { FetchResponseError, TraceableError } from './errors/customError';
import { ErrorNamesEnum } from './errors/interface';
import { Params } from './interface';

export const httpGet = <T = any>(
  path: string,
  signal?: AbortSignal
): Promise<T> => {
  const withoutXSRF = headersWithoutXSRF();
  return http(
    new Request(path, {
      method: 'GET',
      headers: withoutXSRF,
    }),
    signal
  );
};

export const httpPost = <T = any>(path: string, data?: any): Promise<T> => {
  const headers = headersWithXSRF();
  return http(
    new Request(path, {
      method: 'POST',
      body: data && JSON.stringify(data),
      headers,
    })
  );
};

export const httpPut = <T = any>(path: string, data: any): Promise<T> => {
  const headers = headersWithXSRF();

  return http(
    new Request(path, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers,
    })
  );
};

export const httpDelete = <T = any>(path: string, data?: any): Promise<T> => {
  const headers = headersWithXSRF();

  return http(
    new Request(path, {
      method: 'DELETE',
      headers,
      body: data && JSON.stringify(data),
    })
  );
};

export const headersWithoutXSRF = () => {
  const headers = new Headers();
  const selectedEnhetId = sessionStorage.getItem(SELECTED_ENHET_ID);
  headers.append(
    'SELECTED-ENHET',
    selectedEnhetId ? selectedEnhetId.toString() : ''
  );
  return headers;
};

export const headersWithXSRF = () => {
  const headers = new Headers();
  const csrfToken = Cookies.get('XSRF-TOKEN');
  csrfToken && headers.append('X-XSRF-TOKEN', csrfToken);
  headers.append('Content-Type', 'application/json');

  const selectedEnhetId = sessionStorage.getItem(SELECTED_ENHET_ID);
  headers.append(
    'SELECTED-ENHET',
    selectedEnhetId ? selectedEnhetId.toString() : ''
  );
  return headers;
};

export const http = async (request: RequestInfo, signal?: AbortSignal) => {
  try {
    const response = await fetch(request, { signal });
    if (response.status === 401) {
      process.env.VITE_APP_API
        ? window.open(`${process.env.VITE_APP_URL_LOGIN}`, '_blank')
          // eslint-disable-next-line no-console
        : console.error('Unable to find login url from .env');
      throw new FetchResponseError(
        'Du er ikke innlogget, prøv å logg inn på nytt.',
        nanoid(),
        response.status,
        Date.now().toString()
      );
    }
    if (response.ok) {
      if (response.status === 204) {
        return new Promise((resolve) => {
          resolve(null);
        });
      }
      if (response.status === 201) {
        return new Promise((resolve) => {
          resolve(null);
        });
      }
      try {
        return await response.json();
      } catch (e) {
        const err = e as Error;
        throw new TraceableError(
          err.message,
          nanoid(),
          Date.now.toString(),
          err.stack
        );
      }
    } else {
      try {
        const errorResponse = await response.json();
        throw new FetchResponseError(
          errorResponse.message || errorResponse.detail,
          nanoid(),
          errorResponse.status,
          Date.now().toString(),
          undefined,
          errorResponse.instance
        );
      } catch (error) {
        const err = error as Error | FetchResponseError;
        if (err.name === 'SyntaxError') {
          throw new FetchResponseError(
            response.statusText,
            nanoid(),
            response.status,
            Date.now().toString(),
            undefined,
            '',
            false
          );
        }

        throw err;
      }
    }
  } catch (e) {
    const err = e as Error;
    if (err.name === ErrorNamesEnum.AbortError) {
      throw new FetchResponseError(
        'Aborted',
        nanoid(),
        999,
        Date.now().toString(),
        undefined,
        '',
        true
      );
    } else {
      throw err;
    }
  }
};

export const buildPath = (path: string, params?: Params): string => {
  const endpointPath = `${process.env.VITE_APP_API}/${path}`;
  if (!params) return endpointPath;

  const searchParams = new URLSearchParams();
  Object.entries(stripObjectForNonValues(params)).forEach(([key, value]) => {
    if (params[key] && Array.isArray(params[key])) {
      const arr = params[key] as number[] | string[];
      arr?.forEach((value: string | number) => {
        searchParams.append(key, String(value));
      });
    } else {
      searchParams.append(key, String(value));
    }
  });

  const paramsExists = searchParams.toString().length > 0;
  return paramsExists
    ? `${endpointPath}?${searchParams.toString()}`
    : endpointPath;
};

export const getFilenamefromHeaders = (res: Response): string => {
  try {
    const contentDisposition = res.headers.get('Content-Disposition');
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
  return '';
};
