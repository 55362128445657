import { BrukernavnStatus } from 'features/bruker/interface';

import { convertFødselsnummerInput as checkFødselsnummer } from './commonHelperFunctions';
import { SearchType } from './interface';

export const EMAIL_PATTERN = '^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$';

export const getInvalidBrukernavnsStatus = (
  brukernavn: string
): BrukernavnStatus | undefined => {
  if (brukernavn.length < 4) {
    return BrukernavnStatus.FOR_KORT;
  } else if (brukernavn.length > 8) {
    return BrukernavnStatus.FOR_LANGT;
  } else if (brukernavn.match(/[^a-zæøå0-9]/)) {
    return BrukernavnStatus.UGYLDIG_TEGN;
  }
};

export const brukernavnStatusAsText = (
  brukernavnStatus: BrukernavnStatus
): string => {
  switch (brukernavnStatus) {
    case BrukernavnStatus.FOR_KORT:
      return 'for kort';
    case BrukernavnStatus.FOR_LANGT:
      return 'for langt';
    case BrukernavnStatus.UGYLDIG_TEGN:
      return 'ugyldige tegn';
    case BrukernavnStatus.ER_I_BRUK:
      return 'allerede i bruk';
    default:
      return '';
  }
};

export const isValidFødselsnummer = (
  fødselsnummer: string,
  allowDNummer: boolean = false
): boolean => {
  try {
    return checkFødselsnummer(fødselsnummer, allowDNummer);
  } catch (error) {
    return false;
  }
};

export const stripObjectForNonValues = (values: object): object => {
  let newObj = {};
  Object.entries(values).forEach(([key, value]) => {
    if (value !== '' && value !== null && value !== undefined)
      newObj = {
        ...newObj,
        [key]: value,
      };
  });
  return newObj;
};

export const objectHasAnyValues = (
  object: any,
  excludeKeys?: string[]
): boolean => {
  return Object.keys(object).some((key) =>
    excludeKeys?.includes(key)
      ? false
      : object[key] &&
        (typeof object[key] === 'number' || (object[key] as string).length > 0)
  );
};

export const getSearchType = (
  object: any,
  lookupKey: string
): SearchType | undefined => {
  if (object[lookupKey] === undefined) {
    throw new Error(
      `${lookupKey} finnes ikke i objektet: ${JSON.stringify(object)}`
    );
  }

  if (object[lookupKey].length > 0) {
    return SearchType.DirectLookup;
  } else if (
    objectHasAnyValues(object, [lookupKey, 'snutenFnr', 'meldingId', 'rekvisisjonsnummer'])
  ) {
    return SearchType.ByCriterias;
  } else if (object['snutenFnr']?.length > 0) {
    return SearchType.DirectLookupSnuten;
  } else if (object['meldingId']?.length > 0) {
    return SearchType.DirectLookupMeldingId;
  } else if (object['rekvisisjonsnummer']?.length > 0) {
    return SearchType.DirectLookupRekvNr;
  }
  return undefined;
};

export const joinNoBlanks = (
  strings: (string | undefined | null)[],
  separator: string = ' '
): string => {
  return strings.filter(Boolean).join(separator);
};
export function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export type DemaskertTextType =
  | 'NAME'
  | 'ADDRESS'
  | 'LONG_NOTE'
  | 'SHORT_NOTE'
  | 'FNR';

export function getDemaskertSymbols(type?: DemaskertTextType): string {
  switch (type) {
    case 'FNR':
      return '***********';
    case 'ADDRESS':
      return '*************** **********';
    case 'NAME':
      return '****** ******** **********';
    case 'SHORT_NOTE':
      return '**** **** ******* ** ****** ******* *** *** * ****** ******** ****';
    case 'LONG_NOTE':
      return '**** **** ******* ** ****** ******* *** *** * ****** ******** **** **** ******* ** ****** ******* *** *** * ****** ******** ****';
    default:
      return '**** **** **** ****';
  }
}

export const isBlank = (value: string | undefined | null): boolean => {
  return !value || !value.trim();
}
