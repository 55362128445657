import { ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/rootReducer';
import { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

import { Size } from '../interface';
import { sidebarCategories } from '../SidebarLinks';
import ListItemLink from './ListItemLink';

type Props = {
  size: Size;
  selectedEnhetId?: number;
  onPopoverOpen?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ) => void;
  onPopoverClose?: VoidFunction;
};

const Links: React.FC<Props> = ({
  size,
  selectedEnhetId,
  onPopoverOpen,
  onPopoverClose,
}: Props) => {
  const location = useLocation();

  const { allowedRoutes } = useAppSelector(
    (state: RootState) => state.currentBruker
  );
  if (!allowedRoutes[0].userEnhetAccessToPath.length || !selectedEnhetId)
    return (
      <>
        {[0, 1, 2].map((i) => (
          <ListItem key={`navskeleton-${i}`}>
            <Skeleton variant="circular" height="60" width="60" />
          </ListItem>
        ))}
      </>
    );

  const activeRoute = (routeName: string) => location.pathname === routeName;

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ): void => {
    onPopoverOpen && onPopoverOpen(event, content);
  };

  const handlePopoverClose = (): void => {
    onPopoverClose && onPopoverClose();
  };
  const routeMap = new Map<string, React.JSX.Element[]>();
  sidebarCategories.forEach((sidebarCategoryLinks, key) => {
    sidebarCategoryLinks.forEach((sidebarCategoryLinks) => {
      const allowedLink = allowedRoutes.find((allowedRoute) => {
        const userEnhetHasAccessToPath =
          allowedRoute.path === sidebarCategoryLinks.path &&
          allowedRoute.userEnhetAccessToPath.includes(selectedEnhetId!);
        return userEnhetHasAccessToPath;
      });

      const displayLinkInSidebar = !!allowedLink;

      if (displayLinkInSidebar) {
        const { name, path, iconId } = sidebarCategoryLinks;
        const newLink = (
          <ListItemLink
            size={size}
            key={name}
            name={name}
            to={path}
            selected={activeRoute(path)}
            iconId={iconId}
            onPopoverOpen={handlePopoverOpen}
            onPopoverClose={handlePopoverClose}
          />
        );
        routeMap.has(key)
          ? routeMap.get(key)!.push(newLink)
          : routeMap.set(key, [newLink]);
      }
    });
  });

  const links: ReactElement[] = [];
  routeMap.forEach((routeLinks, categoryKey) => {
    if (size !== Size.Large) links.push(...routeLinks);
    else {
      links.push(
        <ListItem key={categoryKey} aria-label={`menykategori ${categoryKey}`}>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="subtitle1"
                color="primary"
                sx={{ fontWeight: 'bold', pl: 1.25 }}>
                {categoryKey.toUpperCase()}
              </Typography>
            }
          />
        </ListItem>
      );
      links.push(...routeLinks);
    }
  });

  return <>{links}</>;
};

export default Links;
