import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ActionEnum, ClickAction } from 'features/notification';
import { dismissNotification } from 'features/notification/notificationSlice';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { getCurrentError, getCurrentNotification } from './selectors';

const Notifier: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notification = useAppSelector(getCurrentNotification);
  const error = useAppSelector(getCurrentError);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  useEffect(() => {
    if (notification) {
      setOpenSnackbar(true);
    }
    if (error) {
      setOpenErrorDialog(true);
    }
  }, [notification, error]);

  if (!notification && !error) {
    return null;
  }

  const closeSnackbar = (
    event: Event | React.SyntheticEvent<any>,
    reason: SnackbarCloseReason
  ) => {
    if (notification && reason === 'timeout') {
      setOpenSnackbar(false);
      dispatch(dismissNotification({ key: notification.options.key }));
    }
  };

  const closeError = () => {
    if (error) {
      setOpenErrorDialog(false);
      dispatch(dismissNotification({ key: error.options.key }));
    }
  };

  const handleClickAction = (action: ClickAction) => {
    switch (action.action) {
      case ActionEnum.Redirect:
        action.value && navigate(`/${action.value}`);
        break;
      case ActionEnum.Refresh:
        window.location.reload();
    }
  };

  const handleSnackbarAction = () => {
    if (notification && notification.action) {
      closeError();
      handleClickAction(notification.action.clickAction);
    }
  };

  const handleErrorAction = () => {
    if (error && error.action) {
      closeError();
      handleClickAction(error.action.clickAction);
    }
  };

  return (
    <>
      {notification && (
        <Snackbar
          key={notification.options.key}
          open={openSnackbar}
          autoHideDuration={notification.options.timeout}
          onClose={closeSnackbar}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
          <Alert icon={false} severity={notification.options.variant}>
            {notification.message.title && (
              <Box>{notification.message.title}</Box>
            )}
            <Box>
              {notification.message.text}
              {notification.action && (
                <Button onClick={handleSnackbarAction} sx={{ ml: 4 }}>
                  {notification.action.text}
                </Button>
              )}
            </Box>
          </Alert>
        </Snackbar>
      )}
      {error && (
        <Dialog open={openErrorDialog} onClose={closeError}>
          <DialogTitle>{error.message.title ?? 'Noe gikk galt'}</DialogTitle>
          <DialogContent>
            <Alert severity="error">{error.message.text}</Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeError}>Lukk</Button>
            {error.action && (
              <Button onClick={handleErrorAction}>{error.action.text}</Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Notifier;
