import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  initialFetchStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';

import { HenvendelseTyperState } from './interface';
import * as thunk from './thunks';

export const initialState: HenvendelseTyperState = {
  status: initialFetchStatus,
  shouldRefetchHenvendelseTyper: false,
};

export const slice = createSlice({
  name: 'henvendelsesTyper',
  initialState,
  reducers: {
    resetHenvendelsesTyper: () => initialState,
    setShouldRefetchHenvendelseTyper: (
      state,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        shouldRefetchHenvendelseTyper: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        thunk.fetchDeltakerHenvendelsetyper.fulfilled,
        (state, { payload }) => {
          state.data = payload;
          state.status = loadingComplete;
        }
      )
      .addCase(thunk.fetchDeltakerHenvendelsetyper.pending, (state) => {
        state.status = loading;
      })
      .addCase(
        thunk.fetchDeltakerHenvendelsetyper.rejected,
        (state, { error: { message } }) => {
          state.status = rejectedStatus(message);
          state.data = initialState.data;
        }
      );
  },
});

export const { resetHenvendelsesTyper, setShouldRefetchHenvendelseTyper } = slice.actions;

export default slice.reducer;
