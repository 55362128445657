import { Box } from '@mui/material';
import { logo_200, logo_50, logo_70, logo_90 } from 'images';
import { NavLink } from 'react-router-dom';

import { Size } from '../interface';

export type Props = {
  size: Size;
  onPopoverOpen?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onPopoverClose?: VoidFunction;
};

const Logo: React.FC<Props> = ({
  size,
  onPopoverOpen,
  onPopoverClose,
}: Props) => {
  const getLogoData = (): { logo: string; height: number } => {
    switch (size) {
      case Size.Large:
        return { logo: logo_90, height: 100 };
      case Size.Medium:
        return { logo: logo_70, height: 70 };
      case Size.Small:
        return { logo: logo_50, height: 50 };
      default:
        return { logo: logo_200, height: 90 };
    }
  };

  const logoData = getLogoData();

  return (
    <NavLink to="/" onMouseEnter={onPopoverOpen} onMouseLeave={onPopoverClose}>
      <Box
        component="img"
        src={logoData.logo}
        alt={'logo for Tarmscreeningprogrammet'}
        sx={{ height: logoData.height }}
      />
    </NavLink>
  );
};

export default Logo;
