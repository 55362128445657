import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  initialFetchStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';
import { DeltakerDetaljerDto } from 'services/deltaker/dto';

import { DetaljerState } from './interface';
import * as thunk from './thunks';

export const initialState: DetaljerState = {
  status: initialFetchStatus,
};

export const slice = createSlice({
  name: 'detaljer',
  initialState,
  reducers: {
    resetDetaljer: () => initialState,
    setDeltakerDetaljer: (
      state,
      payloadAction: PayloadAction<DeltakerDetaljerDto | null>
    ) => {
      const detaljer = payloadAction.payload;
      state.data = {
        ...(state.data as DeltakerDetaljerDto),
        ...detaljer,
        invitasjonsStopp: detaljer?.invitasjonsStopp,
        reservertMotLagring: detaljer?.reservertMotLagring,
        screeningMetode: detaljer?.screeningMetode || '',
        inklusjonsdato: detaljer?.inklusjonsdato || '',
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.fetchDeltaker.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = loadingComplete;
      })
      .addCase(thunk.fetchDeltaker.pending, (state) => {
        state.status = loading;
      })
      .addCase(
        thunk.fetchDeltaker.rejected,
        (state, { payload: errorMessage }) => {
          state.data = initialState.data;
          state.status = rejectedStatus(errorMessage);
        }
      );
  },
});

export const { resetDetaljer, setDeltakerDetaljer } = slice.actions;

export default slice.reducer;
