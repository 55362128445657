import { ManuellHenvisningDto } from 'services/henvisninger/dto';
import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpDelete, httpGet, httpPost, httpPut } from 'utils/http';

import {
  DeleteHenvisningssvarNotat,
  GetHenvisingstext,
  GetHenvisningKanHenvises,
  GetHenvisningManuellMetadata,
  GetHenvisningStatusHistorikk,
  GetHenvisningTyper,
  GetHenvisninger,
  GetHenvisningerPersondata,
  GetHenvisningssvar,
  GetHenvisningssvarNotat,
  HenvisningsService,
  PostManuellHenvisning,
  PutHenvisninger,
  PutHenvisningssvar,
} from './interface';

const basePath = 'henvisninger';

const getHenvisninger: GetHenvisninger = async (id, signal) => {
  const fullPath = buildPath(`${basePath}/${id}`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av henvisning med id ${id} feilet`
    );
  }
};

const getHenvisingstext: GetHenvisingstext = async (id, signal) => {
  const fullPath = buildPath(`${basePath}/${id}/henvisningstekst`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av henvisning med id ${id} feilet`
    );
  }
};

const getHenvisningStatusHistorikk: GetHenvisningStatusHistorikk = async (
  id,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${id}/statushistorikk`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av henvisning med id ${id} feilet`
    );
  }
};

const getHenvisningerPersondata: GetHenvisningerPersondata = async (
  id,
  signal
) => {
  const fullPath = buildPath(`${basePath}/${id}/persondata`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av persondata for henvisning med id ${id} feilet`
    );
  }
};

const getHenvisningTyper: GetHenvisningTyper = async (signal?) => {
  const fullPath = buildPath(`${basePath}/typer`);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av henvisningstyper feilet`
    );
  }
};

const getHenvisningKanHenvises: GetHenvisningKanHenvises = async (
  deltakerId,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${deltakerId}/kan-henvises-manuelt`);
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    if (error.statuscode === 403) {
      return { kanHenvises: false };
    }
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil med henting av kan henvises for deltaker: ${deltakerId}`
    );
  });
};

const getHenvisningManuellMetadata: GetHenvisningManuellMetadata = async (
  deltakerId,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${deltakerId}/manuell-henvisning`);
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil med henting av metadata for manuell henvisning for deltaker ${deltakerId}. Detaljer: ${
          (error as FetchResponseError).message
      }`
    );
  });
};

const postManuellHenvisning: PostManuellHenvisning = async (
  henvisning: ManuellHenvisningDto
) => {
  const path = buildPath(basePath);
  return httpPost(path, henvisning).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Klarte ikke lagre henvisning. Detaljer: ${
        (error as FetchResponseError).message
      }`
    );
  });
};

const getHenvisningssvar: GetHenvisningssvar = async (id) => {
  const path = buildPath(`${basePath}/svar/${id}`);
  return httpGet(path).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved henting av henvisningssvar: ${error.message}`
    );
  });
};

const getHenvisningssvarNotat: GetHenvisningssvarNotat = async (
  henvisningssvarId: number
) => {
  const path = buildPath(`${basePath}/svar/${henvisningssvarId}/notat`);

  return httpGet(path).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved henting av notat: ${error.message}`
    );
  });
};

const deleteHenvisningssvarNotat: DeleteHenvisningssvarNotat = async (id) => {
  const path = buildPath(`${basePath}/svar/${id}/notat`);
  return httpDelete(path).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved oppdatering av henvisningssvar: ${error.message}`
    );
  });
};

const putHenvisningssvar: PutHenvisningssvar = async (id, model) => {
  const path = buildPath(`${basePath}/svar/${id}`);
  return httpPut(path, model).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved oppdatering av henvisningssvar: ${error.message}`
    );
  });
};
const putHenvisninger: PutHenvisninger = async (id, model) => {
  const path = buildPath(`${basePath}/${id}`);
  return httpPut(path, model).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `Det oppsto en feil ved oppdatering av henvisningssvar: ${error.message}`
    );
  });
};

export default {
  getHenvisningTyper,
  getHenvisningerPersondata,
  getHenvisningStatusHistorikk,
  getHenvisningKanHenvises,
  getHenvisningManuellMetadata,
  getHenvisningssvarNotat,
  deleteHenvisningssvarNotat,
  postManuellHenvisning,
  getHenvisninger,
  getHenvisingstext,
  putHenvisningssvar,
  getHenvisningssvar,
  putHenvisninger,
} as HenvisningsService;
