import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet } from 'utils/http';

import {
  BrukerRolleService,
  GetBrukerRollerByBrukerAndEnhet,
} from './interface';

const basePath = 'bruker-rolle';

export const getBrukerRollerByBrukerAndEnhet: GetBrukerRollerByBrukerAndEnhet =
  async (brukerId, enhetId, detailParams?, signal?) => {
    const fullPath = buildPath(
      `${basePath}/brukere/${brukerId}/enheter/${enhetId}/roller/aktive-og-fremtidige`,
      detailParams
    );
    return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved henting av brukerroller for bruker med id ${brukerId} ved enhet med id ${enhetId}`
      );
    });
  };

export default {
  getBrukerRollerByBrukerAndEnhet,
} as BrukerRolleService;
