import {
  DateTimeValidationError,
  DateValidationError,
} from '@mui/x-date-pickers';
import { nanoid } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { TraceableError } from '../errors/customError';
import {
  FormatType,
  OutputType,
  PickerProps,
  STANDARD_DATE_FORMAT,
  STANDARD_DATE_TIME_FORMAT,
  STANDARD_DATE_TIME_FORMAT_INCLUDING_SECONDS,
} from './interface';

export const formatPickerToOutput = (
  { value: date, format: { outputType } }: PickerProps,
  invalidReturnValue: string = ''
): string => {
  if (date === null) {
    return '';
  }
  if (isValidDateTime(date)) {
    return formatToOutput(date, outputType);
  }
  return invalidReturnValue;
};

export const formatToOutput = (
  date: DateTime,
  outputType: OutputType
): string => {
  if (date.isValid) {
    switch (outputType) {
      case 'date':
        return date.toISODate()!;
      case 'datetime':
        return date.toISO({ includeOffset: false })!;
    }
  }
  return '';
};

export const fromMillisToISO = (
  date: number | null,
  outputType: OutputType
): string | undefined => {
  if (date) {
    switch (outputType) {
      case 'date':
        return DateTime.fromMillis(date).toISODate() || undefined;
      case 'datetime':
        return (
          DateTime.fromMillis(date).toISO({ includeOffset: false }) || undefined
        );
    }
  }
};

export const fromISOToMillis = (
  date: string | null | undefined
): number | null => {
  return date ? DateTime.fromISO(date).toMillis() : null;
};

export const fromMillisToDateTime = (date: number | null): DateTime | null => {
  return date ? DateTime.fromMillis(date) : null;
};

export const fromISOToDateTime = (date?: string) => {
  if (date === undefined) {
    return undefined;
  } else {
    return DateTime.fromISO(date);
  }
};

export const isValidDateTime = (object: unknown): boolean => {
  return DateTime.isDateTime(object) && object.isValid;
};

export const isToday = (date: DateTime): boolean => {
  return date.hasSame(DateTime.now(), 'day');
};

export const formatDateFromISO = (date?: string): string => {
  if (!date) {
    return '';
  }
  return formatFromIso(date, 'date');
};

export const formatDateTimeFromISO = (
  date?: string,
  includeSeconds: boolean = false
): string => {
  if (!date) {
    return '';
  }
  return formatFromIso(
    date,
    includeSeconds ? 'datetimeincludingseconds' : 'datetime'
  );
};

const formatFromIso = (date: string, format: FormatType): string => {
  const dateTime = DateTime.fromISO(date);

  if (dateTime.isValid) return makeHumanReadable(dateTime, format);

  throw new TraceableError(
    `Kunne ikke formattere ${date}: ${dateTime.invalidReason}`,
    nanoid(),
    DateTime.now().toMillis().toString()
  );
};

const makeHumanReadable = (dateTime: DateTime, format: FormatType): string => {
  switch (format) {
    case 'date':
      return dateTime.toFormat(STANDARD_DATE_FORMAT);
    case 'datetime':
      return dateTime.toFormat(STANDARD_DATE_TIME_FORMAT);
    case 'datetimeincludingseconds':
      return dateTime.toFormat(STANDARD_DATE_TIME_FORMAT_INCLUDING_SECONDS);
  }
};

export const formatFromMillis = (millis: number, format: FormatType) => {
  const dateTime = fromMillisToDateTime(millis);

  if (dateTime && dateTime.isValid) return makeHumanReadable(dateTime, format);

  throw new TraceableError(
    `Kunne ikke formattere ${millis}: til datetime ${
      dateTime && dateTime.invalidReason
    }`,
    nanoid(),
    DateTime.now().toMillis().toString()
  );
};

export const isSameDate = (dateTime1?: DateTime, dateTime2?: DateTime) => {
  if (dateTime1 === undefined && dateTime2 === undefined) return true;

  if (!dateTime1 || !dateTime2) return false;

  return (
    dateTime1.startOf('day').toMillis() === dateTime2.startOf('day').toMillis()
  );
};

export const isBefore = (
  dateTime1?: DateTime,
  dateTime2?: DateTime
): boolean => {
  if (!dateTime1 || !dateTime2) {
    return false;
  }

  return dateTime1 < dateTime2;
};

export const getNorwegianMonthName = (monthNum: number) => {
  switch (monthNum) {
    case 1:
      return 'januar';
    case 2:
      return 'februar';
    case 3:
      return 'mars';
    case 4:
      return 'april';
    case 5:
      return 'mai';
    case 6:
      return 'juni';
    case 7:
      return 'juli';
    case 8:
      return 'august';
    case 9:
      return 'september';
    case 10:
      return 'oktober';
    case 11:
      return 'november';
    case 12:
      return 'desember';

    default:
      return '';
  }
};
export const getNorwegianDayName = (weekDay: number) => {
  switch (weekDay) {
    case 1:
      return 'mandag';
    case 2:
      return 'tirsdag';
    case 3:
      return 'onsdag';
    case 4:
      return 'torsdag';
    case 5:
      return 'fredag';
    case 6:
      return 'lørdag';
    case 7:
      return 'søndag';
    default:
      return '';
  }
};

export const getDateError = (
  error: DateValidationError | DateTimeValidationError,
  dateTime = false
): string | undefined => {
  switch (error) {
    case 'disablePast':
      return `Kan ikke være tidligere enn dagens dato${
        dateTime ? ' og tidspunkt' : ''
      }`;
    case 'disableFuture':
      return `Kan ikke være senere enn dagens dato${
        dateTime ? ' og tidspunkt' : ''
      }`;
    case 'minDate':
      return 'For tidlig dato';
    case 'minTime':
      return 'For tidlig tidspunkt';
    case 'maxDate':
      return 'For sen dato';
    case 'maxTime':
      return 'For sent tidspunkt';
    case 'invalidDate':
      return 'Ugyldig dato';
    case null:
      return undefined;
    default:
      return error;
  }
};

export const getFirstDayOfYear = () => {
  return DateTime.now().startOf('year');
};
