import { CSSProperties } from 'react';
export const defaultDialogWidth = { width: 592 };
export const overlayDialogWidth = { width: 624 };

export const searchFormSpacing = { px: { xs: 4, md: 2, xl: 4 }, py: 2 };
export const searchDividerSpacing = { p: { xs: 1, lg: 0 } };

export const screenReaderOnly: CSSProperties = {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  width: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
};

export const scrollBar = {
  '&::-webkit-scrollbar': {
    width: 10,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'scr.grey300',
    borderRadius: 8,
  },
};
