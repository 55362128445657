import {
  EnhetScreeningmetodeUpdateResponse,
  GetScreeningsenterInformasjonForEnhetResponse,
} from 'features/senteradmin/kapasitet/components/screeningmetoder/interface';
import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost, httpPut } from 'utils/http';

import {
  EnhetDto,
  EnhetKoloskopiKapasitetDto,
  KapasitetForÅrDto,
  OverordnetEnhetDto,
  ScreeningmetodeVedEnhetDto,
  ScreeningsenterinformasjonDto,
} from './dto';
import {
  EnheterService,
  GetAktivBrukterrettigheterForEnhet,
  GetEnhet,
  GetEnhetOverordnet,
  GetEnheter,
  GetKoloskopikapasitet,
  GetRollerByEnhet,
  GetScreeningmetodeForEnhet,
  GetScreeningsenterInformasjon,
  GetScreeningsenterInformasjonForEnhet,
  PostEnhet,
  PutEnhet,
  PutScreeningmetoderOgOpptrapping,
} from './interface';

const basePath = 'enheter';

const getEnheter: GetEnheter = async (params?, signal?) => {
  const fullPath = buildPath(basePath, params);

  try {
    return await httpGet<EnhetDto[]>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      'Henting av enheter feilet'
    );
  }
};
const getEnhet: GetEnhet = async (enhetId, params?, signal?) => {
  const fullPath = buildPath(`${basePath}/${enhetId}`, params);

  try {
    return await httpGet<EnhetDto>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      'Henting av enheter feilet'
    );
  }
};
const postEnhet: PostEnhet = async (enhet) => {
  const fullPath = buildPath(basePath);

  try {
    return await httpPost<EnhetDto>(fullPath, enhet);
  } catch (error) {
    throw error;
  }
};
const putEnhet: PutEnhet = async (enhet) => {
  const fullPath = buildPath(`${basePath}/${enhet.id}`);

  try {
    return await httpPut<EnhetDto>(fullPath, enhet);
  } catch (error) {
    throw error;
  }
};
const getKoloskopikapasitet: GetKoloskopikapasitet = async (
  enhetId,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${enhetId}/koloskopikapasitet`);
  try {
    return await httpGet<EnhetKoloskopiKapasitetDto>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av koloskopikapasitet for enhetid ${enhetId} feilet`
    );
  }
};

const getScreeningsenterInformasjon: GetScreeningsenterInformasjon = async (
  signal?
) => {
  const fullPath = buildPath(`${basePath}/screeningsenterinformasjon`);

  try {
    return await httpGet<ScreeningsenterinformasjonDto[]>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av screeningsenterinformasjon feilet`
    );
  }
};

const getScreeningmetodeForEnhet: GetScreeningmetodeForEnhet = async (
  enhetId,
  signal?
) => {
  const fullPath = buildPath(
    `${basePath}/${enhetId}/screeningmetoder/aktive-og-fremtidige`
  );

  try {
    return await httpGet<ScreeningmetodeVedEnhetDto[]>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av screeningsenterinformasjon feilet`
    );
  }
};
const putScreeningmetoderOgOpptrapping: PutScreeningmetoderOgOpptrapping =
  async (enhetId, payload) => {
    const fullPath = buildPath(
      `${basePath}/${enhetId}/screeningmetoder-og-opptrapping`
    );

    try {
      return await httpPut<EnhetScreeningmetodeUpdateResponse>(
        fullPath,
        payload
      );
    } catch (error) {
      throw addMessageToExsistingFetchError(
        error as FetchResponseError,
        `oppdatering av screeningsenterinformasjon feilet`
      );
    }
  };
const getScreeningsenterInformasjonForEnhet: GetScreeningsenterInformasjonForEnhet =
  async (enhetId, signal?) => {
    const fullPath = buildPath(
      `${basePath}/${enhetId}/screeningsenterinformasjon`
    );

    try {
      return await httpGet<GetScreeningsenterInformasjonForEnhetResponse>(
        fullPath,
        signal
      );
    } catch (error) {
      throw addMessageToExsistingFetchError(
        error as FetchResponseError,
        `henting av screeningsenterinformasjon for enhet ${enhetId} feilet`
      );
    }
  };
const getEnhetOverordnet: GetEnhetOverordnet = async (signal?) => {
  const fullPath = buildPath(`${basePath}/overordnet`);

  try {
    return await httpGet<OverordnetEnhetDto[]>(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `Henting av screeningsenterinformasjon feilet`
    );
  }
};

const putKoloskopikapasitet = async (
  enhetId: EnhetDto['id'],
  newKapasitet: KapasitetForÅrDto[]
) => {
  const fullPath = buildPath(`${basePath}/${enhetId}/koloskopikapasitet`);

  return httpPut(fullPath, newKapasitet).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved oppdatering av kapasitet. Detaljer: ${error.message}`
    );
  });
};

export const getRollerByEnhet: GetRollerByEnhet = async (enhetId, signal?) => {
  const fullPath = buildPath(`${basePath}/${enhetId}/roller`);
  return httpGet(fullPath, signal).catch((error) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av roller på enhet med id ${enhetId}`
    );
  });
};

export const getAktivBrukterrettigheterForEnhet: GetAktivBrukterrettigheterForEnhet =
  async (ids, signal?) => {
    const { enhetId, brukerId } = ids;
    const fullPath = buildPath(
      `brukere/${brukerId}/enheter/${enhetId}/rettigheter/aktive`
    );
    return httpGet(fullPath, signal).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved henting av bruker ${brukerId} rettighet for enhet ${enhetId}: ${error.message}`
      );
    });
  };

export default {
  getEnheter,
  getEnhet,
  getKoloskopikapasitet,
  getScreeningsenterInformasjon,
  getScreeningmetodeForEnhet,
  putScreeningmetoderOgOpptrapping,
  getScreeningsenterInformasjonForEnhet,
  getEnhetOverordnet,
  postEnhet,
  putEnhet,
  getRollerByEnhet,
  getAktivBrukterrettigheterForEnhet,
  putKoloskopikapasitet,
} as EnheterService;
