import {
  BRUKERE_PATH,
  BRUKER_PATH,
  DOKUMENTER_PATH,
  EKSTERNT_PERSONSOK_PATH,
  ENHETER_PATH,
  HELSEPERSONELL_LANDINGSIDE,
  HELSEPERSONELL_ROLLER,
  HENDELSEKONTROLL_HENDELSELOGG_PATH,
  HENDELSEKONTROLL_KVITTERING_PATH,
  HENDELSEKONTROLL_LANDINGSIDE_PATH,
  HENDELSEKONTROLL_LOGGKONTROLL_MELDING_PATH,
  HENDELSEKONTROLL_LOGGKONTROLL_PATH,
  INVITASJON_OG_INKLUSJON_INKLUSJON_PATH,
  INVITASJON_OG_INKLUSJON_KVITTERING_PATH,
  INVITASJON_OG_INKLUSJON_PATH,
  INVITASJON_OG_INKLUSJON_REDIGER_PATH,
  KAPASITET_PATH,
  OPPFOLGING,
  OPPRETT_ENHET_PATH,
  OPPRETT_HELSEPERSONELL_PATH,
  PERSONOPPSLAG_PATH,
  PERSON_PATH,
  ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH,
  REDIGER_ENHET_PATH,
  REDIGER_HELSEPERSONELL_PATH,
  SENTERTILKNYTNING_PATH,
  SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH,
  SENTRALADMINSOK_UTSENDINGER_PATH,
  SENTRALADMINSØK_DELTAKER_PATH,
  SENTRALADMINSØK_INTERVALL_PATH,
  SENTRALADMINSØK_ROUTE,
  SENTRALADMINSØK_SENTER_PATH,
  SENTRALADMIN_EKSTERNE_MELDINGER,
  SENTRALADMIN_HELSEMELDINGER,
  SENTRALADMIN_LOGISITKKPARTNER,
  SKOPØR_INFORMASJON,
  SKOPØR_RESERVASJON,
  SKOPØR_RESERVERTE,
  SYSTEMINFORMASJON_PATH,
  SYSTEMTABELLER_PATH,
  UTSENDINGSPAUSE_OPPRETT_PATH,
  UTSENDINGSPAUSE_PAGE_PATH,
  UTSENDINGSPAUSE_REDIGER_PATH,
  UTSENDINGTYPE_PATH,
  ADMINISTRASJON_LANDINGSIDE,
  ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH,
  PROFIL_PATH, ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH,
} from 'app/routes/routeConstants';

import { EnhetLinkedRettighet } from '../rettigheter/interface';
import { RettighetValues, RoutePathAccess } from './interface';

export const pathRettigheterRules = new Map<string, RettighetValues[]>([
  ['/', [RettighetValues.LOGGE_INN_I_APPLIKASJONEN]],
  [`/${PROFIL_PATH}`, [RettighetValues.LOGGE_INN_I_APPLIKASJONEN]],
  [
    `/${BRUKERE_PATH}`,
    [
      RettighetValues.BRUKERADMINISTRATOR_ALLE_ENHETER,
      RettighetValues.BRUKERADMINISTRATOR_EGEN_ENHET_UTENOM_TILDELE_BRUKERADMINISTRATOR_ROLLEN,
    ],
  ],
  [
    `/${BRUKER_PATH}`,
    [
      RettighetValues.BRUKERADMINISTRATOR_ALLE_ENHETER,
      RettighetValues.BRUKERADMINISTRATOR_EGEN_ENHET_UTENOM_TILDELE_BRUKERADMINISTRATOR_ROLLEN,
    ],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}`,
    [
      RettighetValues.ADMINISTRERE_HELSEPERSONELL_ROLLER,
      RettighetValues.SENTRAL_FORVALTER_AV_SKOPØRER,
    ],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}/${OPPRETT_HELSEPERSONELL_PATH}`,
    [RettighetValues.ADMINISTRERE_HELSEPERSONELL_ROLLER],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}/${REDIGER_HELSEPERSONELL_PATH}`,
    [RettighetValues.ADMINISTRERE_HELSEPERSONELL_ROLLER],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}/${SKOPØR_INFORMASJON}`,
    [RettighetValues.SENTRAL_FORVALTER_AV_SKOPØRER],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}/${SKOPØR_RESERVASJON}`,
    [RettighetValues.SENTRAL_FORVALTER_AV_SKOPØRER],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}/${SKOPØR_RESERVERTE}`,
    [RettighetValues.OPPLISTING_RESERVERTE_SKOPØRER],
  ],
  [
    `/${HELSEPERSONELL_LANDINGSIDE}/${HELSEPERSONELL_ROLLER}`,
    [RettighetValues.ADMINISTRERE_HELSEPERSONELL_ROLLER],
  ],
  [`/${ADMINISTRASJON_LANDINGSIDE}`, [RettighetValues.BEHANDLE_FEIL]],
  [
    `/${ADMINISTRASJON_LANDINGSIDE}/${ADMINISTRASJON_KOLOSKOPIRAPPORT_PATH}`,
    [RettighetValues.BEHANDLE_FEIL],
  ],
  [
    `/${ADMINISTRASJON_LANDINGSIDE}/${ADMINISTRASJON_PERSONTJENESTEN_SEARCH_PATH}`,
    [RettighetValues.LESE_RESPONS_FRA_FOLKEREGISTERET],
  ],
  [
    `/${ADMINISTRASJON_LANDINGSIDE}/${ADMINISTRASJON_INKLUSJON_KANDIDAT_SEARCH_PATH}`,
    [RettighetValues.BEHANDLE_FEIL],
  ],
  [
    `/${SENTERTILKNYTNING_PATH}`,
    [
      RettighetValues.FORVALTE_TILKNYTNING_MELLOM_SCREENINGSENTER_OG_KOMMUNE_BYDEL,
    ],
  ],
  [
    `/${PERSONOPPSLAG_PATH}`,
    [
      RettighetValues.PERSONOPPSLAG_OG_VISNING,
      RettighetValues.GJØRE_HANDLINGER_TILKNYTTET_ENKELTPERSONER,
    ],
  ],
  [
    `/${PERSON_PATH}`,
    [
      RettighetValues.PERSONOPPSLAG_OG_VISNING,
      RettighetValues.GJØRE_HANDLINGER_TILKNYTTET_ENKELTPERSONER,
    ],
  ],
  [
    `/${KAPASITET_PATH}`,
    [
      RettighetValues.RAPPORTERE_INN_KAPASITET_FOR_EGET_SENTER,
      RettighetValues.SE_INNMELDT_KAPASITET_FOR_ALLE_SENTERE,
      RettighetValues.STILLE_INN_SCREENINGMETODER_FOR_ALLE_SENTERE,
    ],
  ],
  [
    `/${ENHETER_PATH}`,
    [RettighetValues.OPPRETTE_OG_FORVALTE_ENHETER_PÅ_ALLE_NIVÅER],
  ],
  [
    `/${OPPRETT_ENHET_PATH}`,
    [RettighetValues.OPPRETTE_OG_FORVALTE_ENHETER_PÅ_ALLE_NIVÅER],
  ],
  [
    `/${REDIGER_ENHET_PATH}`,
    [RettighetValues.OPPRETTE_OG_FORVALTE_ENHETER_PÅ_ALLE_NIVÅER],
  ],

  [
    `/${OPPFOLGING}`,
    [RettighetValues.TILGANG_TIL_DIREKTE_PERSONIDENTIFISERENDE_OPPLYSNINGER],
  ],
  [
    `/${INVITASJON_OG_INKLUSJON_PATH}`,
    [RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER],
  ],
  [
    `/${INVITASJON_OG_INKLUSJON_PATH}/${INVITASJON_OG_INKLUSJON_REDIGER_PATH}`,
    [RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER],
  ],
  [
    `/${INVITASJON_OG_INKLUSJON_PATH}/${INVITASJON_OG_INKLUSJON_INKLUSJON_PATH}`,
    [RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER],
  ],
  [
    `/${INVITASJON_OG_INKLUSJON_PATH}/${INVITASJON_OG_INKLUSJON_KVITTERING_PATH}`,
    [RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER],
  ],
  [
    `/${HENDELSEKONTROLL_LANDINGSIDE_PATH}`,
    [
      RettighetValues.SØKE_I_HENDELSER,
      RettighetValues.SE_LOGG_FOR_UTFØRT_HENDELSEKONTROLL,
    ],
  ],
  [
    `/${HENDELSEKONTROLL_HENDELSELOGG_PATH}`,
    [RettighetValues.SØKE_I_HENDELSER],
  ],
  [
    `/${HENDELSEKONTROLL_KVITTERING_PATH}`,
    [RettighetValues.SE_LOGG_FOR_UTFØRT_HENDELSEKONTROLL],
  ],
  [
    `/${HENDELSEKONTROLL_LOGGKONTROLL_PATH}`,
    [RettighetValues.SØKE_I_HENDELSER],
  ],
  [
    `/${HENDELSEKONTROLL_LOGGKONTROLL_MELDING_PATH}`,
    [RettighetValues.SØKE_I_HENDELSER],
  ],
  [
    `/${SYSTEMTABELLER_PATH}`,
    [RettighetValues.SETTE_GENERELLE_INNSTILLINGER_I_APPLIKASJONEN],
  ],
  [
    `/${SENTRALADMINSØK_ROUTE}`,
    [
      RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER,
      RettighetValues.GJØRE_OPPLISTINGER_SØK,
      RettighetValues.TILGANG_TIL_DIREKTE_PERSONIDENTIFISERENDE_OPPLYSNINGER,
    ],
  ],
  [
    `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_SENTER_PATH}/${SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH}`,
    [
      RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER,
      RettighetValues.GJØRE_OPPLISTINGER_SØK,
    ],
  ],
  [
    `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_DELTAKER_PATH}/${SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH}`,
    [
      RettighetValues.INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER,
      RettighetValues.GJØRE_OPPLISTINGER_SØK,
    ],
  ],
  [
    `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_INTERVALL_PATH}/${SENTRALADMINSOK_UTSENDINGER_PATH}`,
    [
      RettighetValues.TILGANG_TIL_DIREKTE_PERSONIDENTIFISERENDE_OPPLYSNINGER,
      RettighetValues.GJØRE_OPPLISTINGER_SØK,
    ],
  ],
  [
    `/${SENTRALADMINSØK_ROUTE}/${SENTRALADMINSØK_DELTAKER_PATH}/${SENTRALADMINSOK_UTSENDINGER_PATH}`,
    [
      RettighetValues.TILGANG_TIL_DIREKTE_PERSONIDENTIFISERENDE_OPPLYSNINGER,
      RettighetValues.GJØRE_OPPLISTINGER_SØK,
    ],
  ],
  [`/${EKSTERNT_PERSONSOK_PATH}`, [RettighetValues.GJØRE_EKSTERNT_PERSONSØK]],
  [
    `/${DOKUMENTER_PATH}`,
    [RettighetValues.SE_BREVMALER, RettighetValues.ADMINISTRERE_BREVMALER],
  ],
  [
    `/${UTSENDINGTYPE_PATH}`,
    [RettighetValues.SE_BREVMALER, RettighetValues.ADMINISTRERE_BREVMALER],
  ],
  [
    `/${SYSTEMINFORMASJON_PATH}`,
    [RettighetValues.SETTE_GENERELLE_INNSTILLINGER_I_APPLIKASJONEN],
  ],
  [`/${SENTRALADMIN_HELSEMELDINGER}`, [RettighetValues.BEHANDLE_FEIL]],
  [`/${SENTRALADMIN_EKSTERNE_MELDINGER}`, [RettighetValues.BEHANDLE_FEIL]],

  [`/${SENTRALADMIN_LOGISITKKPARTNER}`, [RettighetValues.BEHANDLE_FEIL]],
  [
    `/${UTSENDINGSPAUSE_PAGE_PATH}`,
    [RettighetValues.TILGANG_TIL_Å_ADMINISTRERE_UTSENDINGSPAUSER],
  ],
  [
    `/${UTSENDINGSPAUSE_PAGE_PATH}/${UTSENDINGSPAUSE_REDIGER_PATH}`,
    [RettighetValues.TILGANG_TIL_Å_ADMINISTRERE_UTSENDINGSPAUSER],
  ],
  [
    `/${UTSENDINGSPAUSE_PAGE_PATH}/${UTSENDINGSPAUSE_OPPRETT_PATH}`,
    [RettighetValues.TILGANG_TIL_Å_ADMINISTRERE_UTSENDINGSPAUSER],
  ],
]);

export const updateAllowedRoutesWithNewEnhet = (
  oldRoutes: readonly RoutePathAccess[],
  enhet: EnhetLinkedRettighet
): RoutePathAccess[] => {
  const { enhetId, rettigheter: enhetsrettigheter } = enhet;

  const newRoutes: RoutePathAccess[] = oldRoutes.map((route) => {
    const allowRulesForPath = pathRettigheterRules.get(route.path);

    const userHasPathAccess = allowRulesForPath?.find((pathRules) =>
      enhetsrettigheter.find(
        (enhetsRett) => enhetsRett.id === pathRules.valueOf()
      )
    );
    const n = [...route.userEnhetAccessToPath, enhetId];
    if (userHasPathAccess) {
      return {
        path: route.path,
        exact: route.exact,
        userEnhetAccessToPath: n,
      };
    } else return route;
  });

  return newRoutes;
};
