import { RettighetDto } from 'services/enheter/dto';

import { RettigheterState } from './interface';

export const getRettigheter = (state: RettigheterState): RettigheterState =>
  state;

export const hasRettigheterByEnhet = (
  state: RettigheterState,
  enhetId: RettighetDto['id'],
  rettigheter: number[] | number
): RettighetDto[] => {
  const enhetToCheck = state.data.find(
    (enhetRettighet) => enhetRettighet.enhetId === enhetId
  );
  if (enhetToCheck) {
    if (typeof rettigheter === 'number') {
      const rettighet = enhetToCheck.rettigheter.find(
        (rettighet) => rettighet.id === rettigheter
      );
      return rettighet ? [rettighet] : [];
    } else {
      return enhetToCheck.rettigheter.filter((rettighet) =>
        rettigheter.includes(rettighet.id)
      );
    }
  } else {
    return [];
  }
};

const selectors = {
  getRettigheter,
  hasRettigheterByEnhet,
};

export default selectors;
