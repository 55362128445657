import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { AppDispatch } from 'app/store';
// import { addNotification } from 'features/notification/notificationSlice';
import { getAktivBrukterrettigheterForEnhet } from 'services/enheter/enheterService';
import { TraceableError } from 'utils/errors/customError';

import { updateAllowedRoutes } from '../allowedRoutes/slice';
import { EnhetLinkedRettighet } from './interface';

export const fetchRettigheter = createAsyncThunk<
  EnhetLinkedRettighet,
  { enhetId: number; brukerId: number },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  'currentBruker/fetchRettigheter',
  async ({ enhetId, brukerId }, { dispatch, getState, rejectWithValue }) => {
    try {
      if (!brukerId) throw new Error('Unknown user id');

      const rettigheter = await getAktivBrukterrettigheterForEnhet({
        brukerId,
        enhetId,
      });
      const enhetlinkedRettighet = {
        enhetId,
        rettigheter,
      } as EnhetLinkedRettighet;
      dispatch(updateAllowedRoutes(enhetlinkedRettighet));
      return enhetlinkedRettighet;
    } catch (err) {
      const e = err as TraceableError;
      return rejectWithValue(e.message);
    }
  }
);
