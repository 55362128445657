import { CircularProgress, Typography } from '@mui/material';

// Denne er nødvendig for å hindre klaging fra axe core
const LoadingApp: React.FC = () => {
  return (
    <>
      <Typography component="h1">Laster innhold...</Typography>
      <CircularProgress aria-label="laster side-animasjon" />
    </>
  );
};

export default LoadingApp;
