import { Avatar, useTheme } from '@mui/material';

import Props from './interface';
import { getSizes } from './utils';

const IconAvatar: React.FC<Props> = ({ text, size, label }: Props) => {
  const theme = useTheme();

  return (
    <Avatar
      aria-label={label}
      sx={{
        bgcolor: 'inherit',
        color: 'primary.main',
        border: 1,
        borderColor: 'primary.main',
        ...getSizes(size, theme),
      }}>
      {text}
    </Avatar>
  );
};

export default IconAvatar;
