import { Update } from '@reduxjs/toolkit';
import { actions } from 'features/errorHandling';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { Dispatcher } from 'features/errorHandling/interface';
import { addNotification } from 'features/notification/notificationSlice';
import { BrukerDto } from 'services/bruker/dto';
import { BrukerRolleDto, CreateUpdateRolleDto } from 'services/brukerRolle/dto';
import { fromISOToMillis, fromMillisToISO } from 'utils/date';
import { TraceableError } from 'utils/errors/customError';

import { BrukerInput, RolleInput } from './interface';

export const dispatchBrukerError = (
  dispatch: Dispatcher,
  error: TraceableError
) => {
  dispatchError(dispatch, error, actions.addError.type);
  dispatch(
    addNotification({
      message: { text: error.message },
      variant: 'error',
    })
  );
};

export const dispatchBrukerInfo = (
  dispatch: Function,
  message: string,
  success?: boolean
) =>
  dispatch(
    addNotification({
      message: { text: message },
      variant: success ? 'success' : 'info',
    })
  );

export const mapInitialRolleInputs = (roller: number[]): RolleInput[] =>
  roller.map(
    (id): RolleInput => ({
      selected: false,
      rolleId: id,
      gyldigFra: { value: null },
      gyldigTil: { value: null },
      kanSlettes: true,
    })
  );

export const mapUpdateRolleInput = (
  brukerRoller: BrukerRolleDto[]
): Update<RolleInput, number>[] =>
  brukerRoller.map(
    ({
      rolleId,
      rolle,
      id: brukerRolleId,
      versjon,
      gyldigFra,
      gyldigTil,
      kanSlettes,
    }) => {
      const id = rolleId || rolle?.id;

      if (!id) {
        throw new Error(
          `Brukerrolle med id ${brukerRolleId} mangler id for rolle`
        );
      }

      return {
        id,
        changes: {
          selected: true,
          brukerRolleId,
          versjon,
          gyldigFra: { value: fromISOToMillis(gyldigFra) },
          gyldigTil: { value: fromISOToMillis(gyldigTil) },
          kanSlettes,
        },
      };
    }
  );

export const mergeExistingBrukerInput = (
  input: BrukerInput,
  {
    id,
    versjon,
    fødselsnr,
    fornavnOgMellomnavn,
    etternavn,
    brukernavn,
    epost,
    telefonNr,
  }: BrukerDto
): BrukerInput => ({
  id: String(id),
  versjon: String(versjon),
  fødselsnr: fødselsnr || input.fødselsnr,
  fornavnOgMellomnavn,
  etternavn,
  brukernavn,
  epost: epost || input.epost,
  telefonNr: telefonNr || input.telefonNr,
});

export const mapToCreateUpdateRolleDto = ({
  brukerRolleId,
  versjon,
  rolleId,
  gyldigFra,
  gyldigTil,
}: RolleInput): CreateUpdateRolleDto => ({
  brukerRolleId,
  versjon,
  rolleId,
  gyldigFra: fromMillisToISO(gyldigFra.value, 'datetime') || '',
  gyldigTil: fromMillisToISO(gyldigTil.value, 'datetime'),
});
