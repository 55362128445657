import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createBruker,
  generateBrukernavn,
  getBrukerById,
  getBrukerWithoutRollerByFødselsnummer,
  updateBruker,
} from 'services/bruker/brukerService';
import { BrukerDto } from 'services/bruker/dto';
import { getBrukerRollerByBrukerAndEnhet } from 'services/brukerRolle/brukerRolleService';
import {
  BrukerRolleDto,
  CreateUpdateBrukerOgRolleDto,
} from 'services/brukerRolle/dto';
import { getRollerByEnhet } from 'services/enheter/enheterService';
import { TraceableError } from 'utils/errors/customError';
import { DybdeParam } from 'utils/interface';

import { dispatchBrukerError, dispatchBrukerInfo } from './utils';

export const fetchBruker = createAsyncThunk<
  BrukerDto | null,
  { id?: number; fødselsnummer?: string },
  { rejectValue: string }
>(
  'brukerEditor/fetchBruker',
  async ({ id, fødselsnummer }, { dispatch, rejectWithValue }) => {
    try {
      if (id) {
        return await getBrukerById(id);
      } else if (fødselsnummer) {
        return await getBrukerWithoutRollerByFødselsnummer({
          fødselsnr: fødselsnummer,
        });
      } else {
        throw new Error('Søk på bruker krever enten id eller fødselsnummer');
      }
    } catch (e) {
      const err = e as TraceableError;
      dispatchBrukerError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchBrukernavn = createAsyncThunk<
  string,
  { fornavnOgMellomnavn: string; etternavn: string },
  { rejectValue: string }
>(
  'brukerEditor/generateBrukernavn',
  async ({ fornavnOgMellomnavn, etternavn }, { dispatch, rejectWithValue }) => {
    try {
      return await generateBrukernavn(fornavnOgMellomnavn, etternavn);
    } catch (e) {
      const err = e as TraceableError;

      dispatchBrukerError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchRollerByEnhet = createAsyncThunk<
  number[],
  number,
  { rejectValue: string }
>(
  'brukerEditor/fetchRollerByEnhet',
  async (enhetId, { dispatch, rejectWithValue }) => {
    try {
      return await getRollerByEnhet(enhetId);
    } catch (e) {
      const err = e as TraceableError;

      dispatchBrukerError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchBrukerRollerByBrukerAndEnhet = createAsyncThunk<
  BrukerRolleDto[],
  { brukerId: number; enhetId: number },
  { rejectValue: string }
>(
  'brukerEditor/fetchBrukerRollerByBrukerAndEnhet',
  async ({ brukerId, enhetId }, { dispatch, rejectWithValue }) => {
    try {
      return await getBrukerRollerByBrukerAndEnhet(brukerId, enhetId, {
        dybde: DybdeParam.min,
      });
    } catch (e) {
      const err = e as TraceableError;

      dispatchBrukerError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);

export const saveBruker = createAsyncThunk<
  BrukerDto,
  CreateUpdateBrukerOgRolleDto,
  { rejectValue: string }
>(
  'brukerEditor/saveBruker',
  async (createUpdateBrukerOgRolleDto, { dispatch, rejectWithValue }) => {
    try {
      let bruker;
      if (createUpdateBrukerOgRolleDto.id) {
        bruker = await updateBruker(createUpdateBrukerOgRolleDto, {
          dybde: DybdeParam.min,
        });
      } else {
        bruker = await createBruker(createUpdateBrukerOgRolleDto);
      }
      dispatchBrukerInfo(dispatch, 'Brukeren ble lagret', true);
      return bruker;
    } catch (e) {
      const err = e as TraceableError;

      dispatchBrukerError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);
