import kanalerSelectors from './kanaler/selector';
import { HenvendelseState } from './reducer';
import sendingSelectors from './sending/selector';
import typeSelectors from './typer/selector';

/* henvendelse typer */
export const getHenvendelseTyper = (state: HenvendelseState) =>
  typeSelectors.getHenvendelseTyper(state.typer);

/* henvendelse sending */
export const getHenvendelseSendStatus = (state: HenvendelseState) =>
  sendingSelectors.getHenvendelseSendStatus(state.sending);

export const getFritekstbrevSendStatus = (state: HenvendelseState) =>
  sendingSelectors.getFritekstbrevSendStatus(state.sending);

/* henvendelse kanaler */
export const getHenvendelseKanaler = (state: HenvendelseState) =>
  kanalerSelectors.getHenvendelseKanaler(state.kanaler);

export const getkanalerByHenvendelsestypeId = (
  state: HenvendelseState,
  henvendelsesTypeId: number | undefined
) =>
  kanalerSelectors.getkanalerByHenvendelsestypeId(
    state.kanaler,
    henvendelsesTypeId
  );
const selectors = {
  getHenvendelseTyper,
  getHenvendelseSendStatus,
  getFritekstbrevSendStatus,
  getHenvendelseKanaler,
  getkanalerByHenvendelsestypeId,
};

export default selectors;
