import { createTheme } from '@mui/material';
import {
  blue,
  green,
  grey,
  lightBlue,
  red,
  yellow,
} from '@mui/material/colors';

export type ScrPalette = {
  yellow900: string;
  yellow100: string;
  red900: string;
  red700: string;
  red100: string;
  green900: string;
  green700: string;
  green100: string;
  blue500: string;
  blue50: string;
  lightBlue900: string;
  grey800: string;
  grey600: string;
  grey400: string;
  grey300: string;
  grey200: string;
  grey100: string;
  grey50: string;
};

export const yellow100: ScrPalette['yellow100'] = yellow[100];
export const yellow900: ScrPalette['yellow900'] = yellow[900];
export const red900: ScrPalette['red900'] = red[900];
export const red700: ScrPalette['red700'] = red[700];
export const red100: ScrPalette['red100'] = red[100];
export const green900: ScrPalette['green900'] = green[900];
export const green700: ScrPalette['green700'] = green[700];
export const green100: ScrPalette['green100'] = green[100];
export const blue500: ScrPalette['blue500'] = blue[500];
export const blue50: ScrPalette['blue50'] = blue[50];
export const lightBlue900: ScrPalette['lightBlue900'] = lightBlue[900];
export const grey800: ScrPalette['grey800'] = grey[800];
export const grey600: ScrPalette['grey600'] = grey[600];
export const grey400: ScrPalette['grey400'] = grey[400];
export const grey300: ScrPalette['grey300'] = grey[300];
export const grey200: ScrPalette['grey200'] = grey[200];
export const grey100: ScrPalette['grey100'] = grey[100];
export const grey50: ScrPalette['grey50'] = grey[50];

const screenitTheme = createTheme({
  palette: {
    primary: { main: '#003E69' },
    secondary: { main: lightBlue900 },
    scr: {
      yellow900: yellow900,
      yellow100: yellow100,
      red900: red900,
      red700: red700,
      red100: red100,
      green900: green900,
      green700: green700,
      green100: green100,
      blue500: blue500,
      blue50: blue50,
      lightBlue900: lightBlue900,
      grey800: grey800,
      grey600: grey600,
      grey400: grey400,
      grey300: grey300,
      grey200: grey200,
      grey100: grey100,
      grey50: grey50,
    },
  },

  breakpoints: {
    values: { xs: 0, sm: 768, md: 1024, lg: 1366, xl: 1920 },
  },

  components: {
    MuiTextField: { defaultProps: { variant: 'filled' } },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'tableTitle' },
          style: {
            fontSize: '16px',
            color: '#003E69',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          fontSize: 0,
          '&>*:nth-of-type(1)': {
            fontSize: 0,
          },
        },
        endIcon: {
          fontSize: 0,
          '&>*:nth-of-type(1)': {
            fontSize: 0,
          },
        },
      },
    },
  },
});

export default screenitTheme;

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    tableTitle: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    scr: ScrPalette;
  }
  interface PaletteOptions {
    scr: ScrPalette;
  }
}
