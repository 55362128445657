import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus, rejectedStatus } from 'features/utils';
import { EnhetDto } from 'services/enheter/dto';

import { BrukerAdminEnheterState } from './interface';
import { fetchBrukeradminEnheter } from './thunks';

export const enheterAdapter = createEntityAdapter<EnhetDto, number>({
  selectId: (enhet) => enhet.id,
  sortComparer: (a, b) => a.navn.localeCompare(b.navn),
});

export const initialState: BrukerAdminEnheterState =
  enheterAdapter.getInitialState({
    status: initialFetchStatus,
  });

export const slice = createSlice({
  name: 'brukeradminEnheter',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrukeradminEnheter.fulfilled, (state, { payload }) => {
        state = enheterAdapter.setAll(state, payload);
        state.status.loading = false;
      })
      .addCase(fetchBrukeradminEnheter.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(
        fetchBrukeradminEnheter.rejected,
        (state, { error: { message } }) => {
          state.entities = {};
          state.ids = [];
          state.status = rejectedStatus(message);
        }
      );
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
