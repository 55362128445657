import { createSelector } from '@reduxjs/toolkit';
import { detaljerSelector } from 'features/deltaker/details/selectors';
import { getAllEnheter, getEnheterStatus } from 'features/enhet/selectors';
import { EnhetDto } from 'services/enheter/dto';
import { getManuellHenvisning } from 'features/deltaker/state/deltakerSelectors';
import { FetchStatus } from 'features/utils';
import {HenvisningManuellMetadataDto} from "../../../../../../../services/henvisninger/dto";

export type ManuellHenvisningDialogSelector = {
    deltakerId?: number;
    enheter: EnhetDto[];
    fetchingEnheter: boolean;
    metadataStatus: FetchStatus;
    metadata?: HenvisningManuellMetadataDto;
    postStatus: FetchStatus;
    closeForm: boolean;
    inDialogErrorMessage: string | undefined,
};

export const manuellHenvisningDialogSelector = createSelector(
  [
    detaljerSelector,
    getAllEnheter,
    getEnheterStatus,
    getManuellHenvisning,
  ],
  (
    detaljer,
    enheter,
    status,
    manuellHenvisningStatus
  ): ManuellHenvisningDialogSelector => {
    return {
      deltakerId: detaljer.deltakerId,
      enheter: enheter,
      fetchingEnheter: status.loading,
      metadataStatus: manuellHenvisningStatus.metadataStatus,
      metadata: manuellHenvisningStatus.metadata,
      postStatus: manuellHenvisningStatus.postStatus,
      closeForm: manuellHenvisningStatus.closeForm,
      inDialogErrorMessage: manuellHenvisningStatus.inDialogErrorMessage,
    };
  }
);
