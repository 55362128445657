import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  resetOppfølgingspunkt,
  updateForm,
} from 'features/deltaker/state/oppfølgingspunkt/slice';
import { initializeForm } from 'features/deltaker/state/oppfølgingspunkt/thunks';
import { useManySysProperties } from 'features/sys/hooks';
import { useEffect } from 'react';

import AppendedOppfølgingspunkt from './AppendedOppfølgingspunkt';
import IndividualOppfølgingspunktForm from './IndividualOppfølgingspunktForm';
import { OppfølgingspunktContainerProps } from './interface';
import { oppfølgingspunktContainerSelector } from './selectors';

const OppfølgingspunktContainer: React.FC<OppfølgingspunktContainerProps> = ({
  close,
  oppfølgingsFormType,
}) => {
  const dispatch = useAppDispatch();

  const { oppfølgingStatusId, loading, loadingComplete } = useAppSelector(
    oppfølgingspunktContainerSelector
  );
  const { loaded, data } =
    useManySysProperties<['oppfølgingsstatus']>('oppfølgingsstatus');

  const shouldInitForm = !loading && loadingComplete === undefined;
  useEffect(() => {
    if (shouldInitForm) {
      dispatch(initializeForm(oppfølgingsFormType));
    }
  }, [dispatch, oppfølgingsFormType, shouldInitForm]);

  const handleClose = () => {
    dispatch(resetOppfølgingspunkt(null));
    close && close();
  };

  const initializeOppfølgingsStatusId =
    !oppfølgingStatusId && loaded('oppfølgingsstatus');
  const initialOppfølgingStatusId = data('oppfølgingsstatus')[0]?.id;

  useEffect(() => {
    if (initializeOppfølgingsStatusId) {
      dispatch(
        updateForm({
          key: 'oppfølgingStatusId',
          value: initialOppfølgingStatusId,
        })
      );
    }
  }, [dispatch, initialOppfølgingStatusId, initializeOppfølgingsStatusId]);

  return oppfølgingsFormType === 'INDIVIDUAL' ? (
    <IndividualOppfølgingspunktForm close={handleClose} />
  ) : (
    <AppendedOppfølgingspunkt />
  );
};

export default OppfølgingspunktContainer;
