import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { AppDispatch } from 'app/store';
import { PostHenvendelsePayload } from 'features/deltaker/interface';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { addNotification } from 'features/notification/notificationSlice';
import { DeltakerService, DeltakerutsendingerService } from 'services';
import { HenvendelserService } from 'services';
import { FritekstbrevDto } from 'services/deltakerutsendinger/dto';
import {
  HenvendelseDto,
  HenvendelseUpdateDto,
} from 'services/henvendelser/dto';
import { objectHasAnyValues } from 'utils';
import { TraceableError } from 'utils/errors/customError';

import { setDeltakerDetaljer } from '../../detaljer/slice';
import { setShouldRefetchLogg } from '../../logg/slice';
import { fetchDeltakerHenvendelsetyper } from '../typer/thunks';

const typeName = 'deltaker/henvendelse/sending';

export const postHenvendelse = createAsyncThunk<
  undefined,
  PostHenvendelsePayload,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>(
  `${typeName}/postHenvendelse`,
  async (
    { henvendelse, fritekstbrev, personAdresse },
    { dispatch, rejectWithValue, getState }
  ) => {
    const {
      deltaker: {
        detaljer,
        oppfølgingspunkt: { formValues: oppfølging },
      },
    } = getState();
    const deltakerId = detaljer.data?.deltakerId;

    try {
      if (deltakerId) {
        const henvendelseDto: HenvendelseDto = { ...henvendelse, deltakerId };
        const result = await HenvendelserService.saveHenvendelse({
          henvendelse: henvendelseDto,
          ...(objectHasAnyValues(oppfølging) && { oppfølging }),
          ...(fritekstbrev && { fritekstbrev }),
          ...(personAdresse && { personAdresse }),
        });
        dispatch(fetchDeltakerHenvendelsetyper(deltakerId));
        const deltaker = await DeltakerService.getDeltakerById(deltakerId);
        dispatch(setDeltakerDetaljer(deltaker));
        dispatch(
          addNotification({
            message: { text: 'Henvendelsen ble lagret' },
            variant: 'success',
          })
        );

        return result;
      } else {
        throw new Error('Har ikke brukerid');
      }
    } catch (error) {
      const err = error as TraceableError;
      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    } finally {
      dispatch(setShouldRefetchLogg(true));
    }
  }
);

export const putHenvendelse = createAsyncThunk<
  undefined,
  { henvendelseId: number; henvendelseUpdate: HenvendelseUpdateDto },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>(
  `${typeName}/putHenvendelse`,
  async (
    { henvendelseId, henvendelseUpdate },
    { dispatch, rejectWithValue, getState }
  ) => {
    const {
      deltaker: { detaljer },
    } = getState();

    const deltakerId = detaljer.data?.deltakerId;
    try {
      if (deltakerId) {
        const result = await HenvendelserService.updateHenvendelse(
          henvendelseId,
          henvendelseUpdate
        );
        dispatch(fetchDeltakerHenvendelsetyper(deltakerId));
        const deltaker = await DeltakerService.getDeltakerById(deltakerId);
        dispatch(setDeltakerDetaljer(deltaker));
        dispatch(
          addNotification({
            message: { text: 'Henvendelsen ble oppdatert' },
            variant: 'success',
          })
        );

        return result;
      } else {
        throw new Error('Har ikke brukerid');
      }
    } catch (error) {
      const err = error as TraceableError;
      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    } finally {
      dispatch(setShouldRefetchLogg(true));
    }
  }
);

export const postFritekstbrev = createAsyncThunk<
  undefined,
  { fritekstbrev: FritekstbrevDto; appendOppfølgingspunkt: boolean },
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>(
  `${typeName}/postFritekstbrev`,
  async (
    { fritekstbrev, appendOppfølgingspunkt },
    { dispatch, rejectWithValue, getState }
  ) => {
    const {
      deltaker: { detaljer, oppfølgingspunkt },
    } = getState();

    const deltakerId = detaljer.data?.deltakerId;

    try {
      if (deltakerId) {
        let result = undefined;

        if (appendOppfølgingspunkt) {
          result =
            await DeltakerutsendingerService.sendFritekstbrevWithOppfølgingspunkt(
              deltakerId,
              {
                fritekstbrev,
                oppfølging: oppfølgingspunkt.formValues,
              }
            );
        } else {
          result = await DeltakerutsendingerService.sendFritekstbrevToDeltaker(
            deltakerId,
            { fritekstbrev }
          );
        }
        dispatch(fetchDeltakerHenvendelsetyper(deltakerId));

        dispatch(
          addNotification({
            message: { text: 'Brevet blir nå sendt' },
            variant: 'success',
          })
        );

        return result;
      } else throw new Error('Har ikke brukerid');
    } catch (error) {
      const err = error as TraceableError;

      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    } finally {
      dispatch(setShouldRefetchLogg(true));
    }
  }
);
