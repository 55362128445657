import { Box, Button } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { LoadingComponent } from 'components/LoadingComponent';
import {
  getAllEnheter,
  getEnhetById,
  getEnheterStatus,
} from 'features/currentBruker/state/selectors';
import { useEffect, useState } from 'react';
import { EnhetDto } from 'services/enheter/dto';
import { SELECTED_ENHET_ID } from 'utils/constants';

import { Size } from '../interface';
import EnhetListDialog from './EnhetListDialog';

type Props = {
  size: Size;
  onSelect: (newEnhetId: number) => void;
  onResetAnchorEl?: VoidFunction;
  onPopoverOpen?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ) => void;
  onPopoverClose?: VoidFunction;
  currentEnhetId: number;
};

const EnheterSelectButton: React.FC<Props> = ({
  size,
  onSelect,
  onPopoverClose,
  onPopoverOpen,
  currentEnhetId,
}: Props) => {
  const [selectedEnhetId, setSelectedEnhetId] = useState<number>();
  const [openDialog, setOpenDialog] = useState(false);
  const [displayName, setDisplayName] = useState<string>();
  const status = useAppSelector(getEnheterStatus);
  const enheter = useAppSelector(getAllEnheter);
  useEffect(() => {
    if (enheter.length && !selectedEnhetId) {
      const selectedIdToSet = currentEnhetId;

      sessionStorage.setItem(SELECTED_ENHET_ID, selectedIdToSet.toString());

      setSelectedEnhetId(selectedIdToSet);
    }
  }, [displayName, selectedEnhetId, enheter, currentEnhetId]);

  useEffect(() => {
    if (selectedEnhetId !== undefined) {
      const enhet = getEnhetById(selectedEnhetId) || enheter[0];
      if (enhet) {
        const { navn, kortNavn, id } = enhet;
        setDisplayName(
          size === Size.Small ? kortNavn : navn.replace('Screeningsenter ', '')
        );
        onSelect(id);
      }
    }
  }, [displayName, setDisplayName, selectedEnhetId, size, onSelect, enheter]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    onPopoverOpen && onPopoverOpen(event, displayName || '');
  };

  const handlePopoverClose = (): void => {
    onPopoverClose && onPopoverClose();
  };

  const handleEnheterClickOpen = () => {
    setOpenDialog(true);
  };

  const handleEnhetSelectionClose = (enhetId?: number) => {
    setOpenDialog(false);

    if (enhetId) {
      const newSelectedEnhetPos = enhetId;
      if (selectedEnhetId !== newSelectedEnhetPos) {
        setSelectedEnhetId(newSelectedEnhetPos);

        sessionStorage.setItem(
          SELECTED_ENHET_ID,
          newSelectedEnhetPos.toString()
        );

        window.location.replace('/');
      }
    }
  };

  return (
    <LoadingComponent
      showLoading={status.loading || selectedEnhetId === undefined}
      numberOfSkeletons={1}
      height={36}>
      <Button
        fullWidth
        size="large"
        aria-label={`enhetsvalg, valgt enhet ${displayName}`}
        disabled={!enheter.length || enheter.length === 1}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handleEnheterClickOpen}
        sx={{
          minHeight: 36,
          maxHeight: 36,
          p: 0,
          textTransform: 'none',
        }}>
        <Box
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: 16,
            fontWeight: size === Size.Small ? 'bold' : 'normal',
            color: 'common.black',
          }}>
          {displayName}
        </Box>
      </Button>

      <EnhetListDialog
        enheter={enheter as EnhetDto[]}
        open={openDialog}
        onClose={handleEnhetSelectionClose}
      />
    </LoadingComponent>
  );
};

export default EnheterSelectButton;
