import { createAsyncThunk } from '@reduxjs/toolkit';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { getDeltakerLog } from 'services/deltaker/deltakerService';
import { DeltakerLogDto } from 'services/deltaker/dto';
import { FetchResponseError } from 'utils/errors/customError';

import { setShouldRefetchLogg } from './slice';

const typeName = 'deltaker/logg';

export const fetchDeltakerLog = createAsyncThunk<
  DeltakerLogDto[],
  number,
  { rejectValue: string }
>(`${typeName}`, async (deltakerId, { rejectWithValue, dispatch }) => {
  try {
    const logg = await getDeltakerLog(deltakerId);

    dispatch(setShouldRefetchLogg(false));
    return logg;
  } catch (error) {
    const err = error as FetchResponseError;
    dispatch(setShouldRefetchLogg(false));
    dispatchError(dispatch, err, AddError);

    return rejectWithValue(err.toString());
  }
});
