import { createAsyncThunk } from '@reduxjs/toolkit';
import { EnheterService } from 'services';
import { EnhetDto } from 'services/enheter/dto';
import skopørService from 'services/skopører/skopørService';
import { TraceableError } from 'utils/errors/customError';

import {
  SkopørKvalifikasjonskravDto,
  SkopørUtenKvalifikasjonskravDto,
} from '../../../services/skopører/dto';
import {
  dispatchFetchSkopør,
  dispatchSkopørError,
  dispatchSkopørInfo,
} from './utils';

export const fetchAllSkopørerWithoutKvalifikasjonskrav = createAsyncThunk<
  {
    skopører: SkopørUtenKvalifikasjonskravDto[];
    enheter: EnhetDto[];
  },
  undefined,
  { rejectValue: string }
>(
  'skopørKvalitetskrav/getAllSkopørerWithoutKvalifikasjonskrav',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const enheter = await EnheterService.getEnheter();
      const skopører =
        await skopørService.getAllSkopørerWithoutKvalifikasjonskrav();
      return {
        skopører,
        enheter,
      };
    } catch (e) {
      const err = e as TraceableError;

      dispatchSkopørError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);

export const saveSkopører = createAsyncThunk<
  void,
  { skopørKvalifikasjonskrav: SkopørKvalifikasjonskravDto[] },
  { rejectValue: string }
>(
  'skopørKvalitetskrav/saveSkopør',
  async ({ skopørKvalifikasjonskrav }, { dispatch, rejectWithValue }) => {
    try {
      await skopørService.postSkopørKvalifikasjonskrav(
        skopørKvalifikasjonskrav
      );
      dispatchSkopørInfo(dispatch, 'Kvalifikasjonskrav ble lagret', true);
      dispatchFetchSkopør(dispatch);
    } catch (e) {
      const err = e as TraceableError;

      dispatchSkopørError(dispatch, err);
      return rejectWithValue(err.message);
    }
  }
);
