import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { FetchStatus } from 'features/utils';
import { EnhetDto } from 'services/enheter/dto';

import { enheterAdapter } from './enheterSlice';

const enheterNormalizedSelectorsGlobal = enheterAdapter.getSelectors<RootState>(
  (state) => state.enheter.data
);

export const getAllEnheter = (state: RootState): EnhetDto[] =>
  enheterNormalizedSelectorsGlobal.selectAll(state);

export const getEnhetById = (
  state: RootState,
  id?: number
): EnhetDto | undefined =>
  id ? enheterNormalizedSelectorsGlobal.selectById(state, id) : undefined;

export const getEnheterStatus = (state: RootState): FetchStatus =>
  state.enheter.status;

export const enheterSelector = createSelector(
  [getAllEnheter, getEnheterStatus],
  (data, status) => {
    return { data, status };
  }
);
