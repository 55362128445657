import Kvittering from 'features/invitasjon/components/Kvittering';
import React from 'react';

const IIKvitteringExample: React.FC = () => {
  return (
    <Kvittering
      messages={[
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        'Nunc quis feugiat neque',
        'Vivamus ultricies vitae lacus eget lobortis',
      ]}
    />
  );
};

export default IIKvitteringExample;
