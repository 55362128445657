import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

import { getStrikeThrough } from './utils';

type Props = {
  isKorrigert: boolean;
  antallKoloskopi: number;
  antallIFobt: number;
  korrigertKoloskopi?: number;
  korrigertIFobt?: number;
  selected: boolean;
};

const KorrigertAntallInvitasjonCell: FC<Props> = ({
  isKorrigert,
  antallKoloskopi,
  antallIFobt,
  korrigertKoloskopi,
  korrigertIFobt,
  selected,
}) =>
  selected ? (
    <>
      <Box>
        <Typography variant="body2" component="span">
          IFOBT:{' '}
        </Typography>
        {isKorrigert && (
          <Typography
            component="span"
            variant="body2"
            sx={{
              mx: 1,
            }}>
            {korrigertIFobt}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{ ...getStrikeThrough(isKorrigert) }}
          component="span">
          {antallIFobt}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" component="span">
          Koloskopi:{' '}
        </Typography>
        {isKorrigert && (
          <Typography
            variant="body2"
            component="span"
            sx={{
              mx: 1,
            }}>
            {korrigertKoloskopi}
          </Typography>
        )}
        <Typography
          variant="body2"
          sx={{ ...getStrikeThrough(isKorrigert) }}
          component="span">
          {antallKoloskopi}
        </Typography>
      </Box>
    </>
  ) : (
    <Typography> - </Typography>
  );

export default KorrigertAntallInvitasjonCell;
