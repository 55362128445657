import { createSelector } from '@reduxjs/toolkit';
import { getInfoDemaskert } from 'features/deltaker/state/deltakerSelectors';
import { detaljerSelector } from 'features/deltaker/details/selectors';

export type ShowHenvendelseSelector = {
  isDemaskert: boolean;
  deltakerHasBeenDemaskert: boolean;
  deltakerId?: number;
};

export const showHenvendelseSelector = createSelector(
  [detaljerSelector, getInfoDemaskert],
  (detaljerSelector, infoDemaskert): ShowHenvendelseSelector => {
    const demaskertInfoDisplaying = !!infoDemaskert.data;

    return {
      isDemaskert: demaskertInfoDisplaying,
      deltakerHasBeenDemaskert:
          demaskertInfoDisplaying ||
          (detaljerSelector?.deltakerId && !!infoDemaskert.data) ||
          false,
      deltakerId: detaljerSelector.deltakerId,
    };
  }
);
