import { Drawer, Popover, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box, SxProps, Theme } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { scrollBar } from 'utils/styles';

import { NavBarSideMenu } from './components';
import {
  SIDEBAR_WIDTH,
  Size,
  defaultDrawerWidth,
  maxDrawerWidth,
  minDrawerWidth,
  shouldShrinkToSmallSize,
} from './interface';

const gitInfoEnabled = process.env.VITE_APP_ENV === 'dev';

const SideBar = () => {
  const theme = useTheme();
  const drawerRef = useRef<HTMLDivElement>(null);
  const [drawerWidth, setDrawerWidth] = useState(
    Number(localStorage.getItem(SIDEBAR_WIDTH)) || defaultDrawerWidth
  );
  const [sidebarSize, setSidebarSize] = useState<Size>();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const [popoverContent, setPopoverContent] = useState<
    string | React.ReactElement
  >();

  const isSmallSideBar = sidebarSize === Size.Small;

  const isMdDownScreen = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  });

  const isBetweenMdXlScreen = useMediaQuery(
    theme.breakpoints.between('md', 'xl'),
    { noSsr: true }
  );

  useEffect(() => {
    if (sidebarSize === undefined) {
      // When the screen loads it will decide if it should show expanded or collapsed sidebar
      if (isMdDownScreen) setSidebarSize(Size.Small);
      else if (isBetweenMdXlScreen) setSidebarSize(Size.Medium);
      else setSidebarSize(Size.Large);
    } else if (!isSmallSideBar) {
      // Makes large sidebar icons render as intended
      const isLargeScreen = !isMdDownScreen && !isBetweenMdXlScreen;
      setSidebarSize(isLargeScreen ? Size.Large : Size.Medium);
    }
  }, [isBetweenMdXlScreen, isMdDownScreen, sidebarSize, isSmallSideBar]);

  useEffect(() => {
    if (drawerWidth < shouldShrinkToSmallSize && sidebarSize !== Size.Small) {
      setSidebarSize(Size.Small);
    } else if (
      drawerWidth >= shouldShrinkToSmallSize &&
      sidebarSize === Size.Small
    ) {
      setSidebarSize(Size.Medium);
    }
  }, [drawerWidth, sidebarSize]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string | React.ReactElement
  ) => {
    setPopoverContent(content);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(undefined);
    setPopoverContent(undefined);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    window.addEventListener('mouseup', handleMouseUp, true);
    window.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseUp = () => {
    window.removeEventListener('mouseup', handleMouseUp, true);
    window.removeEventListener('mousemove', handleMouseMove, true);
    window.removeEventListener('touchend', handleMouseUp, true);
    window.removeEventListener('touchmove', handleTouchMove, true);

    const newWidth = drawerRef.current?.clientWidth ?? 0;

    if (newWidth < 140) {
      setDrawerWidth(minDrawerWidth);
      localStorage.setItem(SIDEBAR_WIDTH, `${minDrawerWidth}`);
    } else {
      localStorage.setItem(SIDEBAR_WIDTH, `${newWidth}`);
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    const newWidth = e.clientX - document.body.offsetLeft;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    const newWidth = e.touches[0].clientX - document.body.offsetLeft;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    e.stopPropagation();
    window.addEventListener('touchend', handleMouseUp, true);
    window.addEventListener('touchmove', handleTouchMove, true);
  };

  const transitionStyles: SxProps<Theme> = {
    ...(isSmallSideBar
      ? {
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }
      : {
          pt: 0,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
  };
  const drawerStyles: SxProps<Theme> = {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    borderColor: 'grey.400',
    overflowX: 'hidden',
    width: drawerWidth,
    ...transitionStyles,
  };
  const paperStyles: SxProps<Theme> = {
    width: drawerWidth,
    overflowX: 'hidden',
    ...scrollBar,
    ...transitionStyles,
  };

  return sidebarSize === undefined ? null : ( // undefined means that hasn't found the screen resolution yet. No need to draw anything at this point
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: paperStyles,
      }}
      ref={drawerRef}
      sx={drawerStyles}>
      <NavBarSideMenu
        sidebarSize={sidebarSize}
        handlePopoverClose={handlePopoverClose}
        handlePopoverOpen={handlePopoverOpen}
        setDrawerWidth={setDrawerWidth}
      />
      <Box
        onTouchStart={handleTouchStart}
        onMouseDown={handleMouseDown}
        sx={{
          width: theme.spacing(0.4),
          cursor: 'ew-resize',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,

          backgroundColor: theme.palette.grey[100],
          '&:hover': {
            backgroundColor: theme.palette.scr.blue500,
          },
          '&:active': {
            backgroundColor: theme.palette.scr.blue500,
          },
        }}
      />

      {isSmallSideBar || gitInfoEnabled ? (
        <Popover
          id="mouse-over-popover"
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          PaperProps={{ sx: { p: 1 } }}
          sx={{ pointerEvents: 'none' }}>
          <Typography>{popoverContent}</Typography>
        </Popover>
      ) : undefined}
    </Drawer>
  );
};

export default SideBar;
