import { Button } from '@mui/material';
import { useState } from 'react';

const AppCrashExample: React.FC = () => {
  const [crash, setCrash] = useState(false);

  if (crash) throw new Error();

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={() => setCrash(true)}
      data-cy="crashButton">
      Simuler app kræsj
    </Button>
  );
};

export default AppCrashExample;
