import { Popover, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
  text: string;
  anchorEl: HTMLElement | null;
};
const EnhancedTableHoverTekst: FC<Props> = ({ text, anchorEl }) => {
  return (
    <Popover
      id="mouse-over-popover"
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{ sx: { p: 1 } }}
      sx={{ pointerEvents: 'none' }}>
      <Typography variant="body2">{text}</Typography>
    </Popover>
  );
};
export default EnhancedTableHoverTekst;
