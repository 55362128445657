import { createSelector } from '@reduxjs/toolkit';
import { detaljerSelector } from 'features/deltaker/details/selectors';
import {
  getFritekstbrevSendStatus,
  getHenvendelseSendStatus,
  getHenvendelseTyper,
} from 'features/deltaker/state/deltakerSelectors';

import { HenvendelseDialogSelector } from './interface';

export const henvendelseDialogSelector = createSelector(
  [
    detaljerSelector,
    getHenvendelseTyper,
    getHenvendelseSendStatus,
    getFritekstbrevSendStatus,
  ],
  (
    detaljer,
    { status },
    sendHenvendelseStatus,
    sendFritekstbrevStatus
  ): HenvendelseDialogSelector => {
    return {
      deltakerId: detaljer.deltakerId,
      isSubmitting:
        sendHenvendelseStatus.loading || sendFritekstbrevStatus.loading,
      fetchingHenvendelseTyper: status.loading,
      hasError:
        !!sendHenvendelseStatus.errorMessage ||
        !!sendFritekstbrevStatus.errorMessage,
    };
  }
);

export const getSendingHenvendelseStatus = createSelector(
  [getHenvendelseSendStatus, getFritekstbrevSendStatus],
  (sendHenvendelseStatus, fritekstBrevStatus) => {
    return {
      completed:
        !!sendHenvendelseStatus.loadingComplete ||
        !!fritekstBrevStatus.loadingComplete,
      loading: sendHenvendelseStatus.loading || fritekstBrevStatus.loading,
      error:
        !!sendHenvendelseStatus.errorMessage ||
        !!fritekstBrevStatus.errorMessage,
      success: sendHenvendelseStatus.success || fritekstBrevStatus.success,
    };
  }
);
