import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDeltakerInfoDemaskert,
  postDemaskertDeltaker,
} from 'services/deltaker/deltakerService';
import { DeltakerInfoDemaskertDto } from 'services/deltaker/dto';
import { TraceableError } from 'utils/errors/customError';

import { setDemaskerDialogOpen } from './slice';

const typeName = 'deltaker/infoDemaskert';

export const demaskDeltaker = createAsyncThunk<
  DeltakerInfoDemaskertDto | undefined,
  { deltakerId: number; årsakId?: number },
  { rejectValue: string }
>(
  `${typeName}`,
  async ({ deltakerId, årsakId }, { rejectWithValue, dispatch }) => {
    try {
      return await getDeltakerInfoDemaskert(deltakerId);
    } catch (err) {
      if (årsakId) {
        try {
          await postDemaskertDeltaker(deltakerId, årsakId);
          const demaskertInfo = await getDeltakerInfoDemaskert(deltakerId);

          return demaskertInfo;
        } catch (error) {
          const err = error as TraceableError;
          return rejectWithValue(err.message);
        }
      } else {
        dispatch(
          setDemaskerDialogOpen({
            open: true,
            deltakerId,
          })
        );
        return;
      }
    }
  }
);
