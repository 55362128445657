import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DisplayError, EnhancedTable, FilterMenu, ScrIcon } from 'components';
import { ErrorTypeEnum } from 'components/Error/interface';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { formatDateFromISO } from 'utils/date';

import AppCrashExample from './components/AppCrashExample';
import FnrSidExample from './components/FnrSidExample';
import HenvendelseDialogExample from './components/HenvendelseDialogExample';
import IIAntallExample from './components/IIAntallExample';
import IIBekreftelseDialogExample from './components/IIBekreftelseDialogExample';
import IIKvitteringExample from './components/IIKvitteringExample';
import NotificationExample from './components/NotificationExample';
import ReestimerExample from './components/ReestimerExample';
import ScrCardExample from './components/ScrCardExample';
import ScrIconExample from './components/ScrIconExample';
import ScrTextAreaAutosizeExample from './components/ScrTextAreaAutosizeExample';
import StatusExample from './components/StatusExample';
import UnauthorizedExample from './components/UnauthorizedExample';

type Actor = {
  id: string;
  name: string;
  dob: string;
  gender: string;
};

type ValueTypes = {
  displayValue: string | number | ReactElement;
  rawValue: string | number;
};

type DisplayData = {
  data: {
    name: ValueTypes;
    dob: ValueTypes;
    icon: ValueTypes;
  };
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StyleGuide: React.FC = () => {
  // *** ENHANCED TABLE ***
  const [actorData] = useState<Actor[]>([
    {
      id: 'JERSEI',
      name: 'Jerry Seinfeld',
      dob: '1954-04-29',
      gender: 'm',
    },
    {
      id: 'JULLDR',
      name: 'Julia Louis-Dreyfus',
      dob: '1961-01-13',
      gender: 'f',
    },
    {
      id: 'JASALE',
      name: 'Jason Alexander',
      dob: '1959-11-23',
      gender: 'm',
    },
    {
      id: 'MICRIC',
      name: 'Michael Richards',
      dob: '1949-06-24',
      gender: 'm',
    },
  ]);
  const [displayDataRows, setDisplayDataRows] = useState<DisplayData[]>([]);
  const tableHeaders = [
    { id: 'name', label: 'Navn', numeric: false, disablePadding: false },
    { id: 'dob', label: 'Født', numeric: false, disablePadding: false },
    {
      id: 'gender',
      label: 'Kjønn',
      numeric: false,
      disablePadding: false,
    },
  ];

  const filterTranslations = new Map()
    .set('name', {
      label: 'Skuespiller',
      translations: new Map().set('name', 'Navn på skuespiller'),
    })
    .set('gender', {
      label: 'Kjønn',
      translations: new Map().set('f', 'Dame').set('m', 'Mann'),
    });

  const createDisplayData = (actorData: Actor[]) =>
    actorData.map((actor) => {
      return {
        data: {
          name: { displayValue: actor.name, rawValue: actor.name },
          dob: {
            displayValue: formatDateFromISO(actor.dob),
            rawValue: actor.dob,
          },
          icon: {
            displayValue: (
              <ScrIcon size="small" iconId={actor.gender === 'f' ? 12 : 13} />
            ),
            rawValue: actor.gender,
          },
        },
      };
    });

  useEffect(() => {
    const displayableActorData = createDisplayData(actorData);
    setDisplayDataRows(displayableActorData);
  }, [actorData]);

  const FilterMenuComponent: React.ReactElement<{
    closeFilter?: VoidFunction;
    buttons?: boolean;
  }> = (
    <FilterMenu
      rawDataRows={actorData}
      updateFilteredRows={(filteredDataRows) =>
        setDisplayDataRows(createDisplayData(filteredDataRows as Actor[]))
      }
      categoryProps={filterTranslations}
    />
  );

  // if not feature toggled, return page not found
  const useQuery = () => new URLSearchParams(useLocation().search);
  const queryDev = useQuery().get('dev');
  if (!queryDev) return <DisplayError errorType={ErrorTypeEnum.NotFound} />;

  return (
    <>
      <Typography variant="h3" component="h2">
        Style guide
      </Typography>
      <Typography variant="h5" component="h3">
        Inklusjon og invitasjon - antall
      </Typography>
      <IIAntallExample />
      <Typography variant="h5" component="h3">
        Inklusjon og invitasjon - bekreftelse
      </Typography>
      <IIBekreftelseDialogExample />
      <Typography variant="h5" component="h3">
        Inklusjon og invitasjon - reestimer og kvittering
      </Typography>
      <ReestimerExample />
      <IIKvitteringExample />
      <Typography variant="h5" component="h3">
        ScrTextareaAutosize
      </Typography>
      <ScrTextAreaAutosizeExample />
      <Typography variant="h5" component="h3">
        Status
      </Typography>
      <StatusExample />
      <Typography variant="h5" component="h3">
        Card
      </Typography>
      <ScrCardExample />
      <Typography variant="h5" component="h3">
        Ikoner
      </Typography>
      <ScrIconExample />
      <Typography variant="h5" component="h3">
        Errorhandling
      </Typography>
      <NotificationExample />
      <Divider />
      <AppCrashExample />
      <Divider />
      <UnauthorizedExample />
      <Divider />
      <Divider />
      <FnrSidExample />
      <Divider />
      <Typography variant="h5" component="h3">
        Henvendelse dialog
      </Typography>
      <HenvendelseDialogExample />
      <Divider />
      <Typography variant="h5">
        Enhanced Table med FilterMenuComponent
      </Typography>
      <EnhancedTable
        toolbarProps={{
          title: 'Toolbar Title',
          FilterMenuComponent: FilterMenuComponent,
        }}
        tableProps={{
          headCells: tableHeaders,
          displayDataRows: displayDataRows,
        }}
      />
      <Typography variant="h5">Color uspesifisert</Typography>
      <Typography variant="h5" color="primary">
        Color primary
      </Typography>
      <Typography variant="h5" color="secondary">
        Color secondary
      </Typography>
      <Divider />
      <Typography variant="h3">Knapper</Typography>
      <Button
        variant="contained"
        endIcon={<ScrIcon iconId={11} size="small" />}>
        Søk (uspesifisert)
      </Button>
      <Button
        color="primary"
        variant="contained"
        endIcon={<ScrIcon iconId={11} size="small" />}>
        Søk (primary)
      </Button>
      <Button
        color="secondary"
        variant="contained"
        endIcon={<ScrIcon iconId={11} size="small" />}>
        Søk (secondary)
      </Button>
      <Divider />
      <Typography variant="h3">TextField</Typography>
      <TextField id="filled-basic" label="Filledsdfsdf" />

      <Typography variant="h5">Breakpoints</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledPaper>xs=12</StyledPaper>
        </Grid>
        <Grid item sm={12} md={6}>
          <StyledPaper>sm=12 md=6</StyledPaper>
        </Grid>
        <Grid item sm={12} md={6}>
          <StyledPaper>sm=12 md=6</StyledPaper>
        </Grid>
        <Grid item md={6} lg={4}>
          <StyledPaper>md=6 lg=4</StyledPaper>
        </Grid>
        <Grid item md={6} lg={4}>
          <StyledPaper>md=6 lg=4</StyledPaper>
        </Grid>
        <Grid item sm={12} md={6} lg={3} xl={2}>
          <StyledPaper>sm=12 md=6 lg=3 xl=2</StyledPaper>
        </Grid>
        <Grid item xl={10}>
          <StyledPaper>xl=10</StyledPaper>
        </Grid>
      </Grid>
    </>
  );
};

export default StyleGuide;
