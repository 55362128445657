import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { getCurrentUserName } from 'features/currentBruker/state/selectors';
import { detaljerSelector } from 'features/deltaker/details/selectors';
import {
  getInfoDemaskert,
  getOppfølgingspunkt,
} from 'features/deltaker/state/deltakerSelectors';

export const oppfølgingspunktContainerSelector = createSelector(
  [
    (state: RootState) =>
      state.deltaker.oppfølgingspunkt.formValues.oppfølgingStatusId,
    (state: RootState) => state.deltaker.infoDemaskert.status,
  ],
  (oppfølgingStatusId, { loading, loadingComplete }) => {
    return {
      oppfølgingStatusId,
      loading,
      loadingComplete,
    };
  }
);

export const oppfølgingspunkt = createSelector(
  [getOppfølgingspunkt, getInfoDemaskert],
  (
    {
      formValues,
      invalidFields,
      status,
      eksternTekstKreverDemaskering,
      eksternTekst,
      closeForm,
      brukernavn,
      isUtførtSelected,
      registrertEndret,
    },
    infoDemaskert
  ) => {
    return {
      formValues,
      invalidFields,
      status,
      eksternTekstKreverDemaskering,
      eksternTekst,
      isDemaskert: !!infoDemaskert.data,
      closeForm,
      brukernavn,
      isUtførtSelected,
      registrertEndret,
    };
  }
);
export const henvendelseSelectSelector = createSelector(
  [getOppfølgingspunkt],
  ({ formValues }) => {
    return {
      tilknyttetHenvendelseId: formValues.knyttetTilHenvendelseId,
    };
  }
);
export const loggedInBruker = createSelector(
  [getCurrentUserName],
  (userName) => {
    return { userName };
  }
);

export const individualOppfølgingspunkt = createSelector(
  [oppfølgingspunkt, getOppfølgingspunkt, detaljerSelector],
  (oppfølgingspunkt, { inDialogErrorMessage }, { deltakerId }) => {
    return {
      formValues: oppfølgingspunkt.formValues,
      status: oppfølgingspunkt.status,
      closeForm: oppfølgingspunkt.closeForm,
      invalidFields: oppfølgingspunkt.invalidFields,
      eksternTekst: oppfølgingspunkt.eksternTekst,
      registrertEndret: oppfølgingspunkt.registrertEndret,
      eksternTekstKreverDemaskering:
        oppfølgingspunkt.eksternTekstKreverDemaskering,
      isDemaskert: oppfølgingspunkt.isDemaskert,
      brukernavnStatus: oppfølgingspunkt.brukernavn.status,
      inDialogErrorMessage,
      deltakerId,
      formIsEmpty: Object.keys(oppfølgingspunkt.formValues).length === 0,
      fristHasAlreadyBeenSetOnce:
        !oppfølgingspunkt.formValues.fristDato &&
        !oppfølgingspunkt.invalidFields.fristDato,
      isDoingSomeFetching:
        oppfølgingspunkt.status.loading &&
        !oppfølgingspunkt.status.loadingComplete,
    };
  }
);
