import { Typography } from '@mui/material';
import { ScrCard } from 'components';
import React from 'react';

const ScrCardExample: React.FC = () => {
  return (
    <ScrCard
      id="card"
      title="Dette er et card"
      subtitle="som også har subtitle"
      statusProps={{ type: 'aktiv' }}>
      <Typography>Card har også et innhold</Typography>
    </ScrCard>
  );
};

export default ScrCardExample;
