import { actions } from 'features/errorHandling';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { Dispatcher } from 'features/errorHandling/interface';
import { addNotification } from 'features/notification/notificationSlice';
import { DateTime } from 'luxon';
import { EnhetDto } from 'services/enheter/dto';
import { SkopørUtenKvalifikasjonskravDto } from 'services/skopører/dto';
import { fromISOToDateTime } from 'utils/date';
import { TraceableError } from 'utils/errors/customError';

import { SkopørInput } from './interface';
import { fetchAllSkopørerWithoutKvalifikasjonskrav } from './skopørKvalitetskravThunk';

export const dispatchSkopørError = (
  dispatch: Dispatcher,
  error: TraceableError
) => {
  dispatchError(dispatch, error, actions.addError.type);
  dispatch(
    addNotification({
      message: { text: error.message },
      variant: 'error',
    })
  );
};

export const dispatchSkopørInfo = (
  dispatch: Function,
  message: string,
  success?: boolean
) =>
  dispatch(
    addNotification({
      message: { text: message },
      variant: success ? 'success' : 'info',
    })
  );

export const dispatchFetchSkopør = (dispatch: Function) =>
  dispatch(fetchAllSkopørerWithoutKvalifikasjonskrav());

export const getFirstOfMonth = (standardDagerTilbakeITid: number) => {
  return DateTime.local()
    .minus({ days: standardDagerTilbakeITid })
    .startOf('month');
};

const getEnhetNavnById = (enhetId: number, enheter: EnhetDto[]) =>
  enheter.find((enhet) => enhet.id === enhetId)?.navn || '';

export const mapInitialSkopørInputs = (
  skopører: SkopørUtenKvalifikasjonskravDto[],
  standardDagerTilbakeITid: number,
  enheter: EnhetDto[],
  maksDagerTilbakeITid: number
): SkopørInput[] => {
  return skopører?.map(
    ({ id, hprNr, førsteKoloskopidato, enhetId }): SkopørInput => {
      const firstOfMonth = getFirstOfMonth(standardDagerTilbakeITid);
      const enhetNavn = getEnhetNavnById(enhetId, enheter);
      return {
        selected: false,
        selectedId: `${id}-${enhetId}`,
        id,
        gyldigFra: {
          value: firstOfMonth.toMillis(),
        },
        hprNr,
        koloskopiRegistert: !!førsteKoloskopidato,
        førsteKoloskopidato: førsteKoloskopidato || DateTime.now().toISODate()!,
        standardDagerTilbakeITid,
        maksDagerTilbakeITid,
        defaultGyldigFra: firstOfMonth.toMillis(),
        enhetId,
        enhetNavn,
        validDate: true,
        minDate: getminDate(maksDagerTilbakeITid, førsteKoloskopidato),
      };
    }
  );
};

export function getminDate(
  daysLimit: number,
  firstKolonoskopiDate?: string | null
): number {
  const firstDayOfMonthMillis = firstDayOfMonthByLimit(daysLimit);
  const firstColonoscopyDateMillis =
    getFirstColonoscopyDateMillis(firstKolonoskopiDate);
  if (
    firstDayOfMonthMillis > firstColonoscopyDateMillis ||
    firstKolonoskopiDate === null
  ) {
    return firstDayOfMonthMillis;
  } else {
    return firstColonoscopyDateMillis;
  }
}

export function firstDayOfMonthByLimit(daysLimit: number): number {
  const now = DateTime.local();
  const daysAgoByLimit = now.minus({ days: daysLimit });
  const firstDayOfMonth = daysAgoByLimit.startOf('month');
  return firstDayOfMonth.toMillis();
}

export function getFirstColonoscopyDateMillis(isoDate?: string | null): number {
  if (isoDate === null || isoDate === undefined) {
    return DateTime.now().startOf('month').toMillis();
  } else {
    return fromISOToDateTime(isoDate)!.startOf('month').toMillis();
  }
}
