import {
  DialogFormState,
  DialogFormStateInvalid,
} from './NyHenvendelse/interface';

export const initialFormState: DialogFormState = Object.freeze({
  henvendelseKategorier: [],
  tekst: '',
  bekreftelsesBrevØnsket: true,
  adresseIdKryptert: undefined,
});

export const initialFormStateInvalid: DialogFormStateInvalid = Object.freeze({
  henvendelseKanalId: false,
  mottattTidspunkt: false,
  confirmation: false,
});
