import { Component, ReactNode } from 'react';

type State = { hasError: boolean };

// https://reactjs.org/docs/error-boundaries.html
class TopLevelErrorBoundary extends Component<{ children: ReactNode }, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line no-console
    console.log('getDerivedStateFromError', error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error('Write this to a log service!', error, errorInfo); // TODO SCREENIT-1979
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Oooops, her skjedde en feil.</h1>
          <p>
            Har du lyst til å fortelle oss hva du gjorde da dette gikk galt, så
            kan du sende en epost til
            <a href="mailto:tp-support@kreftregisteret.no">
              tp-support@kreftregisteret.no
            </a>
            . Vi setter pris på feilmeldinger.
          </p>
          <p>
            <a href="/">Hjem</a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default TopLevelErrorBoundary;
