import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  initialFetchStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';

import { DeltakerLoggState } from './interface';
import * as thunk from './thunks';

export const initialState: DeltakerLoggState = {
  status: initialFetchStatus,
  shouldRefetchLogg: false,
};

export const slice = createSlice({
  name: 'logg',
  initialState,
  reducers: {
    resetLogg: () => initialState,
    setShouldRefetchLogg: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        shouldRefetchLogg: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.fetchDeltakerLog.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = loadingComplete;
      })
      .addCase(thunk.fetchDeltakerLog.pending, (state) => {
        state.status = loading;
      })
      .addCase(
        thunk.fetchDeltakerLog.rejected,
        (state, { error: { message } }) => {
          state.status = rejectedStatus(message);
          state.data = initialState.data;
        }
      );
  },
});

export const { resetLogg, setShouldRefetchLogg } = slice.actions;

export default slice.reducer;
