import { ScrTextarea } from 'components';
import { useState } from 'react';

const ScrTextAreaExample: React.FC = () => {
  const [value, setValue] = useState(
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  );

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string;
      value: string;
    }>
  ) => setValue(e.target.value);

  return (
    <ScrTextarea
      aria-label="ScrTextarea"
      name="fritekst"
      minRows={3}
      value={value}
      onChange={handleChange}
    />
  );
};

export default ScrTextAreaExample;
