import { InfoDemaskertState } from './interface';

const getInfoDemaskert = (state: InfoDemaskertState): InfoDemaskertState =>
  state;

const selectors = {
  getInfoDemaskert,
};

export default selectors;
