import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { LoadingInputComponent } from 'components';
import { useSysProperties } from 'features/sys';
import { FC } from 'react';

import Props from './interface';
import { menuProps } from './utils';
import { SelectedChips } from '.';

export const Henvendelsekategorier: FC<Props> = ({
  handleInvalid,
  handleChange,
  selectedValues,
  disabled,
}: Props) => {
  const { data: kategorier, isLoaded } = useSysProperties(
    'henvendelseKategorier'
  );

  const renderSelectedValues = (selected: number[]) =>
    kategorier && <SelectedChips kategorier={kategorier} selected={selected} />;

  return (
    <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
      <LoadingInputComponent showLoading={!isLoaded}>
        <InputLabel id="henvendelse-kategori-select">
          Kategorisering (valgfritt)
        </InputLabel>
        <Select
          disabled={disabled}
          multiple
          labelId="henvendelse-kategori-select"
          name="henvendelseKategorier"
          value={selectedValues}
          onInvalid={handleInvalid}
          onChange={handleChange}
          MenuProps={menuProps}
          renderValue={renderSelectedValues}>
          {kategorier &&
            kategorier.map((kategori) => (
              <MenuItem key={kategori.id} value={kategori.id}>
                <Checkbox checked={selectedValues.indexOf(kategori.id) > -1} />
                <ListItemText primary={kategori.beskrivelse} />
              </MenuItem>
            ))}
        </Select>
      </LoadingInputComponent>
    </FormControl>
  );
};

export default Henvendelsekategorier;
