import { createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus } from 'features/utils';

import { AuthState } from './interface';
import { fetchCurrentBruker } from './thunks';

export const initialState: AuthState = {
  status: initialFetchStatus,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
    setCurrentEnhetId: (state, { payload }) => {
      state.currentEnhetId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentBruker.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = { loading: false, loadingComplete: true };
      })
      .addCase(fetchCurrentBruker.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(fetchCurrentBruker.rejected, (state, { error: { message } }) => {
        state.data = undefined;
        state.status = {
          loading: false,
          errorMessage: message,
          loadingComplete: true,
        };
      });
  },
});

export const { reset, setCurrentEnhetId } = slice.actions;

export default slice.reducer;
