import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { getHenvendelseKanalIdsByHenvendelseType } from 'services/henvendelser/henvendelerService';
import { FetchResponseError } from 'utils/errors/customError';

export const fetchHenvendelseKanalIdsByHenvendelseType = createAsyncThunk<
  {
    henvendelseTypeId: number;
    data: number[];
  },
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'henvendelser/fetchHenvendelseKanalIdsByHenvendelseType',
  async (henvendelseTypeId: number, { dispatch, rejectWithValue }) => {
    try {
      const henvendelseKanalIds = await getHenvendelseKanalIdsByHenvendelseType(
        henvendelseTypeId
      );
      return {
        henvendelseTypeId,
        data: henvendelseKanalIds,
      };
    } catch (error) {
      const err = error as FetchResponseError;
      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.message);
    }
  }
);
