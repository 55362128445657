import { Box, useTheme } from '@mui/material';
import { IconContext } from 'react-icons';

import {
  largeIconSize,
  mediumIconSize,
  smallIconSize,
  xlIconSize,
} from '../constants';
import Props from './interface';

const Icon: React.FC<Props> = ({
  Icon,
  size = 'medium',
  color,
  label,
  borderColor,
  bgcolor,
}: Props) => {
  const theme = useTheme();

  const isFramed = !!(bgcolor !== undefined || borderColor);

  const getSize = (framedIconSize?: boolean) => {
    switch (size) {
      case 'small':
        return theme.spacing(framedIconSize ? 1.2 : smallIconSize);
      case 'medium':
        return theme.spacing(framedIconSize ? 2 : mediumIconSize);
      case 'large':
        return theme.spacing(framedIconSize ? 2.6 : largeIconSize);
      case 'xl':
        return theme.spacing(framedIconSize ? 4.3 : xlIconSize);
    }
  };

  const itemSize = isFramed ? getSize() : undefined;
  const iconSize = getSize(isFramed);

  return (
    <IconContext.Provider
      value={{
        size: iconSize,
        color: color ?? theme.palette.primary.main,
        style: { verticalAlign: 'middle' },
      }}>
      <Box
        component="span"
        sx={
          isFramed
            ? {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: `1px solid ${borderColor}`,
                bgcolor,
                borderRadius: '50%',
                height: itemSize,
                width: itemSize,
              }
            : {}
        }>
        <Icon aria-label={label} />
      </Box>
    </IconContext.Provider>
  );
};

export default Icon;
