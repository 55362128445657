import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { initialFetchStatus, loading, loadingComplete } from 'features/utils';
import { ReduxPickerProps } from 'utils/date';

import {
  BlockingErrors,
  SkopørInput,
  SkopørKvalitetskravState,
} from './interface';
import {
  fetchAllSkopørerWithoutKvalifikasjonskrav,
  saveSkopører,
} from './skopørKvalitetskravThunk';
import { mapInitialSkopørInputs } from './utils';

export const skopørInputAdapter = createEntityAdapter<SkopørInput, string>({
  selectId: (skopørInput) => skopørInput.selectedId,
});

const initialBlockingErrors: BlockingErrors = {
  skopør: false,
};

export const initialState: SkopørKvalitetskravState = {
  skopørInputs: skopørInputAdapter.getInitialState(),
  initialSkopørInputs: skopørInputAdapter.getInitialState(),
  standardDagerTilbakeITid: 0,
  maksDagerTilbakeITid: 0,
  skopørStatus: initialFetchStatus,
  saveStatus: initialFetchStatus,
  blockingErrors: initialBlockingErrors,
};

const slice = createSlice({
  name: 'skopørKvalitetskrav',
  initialState: initialState,
  reducers: {
    setStandardDagerTilbakeITid: (
      state,
      action: PayloadAction<{
        value: number;
      }>
    ) => {
      const { value } = action.payload;
      state.standardDagerTilbakeITid = value;
    },
    setMaksDagerTilbakeITid: (
      state,
      action: PayloadAction<{
        value: number;
      }>
    ) => {
      const { value } = action.payload;
      state.maksDagerTilbakeITid = value;
    },
    setSkopørInputSelected: (
      state,
      action: PayloadAction<{
        skopørId: string;
        selected: boolean;
        gyldigFra: number | null;
      }>
    ) => {
      const { skopørId, selected, gyldigFra } = action.payload;

      skopørInputAdapter.updateOne(state.skopørInputs, {
        id: skopørId,
        changes: {
          selected,
          gyldigFra: { value: gyldigFra },
        },
      });
    },
    updateGyldigFraPicker: (
      state,
      action: PayloadAction<{
        skopørId: string;
        picker: ReduxPickerProps;
      }>
    ) => {
      const { skopørId, picker } = action.payload;

      skopørInputAdapter.updateOne(state.skopørInputs, {
        id: skopørId,
        changes: {
          gyldigFra: picker,
        },
      });

      state.blockingErrors.skopør = picker.error !== undefined;
    },
    resetSkopørKvalitetskravState: (state) => {
      state.skopørInputs = state.initialSkopørInputs;
      state.skopørStatus = initialFetchStatus;
      state.saveStatus = initialFetchStatus;
      state.blockingErrors = initialBlockingErrors;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSkopørerWithoutKvalifikasjonskrav.pending, (state) => {
        state.skopørStatus = loading;
      })
      .addCase(
        fetchAllSkopørerWithoutKvalifikasjonskrav.fulfilled,
        (state, { payload: { skopører, enheter } }) => {
          const skopørInputs = mapInitialSkopørInputs(
            skopører,
            state.standardDagerTilbakeITid,
            enheter,
            state.maksDagerTilbakeITid
          );
          skopørInputAdapter.setAll(state.skopørInputs, skopørInputs);
          skopørInputAdapter.setAll(state.initialSkopørInputs, skopørInputs);

          state.skopørStatus = loadingComplete;
          state.blockingErrors.skopør = false;
        }
      )
      .addCase(
        fetchAllSkopørerWithoutKvalifikasjonskrav.rejected,
        (state, { payload: errorMessage }) => {
          state.skopørStatus = {
            ...initialFetchStatus,
            errorMessage,
          };
          state.blockingErrors.skopør = true;
        }
      )
      .addCase(saveSkopører.pending, (state) => {
        state.saveStatus = loading;
      })
      .addCase(saveSkopører.fulfilled, (state) => {
        state.saveStatus = loadingComplete;
      })
      .addCase(saveSkopører.rejected, (state, { payload: errorMessage }) => {
        state.saveStatus = {
          ...initialFetchStatus,
          errorMessage,
        };
      });
  },
});

export const {
  setStandardDagerTilbakeITid,
  setSkopørInputSelected,
  updateGyldigFraPicker,
  resetSkopørKvalitetskravState,
  setMaksDagerTilbakeITid,
} = slice.actions;

export default slice.reducer;
