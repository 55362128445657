import { getServertidspunktOrDefaultToClientTime } from 'features/system/systemAPI';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

export enum PendingStatus {
  NOT_STARTED = 0,
  STARTED = 1,
  FINISHED = 2,
}

export const useGetServerTime = (
  succesFunction?: (time?: DateTime) => void
): [DateTime | undefined, PendingStatus] => {
  const [pendingStatus, setPendingStatus] = useState<PendingStatus>(
    PendingStatus.NOT_STARTED
  );
  const [serverTime, setServerTime] = useState<DateTime | undefined>();

  useEffect(() => {
    const fetcher = async () => {
      setPendingStatus(PendingStatus.STARTED);
      try {
        const time = await getServertidspunktOrDefaultToClientTime();
        succesFunction && succesFunction(time);
        setServerTime(time);
        setPendingStatus(PendingStatus.FINISHED);
      } catch (e) {
        setPendingStatus(PendingStatus.FINISHED);
      }
    };
    if (!serverTime && pendingStatus === PendingStatus.NOT_STARTED) {
      fetcher();
    }
  }, [pendingStatus, serverTime, succesFunction]);

  return [serverTime, pendingStatus];
};

export default useGetServerTime;
