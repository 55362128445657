import { SysPropertyState } from 'features/sys/interface';
import { HvemOgTidspunktDto } from 'utils/dto';

export type SysProperty = {
  id: number;
  beskrivelse: string;
};
export type InstillingerSys = SysProperty & { verdi: InnstillingerEnum };

export type HelsenorgeSidereferanse = {
  id: number;
  beskrivelse: string;
  tilleggsinfo: string;
  partKode: string;
  lenkeKortNavn: string;
};

export type UtsendingTypeSys = SysProperty & {
  utsendingTypeKategoriId: number;
  helsenorgeSidereferanse?: HelsenorgeSidereferanse;
  tekstDeltakervisning: string;
  tekstStatusvisningHelsenorge?: string;
  utsendingspauseTypeId: number;
};

export enum InnstillingerEnum {
  INGEN = 0,
  FØDSELSDATO_ANTALL_DAGER_FREM_I_TID_I_INKLUSJON_KANDIDAT = 9,
  SKOPØR_KVALIFIKASJONSKRAV_STANDARD_DAGER_TILBAKE_I_TID = 29,
  SKOPØR_KVALIFIKASJONSKRAV_MAKS_DAGER_TILBAKE_I_TID = 28,
  ANTALL_DAGER_SIDEN_FOR_HENVISNINGER_UTEN_KOLOSKOPIRAPPORT_FRA_DATO = 37,
  ANTALL_DAGER_SIDEN_FOR_HENVISNINGER_UTEN_KOLOSKOPIRAPPORT_TIL_DATO = 38,
  ANTALL_DAGER_SKOPØR_SKAL_SKJULES_FRA_OPPLISTING = 46,
  HELSEPERSONELL_STANDARD_VARIGHET_ROLLER = 68,
}

export type SysProperties = {
  adresseTyper: SysPropertyState;
  adresseInnholdstyper: SysPropertyState;
  deltakerForholdType: SysPropertyState;
  deltakerForholdUnderType: SysPropertyState;
  deltakerUtsendingStatus: SysPropertyState;
  deltakerUtsendingStatusGruppe: SysPropertyState;
  dokumentTyper: SysPropertyState;
  flettefelt: SysPropertyState;
  formidlingskanal: SysPropertyState;
  filformat: SysPropertyState;
  folkeregisterstatuser: SysPropertyState;
  henvendelseKanaler: SysPropertyState;
  henvendelseTyper: SysPropertyState;
  henvendelseKategorier: SysPropertyState;
  hendelsetype: SysPropertyState;
  hendelsekontrollStatus: SysPropertyState;
  hendelsekontrollMeldingStatus: SysPropertyState;
  hendelsekontrollMeldingType: SysPropertyState;
  loggradTyper: SysPropertyState;
  oppfølgingsstatus: SysPropertyState;
  oppfølgingstype: SysPropertyState;
  representasjonType: SysPropertyState;
  roller: SysPropertyState;
  språk: SysPropertyState;
  fargetyper: SysPropertyState;
  utsendingTyper: SysPropertyState;
  utsendingTypeKategorier: SysPropertyState;
  visningsgrunn: SysPropertyState;
  henvisningTyper: SysPropertyState;
  omradeTyper: SysPropertyState;
  enhetTyper: SysPropertyState;
  screeningmetoder: SysPropertyState;
  deltakerNotatTyper: SysPropertyState;
  oppfolgingStatus: SysPropertyState;
  opplistingstyper: SysPropertyState;
  provesvarStatus: SysPropertyState;
  innstillinger: SysPropertyState;
  henvisningerSvarTyper: SysPropertyState;
  ifobtPrøvesvarKonklusjoner: SysPropertyState;
  ifobtPrøvesvarKonklusjonGrupper: SysPropertyState;
  ifobtPrøvesvarStatuser: SysPropertyState;
  henvisningerStatus: SysPropertyState;
  henvisningerStatusGruppe: SysPropertyState;
  helsemeldingerFeilStatuser: SysPropertyState;
  helsemeldingerFeilTyper: SysPropertyState;
  helsemeldingerTyper: SysPropertyState;
  logistikkpartnerForsendelseStatuser: SysPropertyState;
  koloskopirapporterStatuser: SysPropertyState;
  kommunikasjonsretning: SysPropertyState;
  helsepersonellRolleType: SysPropertyState;
  tilleggsinfoType: SysPropertyState;
};

export type InputTypeDto = {
  id: DataTypeEnum;
  versjon: number;
  beskrivelse: keyof typeof DataTypeEnum;
};

export type SystemTabellerMetaKolonner = {
  id: number;
  versjon: number;
  sysmetadataTabellId: number;
  kolonneoverskrift: string;
  kolonneNavn: string;
  jsonNøkkelNavn: string;
  kolonneDatatypeId: DataTypeEnum;
  kanEndres: boolean;
  visningsrekkefølge: number;
  maksAntallTegn: number;
  datatype: InputTypeDto;
};
export type SystemtabellerMetaDto = {
  id: number;
  versjon: number;
  tabellnavn: string;
  endepunktUrl: string;
  nyForekomstTillatt: boolean;
  kolonner: SystemTabellerMetaKolonner[];
  kanEndres: boolean;
};

export type SystemInfo = {
  springActiveProfile: string[];
  andvordApiEnviroment: string;
  ediHerId: number;
  amqpHerId: number;
  orgNr: number;
  helsenorgePersonvernInnstilinggerHerId: number;
  helsenorgeDigitaleInnbyggertjenesterHerId: number;
};

export enum DataTypeEnum {
  varchar = 1,
  tinyint = 2,
  boolean = 3,
  int = 4,
}

export type SystemTabell = {
  id: number;
  henvisningstekst?: string;
  tekstStatusvisningHelsenorge?: string;
  tekstDeltakervisning?: string;
  versjon?: number;
} & HvemOgTidspunktDto & {
    [prop: string]: string | boolean | number | undefined | Object;
  };

export enum EnhetTyperEnum {
  Helseforetak = 1,
  Programsekretariat = 2,
  Screeningsenter = 3,
  Regionalt_helseforetak = 4,
  Privat_sykehusforetak = 5,
  Gastrolab = 6,
  Henvisningsmottak = 7,
}
