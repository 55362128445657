import { combineReducers } from '@reduxjs/toolkit';

import sysRowForm from './form/slice';
import sysMeta from './sysMeta/slice';
import sysTable from './sysTable/slice';

const systemtableReducer = combineReducers({
  sysTable,
  sysMeta,
  sysRowForm,
});

export default systemtableReducer;
export type SystemTableState = ReturnType<typeof systemtableReducer>;
