import { Alert, Skeleton } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
export type LiveMessage = { message: string; clearOnUnmount?: boolean };

type Props = {
  numberOfSkeletons?: number;
  height?: number;
  showLoading: boolean;
  variant?: 'text' | 'rectangular' | 'circular' | undefined;
  animation?: false | 'wave' | 'pulse' | undefined;
  liveMessage?: LiveMessage;
  marginBottom?: number;
  errorMessage?: string;
  children: ReactNode;
  errorMessageSx?: SxProps;
};

const LoadingComponent: React.FC<Props> = ({
  numberOfSkeletons = 6,
  height = 48,
  showLoading,
  variant = 'rectangular',
  animation = 'wave',
  liveMessage,
  marginBottom,
  children,
  errorMessage,
  errorMessageSx,
}) => {
  const [srMessage, setSrMessage] = useState<string | undefined>(
    liveMessage?.message
  );

  useEffect(() => {
    setSrMessage(liveMessage?.message ?? undefined);
    return () => {
      if (liveMessage?.clearOnUnmount) {
        setSrMessage(undefined);
      }
    };
  }, [liveMessage?.clearOnUnmount, liveMessage?.message]);

  const getLoadingElements = () => {
    const elements: ReactElement[] = [];
    for (let i = 0; i < numberOfSkeletons; i++) {
      const oddNumbersAnimation = i % 2 === 1 ? animation : undefined;
      elements.push(
        <Fragment key={i}>
          <Skeleton
            variant={variant}
            height={height}
            animation={oddNumbersAnimation}
            sx={{ width: '100%', marginBottom }}
          />
        </Fragment>
      );
    }
    return elements;
  };

  const getChildrenElements = () => {
    return (
      <>
        {liveMessage && srMessage && (
          <Box aria-live="polite" sx={visuallyHidden}>
            {srMessage}
          </Box>
        )}

        {children}
        {errorMessage ? (
          <Alert sx={{ my: 1, ...errorMessageSx }} severity="error">
            {errorMessage}
          </Alert>
        ) : null}
      </>
    );
  };

  return <>{showLoading ? getLoadingElements() : getChildrenElements()}</>;
};

export default LoadingComponent;
