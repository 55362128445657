import { createAsyncThunk } from '@reduxjs/toolkit';
import { SystemtabellerMetaDto } from 'services/sys/dto';
import { getSysMetaData } from 'services/sys/sysService';
import { FetchResponseError } from 'utils/errors/customError';

const typeName = 'senteradmin/systemtabell/sysMeta';

export const fetchSystemTableMetaData = createAsyncThunk<
  SystemtabellerMetaDto[],
  undefined,
  { rejectValue: string }
>(
  `${typeName}/fetchSystemTableMetaData`,
  // eslint-disable-next-line no-shadow-restricted-names
  async (undefined, { rejectWithValue }) => {
    try {
      return await getSysMetaData();
    } catch (error) {
      const err = error as FetchResponseError;
      return rejectWithValue(err.message);
    }
  }
);
