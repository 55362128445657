import { lazy } from 'react';

const AuthRoute = lazy(() => import('./AuthRoute'));
const EnheterPage = lazy(() => import('features/enhet/EnheterPage'));
const NyEnhetPage = lazy(() => import('features/enhet/nyEnhet/NyEnhetPage'));
const RedigerEnhetPage = lazy(
  () => import('features/enhet/redigereEnhet/RedigerEnhetPage')
);

export const enheterRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <EnheterPage />
      </AuthRoute>
    ),
  },
  {
    path: 'opprett-enhet',
    element: (
      <AuthRoute>
        <NyEnhetPage />
      </AuthRoute>
    ),
  },
  {
    path: 'rediger-enhet/:id',
    element: (
      <AuthRoute>
        <RedigerEnhetPage />
      </AuthRoute>
    ),
  },
];
