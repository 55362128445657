import { createSelector } from '@reduxjs/toolkit';
import { detaljerSelector } from 'features/deltaker/details/selectors';
import { getInfoDemaskert } from 'features/deltaker/state/deltakerSelectors';

export type DemaskerButtonSelector = {
  loading: boolean;
  loadingComplete?: boolean;
  isDemaskert: boolean;
  deltakerId?: number;
};

export const demaskerButtonSelector = createSelector(
  [detaljerSelector, getInfoDemaskert],
  (detaljer, infoDemaskert): DemaskerButtonSelector => {
    const { status, data } = infoDemaskert;

    return {
      loadingComplete: status.loadingComplete,
      loading: detaljer.loading || status.loading,
      isDemaskert: !!data,
      deltakerId: detaljer.deltakerId,
    };
  }
);
