import { RootState } from 'app/rootReducer';
import { FetchStatus } from 'features/utils';
import { SystemTabell, SystemtabellerMetaDto } from 'services/sys/dto';

import { KapasitetForm } from '../kapasitet/interface';
import * as kapasitet from '../kapasitet/state/selectors';
import {
  SysRowFormErrors,
  SysRowFormState,
  SysRowFormStateForm,
} from '../systemtabeller/state/form/interface';
import systemtabeller from '../systemtabeller/state/selectors';

export const getSystemtabellerMetaData = (
  state: RootState
): SystemtabellerMetaDto[] =>
  systemtabeller.getSystemtabellerMetaData(state.senteradmin.systemtabeller);

export const getSelectedMetaDataTable = (
  state: RootState
): SystemtabellerMetaDto | undefined =>
  systemtabeller.getSelectedMetaDataTable(state.senteradmin.systemtabeller);

export const getSysTableStatus = (state: RootState): FetchStatus =>
  systemtabeller.getSysTableStatus(state.senteradmin.systemtabeller);

export const getValgtSystemTable = (
  state: RootState
): SystemTabell[] | undefined =>
  systemtabeller.getValgtSystemTable(state.senteradmin.systemtabeller);

export const getIsEditNewModalOpen = (state: RootState): boolean =>
  systemtabeller.getIsEditNewModalOpen(state.senteradmin.systemtabeller);

export const getSysRowFormState = (state: RootState): SysRowFormState =>
  systemtabeller.getSysRowFormState(state.senteradmin.systemtabeller);

export const getFormValueByKey = (
  state: RootState,
  key: keyof SysRowFormStateForm
): SysRowFormStateForm[keyof SysRowFormStateForm] =>
  systemtabeller.getFormValueByKey(state.senteradmin.systemtabeller, key);

export const getFormError = (state: RootState): SysRowFormErrors =>
  systemtabeller.getFormError(state.senteradmin.systemtabeller);

//Kapasitet

export const getScreeningmetodeForEnhetByMetodeId = (
  state: RootState,
  enhetId: number,
  metodeId: number
) =>
  kapasitet.getScreeningmetodeForEnhetByMetodeId(
    state.senteradmin.kapasitet,
    enhetId,
    metodeId
  );

export const getSenterKapasitetFormByEnhetId = (
  state: RootState,
  enhetId: number
): KapasitetForm => {
  return {
    kapasitetForm: kapasitet.getKapasitetForm(
      state.senteradmin.kapasitet,
      enhetId
    ),
    canEdit: kapasitet.getCanEditKapasitetForm(
      state.senteradmin.kapasitet,
      enhetId
    ),
    formIsValid: kapasitet.getKapasitetFormIsValid(
      state.senteradmin.kapasitet,
      enhetId
    ),
  };
};
export const getHideIdIn = () => [
  'poststed',
  'poststeder',
  'land',
  'kommune',
  'omrade/kommune',
];
