import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { Utsending, UtsendingTypeDokumentType } from 'services/utsending/dto';
import {
  getAllUtsendingByUtsendingType,
  getUtsendingTypeDokumentTypeByUtsendingTypeId,
} from 'services/utsending/utsendingService';
import { FetchResponseError } from 'utils/errors/customError';

import { HvemOgTidspunktParam } from '../../../../utils/interface';

const typeName = 'utsending';

export const fetchUtsendinger = createAsyncThunk<
  Utsending[],
  { utsendingTypeId: number; historikk: boolean },
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}/fetchUtsendinger`,
  async ({ utsendingTypeId, historikk }, { dispatch, rejectWithValue }) => {
    try {
      return await getAllUtsendingByUtsendingType(utsendingTypeId, historikk);
    } catch (error) {
      const err = error as FetchResponseError;

      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    }
  }
);

export const fetchUtsendingtypeDokumenttyper = createAsyncThunk<
  UtsendingTypeDokumentType[],
  { utsendingTypeId: number },
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}/fetchUtsendingtypeDokumenttyper`,
  async ({ utsendingTypeId }, { dispatch, rejectWithValue }) => {
    try {
      return await getUtsendingTypeDokumentTypeByUtsendingTypeId(
        utsendingTypeId,
        { hvemogtidspunkt: HvemOgTidspunktParam.detaljer }
      );
    } catch (error) {
      const err = error as FetchResponseError;

      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    }
  }
);
