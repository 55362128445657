import { DateTime } from 'luxon';
import { buildPath, httpGet } from 'utils/http';

const BASEPATH = 'system';

export const getServertidspunkt = (): Promise<DateTime> => {
  const path = buildPath(`${BASEPATH}/tidspunkt`);
  return httpGet(path)
    .then((result) => DateTime.fromISO(result.tidspunkt))
    .catch((error) => {
      throw new Error('Kunne ikke hente tidspunkt fra server');
    });
};

export const getServertidspunktOrDefaultToClientTime = (): Promise<DateTime> =>
  getServertidspunkt().catch((err) => {
    // eslint-disable-next-line no-console
    console.warn(
      `Baserer tid på nettlesertidspunkt. Feilmelding: ${err.message}`
    );
    return DateTime.now();
  });
