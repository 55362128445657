import { RootState } from 'app/rootReducer';

import { DetaljerState } from './detaljer/interface';
import detaljerSelectors from './detaljer/selector';
import henvendelseSelectors from './henvendelse/selectors';
import { InfoDemaskertState } from './infoDemaskert/interface';
import infoDemaskertSelectors from './infoDemaskert/selector';
import { ManuellHenvisningState, OppfølgingstyperState } from './interface';
import { DeltakerLoggState } from './logg/interface';
import deltakerLogSelectors from './logg/selector';
import { SnutenState } from './snuten/interface';
import snutenSelectors from './snuten/selector';
import oppfølgingspunktSelectors from './oppfølgingspunkt/selectors';
import henvisningSelectors from './henvisning/selector';

export const getDetaljer = (state: RootState): DetaljerState =>
  detaljerSelectors.getDetaljer(state.deltaker.detaljer);

export const getInfoDemaskert = (state: RootState): InfoDemaskertState =>
  infoDemaskertSelectors.getInfoDemaskert(state.deltaker.infoDemaskert);

export const getDeltakerLog = (state: RootState): DeltakerLoggState =>
  deltakerLogSelectors.getDeltakerLog(state.deltaker.deltakerLogg);

export const getSnutenFnr = (state: RootState): SnutenState =>
    snutenSelectors.getSnuten(state.deltaker.snuten);

export const getShouldRefetch = (state: RootState): boolean =>
  deltakerLogSelectors.getShouldRefetch(state.deltaker.deltakerLogg);

export const getOppfølgingspunkt = (state: RootState): OppfølgingstyperState =>
  oppfølgingspunktSelectors.getOppfølgingspunkt(
    state.deltaker.oppfølgingspunkt
  );

export const getManuellHenvisning = (state: RootState): ManuellHenvisningState =>
  henvisningSelectors.getHenvisningSendStatus(
    state.deltaker.henvisning
  );

/* henvendelser */
export const getHenvendelseTyper = (state: RootState) =>
  henvendelseSelectors.getHenvendelseTyper(state.deltaker.henvendelse);

export const getHenvendelseSendStatus = (state: RootState) =>
  henvendelseSelectors.getHenvendelseSendStatus(state.deltaker.henvendelse);

export const getFritekstbrevSendStatus = (state: RootState) =>
  henvendelseSelectors.getFritekstbrevSendStatus(state.deltaker.henvendelse);

export const getHenvendelseKanaler = (state: RootState) =>
  henvendelseSelectors.getHenvendelseKanaler(state.deltaker.henvendelse);

export const getkanalerByHenvendelsestypeId = (
  state: RootState,
  henvendelsesTypeId: number | undefined
) =>
  henvendelseSelectors.getkanalerByHenvendelsestypeId(
    state.deltaker.henvendelse,
    henvendelsesTypeId
  );
