import { useAppDispatch, useAppSelector } from 'app/hooks';
import { postFritekstbrev } from 'features/deltaker/state/henvendelse/sending/thunks';
import { useSysProperties } from 'features/sys';
import React, { useState } from 'react';
import { HenvendelseTypeDto } from 'services/henvendelser/dto';

import {
  AppendedOppfølgingspunktContainer,
  DialogContentWrapper,
  DialogFormButtons,
} from '../components';
import { henvendelseDialogSelector } from '../selectors';
import { FritekstBrevDialogFormProps, FritekstFormState } from './interface';
import { initialFormState } from './utils';
import { BekreftDialog, FritekstBrevFormFields } from '.';

const FritekstBrevDialogForm: React.FC<FritekstBrevDialogFormProps> = ({
  henvendelseTypeId,
  deltakerAdresser,
  effektivPostAdresseIdKryptert,
  close,
}) => {
  const dispatch = useAppDispatch();

  const { isSubmitting, hasError } = useAppSelector(henvendelseDialogSelector);

  const [formState, setFormState] = useState<FritekstFormState>({
    ...initialFormState,
    adresseIdKryptert: effektivPostAdresseIdKryptert,
  });
  const [showOppfølgingsform, setShowOppfølgingsform] = useState(false);
  const [bekreftOpen, setBekreftOpen] = useState<boolean>(false);

  const sysHenvendelseTyper = useSysProperties('henvendelseTyper');
  const henvendelseType = sysHenvendelseTyper.map(
    henvendelseTypeId
  ) as HenvendelseTypeDto;
  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    //https://github.com/eclipsesource/jsonforms/issues/1412 Check added because of this bug reported

    if (name === 'fysiskPost') {
      name && setFormState({ ...formState, [name]: !formState.fysiskPost });
    } else {
      name && setFormState({ ...formState, [name]: value });
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setBekreftOpen(true);
  };

  const handleBekreft = () => {
    setBekreftOpen(false);

    dispatch(
      postFritekstbrev({
        fritekstbrev: {
          hovedoverskrift: formState.hovedoverskrift,
          fritekst: formState.fritekst,
          bareFysiskPost: formState.fysiskPost,
          personAdresseIdKryptert: formState.adresseIdKryptert,
        },
        appendOppfølgingspunkt: showOppfølgingsform,
      })
    );
  };

  const handleShowOppfølgingsformChange = (show: boolean) =>
    setShowOppfølgingsform(show);

  const errorMessage = hasError
    ? {
        title: 'Noe gikk galt ved lagring',
        message: 'Vennligst prøv igjen',
      }
    : undefined;

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContentWrapper
          showDeltakerInfo
          tekstOverskrift={henvendelseType.tekstOverskrift}
          tekstVeiledning={henvendelseType.tekstVeiledning}
          errorMessage={!showOppfølgingsform ? errorMessage : undefined}
          hideDeltakerAdresse={
            !!deltakerAdresser && deltakerAdresser.length > 1
          }>
          <FritekstBrevFormFields
            formState={formState}
            handleChange={handleChange}
            disabled={isSubmitting}
            deltakerAdresser={deltakerAdresser}
            effektivPostAdresseIdKryptert={effektivPostAdresseIdKryptert}
            appendedFritekstbrev={false}
          />
        </DialogContentWrapper>

        <AppendedOppfølgingspunktContainer
          showContent={showOppfølgingsform}
          setShowContent={handleShowOppfølgingsformChange}
          errorMessage={showOppfølgingsform ? errorMessage : undefined}
        />

        <DialogFormButtons
          tekstHandling={henvendelseType.tekstHandling}
          isLoading={isSubmitting}
          close={close}
          disabled={isSubmitting}
        />
      </form>

      <BekreftDialog
        open={bekreftOpen}
        handleBekreft={handleBekreft}
        handleClose={setBekreftOpen}
      />
    </>
  );
};

export default FritekstBrevDialogForm;
