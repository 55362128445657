import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  initialFetchStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';

import { DialogTypesEnum, InfoDemaskertState } from './interface';
import * as thunk from './thunks';

export const initialState: InfoDemaskertState = {
  status: initialFetchStatus,
  dialogOpen: false,
  typeToOpenOnSuccess: DialogTypesEnum.INGEN,
};

export const slice = createSlice({
  name: 'infoDemaskert',
  initialState,
  reducers: {
    resetInfoDemaskert: () => initialState,
    setDemaskerDialogOpen: (
      state,
      {
        payload: { open, deltakerId },
      }: PayloadAction<{
        open: boolean;
        deltakerId?: number;
      }>
    ) => {
      state.dialogOpen = open;
      if (deltakerId) {
        state.deltakerToDemask = deltakerId;
      }
    },
    setTypeToDemask: (
      state,
      {
        payload: { typeToOpenOnSuccess },
      }: PayloadAction<{
        typeToOpenOnSuccess: DialogTypesEnum;
      }>
    ) => {
      state.typeToOpenOnSuccess = typeToOpenOnSuccess;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.demaskDeltaker.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = loadingComplete;
      })
      .addCase(thunk.demaskDeltaker.pending, (state) => {
        state.status = loading;
      })
      .addCase(
        thunk.demaskDeltaker.rejected,
        (state, { error: { message } }) => {
          state.status = rejectedStatus(message);
          state.data = initialState.data;
        }
      );
  },
});

export const { resetInfoDemaskert, setDemaskerDialogOpen, setTypeToDemask } =
  slice.actions;

export default slice.reducer;
