import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost } from 'utils/http';

import { ScreeningsenterInklusjonEstimatListDto } from './dto';
import {
  GetInklusjonsEstimat,
  GetScreeningmetodeFordeling,
  InklusjonsService,
  PostInklusjonerPerSenter,
} from './interface';

const getInklusjonsEstimat: GetInklusjonsEstimat = async (
  tilOgMedDato,
  signal
) => {
  const fullPath = buildPath('enheter/screeningsenterinklusjonestimat', {
    tilOgMedDato,
  });

  try {
    return await httpGet<ScreeningsenterInklusjonEstimatListDto>(
      fullPath,
      signal
    );
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `En feil oppstod ved henting av inklusjon: ${
        (error as FetchResponseError).message
      }`
    );
  }
};

const postInklusjonerPerSenter: PostInklusjonerPerSenter = async (payload) => {
  const fullPath = buildPath('deltakere/inkluder');
  try {
    return await httpPost(fullPath, payload);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `En feil oppstod ved inkludering:  ${
        (error as FetchResponseError).message
      }`
    );
  }
};

const getScreeningmetodeFordeling: GetScreeningmetodeFordeling = async (
  tilOgMedDato,
  korrigertTotaltAntall,
  enhetId,
  signal
) => {
  const fullPath = buildPath(
    `enheter/${enhetId}/screeningmetodefordeling?tilOgMedDato=${tilOgMedDato}&korrigertTotaltAntall=${korrigertTotaltAntall}`
  );
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `En feil oppstod ved henting av inklusjon: ${
        (error as FetchResponseError).message
      }`
    );
  }
};

export default {
  getInklusjonsEstimat,
  postInklusjonerPerSenter,
  getScreeningmetodeFordeling,
} as InklusjonsService;
