import { nanoid } from '@reduxjs/toolkit';
import { actions, interfaces } from 'features/errorHandling';
import { VariantType } from 'features/notification';
import { addNotification } from 'features/notification/notificationSlice';
import {
  FetchResponseError,
  TraceableError,
  createNewTraceableError,
} from 'utils/errors/customError';

export const parseErrorToSerializableObject = (
  error: TraceableError | FetchResponseError | Error
): TraceableError | FetchResponseError => {
  switch (error.constructor) {
    case FetchResponseError:
      return createFetchErrorObjectFromError(error as FetchResponseError);
    case TraceableError:
      return createTraceableObjectFromError(error as TraceableError);
    case Error:
      return createTraceableObjectFromStandardError(error as Error);
    default:
      return createTraceableObjectFromStandardError(
        new Error(typeof error === 'string' ? error : 'Input not serializable')
      );
  }
};

const createErrorObjectFromError = (error: Error): Error => {
  return {
    message: error.message,
    name: error.name,
    stack: error.stack,
  };
};

const createTraceableObjectFromStandardError = (
  error: Error
): TraceableError => {
  return {
    ...createErrorObjectFromError(error),
    traceId: nanoid(),
    timestamp: Date.now().toString(),
  };
};

const createTraceableObjectFromError = (
  error: TraceableError
): TraceableError => {
  return {
    ...createErrorObjectFromError(error),
    timestamp: error.timestamp,
    traceId: error.traceId,
  };
};

const createFetchErrorObjectFromError = (
  error: FetchResponseError
): FetchResponseError => {
  return {
    ...createTraceableObjectFromError(error),
    statuscode: error.statuscode,
  };
};

export const displayNotificationAndLogError = (
  dispatch: interfaces.Dispatcher,
  err: Error,
  variant?: VariantType
) => {
  dispatchError(dispatch, createNewTraceableError(err), interfaces.AddError);
  dispatch(
    addNotification({
      message: { text: err.message },
      variant: variant ?? 'error',
    })
  );
};

//Dispatchers
export const dispatchError = (
  dispatch: interfaces.Dispatcher,
  error: TraceableError,
  type: interfaces.ErrorTypes,
  argument?: string
) => {
  switch (type) {
    case interfaces.AddError:
      dispatch(
        actions.addError({
          error: parseErrorToSerializableObject(error),
          id: nanoid(),
        })
      );
      break;
    case interfaces.ClearErrors:
      dispatch(actions.clearErrors());
      break;
    case interfaces.RemoveErrorById:
      if (argument) {
        dispatch(actions.removeErrorById(argument));
      }
      break;
    case interfaces.RemoveErrorByTraceId:
      if (argument) {
        dispatch(actions.removeErrorByTraceId(argument));
      }
      break;
    default:
      break;
  }
};

export default dispatchError;
