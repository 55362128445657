import { Box, Chip } from '@mui/material';
import { FC } from 'react';
import { SysProperty } from 'services/sys/dto';

import { SelectedChipsProps } from './interface';

export const SelectedChips: FC<SelectedChipsProps> = ({
  kategorier,
  selected,
}) => {
  const onlySelectedValues = kategorier.filter((sysProp) =>
    selected.find((selectedId) => {
      return sysProp.id === selectedId;
    })
  );
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {onlySelectedValues.map((val: SysProperty) => (
        <Chip key={val.id} label={val.beskrivelse} sx={{ m: '2px' }} />
      ))}
    </Box>
  );
};
export default SelectedChips;
