import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import {
  buildPath,
  headersWithXSRF,
  http,
  httpDelete,
  httpGet,
} from 'utils/http';

import { DokumentCreateDto, DokumentUpdateDto } from './dto';
import {
  DeleteDokument,
  DokumentService,
  GetAlleDokumenterByDokumenttype,
  GetDokumentById,
} from './interface';

export const getDokumentById: GetDokumentById = async (
  id,
  params?,
  signal?
) => {
  const fullPath = buildPath(`dokument/${id}`, params);

  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto i henting av dokument id ${id}, følgende feilmelding ble returnert: ${error.message}`
    );
  });
};

export const getAlleDokumenterByDokumenttype: GetAlleDokumenterByDokumenttype =
  async (dokumenttypeId, historikk, signal?) => {
    const fullPath = buildPath(`dokumenter/dokument-type/${dokumenttypeId}`, {
      historikk,
      hvemogtidspunkt: 'detaljer',
    });

    return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto i henting dokumenter, følgende feilmelding ble returnert: ${error.message}`
      );
    });
  };

export const getFlettefeltForDokument = async (id: number) => {
  const fullPath = buildPath(`dokumenter/${id}/flettefelt`);

  return httpGet(fullPath).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto i henting av flettefelt for dokument ${id} følgende feilmelding ble returnert: ${error.message}`
    );
  });
};

export const putDokument = async (dokument: Partial<DokumentUpdateDto>) => {
  const fullPath = buildPath(`dokumenter/${dokument.id}`);
  const dokumentFileBlob = dokument.dokument as Blob;
  const dokumentBlob = new Blob([JSON.stringify(dokument.json)], {
    type: 'application/json',
  });

  const formdata = new FormData();
  formdata.append('dokument', dokumentBlob);
  formdata.append('dokumentFile', dokumentFileBlob);
  const headers = headersWithXSRF();
  headers.delete('Content-Type');

  return http(
    new Request(fullPath, {
      method: 'PUT',
      body: formdata,
      headers,
    })
  ).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved lagring av dokument id ${dokument.id}: ${error.message}`
    );
  });
};

export const postDokument = async (dokument: Partial<DokumentCreateDto>) => {
  const fullPath = buildPath(`dokumenter`);
  const dokumentFileBlob = dokument.dokument as Blob;
  const dokumentBlob = new Blob([JSON.stringify(dokument.json)], {
    type: 'application/json',
  });

  const formdata = new FormData();
  formdata.append('dokument', dokumentBlob);
  formdata.append('dokumentFile', dokumentFileBlob);
  const headers = headersWithXSRF();
  headers.delete('Content-Type');

  return http(
    new Request(fullPath, {
      method: 'POST',
      body: formdata,
      headers,
    })
  ).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved lagring av dokument: ${error.message}`
    );
  });
};

export const deleteDokument: DeleteDokument = async (id) => {
  const fullPath = buildPath(`dokumenter/${id}`);
  return httpDelete(fullPath).catch((error) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto i sletting av dokumenter, følgende feilmelding ble returnert: ${error.message}`
    );
  });
};
export default {
  getDokumentById,
  getAlleDokumenterByDokumenttype,
  getFlettefeltForDokument,
  putDokument,
  postDokument,
  deleteDokument,
} as DokumentService;
