import { createSlice } from '@reduxjs/toolkit';
import {
  initialFetchStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';

import { SnutenState } from './interface';
import * as thunk from './thunks';

export const initialState: SnutenState = {
  status: initialFetchStatus,
};

export const slice = createSlice({
  name: 'snuten',
  initialState,
  reducers: {
    resetSnuten: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.fetchSnutenFnr.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = loadingComplete;
      })
      .addCase(thunk.fetchSnutenFnr.pending, (state) => {
        state.status = loading;
      })
      .addCase(
        thunk.fetchSnutenFnr.rejected,
        (state, { error: { message } }) => {
          state.status = rejectedStatus(message);
          state.data = initialState.data;
        }
      );
  },
});

export const { resetSnuten } = slice.actions;

export default slice.reducer;
