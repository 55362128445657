import { List, ListItem } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from 'app/hooks';
import { getAuth } from 'features/currentBruker/state/selectors';
import { FC, useState } from 'react';

import {
  SIDEBAR_WIDTH,
  Size,
  defaultDrawerWidth,
  minDrawerWidth,
} from '../interface';
import Environment from './Environment';
import Information from './Information';
import { NavBarSideMenuProps } from './interface';
import {
  EnheterSelectButton,
  Links,
  LogOutButton,
  Logo,
  SizeToggler,
  UserName,
} from '.';

const gitInfoEnabled = process.env.VITE_APP_ENV === 'dev';

export const NavBarSideMenu: FC<NavBarSideMenuProps> = ({
  sidebarSize,
  handlePopoverClose,
  handlePopoverOpen,
  setDrawerWidth,
}) => {
  const { currentEnhetId } = useAppSelector(getAuth);

  const isSmallSideBar = sidebarSize === Size.Small;

  const [selectedEnhetId, setSelectedEnhetId] = useState<number | undefined>(
    currentEnhetId
  );

  const toggleDrawerOpenClose = (setOpen: boolean) => {
    if (!setOpen) {
      setDrawerWidth(minDrawerWidth);
      localStorage.setItem(SIDEBAR_WIDTH, `${minDrawerWidth}`);
    } else {
      setDrawerWidth(defaultDrawerWidth);
      localStorage.setItem(SIDEBAR_WIDTH, `${defaultDrawerWidth}`);
    }
  };

  const handleEnhetIdChange = (newEnhetId: number) =>
    setSelectedEnhetId(newEnhetId);

  const hoverProps = {
    onPopoverOpen: isSmallSideBar ? handlePopoverOpen : undefined,
    onPopoverClose: isSmallSideBar ? handlePopoverClose : undefined,
  };

  const gitInfoHoverProps = gitInfoEnabled && {
    onPopoverOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) =>
      handlePopoverOpen(e, <Information />),
    onPopoverClose: handlePopoverClose,
  };
  return (
    <Box
      component="nav"
      sx={{
        position: 'relative',
      }}>
      <SizeToggler size={sidebarSize} onClick={toggleDrawerOpenClose} />
      <List>
        <ListItem sx={{ justifyContent: 'center' }}>
          <Logo size={sidebarSize} {...gitInfoHoverProps} />
        </ListItem>
        <ListItem sx={{ justifyContent: 'center' }}>
          <UserName size={sidebarSize} {...hoverProps} />
        </ListItem>
        <ListItem sx={{ justifyContent: 'center' }}>
          <LogOutButton size={sidebarSize} {...hoverProps} />
        </ListItem>
        <ListItem sx={{ justifyContent: 'center' }}>
          {currentEnhetId && (
            <EnheterSelectButton
              size={sidebarSize}
              currentEnhetId={currentEnhetId}
              onSelect={handleEnhetIdChange}
              {...hoverProps}
            />
          )}
        </ListItem>
        {process.env.VITE_APP_ENV !== 'prod' && (
          <ListItem sx={{ justifyContent: 'center' }}>
            <Environment size={sidebarSize} />
          </ListItem>
        )}
        <ListItem sx={{ p: 0 }}>
          <List
            sx={{
              width: '100%',
              mt: isSmallSideBar ? 1 : 2,
            }}>
            <Links
              size={sidebarSize}
              selectedEnhetId={selectedEnhetId}
              {...hoverProps}
            />
          </List>
        </ListItem>
      </List>
    </Box>
  );
};
export default NavBarSideMenu;
