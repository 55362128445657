import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { DatePicker, DateValidationError } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadingComponent, ScrTextarea } from 'components';
import {
  setInvalidField,
  updateForm,
} from 'features/deltaker/state/oppfølgingspunkt/slice';
import { useManySysProperties } from 'features/sys/hooks';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { OppfølgingCreateDto } from 'services/deltaker/dto';
import {
  PickerFormat,
  PickerProps,
  fromISOToDateTime,
  getDateError,
  isValidDateTime,
} from 'utils/date';

import { HenvendelseSelect } from './';
import {
  OppfølgingspunktFormFieldsProps,
  ValidateFormTypes,
} from './interface';
import OppfølgingUtførtFormFields from './OppfølgingUtførtFields';
import { oppfølgingspunkt } from './selectors';

const OppfølgingspunktFormFields = ({
  hideHenvendelseSelect,
}: OppfølgingspunktFormFieldsProps) => {
  const dispatch = useAppDispatch();

  const {
    formValues,
    invalidFields,
    isUtførtSelected,
    eksternTekstKreverDemaskering,
    eksternTekst,
    isDemaskert,
  } = useAppSelector(oppfølgingspunkt);

  const { loaded, data } = useManySysProperties<
    ['oppfølgingstype', 'oppfølgingsstatus']
  >(['oppfølgingstype', 'oppfølgingsstatus']);

  const [fristDato, setFristDato] = useState<PickerProps>({
    format: PickerFormat.DATE,
    value: null,
  });

  useEffect(() => {
    formValues.fristDato &&
      setFristDato((prev) => {
        return {
          ...prev,
          value: fromISOToDateTime(formValues.fristDato) || null,
          error: undefined,
        };
      });
  }, [formValues.fristDato]);

  const dispatchInvalid = (key: keyof ValidateFormTypes) =>
    dispatch(setInvalidField({ key }));

  const handleDateChange = (value: DateTime | null) => {
    if (isValidDateTime(value)) {
      dispatch(updateForm({ key: 'fristDato', value: value?.toISODate() }));
    } else {
      setFristDato({
        ...fristDato,
        value: value,
        error: 'Ugyldig dato',
      });
      !invalidFields.fristDato && dispatchInvalid('fristDato');
    }
  };

  const handleDateError = (error: DateValidationError) =>
    setFristDato({
      ...fristDato,
      error: error ? getDateError(error) : 'Frist må fylles ut',
    });

  const handleUpdateFormDispatch = (
    key: keyof OppfølgingCreateDto,
    value: any
  ) => dispatch(updateForm({ key, value }));

  const handleInvalid = (event: React.FormEvent) => {
    event.preventDefault();

    const { name } = event.target as HTMLInputElement;

    dispatchInvalid(name as keyof ValidateFormTypes);
  };

  const loadingSys = !loaded('oppfølgingsstatus') || !loaded('oppfølgingstype');
  const oppfølgingsstatuser = data('oppfølgingsstatus');
  const oppfølgingstyper = data<{
    id: number;
    beskrivelse: string;
    manuellRegistrering: boolean;
  }>('oppfølgingstype');

  return (
    <LoadingComponent showLoading={loadingSys}>
      <FormControl
        variant="filled"
        fullWidth
        sx={{ mb: 2 }}
        required
        error={invalidFields.oppfølgingTypeId}>
        <InputLabel id="oppfølgingstype-select-label">
          Oppfølgingstype
        </InputLabel>
        <Select
          labelId="oppfølgingstype-select-label"
          color="secondary"
          onChange={({ target: { value } }) =>
            handleUpdateFormDispatch('oppfølgingTypeId', value)
          }
          onInvalid={handleInvalid}
          required
          name="oppfølgingTypeId"
          value={formValues?.oppfølgingTypeId ?? ''}>
          {oppfølgingstyper &&
            oppfølgingstyper
              ?.sort(
                (a, b) =>
                  Number(b.manuellRegistrering) - Number(a.manuellRegistrering)
              )
              .map(({ id, beskrivelse, manuellRegistrering }) => (
                <MenuItem disabled={!manuellRegistrering} key={id} value={id}>
                  {beskrivelse}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
      {!hideHenvendelseSelect && <HenvendelseSelect />}
      <DatePicker
        label="Frist for oppfølging"
        aria-label="Frist for oppfølging"
        value={fristDato.value}
        format={fristDato.format.inputFormat}
        onChange={handleDateChange}
        onError={handleDateError}
        slotProps={{
          textField: {
            name: 'fristDato',
            error: !!fristDato.error,
            helperText: fristDato.error,
            required: true,
            onInvalid: handleInvalid,
          },
        }}
        sx={{ mb: 2 }}
      />
      <ScrTextarea
        aria-label="fritekst"
        name="tekst"
        minRows={5}
        value={formValues?.tekst ? `${formValues?.tekst}` : ''}
        onChange={({ target: { value } }) =>
          handleUpdateFormDispatch('tekst', value)
        }
      />

      {eksternTekstKreverDemaskering && (
        <ScrTextarea
          placeholder="Ekstern tekst"
          aria-label="ekstern-tekst"
          name="ekstern-tekst"
          disabled
          minRows={5}
          value={
            isDemaskert && eksternTekst ? eksternTekst : '***** ***** *****'
          }
        />
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ color: 'primary.main' }}>
          Status
        </FormLabel>
        <RadioGroup
          aria-label="oppfølgingsstatus"
          name="oppfølgingStatusId"
          value={
            formValues?.oppfølgingStatusId === undefined
              ? oppfølgingsstatuser?.length && oppfølgingsstatuser[0].id
              : Number(formValues.oppfølgingStatusId)
          }
          onChange={({ target: { value } }) =>
            handleUpdateFormDispatch('oppfølgingStatusId', value)
          }>
          {oppfølgingsstatuser?.map(({ id, beskrivelse }) => (
            <FormControlLabel
              key={id}
              value={id}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 20,
                    },
                  }}
                />
              }
              label={beskrivelse}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {isUtførtSelected && (
        <OppfølgingUtførtFormFields onInvalid={handleInvalid} />
      )}
    </LoadingComponent>
  );
};

export default OppfølgingspunktFormFields;
