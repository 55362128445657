import { Checkbox, TableCell, TableRow, TextField } from '@mui/material';
import React from 'react';
import { useState } from 'react';

import { AntallTableRowProps } from './interface';
import { getStrikeThrough } from './utils';

const AntallTableRow: React.FC<AntallTableRowProps> = ({
  antallsbegrensningActive,
  row,
  dataFunctions: { setKorrigertAntall, setSelected },
}) => {
  const [korrigertAntallInput, setKorrigertAntallInput] = useState('');

  const handleAntallChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (setKorrigertAntall) {
      if (!value) {
        setKorrigertAntallInput('');
        setKorrigertAntall(Number(name), undefined);
      } else if (Number.isInteger(Number(value))) {
        setKorrigertAntallInput(value);
        setKorrigertAntall(Number(name), Number(value));
      }
    }
  };

  return (
    <TableRow>
      <TableCell>
        <Checkbox
          checked={row.selected}
          onClick={() => setSelected(row.id, !row.selected)}
        />
      </TableCell>
      <TableCell>{row.text}</TableCell>
      <TableCell
        align="center"
        sx={getStrikeThrough(
          antallsbegrensningActive &&
            row.selected &&
            Number.isInteger(row.korrigertAntall)
        )}>
        {row.selected ? row.antall : '-'}
      </TableCell>
      {antallsbegrensningActive && (
        <TableCell align="center" sx={{ bgcolor: 'scr.grey50' }}>
          {row.selected && (
            <TextField
              name={row.id.toString()}
              value={korrigertAntallInput}
              variant="outlined"
              size="small"
              onChange={handleAntallChange}
              error={(row.korrigertAntall ?? 0) > row.antall}
              sx={{ width: 80 }}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

export default AntallTableRow;
