import { Theme } from '@mui/material/styles';
import { SystemProps } from '@mui/system';
import { FontSize } from 'components/ScrIcon/interface';

import {
  largeIconSize,
  mediumIconSize,
  smallIconSize,
  xlIconSize,
} from '../constants';

export const getSizes = (size: FontSize, theme: Theme): SystemProps => {
  switch (size) {
    case 'small':
      return {
        width: theme.spacing(smallIconSize),
        height: theme.spacing(smallIconSize),
        fontSize: theme.spacing(smallIconSize - 1),
      };
    case 'medium':
      return {
        width: theme.spacing(mediumIconSize),
        height: theme.spacing(mediumIconSize),
        fontSize: theme.spacing(mediumIconSize - 1.5),
      };
    case 'large':
      return {
        width: theme.spacing(largeIconSize),
        height: theme.spacing(largeIconSize),
        fontSize: theme.spacing(largeIconSize - 2),
      };
    case 'xl':
      return {
        width: theme.spacing(xlIconSize),
        height: theme.spacing(xlIconSize),
        fontSize: theme.spacing(xlIconSize - 2.5),
      };
  }
};
