import { createSelector } from '@reduxjs/toolkit';

import { getDetaljer, getInfoDemaskert } from '../state/deltakerSelectors';
import { DetaljerSelector } from './interface';

export const detaljerSelector = createSelector(
  [getDetaljer, getInfoDemaskert],
  (detaljer, infoDemaskert): DetaljerSelector => {
    return {
      loading: detaljer.status.loading,
      loadingComplete: !!detaljer.status.loadingComplete,
      errorMessage: detaljer.status.errorMessage,
      deltakerId: detaljer.data?.deltakerId,
      deltakerdetaljer: detaljer.data,
      isDemaskert: !!infoDemaskert.data,
    };
  }
);
