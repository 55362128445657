import { Button } from '@mui/material';
import { FødselsnummerDeltakerIdField } from 'components';
import {
  TrackProps,
  ValueType,
} from 'components/FødselsnummerDeltakerIdField/interface';
import { useState } from 'react';

const FnrSidExample = () => {
  const [showValidStatus, setShowValidStatus] = useState(false);

  const handleBlur = ({ value, valid, valueType }: TrackProps) => {
    console.log({ value, valid, valueType });
  };

  const handleClick = () => {
    setShowValidStatus(true);
  };

  return (
    <>
      <FødselsnummerDeltakerIdField
        trackValue={handleBlur}
        trackOn={{ blur: true }}
        showValidStatus={showValidStatus}
      />
      <FødselsnummerDeltakerIdField
        restrictToType={ValueType.Fødselsnummer}
        trackValue={handleBlur}
        trackOn={{ blur: true }}
        showValidStatus={showValidStatus}
      />
      <FødselsnummerDeltakerIdField
        restrictToType={ValueType.DeltakerID}
        trackValue={handleBlur}
        trackOn={{ blur: true }}
        showValidStatus={showValidStatus}
      />
      <Button onClick={handleClick} variant="contained">
        Valider
      </Button>
    </>
  );
};

export default FnrSidExample;
