import {
  Alert,
  AlertTitle,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Box } from '@mui/system';
import { ScrWrapper } from 'components';
import { DeltakerInformation } from 'features/deltaker/components';
import { FC } from 'react';

import DemaskerButton from '../../../DemaskerButton';
import { DialogContentWrapperProps } from './interface';

const DialogContentWrapper: FC<DialogContentWrapperProps> = ({
  tekstOverskrift,
  tekstVeiledning,
  showDeltakerInfo,
  errorMessage,
  children,
  showDemasker,
  hideDeltakerAdresse,
}) => {
  return (
    <>
      {tekstOverskrift && (
        <DialogTitle id="henvendelse-dialog-title" style={{ cursor: 'move' }}>
          {tekstOverskrift}
        </DialogTitle>
      )}
      <DialogContent>
        {tekstVeiledning && (
          <DialogContentText>{tekstVeiledning}</DialogContentText>
        )}

        {showDemasker && (
          <Box sx={{ pb: 2 }}>
            <DemaskerButton fullwidth />
          </Box>
        )}
        {showDeltakerInfo && (
          <ScrWrapper variant="dialog">
            <DeltakerInformation
              hideFødselsnummer
              hideDeltakerAdresse={hideDeltakerAdresse}
            />
          </ScrWrapper>
        )}
        <ScrWrapper variant="dialog" sx={{ p: 3 }}>
          {children}
        </ScrWrapper>
        {!!errorMessage && (
          <Alert severity="error" sx={{ mt: 1 }}>
            <AlertTitle>{errorMessage?.title ?? 'Noe gikk galt'}</AlertTitle>
            {errorMessage.message}
          </Alert>
        )}
      </DialogContent>
    </>
  );
};
export default DialogContentWrapper;
