import { FC } from 'react';

import OppfølgingspunktContainer from '../Oppfølgingspunkt';
import DialogContentWrapper from './DialogContentWrapper';
import { AppendedContentButton } from './index';
import { AppendedContentContainerProps } from './interface';

const AppendedOppfølgingspunktContainer: FC<AppendedContentContainerProps> = ({
  showContent,
  setShowContent,
  errorMessage,
}) => {
  return (
    <DialogContentWrapper
      errorMessage={showContent ? errorMessage : undefined}
      hideDeltakerAdresse={false}>
      {showContent ? (
        <>
          <AppendedContentButton
            showContent={setShowContent}
            buttonType="close"
            text="Lukk nytt oppfølgingspunkt"
          />
          <OppfølgingspunktContainer oppfølgingsFormType="APPENDED" />
        </>
      ) : (
        <AppendedContentButton
          showContent={setShowContent}
          buttonType="open"
          text="Legg til oppfølgingspunkt"
        />
      )}
    </DialogContentWrapper>
  );
};

export default AppendedOppfølgingspunktContainer;
