import { EnhetLinkedRettighet } from './interface';

export const addOrUpdateRettigheter = (
  existingRettigheter: readonly EnhetLinkedRettighet[],
  enhetsrettigheter: EnhetLinkedRettighet
) => {
  const enhetPosition = existingRettigheter.findIndex(
    (rettighet) => rettighet.enhetId === enhetsrettigheter.enhetId
  );

  const updatedRettigheter: EnhetLinkedRettighet[] = [...existingRettigheter];

  const addRettigheterToArray = enhetPosition === -1;
  addRettigheterToArray
    ? updatedRettigheter.push(enhetsrettigheter)
    : (updatedRettigheter[enhetPosition] = enhetsrettigheter);
  return updatedRettigheter;
};
