import { createAction, createReducer } from '@reduxjs/toolkit';

import { interfaces } from './';
import {
  AddError,
  ClearErrors,
  RemoveErrorById,
  RemoveErrorByTraceId,
} from './interface';

const initialState: interfaces.State = {
  list: [],
};

const addError = createAction<interfaces.Errors, interfaces.AddErrorType>(
  AddError
);

const removeErrorById = createAction<string, interfaces.RemoveErrorByIdType>(
  RemoveErrorById
);

const removeErrorByTraceId = createAction<
  string,
  interfaces.RemoveErrorByTraceIdType
>(RemoveErrorByTraceId);

const clearErrors = createAction<undefined, interfaces.ClearErrorsType>(
  ClearErrors
);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(addError, (state, action) => {
      state.list.push(action.payload);
    })
    .addCase(removeErrorById, (state, action) => {
      state.list = state.list.filter((err) => err.id !== action.payload);
    })
    .addCase(removeErrorByTraceId, (state, action) => {
      state.list = state.list.filter(
        (err) => err.error.traceId !== action.payload
      );
    })
    .addCase(clearErrors, (state) => {
      state.list = [];
    });
});

export {
  addError,
  removeErrorById,
  clearErrors,
  removeErrorByTraceId,
  initialState,
};

export default reducer;
