import { HenvendelseTypeHenvendelseKanal, KanalerState } from './interface';

export const getHenvendelseKanaler = (state: KanalerState): KanalerState =>
  state;

export const getkanalerByHenvendelsestypeId = (
  state: KanalerState,
  henvendelsestypeId: number | undefined
): HenvendelseTypeHenvendelseKanal | undefined => {
  if (henvendelsestypeId) {
    if (state[henvendelsestypeId]) {
      return state[henvendelsestypeId];
    }
  }
  return undefined;
};

const selectors = {
  getHenvendelseKanaler,
  getkanalerByHenvendelsestypeId,
};

export default selectors;
