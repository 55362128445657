import { lazy } from 'react';

const GoBack = lazy(() => import('app/routes/GoBack'));

const AuthRoute = lazy(() => import('./AuthRoute'));

const HelsepersonellOversiktPage = lazy(
  () => import('features/skopør/HelsepersonellOversiktPage')
);
const SkopørPage = lazy(
  () => import('features/skopør/kvalitetskrav/SkopørPage/SkopørPage')
);
const SkopørReservasjonPage = lazy(
  () => import('features/skopør/skopørreservasjon/SkopørReservasjonPage')
);
const ReserverteSkopørerPage = lazy(
  () => import('features/skopør/skopørreservasjon/ReserverteSkopørerPage')
);
const HelsepersonellRollerPage = lazy(
  () => import('features/skopør/helsepersonell-roller/HelsepersonellRollerPage')
);

const HelsepersonellNy = lazy(
  () =>
    import(
      'features/skopør/helsepersonell-roller/editor/NyHelsepersonell/HelsepersonellNy'
    )
);

const HelsepersonellRediger = lazy(
  () =>
    import(
      'features/skopør/helsepersonell-roller/editor/Redigerhelsepersonell/HelsepersonellRediger'
    )
);

export const helsepersonellRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <HelsepersonellOversiktPage />
      </AuthRoute>
    ),
  },
  {
    path: 'informasjon',
    element: (
      <AuthRoute>
        <GoBack />
        <SkopørPage />
      </AuthRoute>
    ),
  },
  {
    path: 'reservasjon',
    element: (
      <AuthRoute>
        <GoBack />
        <SkopørReservasjonPage />
      </AuthRoute>
    ),
  },
  {
    path: 'reserverte',
    element: (
      <AuthRoute>
        <GoBack />
        <ReserverteSkopørerPage />
      </AuthRoute>
    ),
  },
  {
    path: 'helsepersonell-roller',
    element: (
      <AuthRoute>
        <GoBack />
        <HelsepersonellRollerPage />
      </AuthRoute>
    ),
  },

  {
    path: 'opprett-helsepersonell',
    element: (
      <AuthRoute>
        <GoBack />
        <HelsepersonellNy />
      </AuthRoute>
    ),
  },
  {
    path: 'rediger-helsepersonell/:id/:enhetId',
    element: (
      <AuthRoute>
        <GoBack />
        <HelsepersonellRediger />
      </AuthRoute>
    ),
  },
];
