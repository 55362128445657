import { AuthState } from './interface';

export const getAuth = (state: AuthState): AuthState => state;

export const getCurrentBrukerId = (state: AuthState): number | undefined =>
  state.data?.id;

export const getCurrentUserName = (state: AuthState): string | undefined =>
  state.data?.userName;

export const getIsAuthorized = (state: AuthState): boolean =>
  !!state.data?.id && state.data.authority === 'ROLE_ACTIVE';

export const getCurrentEnhetId = (state: AuthState): number | undefined =>
  state.currentEnhetId;

const selectors = {
  getAuth,
  getCurrentBrukerId,
  getIsAuthorized,
  getCurrentEnhetId,
  getCurrentUserName,
};

export default selectors;
