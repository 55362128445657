import { Avatar, Box, ListItemAvatar } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/rootReducer';
import { useMemo } from 'react';

import { Size } from '../interface';
import { GetUserNameData, getUserNameDataFactory } from './selectors';

type Props = {
  size: Size;
  onPopoverOpen?: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    content: string
  ) => void;
  onPopoverClose?: VoidFunction;
};

const UserName: React.FC<Props> = ({
  size,
  onPopoverOpen,
  onPopoverClose,
}: Props) => {
  //https://react-redux.js.org/api/hooks#using-memoizing-selectors
  const getUsernameFactory = useMemo(getUserNameDataFactory, []);

  const { fullName, initials } = useAppSelector<GetUserNameData>(
    (state: RootState) => getUsernameFactory(state)
  );

  return size === Size.Small ? (
    <ListItemAvatar
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          border: 1,
          borderColor: 'scr.grey600',
        }}>
        <Avatar
          aria-label="avatar"
          sx={{
            width: 50,
            height: 50,
            backgroundColor: 'common.white',
            color: 'scr.grey600',
            fontWeight: 'bolder',
          }}
          onMouseEnter={(e) => onPopoverOpen!(e, fullName)}
          onMouseLeave={onPopoverClose}>
          {initials}
        </Avatar>
      </Box>
    </ListItemAvatar>
  ) : (
    <Box sx={{ color: 'common.black', fontWeight: 'bold', fontSize: 16 }}>
      {fullName}
    </Box>
  );
};

export default UserName;
