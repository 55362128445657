import { Box, TableBody, TableCell, TableRow } from '@mui/material';

import { AcceptableRowValues, Data, Order, RowProps } from './interfaces';

type EnhancedTableBodyProps = {
  rows: RowProps[];
  order: Order;
  orderBy: keyof Data<AcceptableRowValues>;
  dense: boolean;
  onRowClick?: (index: number, row: any) => void;
  page: number;
  border: boolean;
  rowsPerPage: number;
  pagination: boolean;
  handleMouseEnter: (
    event: React.MouseEvent<HTMLElement>,
    text: string
  ) => void;
  handleMouseLeave: (event: React.MouseEvent<HTMLElement>) => void;
};

const EnhancedTableBody: React.FC<EnhancedTableBodyProps> = ({
  rows,
  page,
  rowsPerPage,
  dense,
  onRowClick,
  pagination,
  border,
  handleMouseEnter,
  handleMouseLeave,
}: EnhancedTableBodyProps) => {
  const rowStart = pagination ? page * rowsPerPage : 0;
  const rowEnd = pagination ? page * rowsPerPage + rowsPerPage : rows.length;

  const emptyRows = pagination
    ? rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)
    : 0;

  const onMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    hovertekst: string
  ) => {
    handleMouseEnter(event, hovertekst);
  };

  return (
    <TableBody>
      {
        // stableSort(rows, getComparator(order, orderBy))
        //TODO: SCREENIT-1978 Legge på en "hentFlere" metode når listen blir for lang, er veldig preformance heavy (load on scroll, kan ligge i cach frontend)

        rows.slice(rowStart, rowEnd).map(({ data, config }) => {
          const labelId = `enhanced-table-checkbox-${data.rowIndex}`;
          const isClickableRow: boolean = !!config?.clickable && !!onRowClick;
          return (
            <TableRow
              hover
              key={labelId}
              onClick={() => isClickableRow && onRowClick!(data.rowIndex, data)}
              onMouseEnter={(ev) =>
                config?.hovertekst && onMouseEnter(ev, config.hovertekst)
              }
              onMouseLeave={handleMouseLeave}
              sx={{ ...(isClickableRow && { cursor: 'pointer' }) }}>
              {Object.keys(data)
                .filter((key) => key !== 'rowIndex')
                .map((key) => (
                  <TableCell
                    key={key}
                    sx={{
                      width: data[key].width,
                      fontSize: 16,
                      ...(!border && { borderBottom: 'none' }),
                    }}>
                    {data[key].status && (
                      <Box
                        component="span"
                        sx={{ color: data[key].status.color }}>
                        {data[key].status.text}:{' '}
                      </Box>
                    )}
                    {data[key].displayValue}
                  </TableCell>
                ))}
            </TableRow>
          );
        })
      }
      {emptyRows > 0 && (
        <TableRow sx={{ height: (dense ? 33 : 53) * emptyRows }}>
          <TableCell
            colSpan={6}
            sx={{ ...(!border && { borderBottom: 'none' }) }}
          />
        </TableRow>
      )}
    </TableBody>
  );
};

export default EnhancedTableBody;
