import './routeStyles.css';

import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import LoadingApp from 'app/LoadingApp';
import {
  DemaskertSelector,
  demaskertSelector,
  getAppInfo,
} from 'app/selectors';
import { DisplayError, Notifier, SideBar } from 'components';
import { ErrorTypeEnum } from 'components/Error/interface';
import { fetchCurrentBruker } from 'features/currentBruker';
import { DemaskerÅrsakDialog } from 'features/deltaker/components';
import { setDemaskerDialogOpen } from 'features/deltaker/state/infoDemaskert/slice';
import { demaskDeltaker } from 'features/deltaker/state/infoDemaskert/thunks';
import { Suspense, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

export default function Root() {
  const dispatch = useAppDispatch();
  const [isAuthed, setIsAuthed] = useState<boolean>();

  const isLoggetUtContext = window.location.pathname === '/logget-ut';

  const { hasHelseIdToken, loadingComplete, isLoading } = useAppSelector(
    (state) => getAppInfo(state)
  );
  const { dialogOpen, deltakerId } =
    useAppSelector<DemaskertSelector>(demaskertSelector);
  useEffect(() => {
    if (!isLoading) {
      if (!isLoggetUtContext && !loadingComplete)
        dispatch(fetchCurrentBruker());
      else if (isAuthed === undefined) setIsAuthed(hasHelseIdToken);
    }
  }, [
    dispatch,
    loadingComplete,
    isLoading,
    isLoggetUtContext,
    isAuthed,
    hasHelseIdToken,
  ]);

  if (isLoggetUtContext && isAuthed === false)
    return <DisplayError errorType={ErrorTypeEnum.LoggedOut} />;

  const waitingForHelseIdToken =
    !hasHelseIdToken && !loadingComplete && isLoading;

  const showSpinner = waitingForHelseIdToken || isAuthed === undefined;
  const handleCloseDemaskerReason = (årsakId?: number | undefined) => {
    dispatch(setDemaskerDialogOpen({ open: false }));
    if (årsakId && deltakerId) {
      dispatch(
        demaskDeltaker({
          deltakerId,
          årsakId,
        })
      );
    }
  };
  return (
    <Box sx={{ display: 'flex' }}>
      {!showSpinner && <SideBar />}
      <Box sx={{ flexGrow: 1, p: { xs: 4, md: 2, xl: 4 } }}>
        <Notifier />
        <main role="main">
          {showSpinner ? (
            <LoadingApp />
          ) : (
            <Suspense fallback={<LoadingApp />}>
              <Outlet />
            </Suspense>
          )}
          {isAuthed && (
            <DemaskerÅrsakDialog
              open={dialogOpen}
              close={handleCloseDemaskerReason}
            />
          )}
        </main>
      </Box>
    </Box>
  );
}
