import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import dashboardService from 'services/dashboard';
import { FetchResponseError } from 'utils/errors/customError';
import { DashboardData } from 'services/dashboard/dto';
import { RootState } from 'app/rootReducer';

const typeName = 'dashboard';
export const fetchDashboardData = createAsyncThunk<
  DashboardData,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
  }
>(
  `${typeName}/fetchDashboardData`,
  // eslint-disable-next-line no-shadow-restricted-names
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      return await dashboardService.getDashboardData();
    } catch (error) {
      const err = error as FetchResponseError;

      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    }
  }
);
