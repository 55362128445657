import { Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

type Props = {
  title: string;
  value?: string | ReactNode;
  left?: number;
  right?: number;
};

const DataRow: FC<Props> = ({ title, value, left = 5, right = 7 }) => {
  return (
    <Grid container item>
      <Grid item xs={left}>
        <Typography variant="subtitle2">{title}:</Typography>
      </Grid>
      <Grid item xs={right}>
        {typeof value === 'string' ? (
          <Typography variant="body2">{value}</Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>
  );
};

export default DataRow;
