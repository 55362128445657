import { MenuProps } from '@mui/material';

export const menuProps: Partial<MenuProps> = {
  variant: 'menu',
  PaperProps: {
    sx: {
      width: 250,
    },
  },
};
