import { FetchStatus } from 'features/utils';
import { DeltakerInfoDemaskertDto } from 'services/deltaker/dto';

export enum DialogTypesEnum {
  HENVENDELSE,
  ADRESSEHISTORIKK,
  KRR,
  INGEN,
}

export type InfoDemaskertState = {
  data?: DeltakerInfoDemaskertDto;
  status: FetchStatus;
  dialogOpen: boolean;
  deltakerToDemask?: number;
  typeToOpenOnSuccess: DialogTypesEnum;
};
