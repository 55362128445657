import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost, httpPut } from 'utils/http';

import {
  FritekstbrevWithOppfølgingspunktDto,
  FritekstbrevWithoutOppfølgingspunktDto,
} from './dto';
import {
  DeltakerutsendingerService,
  GetDeltakerUtsendingDetaljer,
  GetDeltakerUtsendingPersondata,
  ResendBrev,
  SendFritekstbrevToDeltaker,
  SendFritekstbrevWithOppfølgingspunkt,
  UpdateManuellDeltakerUtsendingStatus,
} from './interface';

const basePath = 'deltakerutsendinger';

const getUtsendingPersondata: GetDeltakerUtsendingPersondata = async (
  deltakerUtsendingId?,
  signal?
) => {
  const fullPath = buildPath(`${basePath}/${deltakerUtsendingId}/persondata`);

  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppstod i henting av forsendelsesinformasjon for deltajerUtsendingId ${deltakerUtsendingId}: ${error.message}`
    );
  });
};

export const getDeltakerUtsendingDetaljer: GetDeltakerUtsendingDetaljer =
  async (deltakerUtsendingId, detailParams, signal) => {
    const fullPath = buildPath(
      `${basePath}/${deltakerUtsendingId}`,
      detailParams
    );

    return httpGet(fullPath, signal)
      .then((res) => res)
      .catch((error) => {
        if (!signal?.aborted) {
          const err = error as FetchResponseError;
          throw addMessageToExsistingFetchError(
            err,
            `En feil oppsto ved henting av deltakerutsending visningsdetaljer: ${err.message}`
          );
        }
      });
  };

export const sendFritekstbrevToDeltaker: SendFritekstbrevToDeltaker = async (
  deltakerId,
  fritekstbrevWithoutOppfølgingspunkt: FritekstbrevWithoutOppfølgingspunktDto
) => {
  const fullPath = buildPath(
    `${basePath}/deltakere/${deltakerId}/utsendinger/fritekstbrev`
  );
  return httpPost(fullPath, fritekstbrevWithoutOppfølgingspunkt)
    .then((res) => res)
    .catch((error) => {
      const err = error as FetchResponseError;
      throw addMessageToExsistingFetchError(
        err,
        `Klarte ikke å sende fritekstbrev: ${error.message}`
      );
    });
};

export const sendFritekstbrevWithOppfølgingspunkt: SendFritekstbrevWithOppfølgingspunkt =
  async (
    deltakerId,
    fritekstbrevWithOppfølgingspunkt: FritekstbrevWithOppfølgingspunktDto
  ) => {
    const fullPath = buildPath(
      `${basePath}/deltakere/${deltakerId}/utsendinger/fritekstbrev`
    );
    return httpPost(fullPath, fritekstbrevWithOppfølgingspunkt).catch(
      (error: FetchResponseError) => {
        throw addMessageToExsistingFetchError(
          error,
          'klarte ikke lagre fritekstbrev med oppfølgingspunkt'
        );
      }
    );
  };

export const updateManuellDeltakerUtsendingStatus: UpdateManuellDeltakerUtsendingStatus =
  async (deltakerUtsendingId, dto, detailParams) => {
    const fullPath = buildPath(
      `${basePath}/${deltakerUtsendingId}/manuell-status-og-formidlingskanal`,
      detailParams
    );

    return httpPut(fullPath, dto).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        'Kunne ikke oppdatere status på utsending'
      );
    });
  };

export const resendBrev: ResendBrev =
  async (deltakerUtsendingId) => {
    const fullPath = buildPath(
      `${basePath}/${deltakerUtsendingId}/resend`
    );

    return httpPost(fullPath).catch((error: FetchResponseError) => {
      throw addMessageToExsistingFetchError(
        error,
        'Kunne ikke resende utsending'
      );
    });
  };

export default {
  getDeltakerUtsendingDetaljer,
  getUtsendingPersondata,
  sendFritekstbrevToDeltaker,
  sendFritekstbrevWithOppfølgingspunkt,
  updateManuellDeltakerUtsendingStatus,
  resendBrev,
} as DeltakerutsendingerService;
