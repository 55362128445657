import { combineReducers } from '@reduxjs/toolkit';

import systemtabeller from '../systemtabeller/state/slice';
import kapasitet from '../kapasitet/state/slice';


const senteradminReducer = combineReducers({
  systemtabeller,
  kapasitet
});

export default senteradminReducer;
