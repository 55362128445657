export const paths = {
  adresseTyper: 'adresser/typer',
  adresseInnholdstyper: 'adresser/innholdstyper',
  deltakerForholdType: 'deltakere/forhold/typer',
  deltakerForholdUnderType: 'deltakere/forhold/undertyper',
  deltakerUtsendingStatus: 'deltaker-utsending-status',
  deltakerUtsendingStatusGruppe: 'deltaker-utsending-status/grupper',
  dokumentTyper: 'dokumenttype',
  flettefelt: 'flettefelt',
  enhetTyper: 'enheter/typer',
  folkeregisterstatuser: 'deltakere/folkeregisterstatuser',
  filformat: 'filformat',
  formidlingskanal: 'formidlingskanal',
  henvendelseKanaler: 'henvendelser/kanaler',
  henvendelseTyper: 'henvendelser/typer',
  henvendelseKategorier: 'henvendelser/kategorier',
  hendelsetype: 'hendelser/hendelsetype',
  hendelsekontrollStatus: 'hendelsekontroll/status',
  hendelsekontrollMeldingStatus:
    'hendelsekontroll/loggkontroll-meldinger/status',
  hendelsekontrollMeldingType: 'hendelsekontroll/loggkontroll-meldinger/type',
  loggradTyper: 'loggrad-type',
  deltakerNotatTyper: 'notat/typer',
  oppfølgingsstatus: 'oppfolginger/statuser',
  oppfølgingstype: 'oppfolginger/typer',
  representasjonType: 'representasjon-type',
  roller: 'roller',
  språk: 'sprak',
  fargetyper: 'fargetyper',
  utsendingTyper: 'utsendingstyper',
  utsendingTypeKategorier: 'utsendingstyper/kategorier',
  visningsgrunn: 'hendelser/visningsgrunn',
  henvisningTyper: 'henvisninger/typer',
  omradeTyper: 'omrade/typer',
  screeningmetoder: 'system/typer/screeningmetoder',
  oppfolgingStatus: 'oppfolginger/statuser',
  opplistingstyper: 'opplisting/typer',
  provesvarStatus: 'ifobt/provesvar/statuser',
  innstillinger: 'innstillinger',
  henvisningerSvarTyper: 'henvisninger/svar/typer',
  ifobtPrøvesvarKonklusjoner: 'ifobt/provesvar/konklusjoner',
  ifobtPrøvesvarKonklusjonGrupper: 'ifobt/provesvar/konklusjoner/gruppe',
  ifobtPrøvesvarStatuser: 'ifobt/provesvar/statuser',
  henvisningerStatus: 'henvisninger/status',
  henvisningerStatusGruppe: 'henvisninger/status/grupper',
  helsemeldingerFeilStatuser: 'helsemeldinger/feil/statuser',
  helsemeldingerFeilTyper: 'helsemeldinger/feil/typer',
  helsemeldingerTyper: 'helsemeldinger/typer',
  logistikkpartnerForsendelseStatuser: 'logistikkpartnerforsendelser/statuser',
  koloskopirapporterStatuser: 'koloskopirapporter/statuser',
  kommunikasjonsretning: 'kommunikasjonsretning',
  helsepersonellRolleType: 'helsepersoneller/rolle/type',
  tilleggsinfoType: 'deltakere/forhold/tilleggsinfo/type',
};
