import { DateTime } from 'luxon';

export const STANDARD_DATE_FORMAT = 'dd.MM.yyyy';
export const STANDARD_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
export const STANDARD_DATE_TIME_FORMAT_INCLUDING_SECONDS =
  'dd.MM.yyyy HH:mm:ss';

export type FormatType = `date` | 'datetime' | 'datetimeincludingseconds';

export type OutputType = 'date' | 'datetime';

export class PickerFormat {
  static readonly DATE = new PickerFormat(STANDARD_DATE_FORMAT, 'date');
  static readonly DATE_TIME = new PickerFormat(
    STANDARD_DATE_TIME_FORMAT,
    'datetime'
  );
  static readonly DATE_TO_DATE_TIME = new PickerFormat(
    STANDARD_DATE_FORMAT,
    'datetime'
  );

  readonly inputFormat: string;
  readonly outputType: OutputType;

  private constructor(inputFormat: string, outputType: OutputType) {
    this.inputFormat = inputFormat;
    this.outputType = outputType;
  }
}

export type PickerProps = {
  value: DateTime | null;
  format: PickerFormat;
  error?: string;
  displayError?: boolean;
};

export type ReduxPickerProps = {
  value: number | null;
  error?: string;
  displayError?: boolean;
};
