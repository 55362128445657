export const FØDSELSNUMMER_LENGTH = 11;
export const MAX_SCREENITID_LENGTH = 9;

export enum ValueType {
  DeltakerID,
  Fødselsnummer,
}

export type TrackProps = {
  value: string;
  valid: boolean;
  valueType?: ValueType;
};
