import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEnheterForBrukeradmin } from 'services/bruker/brukerService';
import { EnhetDto } from 'services/enheter/dto';
import { TraceableError } from 'utils/errors/customError';

import { BrukerAdminEnehterFetchInput } from './interface';

export const fetchBrukeradminEnheter = createAsyncThunk<
  EnhetDto[],
  BrukerAdminEnehterFetchInput,
  {
    rejectValue: string;
  }
>(
  'currentBruker/fetchBrukeradminEnheter',
  async (
    brukerAdminEnehterInput: BrukerAdminEnehterFetchInput,
    { rejectWithValue }
  ) => {
    try {
      const { brukerId, signal } = brukerAdminEnehterInput;
      return getEnheterForBrukeradmin(brukerId, signal);
    } catch (e) {
      const err = e as TraceableError;
      return rejectWithValue(err.message);
    }
  }
);
