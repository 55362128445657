import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SysRowFormState, SysRowFormStateForm } from './interface';

export const initialState: SysRowFormState = {
  form: {
    id: undefined,
  },
  errors: {
    id: {
      errorText: '',
      invalid: false,
      display: false,
    },
  },
  type: undefined,
  isModalOpen: false,
};

const slice = createSlice({
  name: 'sysTableForm',
  initialState,
  reducers: {
    resetForm: () => initialState,
    setIsModalOpen: (
      state,
      {
        payload: { isOpen, type },
      }: PayloadAction<{ isOpen: boolean; type: SysRowFormState['type'] }>
    ) => {
      if (isOpen === false) {
        state.form = initialState.form;
        state.errors = initialState.errors;
      }

      state.isModalOpen = isOpen;
      state.type = type;
    },
    setForm: (state, action: PayloadAction<Partial<SysRowFormStateForm>>) => {
      state.form = action.payload;
    },
    updateForm: (
      state,
      {
        payload: { key, value },
      }: PayloadAction<{
        key: keyof SysRowFormStateForm;
        value: string | number | boolean;
      }>
    ) => {
      state.form[key] = value;
      if (state.errors[key]) {
        state.errors[key].display = false;
      }
    },
    updateFormError: (
      state,
      {
        payload: { key, errorText, isInvalid },
      }: PayloadAction<{
        key: string;
        errorText: string;
        isInvalid: boolean;
      }>
    ) => {
      return {
        ...state,
        errors: {
          ...state.errors,
          [key]: {
            errorText: errorText,
            invalid: isInvalid,
            display: true,
          },
        },
      };
    },
    setFormType: (state, action: PayloadAction<SysRowFormState['type']>) => {
      state.type = action.payload;
    },
  },
});

export const {
  resetForm,
  updateForm,
  setFormType,
  setForm,
  setIsModalOpen,
  updateFormError,
} = slice.actions;

export default slice.reducer;
