import { createSelector } from '@reduxjs/toolkit';
import { detaljerSelector } from 'features/deltaker/details/selectors';
import { getInfoDemaskert } from 'features/deltaker/state/deltakerSelectors';
import {
  DeltakerAdresseDto,
  DeltakerInfoDemaskertDto,
} from 'services/deltaker/dto';
import { joinNoBlanks } from 'utils';

import { DemaskertSelector } from './interface';
import { getRettigheter } from 'features/currentBruker/state/selectors';
import { RettighetValues } from 'features/currentBruker/state/allowedRoutes/interface';

const getNavn = (data?: DeltakerInfoDemaskertDto | undefined) => {
  if (!data) return undefined;

  const { fornavn, etternavn, mellomnavn } = data;

  if (!etternavn && !fornavn) return 'Ukjent navn';

  return `${etternavn}, ${joinNoBlanks([fornavn, mellomnavn])}`;
};

const getBostedsadresse = (adresser?: DeltakerAdresseDto[]) => {
  if (!adresser) return undefined;

  return (
    adresser.find((adresse) => adresse.adresseType === 1)?.adresse ||
    'Ingen bostedsadresse registrert.'
  );
};

export const canAccessSnutenSelector = createSelector(getRettigheter, (rettigheter) => ({
  canAccessSnuten: !!rettigheter.data[0]?.rettigheter.find(
      (rettighet) => rettighet.id === RettighetValues.TILGANG_TIL_SNUTEN_KRYPTERT_FØDSELSNUMMER
  ),
}));

export const demaskertSelector = createSelector(
  [detaljerSelector, getInfoDemaskert],
  (detaljer, infoDemaskert): DemaskertSelector => {
    const { data } = infoDemaskert;

    const alder = detaljer.deltakerdetaljer?.alder;
    const fødselsnummer =
      data?.fødselsnummer &&
      [data.fødselsnummer.slice(0, 6), ' ', data.fødselsnummer.slice(6)].join(
        ''
      );

    return {
      isDemaskert: !!data,
      fødselsnummer: fødselsnummer ?? `*********** (${alder} år)`,
      navn: getNavn(data) ?? '****** ******** **********',
      bostedsadresse:
        getBostedsadresse(data?.adresse) ?? '*************** **********',
      adresser: data?.adresse,
    };
  }
);
