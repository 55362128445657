import { createSlice } from '@reduxjs/toolkit';

import { DashboardState } from './interface';

import { initialFetchStatus, rejectedStatus } from 'features/utils';
import { fetchDashboardData } from './dashboardThunk';
import { dashboardDataInitialState } from 'services/dashboard/dto';

export const initialState: DashboardState = {
  data: dashboardDataInitialState,
  status: initialFetchStatus,
};

const slice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status.loading = false;
        state.status.loadingComplete = true;
      })
      .addCase(fetchDashboardData.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(fetchDashboardData.rejected, (state, { error: { message } }) => {
        state.status = rejectedStatus(message);
        state.status.loadingComplete = true;
      });
  },
});

export default slice.reducer;
