import { Box, ListItemAvatar } from '@mui/material';
import { red900 } from 'theme';

import { Size } from '../interface';

type Props = {
  size: Size;
};

const Environment: React.FC<Props> = ({ size }: Props) => {
  return size === Size.Small ? (
    <ListItemAvatar
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          color: red900,
          textTransform: 'uppercase',
          border: 1,
          borderColor: 'scr.grey600',
        }}>
        {process.env.VITE_APP_ENV}
      </Box>
    </ListItemAvatar>
  ) : (
    <Box
      sx={{
        color: red900,
        fontWeight: 'bold',
        fontSize: 16,
        textTransform: 'uppercase',
      }}>
      {process.env.VITE_APP_ENV}
    </Box>
  );
};

export default Environment;
