import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus, loading, loadingComplete } from 'features/utils';
import { EnhetDto } from 'services/enheter/dto';

import { fetchEnheter } from './enheterThunk';
import { EnheterState } from './interface';

export const enheterAdapter = createEntityAdapter<EnhetDto, number>({
  selectId: (enhet) => enhet.id,
});

export const initialState: EnheterState = {
  data: enheterAdapter.getInitialState(),
  status: initialFetchStatus,
};

const slice = createSlice({
  name: 'enheter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnheter.pending, (state) => {
        state.status = loading;
      })
      .addCase(fetchEnheter.fulfilled, (state, { payload: enheter }) => {
        state.status = loadingComplete;
        enheterAdapter.setAll(state.data, enheter);
      })
      .addCase(fetchEnheter.rejected, (state, { payload: errorMessage }) => {
        state.status = { ...initialFetchStatus, errorMessage };
      });
  },
});

export default slice.reducer;
