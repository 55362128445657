import { createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus } from 'features/utils';

import { RettigheterState } from './interface';
import { fetchRettigheter } from './thunks';
import { addOrUpdateRettigheter } from './utils';

export const initialRettigheterState: RettigheterState = {
  data: [],
  status: initialFetchStatus,
};

export const initialState: RettigheterState = initialRettigheterState;

export const slice = createSlice({
  name: 'rettigheter',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        fetchRettigheter.fulfilled,
        (state, { payload: enhetsrettigheter }) => {
          state.data = addOrUpdateRettigheter(state.data, enhetsrettigheter);
          state.status = { loading: false, loadingComplete: true };
        }
      )
      .addCase(fetchRettigheter.pending, (state) => {
        state.status.loading = true;
        state.status.loadingComplete = false;
      })
      .addCase(fetchRettigheter.rejected, (state, { error: { message } }) => {
        state.data = initialRettigheterState.data;
        state.status = { loading: false, errorMessage: message };
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
