import { Typography } from '@mui/material';
import { FC } from 'react';

import { FritekstBrevFormFields } from '../fritekstBrev';
import { FritekstBrevFormFieldsProps } from '../fritekstBrev/interface';
import { AppendedContentButton, DialogContentWrapper } from './index';
import { AppendedContentContainerProps } from './interface';

const AppendedFritekstbrevContainer: FC<
  AppendedContentContainerProps & FritekstBrevFormFieldsProps
> = ({
  showContent,
  setShowContent,
  errorMessage,
  handleChange,
  formState,
  disabled,
  appendedFritekstbrev,
}) => {
  return (
    <DialogContentWrapper errorMessage={showContent ? errorMessage : undefined}>
      {showContent ? (
        <>
          <AppendedContentButton
            showContent={setShowContent}
            buttonType="close"
            text="Lukk fritekstbrev"
          />
          <Typography component="h2" variant="tableTitle" sx={{ mb: 2 }}>
            Fritekstbrev
          </Typography>
          <FritekstBrevFormFields
            handleChange={handleChange}
            formState={formState}
            disabled={disabled}
            appendedFritekstbrev={appendedFritekstbrev}
          />
        </>
      ) : (
        <AppendedContentButton
          showContent={setShowContent}
          buttonType="open"
          text="Fritekstbrev"
        />
      )}
    </DialogContentWrapper>
  );
};

export default AppendedFritekstbrevContainer;
