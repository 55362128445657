import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AppDispatch } from 'app/store';
import { isArray } from 'lodash';
import { memoize } from 'proxy-memoize';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SysProperties, SysProperty } from 'services/sys/dto';
import { useParamSelectorWithMemoize } from 'utils/hooks/useParamSelector';

import {
  GetSysStateObjectBySysKeyWithKey,
  GetSysStateObjectBySyskey,
  sysSelectors,
} from './interface';
import {
  getSysStateObjectBySyskey,
  getSysStateObjectBySyskeys,
} from './selectors';
import { fetchSysProperties } from './sysPropertiesThunk';

export const useSysProperties = <T extends SysProperty>(
  sysKey: keyof SysProperties
) => {
  const { data, status } = useParamSelectorWithMemoize<GetSysStateObjectBySyskey>(
    getSysStateObjectBySyskey,
    sysKey
  );
  const dispatch = useDispatch<AppDispatch>();
  const { selectById, selectAll } = sysSelectors;

  useEffect(() => {
    const prm = dispatch(fetchSysProperties(sysKey));
    return () => prm.abort();
  }, [dispatch, sysKey]);

  const map = (id?: number): T | SysProperty | undefined => {
    return selectById(data, id || -1);
  };

  const mapBeskrivelse = (id?: number): string => {
    return map(id)?.beskrivelse || '';
  };

  return {
    map,
    mapBeskrivelse,
    data: selectAll(data) as T[],
    isLoaded: status.loadingComplete ?? false,
  };
};

export const useManySysProperties = <U extends Array<keyof SysProperties>>(
  syskeys: keyof SysProperties | Array<keyof SysProperties>
) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector<Array<GetSysStateObjectBySysKeyWithKey>>(
    memoize((state) =>
      isArray(syskeys)
        ? getSysStateObjectBySyskeys(
            state,
            syskeys as Array<keyof SysProperties>
          )
        : [{ key: syskeys, ...getSysStateObjectBySyskey(state, syskeys) }]
    )
  );

  const { selectById, selectAll } = sysSelectors;

  useEffect(() => {
    if (isArray(syskeys)) {
      for (let i = 0; i < syskeys.length; i++) {
        dispatch(fetchSysProperties(syskeys[i]));
      }
    } else {
      const prm = dispatch(fetchSysProperties(syskeys));
      return () => prm.abort();
    }
  }, [dispatch, syskeys]);

  const map = <T extends SysProperty = SysProperty>(
    id: number,
    key: U[number]
  ): T | undefined => {
    const dt = { ...data?.find((table) => table.key === key) };
    if (dt && dt.data) {
      return selectById(dt.data, id) as T;
    }
    return undefined;
  };
  const mapBeskrivelse = (id: number, key: U[number]): string => {
    return map(id, key)?.beskrivelse || '';
  };
  const getData = useCallback(
    <T extends SysProperty>(key: U[number]) => {
      const sysData = data?.find((sysObj) => {
        return sysObj.key === key;
      });
      if (sysData) {
        return selectAll(sysData.data) as T[];
      }

      return [];
    },
    [data, selectAll]
  );
  const getLoaded = useCallback(
    (key: U[number]) => {
      const sysData = data?.find((sysObj) => {
        return sysObj.key === key;
      });
      if (sysData) {
        return sysData.status.loadingComplete ?? false;
      }
      return false;
    },
    [data]
  );
  return {
    map,
    mapBeskrivelse,
    data: getData,
    loaded: getLoaded,
  };
};
