export const redirectToLogin = () =>
  redirect(true, 'Redirigerer til innloggingstjeneste');

export const redirectToLogout = () =>
  redirect(false, 'Redirigerer til utloggingstjeneste');

const redirect = (isLogin: boolean, explanationText: string): React.JSX.Element => {
  if (isLogin) {
    process.env.VITE_APP_API
      ? (window.location.href = `${process.env.VITE_APP_URL_LOGIN}`)
        // eslint-disable-next-line no-console
      : console.error('Unable to find login url from .env');
  } else {
    process.env.VITE_APP_URL_LOGOUT
      ? (window.location.href = process.env.VITE_APP_URL_LOGOUT)
        // eslint-disable-next-line no-console
      : console.error('Unable to find logout url from .env');
  }

  return <h1>{explanationText}</h1>;
};

const redirectObj = {
  redirectToLogin,
  redirectToLogout,
};
export default redirectObj;
