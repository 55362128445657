import { FormControl, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { initializeForm } from 'features/deltaker/state/oppfølgingspunkt/thunks';
import { useEffect } from 'react';

import { oppfølgingspunkt } from './selectors';
import { OppfølgingspunktFormFields } from '.';

const AppendedOppfølgingspunkt = () => {
  const dispatch = useAppDispatch();

  const { formValues, status } = useAppSelector(oppfølgingspunkt);

  useEffect(() => {
    const isDoingSomeFetching = status.loading && !status.loadingComplete;

    if (!isDoingSomeFetching && !formValues.fristDato)
      dispatch(initializeForm('APPENDED'));
  }, [dispatch, status.loading, status.loadingComplete, formValues.fristDato]);

  return (
    <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
      <Typography component="h2" variant="tableTitle" sx={{ mb: 2 }}>
        Nytt oppfølgingspunkt
      </Typography>
      <OppfølgingspunktFormFields hideHenvendelseSelect={true} />
    </FormControl>
  );
};

export default AppendedOppfølgingspunkt;
