import { createSelector } from '@reduxjs/toolkit';
import { AuthState } from 'features/currentBruker/state/auth/interface';
import { getAuth } from 'features/currentBruker/state/selectors';
import { LoggedInBruker } from 'services/loggedInnBruker/dto';
import { joinNoBlanks } from 'utils';

export type GetUserNameData = {
  fullName: string;
  initials: string;
};

const setInitials = (innloggetBruker: LoggedInBruker | undefined) =>
  !!innloggetBruker
    ? `${innloggetBruker.givenName[0]}${innloggetBruker.familyName[0]}`
    : '';

const setFullname = (innloggetBruker: LoggedInBruker | undefined) =>
  !!innloggetBruker
    ? joinNoBlanks([innloggetBruker.givenName, innloggetBruker.familyName])
    : '';

//Forklaring på memorizing av selectors for komponenter som skal brukes flere steder i applikasjonen
//https://react-redux.js.org/api/hooks#using-memoizing-selectors
const getUserNameData = createSelector(
  [getAuth],
  (auth: AuthState): GetUserNameData => {
    return {
      fullName: setFullname(auth.data),
      initials: setInitials(auth.data),
    };
  }
);
export const getUserNameDataFactory = () => getUserNameData;
