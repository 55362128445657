import { combineReducers } from '@reduxjs/toolkit';

import detaljerReducer from './detaljer/slice';
import deltakerHenvendelser from './henvendelse/reducer';
import infoDemaskert from './infoDemaskert/slice';
import logg from './logg/slice';
import snuten from './snuten/slice';
import oppfølgingspunkt from './oppfølgingspunkt/slice';
import henvisning from './henvisning/slice';

const deltakerReducer = combineReducers({
  detaljer: detaljerReducer,
  henvendelse: deltakerHenvendelser,
  infoDemaskert: infoDemaskert,
  deltakerLogg: logg,
  snuten: snuten,
  oppfølgingspunkt: oppfølgingspunkt,
  henvisning: henvisning,
});

export default deltakerReducer;
export type DektakerState = ReturnType<typeof deltakerReducer>;
