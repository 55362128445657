import React, { ReactNode } from 'react';

import LoadingComponent from './LoadingComponent';

type Props = {
  showLoading: boolean;
  marginBottom?: number;
  height?: number;
  children: ReactNode;
};

const LoadingInputComponent: React.FC<Props> = ({
  showLoading,
  marginBottom,
  height = 56,
  children,
}) => {
  return (
    <LoadingComponent
      showLoading={showLoading}
      numberOfSkeletons={1}
      height={height}
      marginBottom={marginBottom}>
      {children}
    </LoadingComponent>
  );
};

export default LoadingInputComponent;
