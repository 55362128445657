import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { HenvendelserService } from 'services';
import { HenvendelseDeltakerDto } from 'services/henvendelser/dto';
import { TraceableError } from 'utils/errors/customError';

const typeName = 'deltaker/henvendelse/typer';

export const fetchDeltakerHenvendelsetyper = createAsyncThunk<
  HenvendelseDeltakerDto[],
  number,
  { dispatch: AppDispatch; rejectValue: string }
>(`${typeName}`, async (deltakerId, { dispatch, rejectWithValue }) => {
  try {
    return await HenvendelserService.getAvailableHenvendelseTyperByDeltaker(
      deltakerId
    );
  } catch (error) {
    const err = error as TraceableError;

    dispatchError(dispatch, err, AddError);
    return rejectWithValue(err.message);
  }
});
