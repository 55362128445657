import {
  Checkbox,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { ScrWrapper } from 'components';
import React from 'react';

import { AntallInklusjonRow, AntallTableRow } from './';
import { AntallDataInvitasjon, AntallTableProps } from './interface';
import { ColumnTableCell } from './styled';
import { getStrikeThrough, sum, sumKorrigert } from './utils';

const AntallTable: React.FC<AntallTableProps> = ({
  header,
  antallsbegrensningActive,
  invalid,
  data,
  dataFunctions,
  inklusjonProps,
  setDisableButton,
}) => {
  const isAnyDataKorrigert = !!data.find(
    (item) => item.korrigertAntall !== undefined
  );
  const isAllDataSelected = data.every((item) => item.selected);
  const isAnyDataSelected = !!data.find((item) => item.selected);

  return (
    <ScrWrapper error={invalid}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <ColumnTableCell sx={{ width: 74 }}>
              <Checkbox
                checked={isAllDataSelected}
                indeterminate={!isAllDataSelected && isAnyDataSelected}
                onClick={() => dataFunctions.setSelectedAll(!isAllDataSelected)}
              />
            </ColumnTableCell>
            <ColumnTableCell>{header}</ColumnTableCell>
            {inklusjonProps && (
              <ColumnTableCell align="center" sx={{ width: 150 }}>
                Fordelt pr. metode
              </ColumnTableCell>
            )}
            <ColumnTableCell align="center">Antall</ColumnTableCell>
            {antallsbegrensningActive && (
              <ColumnTableCell align="center" sx={{ width: 112 }}>
                Korrigert
              </ColumnTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) =>
            inklusjonProps &&
            dataFunctions?.setKorrigertAntall &&
            inklusjonProps.tilOgMedDato ? (
              <AntallInklusjonRow
                key={row.id}
                antallsbegrensningActive={antallsbegrensningActive}
                row={row as AntallDataInvitasjon}
                dataFunctions={dataFunctions}
                inklusjonsProps={inklusjonProps}
                setDisableButton={setDisableButton}
              />
            ) : (
              <AntallTableRow
                key={row.id}
                antallsbegrensningActive={antallsbegrensningActive}
                row={row}
                dataFunctions={dataFunctions}
              />
            )
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <ColumnTableCell colSpan={inklusjonProps ? 3 : 2} align="right">
              {inklusjonProps ? 'Totalt antall kandidater' : 'Totalt'}
            </ColumnTableCell>
            <ColumnTableCell
              align="center"
              sx={getStrikeThrough(
                antallsbegrensningActive &&
                  isAnyDataSelected &&
                  isAnyDataKorrigert
              )}>
              {sum(data)}
            </ColumnTableCell>
            {antallsbegrensningActive && (
              <ColumnTableCell align="center">
                {sumKorrigert(data)}
              </ColumnTableCell>
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </ScrWrapper>
  );
};

export default AntallTable;
