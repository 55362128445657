import { Button, Typography } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import BekreftelseDialog from 'features/invitasjon/components/BekreftelseDialog';
import React, { useState } from 'react';

export const InlineTypography = styled(Typography)(() => ({
  display: 'inline',
}));

const IIBekreftelseDialogExample: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleReestimate = () => {
    try {
      const tempIsSuccessfull = Math.random() < 0.5;

      if (tempIsSuccessfull) {
        return Promise.resolve(null);
      } else throw new Error('Noe feilet');
    } catch (err) {
      return Promise.reject();
    }
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Åpne bekreftelsedialog for inklusjon og invitasjon
      </Button>
      <BekreftelseDialog
        open={open}
        close={() => setOpen(false)}
        bekreftText="Ja, send prøvesett"
        bekreft={handleReestimate}>
        <>
          <InlineTypography>
            Med disse instillingene vil omlag{' '}
          </InlineTypography>
          <InlineTypography sx={{ fontWeight: 'bold' }}>2203</InlineTypography>
          <InlineTypography>
            {' '}
            prøvesett bli generert. "Til og med"-dato er satt til 24.05.2021
          </InlineTypography>
        </>
      </BekreftelseDialog>
    </>
  );
};

export default IIBekreftelseDialogExample;
