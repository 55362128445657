import { SysRowFormState, SysRowFormStateForm } from './interface';

export const getSysRowFormState = (state: SysRowFormState) => state;

export const getIsEditNewModalOpen = (state: SysRowFormState) =>
  state.isModalOpen;

export const getFormValueByKey = (
  state: SysRowFormState,
  key: keyof SysRowFormStateForm
) => state.form[key];

export const getFormErrorByKey = (
  state: SysRowFormState,
  key: keyof SysRowFormStateForm
) => state.errors[key];

export const getFormError = (state: SysRowFormState) => state.errors;
