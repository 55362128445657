import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { AppDispatch } from 'app/store';
import { PostHenvisningPayload } from 'features/deltaker/interface';
import { addError } from 'features/errorHandling/errorHandlingSlice';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { addNotification } from 'features/notification/notificationSlice';
import { DeltakerService } from 'services';
import {
  HenvisningKanHenvisesDto,
  HenvisningManuellMetadataDto,
} from 'services/henvisninger/dto';
import HenvisningerService from 'services/henvisninger/henvisningerService';
import { TraceableError } from 'utils/errors/customError';

import { setDeltakerDetaljer } from '../detaljer/slice';
import { setShouldRefetchHenvendelseTyper } from '../henvendelse/typer/slice';
import { setShouldRefetchLogg } from '../logg/slice';

const typeName = 'deltaker/henvisning';

export const fetchManuellHenvisningKanHenvises = createAsyncThunk<
  HenvisningKanHenvisesDto,
  number,
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}/fetchManuellHenvisingKanHenvises`,
  async (deltakerId, { dispatch, rejectWithValue }) => {
    try {
      return await HenvisningerService.getHenvisningKanHenvises(deltakerId);
    } catch (error) {
      const err = error as TraceableError;
      dispatch(
        addNotification({
          message: { text: err.message },
          variant: 'error',
        })
      );
      dispatchError(dispatch, err, addError.type);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchManuellHenvisningMetadata = createAsyncThunk<
  HenvisningManuellMetadataDto,
  number,
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}/fetchManuellHenvisingMetadata`,
  async (deltakerId, { dispatch, rejectWithValue }) => {
    try {
      return await HenvisningerService.getHenvisningManuellMetadata(deltakerId);
    } catch (error) {
      const err = error as TraceableError;
      dispatch(
        addNotification({
          message: { text: err.message },
          variant: 'error',
        })
      );
      dispatchError(dispatch, err, addError.type);
      return rejectWithValue(err.message);
    }
  }
);

export const postManuellHenvising = createAsyncThunk<
  null,
  PostHenvisningPayload,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>(
  `${typeName}/postManuellHenvising`,
  async ({ henvisning }, { dispatch, rejectWithValue, getState }) => {
    const {
      deltaker: { detaljer },
    } = getState();

    const deltakerId = detaljer.data?.deltakerId;

    try {
      if (deltakerId) {
        const result = await HenvisningerService.postManuellHenvisning({
          ...henvisning,
          deltakerId,
        });
        const deltaker = await DeltakerService.getDeltakerById(deltakerId);
        dispatch(setDeltakerDetaljer(deltaker));
        dispatch(
          addNotification({
            message: { text: 'Henvendelsen ble lagret' },
            variant: 'success',
          })
        );
        return result;
      } else {
        throw new Error('Har ikke brukerid');
      }
    } catch (error) {
      const err = error as TraceableError;
      dispatchError(dispatch, err, AddError);

      return rejectWithValue(err.toString());
    } finally {
      dispatch(setShouldRefetchLogg(true));
      dispatch(setShouldRefetchHenvendelseTyper(true));
    }
  }
);
