import { FetchStatus } from 'features/utils';
import { BrukerDto } from 'services/bruker/dto';

export interface BrukereState {
  data?: BrukerDto[];
  status: FetchStatus;
}

export enum BrukernavnStatus {
  OK = 'OK',
  FOR_KORT = 'FOR_KORT',
  FOR_LANGT = 'FOR_LANGT',
  UGYLDIG_TEGN = 'UGYLDIG_TEGN',
  ER_I_BRUK = 'ER_I_BRUK',
}
