import { ActionCreatorWithPayload, nanoid } from '@reduxjs/toolkit';
import { interfaces, reducer, utils } from 'features/errorHandling';
import { Component } from 'react';
import { connect } from 'react-redux';
import LoggService from 'services/logg/LoggService';
import { fromMillisToISO } from 'utils/date';

import { FallbackComponent } from './';

type State = { hasError: boolean };

// https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component<
  {
    addError: ActionCreatorWithPayload<interfaces.Errors, 'errors/addError'>;
    children: any;
  },
  State
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line no-console
    console.log('getDerivedStateFromError', error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    this.props.addError({
      id: nanoid(),
      error: utils.parseErrorToSerializableObject(error),
    });
    LoggService.postLogg({
      tidspunkt: fromMillisToISO(Date.now(), 'datetime') || '',
      type: 'ERROR',
      beskrivelse: errorInfo,
      stackTrace: error.stack,
    });
  }

  render() {
    if (this.state.hasError) {
      return <FallbackComponent />;
    }

    return <>{this.props.children}</>;
  }
}
const mapDispatchToProps = {
  addError: reducer.addError,
};
export default connect((state) => state, mapDispatchToProps)(ErrorBoundary);
