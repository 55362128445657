import { createAsyncThunk } from '@reduxjs/toolkit';
import { SystemTabell } from 'services/sys/dto';
import { getSysTable } from 'services/sys/sysService';
import { FetchResponseError } from 'utils/errors/customError';

const typeName = 'senteradmin/systemtabell/sysTable';

export const fetchSystemTable = createAsyncThunk<
  SystemTabell[],
  string,
  { rejectValue: string }
>(`${typeName}/fetchSystemTable`, async (path, { rejectWithValue }) => {
  try {
    return await getSysTable(path);
  } catch (error) {
    const err = error as FetchResponseError;
    return rejectWithValue(err.message);
  }
});
