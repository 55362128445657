import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadingInputComponent } from 'components';
import { updateForm } from 'features/deltaker/state/oppfølgingspunkt/slice';
import { useManySysProperties } from 'features/sys/hooks';
import { useCallback } from 'react';
import { DeltakerService } from 'services';
import { formatDateFromISO } from 'utils/date';
import { useFetchData } from 'utils/hooks/useFetchData';

import { henvendelseDialogSelector } from '../selectors';
import { henvendelseSelectSelector } from './selectors';

const HenvendelseSelect = () => {
  const dispatch = useAppDispatch();

  const { deltakerId } = useAppSelector(henvendelseDialogSelector);

  const fetchHenvendelser = useCallback(
    (signal?: AbortSignal) =>
      DeltakerService.getHenvendelserByDeltakerId(deltakerId, signal),
    [deltakerId]
  );
  const { status, data } = useFetchData(fetchHenvendelser);
  const { tilknyttetHenvendelseId } = useAppSelector(henvendelseSelectSelector);
  const { mapBeskrivelse, loaded } = useManySysProperties<
    ['henvendelseTyper', 'henvendelseKanaler']
  >(['henvendelseTyper', 'henvendelseKanaler']);
  const handleUpdateFormDispatch = (value: any) =>
    dispatch(
      updateForm({
        key: 'knyttetTilHenvendelseId',
        value: value ? (value as number).toString() : undefined,
      })
    );

  const loading = !status.loadingComplete && status.loading;

  const getInputLabelText = () => {
    if (loading) return '';
    else {
      if (data) return 'Koble til eksisterende henvendelse';
      else return 'Ingen Henvendelser';
    }
  };
  return (
    <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
      <InputLabel id="knyttet-select-label">{getInputLabelText()}</InputLabel>
      <LoadingInputComponent
        showLoading={
          loading &&
          !!loaded('henvendelseKanaler') &&
          !!loaded('henvendelseTyper')
        }
        height={50}>
        <Select
          color="secondary"
          onChange={({ target: { value } }) => handleUpdateFormDispatch(value)}
          name="knyttetTilHenvendelseId"
          disabled={!data?.length}
          value={data && (tilknyttetHenvendelseId || '')}
          labelId="knyttet-select-label">
          <MenuItem value={undefined}>Ingen tilknyttet henvendelse</MenuItem>
          {data?.length &&
            data?.map((henvendelse) => (
              <MenuItem key={henvendelse.id} value={henvendelse.id}>
                {`${mapBeskrivelse(
                  henvendelse.henvendelseTypeId,
                  'henvendelseTyper'
                )} (${formatDateFromISO(henvendelse.mottattTidspunkt)} ${
                  mapBeskrivelse(
                    henvendelse.henvendelseKanalId,
                    'henvendelseKanaler'
                  ) &&
                  `via ${mapBeskrivelse(
                    henvendelse.henvendelseKanalId,
                    'henvendelseKanaler'
                  )}`
                })`}
              </MenuItem>
            ))}
        </Select>
      </LoadingInputComponent>
    </FormControl>
  );
};

export default HenvendelseSelect;
