import { addError } from 'features/errorHandling/errorHandlingSlice';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { SendStatus } from 'features/utils';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchResponseError } from 'utils/errors/customError';

import { SendReturn } from './interface';

const initialStatus = {
  loading: false,
  loadingComplete: false,
  success: false,
};

export const useSendData = <T>(): SendReturn<T> => {
  const [status, setStatus] = useState<SendStatus>(initialStatus);
  const dispatch = useDispatch();

  const [data, setData] = useState<T | undefined>(undefined);

  const send = useCallback(
    async (func: () => Promise<T>) => {
      try {
        setStatus({
          loading: true,
          loadingComplete: false,
          success: false,
          errorMessage: undefined,
        });
        const response = await func();

        setData(response);
        setStatus({
          loadingComplete: true,
          loading: false,
          success: true,
        });
        return response;
      } catch (err) {
        const error = err as FetchResponseError;
        dispatchError(dispatch, error, addError.type);
        setData(undefined);
        setStatus({
          errorMessage: error.message,
          loadingComplete: true,
          loading: false,
          success: false,
        });
        throw error;
      }
    },
    [dispatch]
  );

  const resetData = (orgData?: T) => setData(orgData ? orgData : undefined);
  const clearStatus = () => setStatus(initialStatus);
  return { data, status, send, resetData, clearStatus };
};
