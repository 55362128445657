import { KapasitetFormMetode, KapasitetFormState } from './interface';

export const getKapasitetForm = (state: KapasitetFormState, enhetId: number) =>
  state.form[enhetId] || {
    metoder: [],
    iruteDato: {
      endret: false,
      value: '',
    },
    maksAntallKoloskopierPerMåned: {
      endret: false,
      value: '',
    },
    runde25AntallsbegrensningPerDag: {
      endret: false,
      value: '',
    },
  };
export const getScreeningmetodeForEnhetByMetodeId = (
  state: KapasitetFormState,
  enhetId: number,
  metodeId: number
): KapasitetFormMetode | undefined => {
  return getKapasitetForm(state, enhetId).metoder.filter(
    (metode) => metode.id === metodeId
  )[0];
};
export const getCanEditKapasitetForm = (
  state: KapasitetFormState,
  enhetId: number
) =>
  state.form[enhetId]?.metoder?.find(
    (koloskopiMetode) => koloskopiMetode.kanEndres
  )?.kanEndres || false;

export const getKapasitetFormIsValid = (
  state: KapasitetFormState,
  enhetId: number
) => {
  if (!state.form[enhetId]) return false;
  const invalidMetoder = state.form[enhetId]?.metoder.some(
    (metode) =>
      (metode.screeningFraOgMedDato.error &&
        metode.screeningFraOgMedDato.endret) ||
      (metode.screeningFraOgMedDato.error &&
        metode.active.endret &&
        metode.active.value === true)
  );
  const iruteDatoValid =
    !state.form[enhetId]?.iruteDato.error ||
    (state.form[enhetId]?.iruteDato.error &&
      !state.form[enhetId]?.iruteDato.endret);

  const maksAntallKoloskopierPerMånedValid =
    !state.form[enhetId]?.maksAntallKoloskopierPerMåned.error ||
    (state.form[enhetId]?.maksAntallKoloskopierPerMåned.error &&
      !state.form[enhetId]?.maksAntallKoloskopierPerMåned.endret);

  const runde25AntallsbegrensningPerDag =
    !state.form[enhetId]?.runde25AntallsbegrensningPerDag.error ||
    (state.form[enhetId]?.runde25AntallsbegrensningPerDag.error &&
      !state.form[enhetId]?.runde25AntallsbegrensningPerDag.endret);

  return (
    iruteDatoValid &&
    !invalidMetoder &&
    maksAntallKoloskopierPerMånedValid &&
    runde25AntallsbegrensningPerDag
  );
};
