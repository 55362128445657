import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import { FetchStatus, initialFetchStatus } from 'features/utils';
import { SysProperties, SysProperty } from 'services/sys/dto';

export const sysAdapter = createEntityAdapter<SysProperty, number>({
  selectId: ({ id }) => id,
});
export const sysSelectors = sysAdapter.getSelectors();

export type SysPropertyState = EntityState<SysProperty, number> & {
  status: FetchStatus;
};

export type GetSysStateObjectBySyskey = {
  data: EntityState<SysProperty, number>;
  status: FetchStatus;
};
export type GetSysStateObjectBySysKeyWithKey = GetSysStateObjectBySyskey & {
  key: keyof SysProperties;
};

const initialSysPropertyState: SysPropertyState = {
  entities: {},
  ids: [],
  status: initialFetchStatus,
};

export const initialState: SysProperties = {
  adresseTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  adresseInnholdstyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  deltakerForholdType: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  deltakerForholdUnderType: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  deltakerUtsendingStatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  deltakerUtsendingStatusGruppe: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  dokumentTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  flettefelt: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  enhetTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  filformat: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  formidlingskanal: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  folkeregisterstatuser: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvendelseKanaler: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvendelseTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvendelseKategorier: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  hendelsetype: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  hendelsekontrollStatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  hendelsekontrollMeldingStatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  hendelsekontrollMeldingType: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  loggradTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  oppfølgingsstatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  oppfølgingstype: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  representasjonType: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  roller: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  språk: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  fargetyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  utsendingTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  utsendingTypeKategorier: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  visningsgrunn: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvisningTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  omradeTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  screeningmetoder: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  deltakerNotatTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  oppfolgingStatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  opplistingstyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  provesvarStatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  innstillinger: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvisningerSvarTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  ifobtPrøvesvarKonklusjoner: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  ifobtPrøvesvarKonklusjonGrupper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  ifobtPrøvesvarStatuser: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvisningerStatus: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  henvisningerStatusGruppe: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  helsemeldingerFeilStatuser: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  helsemeldingerFeilTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  helsemeldingerTyper: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  logistikkpartnerForsendelseStatuser: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  koloskopirapporterStatuser: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  kommunikasjonsretning: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  helsepersonellRolleType: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
  tilleggsinfoType: sysAdapter.getInitialState({
    ...initialSysPropertyState,
  }),
};
