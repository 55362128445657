import { Container, Link, Typography } from '@mui/material';
import { FC } from 'react';

const FallbackComponent: FC = () => {
  return (
    <Container>
      <Typography variant="h1">Oooops, her skjedde en feil.</Typography>
      <Typography variant="body2">
        Har du lyst til å fortelle oss hva du gjorde da dette gikk galt, så kan
        du sende en epost til
        <Link href="mailto:tp-support@kreftregisteret.no">
            tp-support@kreftregisteret.no
        </Link>
        .
      </Typography>
      <Typography variant="body2">Vi setter pris på feilmeldinger.</Typography>
      <Typography variant="body2">
        <Link href="/">Hjem</Link>
      </Typography>
    </Container>
  );
};
export default FallbackComponent;
