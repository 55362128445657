import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { ScrTextarea } from 'components';
import { useSysProperties } from 'features/sys';
import { FC, useEffect, useState } from 'react';

import { FritekstBrevFormFieldsProps } from './interface';

const FritekstBrevFormFields: FC<FritekstBrevFormFieldsProps> = ({
  handleChange,
  formState,
  disabled,
  deltakerAdresser,
  effektivPostAdresseIdKryptert,
  appendedFritekstbrev,
}) => {
  const { mapBeskrivelse } = useSysProperties('adresseTyper');
  const [fritekstInvalid, setFritekstInvalid] = useState<boolean>(false);


  useEffect(() => {
    formState.fritekst && setFritekstInvalid(false);
  }, [formState.fritekst]);


  const handleInvalid = (event: React.FormEvent) => {
    event.preventDefault();
    setFritekstInvalid(true);
  };

  return (
    <>
      {!appendedFritekstbrev &&
        deltakerAdresser &&
        deltakerAdresser.length > 1 && (
          <>
            <FormLabel component="legend" sx={{ fontWeight: 'bold' }}>
              <b>Velg adresse</b>
            </FormLabel>
            <RadioGroup
              aria-labelledby="addresser-radio-group-label"
              name="adresseIdKryptert"
              sx={{ color: 'primary.main', paddingBottom: 2 }}
              value={
                formState.adresseIdKryptert || effektivPostAdresseIdKryptert
              }
              onChange={handleChange}>
              {deltakerAdresser?.map(({ idKryptert, adresse, adresseType }) => (
                <FormControlLabel
                  key={idKryptert}
                  value={idKryptert}
                  control={<Radio />}
                  label={adresse + ' [' + mapBeskrivelse(adresseType) + ']'}
                />
              ))}
            </RadioGroup>
          </>
        )}

      <TextField
        label="Tittel"
        name="hovedoverskrift"
        value={formState.hovedoverskrift}
        type="text"
        fullWidth
        disabled={disabled}
        InputLabelProps={{ shrink: true, 'aria-label': 'brevtittel' }}
        onChange={handleChange}
        sx={{ mb: 2 }}
      />
      <ScrTextarea
        aria-label="brevinnhold"
        name="fritekst"
        required
        minRows={20}
        disabled={disabled}
        error={fritekstInvalid}
        value={formState.fritekst}
        onChange={handleChange}
        onInvalid={handleInvalid}
      />

      {!appendedFritekstbrev && (
        <>
          <FormLabel
            component="legend"
            sx={{ color: 'primary.main', paddingTop: 2 }}>
            <b>Fysisk Post</b>
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox value={formState.fysiskPost} onChange={handleChange} />
            }
            label="Fysisk Post"
            name="fysiskPost"
            checked={formState.fysiskPost}
            sx={{ pl: { xs: 1, lg: 0 } }}
          />
        </>
      )}
    </>
  );
};

export default FritekstBrevFormFields;
