import { createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus } from 'features/utils';

import { SysTableState } from './interface';
import { fetchSystemTable } from './thunks';

export const initialState: SysTableState = {
  status: initialFetchStatus,
};

const slice = createSlice({
  name: 'sysTable',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemTable.fulfilled, (state, { payload }) => {
        state.valgtSystemTable = payload;
        state.status = { loading: false, loadingComplete: true };
      })
      .addCase(fetchSystemTable.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(fetchSystemTable.rejected, (state, { error: { message } }) => {
        return {
          ...initialState,
          status: {
            ...initialState.status,
            loading: false,
            errorMessage: message,
            loadingComplete: true,
          },
        };
      });
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
