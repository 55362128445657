import { Typography } from '@mui/material';
import Antall from 'features/invitasjon/components/Antall';
import { AntallData } from 'features/invitasjon/components/Antall/interface';
import { sum, sumKorrigert } from 'features/invitasjon/components/Antall/utils';
import update from 'immutability-helper';
import React from 'react';
import { useState } from 'react';

const IIAntallExample: React.FC = () => {
  const [antallsbegrensningActive, setAntallsbegrensningActive] =
    useState(false);
  const [data, setData] = useState<AntallData[]>([
    {
      id: 1,
      text: 'Oslo universitetsykehus',
      selected: true,
      antall: 221,
    },
    {
      id: 2,
      text: 'Akershus universitetsykehus',
      selected: true,
      antall: 621,
    },
    {
      id: 3,
      text: 'Østre og Vestre Toten Regionsykehus HF',
      selected: true,
      antall: 772,
    },
  ]);

  const toggleAntallsbegrensning = () =>
    setAntallsbegrensningActive(!antallsbegrensningActive);

  const setKorrigertAntall = (id: number, korrigertAntall?: number) => {
    const index = data.findIndex((item) => item.id === id);
    const updatedData = update(data, {
      [index]: { korrigertAntall: { $set: korrigertAntall } },
    });
    setData(updatedData);
  };

  const setSelected = (id: number, selected: boolean) => {
    const index = data.findIndex((item) => item.id === id);
    const updatedData = update(data, {
      [index]: { selected: { $set: selected } },
    });
    setData(updatedData);
  };

  const setSelectedAll = (selected: boolean) => {
    const updatedData = data.map((item) => {
      return { ...item, selected };
    });
    setData(updatedData);
  };

  const submit = () => {
    const dataToSend = data.map((item) => {
      const antall = !antallsbegrensningActive
        ? item.antall
        : item.korrigertAntall ?? item.antall;

      return `[${item.id}]: ${item.selected ? antall : 'ikke inkludert'}`;
    });
    console.log(`Sending ${dataToSend}`);
    return Promise.resolve(null);
  };

  return (
    <>
      <Typography component="h4" variant="h6">
        Med antallsbegrensning
      </Typography>
      <Antall
        antallsbegrensningProps={{
          active: antallsbegrensningActive,
          toggle: toggleAntallsbegrensning,
          text: 'Korrigert antall kan ikke overskride totalt antall estimerte kanditater per senter',
        }}
        tableHeader="Senter"
        submitButtonText="Send prøvesett"
        submit={submit}
        bekreftDialogContent={
          <Typography>{`${
            antallsbegrensningActive ? sumKorrigert(data) : sum(data)
          } prøvesett blir sendt`}</Typography>
        }
        data={data}
        dataFunctions={{ setSelected, setSelectedAll, setKorrigertAntall }}
      />
      <Typography component="h4" variant="h6">
        Uten antallsbegrensning
      </Typography>
      <Antall
        tableHeader="Senter"
        submitButtonText="Send prøvesett"
        submit={submit}
        bekreftDialogContent={
          <Typography>{`${sum(data)} prøvesett blir sendt`}</Typography>
        }
        data={data}
        dataFunctions={{ setSelected, setSelectedAll }}
      />
    </>
  );
};

export default IIAntallExample;
