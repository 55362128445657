import { createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus, loading, loadingComplete } from 'features/utils';

import { ManuellHenvisningState } from '../interface';
import * as thunk from './thunks';

export const initialState: ManuellHenvisningState = {
  kanHenvisesStatus: initialFetchStatus,
  metadataStatus: initialFetchStatus,
  postStatus: initialFetchStatus,
  closeForm: false,
};

export const slice = createSlice({
  name: 'henvisning',
  initialState,
  reducers: {
    resetManuellHenvisningSending: (state) => {
      state.metadataStatus = initialFetchStatus;
      state.postStatus = initialFetchStatus;
      state.metadata = undefined;
      state.closeForm = false;
      state.inDialogErrorMessage = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunk.fetchManuellHenvisningKanHenvises.pending, (state) => {
        state.kanHenvisesStatus = { ...loading, loadingComplete: false };
      })
      .addCase(
        thunk.fetchManuellHenvisningKanHenvises.fulfilled,
        (state, { payload: kanHenvises }) => {
          state.kanHenvises = kanHenvises.kanHenvises;
          state.kanHenvisesStatus = loadingComplete;
        }
      )
      .addCase(
        thunk.fetchManuellHenvisningKanHenvises.rejected,
        (state, { payload }) => {
          state.kanHenvisesStatus = loadingComplete;
          state.inDialogErrorMessage = payload;
        }
      )
      .addCase(thunk.fetchManuellHenvisningMetadata.pending, (state) => {
        state.metadataStatus = { ...loading, loadingComplete: false };
      })
      .addCase(
        thunk.fetchManuellHenvisningMetadata.fulfilled,
        (state, { payload: metadata }) => {
          state.metadata = metadata;
          state.metadataStatus = loadingComplete;
        }
      )
      .addCase(
        thunk.fetchManuellHenvisningMetadata.rejected,
        (state, { payload }) => {
          state.metadataStatus = loadingComplete;
          state.inDialogErrorMessage = payload;
        }
      )
      .addCase(thunk.postManuellHenvising.pending, (state) => {
        state.postStatus = { ...loading, loadingComplete: false };
      })
      .addCase(thunk.postManuellHenvising.fulfilled, (state) => {
        resetManuellHenvisningSending();
        state.closeForm = true;
        state.postStatus = loadingComplete;
      })
      .addCase(thunk.postManuellHenvising.rejected, (state, { payload }) => {
        state.postStatus = loadingComplete;
        state.inDialogErrorMessage = payload;
      });
  },
});

export const { resetManuellHenvisningSending } = slice.actions;

export default slice.reducer;
