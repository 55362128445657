import { AllowedRoutesState, RoutePathAccess } from './interface';

export const getAllowedRoutes = (
  state: AllowedRoutesState
): RoutePathAccess[] => state;

const selectors = {
  getAllowedRoutes,
};

export default selectors;
