import { lazy } from 'react';

import {
  HENDELSEKONTROLL_LOGGKONTROLL,
  HENDELSEKONTROLL_LOGGKONTROLL_MELDING,
  HENDELSEKONTROLL_HENDELSELOGG,
  HENDELSEKONTROLL_KVITTERING,
} from './routeConstants';

const AuthRoute = lazy(() => import('./AuthRoute'));

const HendelsekontrollOversiktPage = lazy(
  () => import('features/hendelsekontroll/HendelsekontrollPage')
);
const HendelseloggPage = lazy(
  () => import('features/hendelsekontroll/logg/HendelseloggPage')
);
const HendelsekontrollPage = lazy(
  () => import('features/hendelsekontroll/kontroll/HendelsekontrollPage')
);
const HendelseKontrollMeldingPage = lazy(
  () => import('features/hendelsekontroll/melding/HendelseKontrollMeldingPage')
);
const KvitteringPage = lazy(
  () => import('features/hendelsekontroll/kvittering/KvitteringerPage')
);
export const hendelsekontrollRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <HendelsekontrollOversiktPage />
      </AuthRoute>
    ),
  },
  {
    path: HENDELSEKONTROLL_HENDELSELOGG,
    element: (
      <AuthRoute>
        <HendelseloggPage />
      </AuthRoute>
    ),
  },
  {
    path: HENDELSEKONTROLL_LOGGKONTROLL,
    element: (
      <AuthRoute>
        <HendelsekontrollPage />
      </AuthRoute>
    ),
  },
  {
    path: HENDELSEKONTROLL_LOGGKONTROLL_MELDING,
    element: (
      <AuthRoute>
        <HendelseKontrollMeldingPage />
      </AuthRoute>
    ),
  },
  {
    path: HENDELSEKONTROLL_KVITTERING,
    element: (
      <AuthRoute>
        <KvitteringPage />
      </AuthRoute>
    ),
  },
];
