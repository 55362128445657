import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ValidateFormTypes } from 'features/deltaker/details/components/Henvendelser/HenvendelseDialog/Oppfølgingspunkt/interface';
import {
  initialFetchStatus,
  loading,
  loadingComplete,
  rejectedStatus,
} from 'features/utils';
import { OppfølgingCreateDto } from 'services/deltaker/dto';

import { OppfølgingstyperState } from '../interface';
import * as thunk from './thunks';

type OppfølgingsForm = Partial<OppfølgingCreateDto>;

export type IndividualOppfølgingsForm = OppfølgingsForm;
export type AppendedOppfølgingsForm = Omit<
  OppfølgingsForm,
  'knyttetTilHenvendelseId'
>;

const formValues: IndividualOppfølgingsForm | AppendedOppfølgingsForm = {};

const standardInvalidFields = {
  fristDato: false,
  oppfølgingTypeId: false,
  utførtAvBrukerId: false,
  utførtTidspunkt: false,
};
export const initialState: OppfølgingstyperState = {
  formValues: {},
  formInitialized: false,
  eksternTekstKreverDemaskering: false,
  brukernavn: { brukernavn: '', status: { loading: false } },
  invalidFields: standardInvalidFields,
  status: initialFetchStatus,
  isUtførtSelected: false,
  closeForm: false,
  inDialogErrorMessage: undefined,
};

const isUtførtSelected = (valueToCheck: unknown) => {
  // eslint-disable-next-line eqeqeq
  return valueToCheck == 3;
};
export const slice = createSlice({
  name: 'oppfølgingspunkt',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(thunk.initializeForm.pending, (state) => {
        state.status = { ...loading, loadingComplete: false };
      })
      .addCase(thunk.initializeForm.fulfilled, (state, { payload }) => {
        const { fristDate, type } = payload;

        if (type === 'INDIVIDUAL') {
          state.invalidFields = {
            ...state.invalidFields,
            knyttetTilHenvendelseId: false,
          };
        }
        if (!state.formValues.fristDato) {
          state.formValues.fristDato = fristDate;
        }
        state.status = loadingComplete;
      })
      .addCase(thunk.getOppfølgingById.pending, (state) => {
        state.status = { ...loading, loadingComplete: false };
      })
      .addCase(thunk.getOppfølgingById.fulfilled, (state, { payload }) => {
        const {
          utførtAv,
          eksternTekstKreverDemaskering,
          eksternTekst,
          ...data
        } = payload; // ignorerer utførtAv

        if (!state.formInitialized) {
          state.formValues = data;
          state.isUtførtSelected = isUtførtSelected(payload.oppfølgingStatusId);
          state.brukernavn.brukernavn = payload.utførtAv?.brukernavn ?? '';
          state.formInitialized = true;
        }

        if (eksternTekst) {
          state.eksternTekst = eksternTekst;
        }
        state.eksternTekstKreverDemaskering = eksternTekstKreverDemaskering;
        state.registrertEndret = payload.registrertEndret;
        state.status = loadingComplete;
      })
      .addCase(
        thunk.getOppfølgingById.rejected,
        (state, { error: { message } }) => {
          state.status = rejectedStatus(message);
          state.formValues = formValues;
          state.closeForm = true;

          state.status = loadingComplete;
        }
      )
      .addCase(thunk.setBrukerIdFromNavn.pending, (state) => {
        state.brukernavn.status = { ...loading, loadingComplete: false };
      })
      .addCase(thunk.setBrukerIdFromNavn.fulfilled, (state, { payload }) => {
        const { brukernavn, brukerId } = payload;

        const isInvalidBrukerId = brukerId === undefined;

        state.brukernavn.brukernavn = brukernavn;
        state.formValues.utførtAvBrukerId = brukerId;
        state.invalidFields.utførtAvBrukerId = isInvalidBrukerId;
        state.brukernavn.status = loadingComplete;
      })
      .addCase(thunk.setBrukerIdFromNavn.rejected, (state, { payload }) => {
        state.brukernavn.brukernavn = payload?.brukernavn ?? '';
        state.formValues.utførtAvBrukerId = undefined;
        state.invalidFields.utførtAvBrukerId = true;
        state.brukernavn.status = {
          ...loadingComplete,
          errorMessage: payload?.errorMessage,
        };
      })
      .addCase(thunk.sendOppfølging.pending, (state) => {
        state.status = { ...loading, loadingComplete: false };
      })
      .addCase(thunk.sendOppfølging.fulfilled, (state) => {
        resetOppfølgingspunkt(null);
        state.closeForm = false;
        state.status = loadingComplete;
      })
      .addCase(thunk.sendOppfølging.rejected, (state, { payload }) => {
        state.status = loadingComplete;
        state.inDialogErrorMessage = payload;
      })
      .addCase(thunk.autoFillUtførtTidspunkt.pending, (state) => {
        state.status = { ...loading, loadingComplete: false };
      })
      .addCase(
        thunk.autoFillUtførtTidspunkt.fulfilled,
        (state, { payload }) => {
          state.formValues.utførtTidspunkt = payload;
          state.status = loadingComplete;
        }
      )
      .addCase(thunk.autoFillUtførtTidspunkt.rejected, (state) => {
        state.status = loadingComplete;
      });
  },
  reducers: {
    resetOppfølgingspunkt: (state, action: PayloadAction<null>) => initialState,
    setOppfølgingspunkt: (state, action: PayloadAction<OppfølgingsForm>) => {
      state.formValues = action.payload;

      state.isUtførtSelected = isUtførtSelected(
        action.payload.oppfølgingStatusId
      );
    },
    removeBrukerId: (state, action: PayloadAction<any>) =>
      (state.formValues.utførtAvBrukerId = undefined),
    updateForm: (
      state,
      action: PayloadAction<{
        key: keyof OppfølgingsForm;
        value: any;
      }>
    ) => {
      const { key, value } = action.payload;

      if (key === 'oppfølgingStatusId') {
        const isUtført = isUtførtSelected(value);
        state.isUtførtSelected = isUtført;

        if (!isUtført) state.invalidFields.utførtAvBrukerId = false;
      }

      state.formValues[key] = value;

      if (key === 'oppfølgingTypeId' && value)
        state.invalidFields.oppfølgingTypeId = false;
      else if (key === 'fristDato' || key === 'utførtTidspunkt')
        state.invalidFields[key] = false;
    },
    setInvalidField: (
      state,
      action: PayloadAction<{
        key: keyof ValidateFormTypes;
      }>
    ) => {
      const { key } = action.payload;

      state.invalidFields[key] = true;
    },
  },
});

export const {
  resetOppfølgingspunkt,
  updateForm,
  setOppfølgingspunkt,
  setInvalidField,
  removeBrukerId,
} = slice.actions;

export default slice.reducer;
