import { SysRowFormStateForm } from './form/interface';
import * as sysRowFormSelectors from './form/selectors';
import { SystemTableState } from './slice';
import * as metaSelectors from './sysMeta/selectors';
import * as sysTableSelectors from './sysTable/selectors';

//SysMeta
export const getSystemtabellerMetaData = (state: SystemTableState) =>
  metaSelectors.getSystemtabellerMetaData(state.sysMeta);

export const getSelectedMetaDataTable = (state: SystemTableState) =>
  metaSelectors.getSelectedMetaDataTable(state.sysMeta);

export const getSysMetaStatus = (state: SystemTableState) =>
  metaSelectors.getSysMetaStatus(state.sysMeta);

//sysTable
export const getSysTableStatus = (state: SystemTableState) =>
  sysTableSelectors.getSysTableStatus(state.sysTable);

export const getValgtSystemTable = (state: SystemTableState) =>
  sysTableSelectors.getValgtSystemTable(state.sysTable);

//Form
export const getIsEditNewModalOpen = (state: SystemTableState) =>
  sysRowFormSelectors.getIsEditNewModalOpen(state.sysRowForm);

export const getSysRowFormState = (state: SystemTableState) =>
  sysRowFormSelectors.getSysRowFormState(state.sysRowForm);

export const getFormValueByKey = (
  state: SystemTableState,
  key: keyof SysRowFormStateForm
) => sysRowFormSelectors.getFormValueByKey(state.sysRowForm, key);

export const getFormErrorByKey = (
  state: SystemTableState,
  key: keyof SysRowFormStateForm
) => sysRowFormSelectors.getFormErrorByKey(state.sysRowForm, key);
export const getFormError = (state: SystemTableState) =>
  sysRowFormSelectors.getFormError(state.sysRowForm);

const selectors = {
  getSystemtabellerMetaData,
  getSelectedMetaDataTable,
  getSysTableStatus,
  getValgtSystemTable,
  getIsEditNewModalOpen,
  getSysRowFormState,
  getFormValueByKey,
  getFormErrorByKey,
  getFormError,
};

export default selectors;
