import {
  SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH,
  SENTRALADMINSOK_UTSENDINGER_PATH,
  SENTRALADMINSØK_DELTAKER_PATH,
  SENTRALADMINSØK_INTERVALL_PATH,
  SENTRALADMINSØK_SENTER_PATH,
} from 'app/routes/routeConstants';
import { lazy } from 'react';

const AuthRoute = lazy(() => import('./AuthRoute'));

const SentraladminStartPage = lazy(
  () => import('features/sentraladmin/SentraladminStartPage')
);

const SentraladminSøkSenterPage = lazy(
  () => import('features/sentraladmin/senter/SenterPage')
);
const SentraladminSøkDeltakerPage = lazy(
  () => import('features/sentraladmin/deltaker/DeltakerPage')
);

const SentraladminSøkSenterUtsendingPage = lazy(
  () => import('features/sentraladmin/utsendinger/IntervallSøk/IntervallPage')
);
const SentraladminSøkDeltakerUtsendingPage = lazy(
  () => import('features/sentraladmin/utsendinger/Deltaker/DeltakerPage')
);
export const sentraladminSokRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <SentraladminStartPage />
      </AuthRoute>
    ),
  },
  {
    path: `${SENTRALADMINSØK_SENTER_PATH}/${SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH}`,
    element: (
      <AuthRoute>
        <SentraladminSøkSenterPage />
      </AuthRoute>
    ),
  },
  {
    path: `${SENTRALADMINSØK_DELTAKER_PATH}/${SENTRALADMINSOK_INKL_PROVESVAR_HENVISNING_PATH}`,
    element: (
      <AuthRoute>
        <SentraladminSøkDeltakerPage />
      </AuthRoute>
    ),
  },
  {
    path: `${SENTRALADMINSØK_INTERVALL_PATH}/${SENTRALADMINSOK_UTSENDINGER_PATH}`,
    element: (
      <AuthRoute>
        <SentraladminSøkSenterUtsendingPage />
      </AuthRoute>
    ),
  },
  {
    path: `${SENTRALADMINSØK_DELTAKER_PATH}/${SENTRALADMINSOK_UTSENDINGER_PATH}`,
    element: (
      <AuthRoute>
        <SentraladminSøkDeltakerUtsendingPage />
      </AuthRoute>
    ),
  },
];
