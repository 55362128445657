export type AllowedRoutesState = RoutePathAccess[];

export type RoutePathAccess = {
  path: string;
  exact: boolean;
  userEnhetAccessToPath: number[];
};

export enum RettighetValues {
  BRUKERADMINISTRATOR_ALLE_ENHETER = 2,
  BRUKERADMINISTRATOR_EGEN_ENHET_UTENOM_TILDELE_BRUKERADMINISTRATOR_ROLLEN = 3,
  SENTRAL_SENTERADMINISTRASJON = 4,
  RAPPORTERE_INN_KAPASITET_FOR_EGET_SENTER = 5,
  INVITERE_SENDE_PRØVESETT_PÅMINNELSER_HENVISNINGER = 6,
  PERSONOPPSLAG_OG_VISNING = 7,
  GJØRE_HANDLINGER_TILKNYTTET_ENKELTPERSONER = 8,
  LOGGE_INN_I_APPLIKASJONEN = 12,
  MELDE_INN_VEDLIKEHOLDE_SKOPØRINFO_EGET_SENTER = 13,
  SE_NASJONAL_STATISTIKK = 15,
  SETTE_GENERELLE_INNSTILLINGER_I_APPLIKASJONEN = 16,
  TILGANG_APPLIKASJONSOVERVÅKNING = 17,
  EPOST_MOTTAKER_OVERVÅKNING = 18,
  SE_BREVMALER = 19,
  ADMINISTRERE_BREVMALER = 20,
  OPPRETTE_OG_FORVALTE_ENHETER_PÅ_ALLE_NIVÅER = 21,
  FORVALTE_TILKNYTNING_MELLOM_SCREENINGSENTER_OG_KOMMUNE_BYDEL = 24,
  STILLE_INN_SCREENINGMETODER_FOR_ALLE_SENTERE = 25,
  SE_INNMELDT_KAPASITET_FOR_ALLE_SENTERE = 26,
  SE_PURRING_FOR_ALLE_SENTERE = 29,
  RESERVASJONSBEHANDLING = 30,
  BEHANDLE_FEIL = 31,
  LESE_HENDELSER = 32,
  TILGANG_TIL_DIREKTE_PERSONIDENTIFISERENDE_OPPLYSNINGER = 33,
  SE_STATISTIKK_FOR_ALLE_SCREENINGSENTERE_ENKELTVIS = 34,
  SE_STATISTIKK_FOR_EGEN_ENHET = 35,
  SE_OG_BEHANDLE_PURRINGER_FOR_EGET_SENTER = 36,
  RAPPORTERE_INN_KAPASITET_FOR_ALLE_SENTRE = 37,
  MOTTA_VARSLINGER = 38,
  TILGANG_TIL_SNUTEN_KRYPTERT_FØDSELSNUMMER = 39,
  GJØRE_EKSTERNT_PERSONSØK = 40,
  SENTRAL_FORVALTER_AV_SKOPØRER = 41,
  GJØRE_OPPLISTINGER_SØK = 42,
  MANUELL_HENVISNING = 43,
  TILGANG_TIL_Å_ADMINISTRERE_UTSENDINGSPAUSER = 44,
  LESE_OG_BEHANDLE_HENVISNINGSTEKSTER = 45,
  SØKE_I_HENDELSER = 46,
  LAG_UTKVITTERING_AV_HENDELSESØK = 47,
  SE_LOGG_FOR_UTFØRT_HENDELSEKONTROLL = 48,
  OPPLISTING_RESERVERTE_SKOPØRER = 49,
  SLETTE_OPPFØLGINGSPUNKTER = 50,
  KANSELLERE_HENVISNINGER = 51,
  SETTE_INNSTILLINGER_FOR_HENDELSESKONTROLL = 52,
  ADMINISTRERE_HELSEPERSONELL_ROLLER = 53,
  LESE_RESPONS_FRA_FOLKEREGISTERET = 54,
}
