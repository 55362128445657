import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScreeningmetodeVedEnhetDto } from 'services/enheter/dto';

import {
  KapasitetFormError,
  KapasitetFormMetode,
  KapasitetFormState,
  KapasitetScreeningmetodeFormProps,
  OpptrappingFormKeys,
  ScreeningmetodeFormKeys,
} from './interface';

export const initialState: KapasitetFormState = {
  form: {},
};

const slice = createSlice({
  name: 'screeningmetoder',
  initialState,
  reducers: {
    setKapasitetForm: (
      state,
      action: PayloadAction<{
        enhetId: number;
        metoder: ScreeningmetodeVedEnhetDto[];
        antallKandidater?: number | null;
        iruteDato: string | null;
        maksAntallKoloskopierPerMåned?: number | null;
        runde25AntallsbegrensningPerDag?: number | null;
        gjennomsnittligAntallKoloskopierPerInvitasjon?: string;
      }>
    ) => {
      const metoder = action.payload.metoder.map<KapasitetFormMetode>(
        (metode) => ({
          id: metode.screeningmetodeId,
          active: {
            endret: false,
            value: metode.erGyldigNå,
          },
          screeningFraOgMedDato: {
            endret: false,
            value: metode.screeningFraOgMedDato || null,
          },
          gjennomsnittligAntallKoloskopierPerInvitasjon: {
            endret: false,
            value: metode.gjennomsnittligAntallKoloskopierPerInvitasjon || '',
          },
          kanEndres: metode.kanEndres,
          OriginalScreeningFraOgMedDato: metode.screeningFraOgMedDato || null,
        })
      );
      state.form[action.payload.enhetId] = {
        metoder: [...metoder],

        iruteDato: {
          endret: false,
          value: action.payload.iruteDato,
        },
        maksAntallKoloskopierPerMåned: {
          endret: false,
          value: action.payload.maksAntallKoloskopierPerMåned?.toString() || '',
        },
        runde25AntallsbegrensningPerDag: {
          endret: false,
          value:
            action.payload.runde25AntallsbegrensningPerDag?.toString() || '',
        },
      };
    },
    updateKapasitetForm: <K extends keyof KapasitetScreeningmetodeFormProps>(
      state: KapasitetFormState,
      {
        payload: { metodeId, key, value, enhetId, error },
      }: PayloadAction<{
        metodeId?: number;
        key: K;
        value: any;
        enhetId: number;
        error?: KapasitetFormError;
      }>
    ) => {
      if (metodeId) {
        const newMetoder = [...state.form[enhetId].metoder];
        const index = newMetoder.findIndex((elm) => elm.id === metodeId);

        newMetoder[index] = {
          ...newMetoder[index],
          [key]: {
            value,
            endret: true,
            error,
          },
        };
        state.form[enhetId].metoder = newMetoder;
      } else {
        state.form[enhetId][key as OpptrappingFormKeys] = {
          value,
          endret: true,
          error,
        };
      }
    },
    setKapasitetFormValidity: <
      K extends keyof KapasitetScreeningmetodeFormProps,
    >(
      state: KapasitetFormState,
      {
        payload: { metodeId, key, enhetId, error },
      }: PayloadAction<{
        key: K;
        enhetId: number;
        error: KapasitetFormError;
        metodeId?: number;
      }>
    ) => {
      if (metodeId) {
        const newMetoder = [...state.form[enhetId].metoder];
        const index = newMetoder.findIndex((elm) => elm.id === metodeId);
        newMetoder[index] = {
          ...newMetoder[index],
          [key]: {
            ...newMetoder[index][key as ScreeningmetodeFormKeys],
            error,
          },
        };
        state.form[enhetId].metoder = newMetoder;
      } else {
        state.form[enhetId][key as OpptrappingFormKeys].error = error;
      }
    },
  },
});

export const {
  updateKapasitetForm,
  setKapasitetForm,
  setKapasitetFormValidity,
} = slice.actions;

export default slice.reducer;
