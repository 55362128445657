import { OppfølgingstyperState } from '../interface';

export const getOppfølgingspunkt = (
  state: OppfølgingstyperState
): OppfølgingstyperState => state;

const selectors = {
  getOppfølgingspunkt,
};

export default selectors;
