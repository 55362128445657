import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpPost } from 'utils/http';

import { LoggDto } from './dto';
import { LoggService, PostLogg } from './interface';

const basePath = 'logg';

const postLogg: PostLogg = async (body: LoggDto) => {
  const fullPath = buildPath(basePath);
  try {
    return await httpPost(fullPath, body);
  } catch (error) {
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `En feil oppstod ved logging:  ${(error as FetchResponseError).message}`
    );
  }
};

export default {
  postLogg,
} as LoggService;
