import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { addError } from 'features/errorHandling/errorHandlingSlice';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { addNotification } from 'features/notification/notificationSlice';
import { DeltakerService } from 'services';
import { DeltakerDetaljerDto } from 'services/deltaker/dto';
import { TraceableError } from 'utils/errors/customError';

const typeName = 'deltaker/detaljer';

export const fetchDeltaker = createAsyncThunk<
  DeltakerDetaljerDto | null,
  { deltakerId: number; directLookup?: boolean },
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}`,
  async ({ deltakerId, directLookup }, { dispatch, rejectWithValue }) => {
    try {
      const deltaker = await DeltakerService.getDeltakerById(deltakerId);

      if (!deltaker && directLookup) {
        dispatch(
          addNotification({
            message: {
              text: `Fant ingen person med Intern-ID ${deltakerId}`,
            },
            variant: 'info',
          })
        );
      } else if (!deltaker) {
        return rejectWithValue(`Fant ingen person med Intern-ID ${deltakerId}`);
      }
      return deltaker;
    } catch (error) {
      const err = error as TraceableError;
      dispatch(
        addNotification({
          message: { text: err.message },
          variant: 'error',
        })
      );
      dispatchError(dispatch, err, addError.type);
      return rejectWithValue(err.message);
    }
  }
);

export const fetchDeltakerBySnutenKryptertFnr = createAsyncThunk<
  DeltakerDetaljerDto | null,
  { snutenKryptertFnr: string },
  { dispatch: AppDispatch; rejectValue: string }
>(
  `${typeName}`,
  async ({ snutenKryptertFnr }, { dispatch, rejectWithValue }) => {
    try {
      const deltakerId =
        await DeltakerService.getDeltakerIdBySnutenKryptertFødselsnummer(
          snutenKryptertFnr
        );

      let deltaker = null;
      if (!deltakerId) {
        dispatch(
          addNotification({
            message: {
              text: `Fant ingen person med SNUTEN-kryptert fødselsnummer ${snutenKryptertFnr}`,
            },
            variant: 'info',
          })
        );
      } else {
        deltaker = await DeltakerService.getDeltakerById(deltakerId.id);
      }
      return deltaker;
    } catch (error) {
      const err = error as TraceableError;
      dispatch(
        addNotification({
          message: { text: err.message },
          variant: 'error',
        })
      );
      dispatchError(dispatch, err, addError.type);
      return rejectWithValue(err.message);
    }
  }
);
