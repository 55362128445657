import { RootState } from 'app/rootReducer';
import store from 'app/store';
import { FetchStatus } from 'features/utils';

import { EnheterState } from './interface';
import { enheterAdapter } from './slice';

const enheterNormalizedSelectorsGlobal = enheterAdapter.getSelectors<RootState>(
  (state) => state.currentBruker.enheter
);
export const getEnhetById = (id: number) =>
  enheterNormalizedSelectorsGlobal.selectById(store.getState(), id);

export const getAllEnheter = () =>
  enheterNormalizedSelectorsGlobal.selectAll(store.getState());

export const getEnheterStatus = (state: EnheterState): FetchStatus =>
  state.status;

const selectors = {
  getEnhetById,
  getAllEnheter,
  getEnheterStatus,
};

export default selectors;
