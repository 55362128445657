export enum ErrorTypeEnum {
  NotFound,
  NotCreated,
  NotAllowed,
  LoggedOut,
  NoRights,
  NoAccess,
  LimitedAccess,
}

export type NotificationButton = {
  text: string;
  navBack?: boolean;
  linkTo?: string;
};
