import { Button, Grid, Typography, Card } from '@mui/material';

import { ErrorTypeEnum, NotificationButton } from './interface';

type StaticProps = { errorType: ErrorTypeEnum };

type DynamicProps = {
  header?: string;
  subheader?: string;
  body?: string;
  buttons?: NotificationButton[];
  notifyUsOnEmail?: boolean;
  elevation?: boolean;
};

type Props = StaticProps | DynamicProps;

const DisplayError: React.FC<Props> = (props: Props) => {
  const isStatic = (props: Props): props is StaticProps =>
    (props as StaticProps).errorType !== undefined;

  const getStaticContent = (pageType: ErrorTypeEnum) => {
    const backButton: NotificationButton = {
      text: 'Gå tilbake',
      navBack: true,
    };
    const homeButton: NotificationButton = {
      text: 'Gå hjem',
      linkTo: '/',
    };
    const logInButton: NotificationButton = {
      text: 'Logg inn',
      linkTo: '/',
    };

    switch (pageType) {
      case ErrorTypeEnum.LoggedOut:
        return {
          header: 'Ikke innlogget',
          subheader: 'Du er logget ut av TAPAS',
          body: `Vennligst logg inn igjen`,
          notifyUsOnEmail: false,
          elevation: true,
          buttons: [logInButton],
        };
      case ErrorTypeEnum.NotFound:
        return {
          header: '404',
          subheader: 'Beklager, siden finnes ikke',
          body: `Vi fant dessverre ikke siden du lette etter (404-feil).\nDet kan være at den ikke finnes, eller at lenken du fulgte ikke er korrekt.\nVi er takknemlig for tips om feil.`,
          notifyUsOnEmail: true,
          elevation: true,
          buttons: [homeButton, backButton],
        };
      case ErrorTypeEnum.NotAllowed:
        return {
          header: 'Ingen tilgang',
          subheader:
            'Beklager, du har ikke tilgang til denne siden på denne enheten',
          body: 'Du har ikke tilgang til denne siden på valgt enhet. Mener du dette er feil, kan du sende oss en epost og forklare problemet.',
          notifyUsOnEmail: true,
          elevation: false,
          buttons: [homeButton],
        };
      case ErrorTypeEnum.NotCreated:
        return {
          header: 'Under arbeid',
          subheader: 'Beklager, siden finnes ikke --- enda',
          body: `Vi er på saken, og siden vil dukke opp her når den er ferdig`,
          buttons: [homeButton, backButton],
        };
      case ErrorTypeEnum.NoRights:
        return {
          subheader: 'Bruker har ingen aktive roller i applikasjonen',
          notifyUsOnEmail: true,
          elevation: false,
        };
      case ErrorTypeEnum.NoAccess:
        return {
          subheader: 'Innlogget person har ikke tilgang til applikasjonen',
          notifyUsOnEmail: true,
          elevation: false,
        };
      case ErrorTypeEnum.LimitedAccess:
        return {
          subheader:
            'Innlogget person har ikke tilstrekkelig tilgang. Vennligst logg ut og logg inn igjen ved å velge elektronisk id som gir nivå 4 tilgang f.eks. Bank-ID.',
          notifyUsOnEmail: false,
          elevation: false,
        };
      default:
        throw new Error('Unknown static error page');
    }
  };

  const content: DynamicProps = isStatic(props)
    ? getStaticContent(props.errorType)
    : props;

  const renderContent = ({
    header,
    subheader,
    body,
    buttons,
    notifyUsOnEmail,
    elevation,
  }: DynamicProps) => {
    return (
      <Grid
        container
        direction={elevation ? 'row' : 'column'}
        justifyContent="center">
        <Card sx={{ m: 3, p: 3, boxShadow: 1 }}>
          <Typography variant="h1" component="h2" color="primary" gutterBottom>
            {header}
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            {subheader}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ whiteSpace: 'pre-line' }}>
            {body}
          </Typography>
          {notifyUsOnEmail && (
            <Typography
              variant="body1"
              gutterBottom
              sx={{ whiteSpace: 'pre-line' }}>
              Send gjerne en e-post til{' '}
              <a href="mailto:tp-support@kreftregisteret.no">
                tp-support@kreftregisteret.no
              </a>{' '}
              ved spørsmål
            </Typography>
          )}
          <Grid container spacing={2} sx={{ mt: 4 }}>
            {buttons?.map((button, idx) => (
              <Grid item key={`btngrid_${idx}`}>
                <Button
                  variant="contained"
                  color="secondary"
                  href={button.linkTo}
                  onClick={() => {
                    return button.navBack && window.history.back();
                  }}
                  data-cy="additionalButton">
                  {button.text}
                </Button>
              </Grid>
            ))}
          </Grid>{' '}
        </Card>
      </Grid>
    );
  };

  return renderContent(content);
};

export default DisplayError;
