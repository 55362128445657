import { createSelector } from '@reduxjs/toolkit';
import { demaskertSelector } from 'features/deltaker/details/components/selectors';

import { getInfoDemaskert } from '../state/deltakerSelectors';
import { DeltakerInformasjonSelector } from './interface';

export type DemaskerÅrsakSelector = {
  deltakerToDemask?: number;
};

export const deltakerInformasjonSelector = createSelector(
  [demaskertSelector],
  (selector): DeltakerInformasjonSelector => {
    return {
      fødselsnummer: selector.isDemaskert
        ? selector.fødselsnummer
        : '****** *****',
      bostedsadresse: selector.bostedsadresse,
      navn: selector.navn,
      adresser: selector.adresser
    };
  }
);

export const demaskerÅrsakSelector = createSelector(
  [getInfoDemaskert],
  ({ deltakerToDemask }): DemaskerÅrsakSelector => {
    return {
      deltakerToDemask,
    };
  }
);
