import { createAsyncThunk } from '@reduxjs/toolkit';
import enheterService from 'services/enheter';
import { EnhetDto } from 'services/enheter/dto';
import { TraceableError } from 'utils/errors/customError';
import { DybdeParam } from 'utils/interface';

export const fetchEnheter = createAsyncThunk<
  EnhetDto[],
  undefined,
  { rejectValue: string }
>('enheter/fetchEnheter', async (none, { rejectWithValue, signal }) => {
  try {
    const res = await enheterService.getEnheter({ dybde: DybdeParam.detaljer });
    return res;
  } catch (error) {
    const err = error as TraceableError;
    return rejectWithValue(err.message);
  }
});
