import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { LoadingInputComponent, ScrIcon } from 'components';
import { resetManuellHenvisningSending } from 'features/deltaker/state/henvisning/slice';
import {
  fetchManuellHenvisningMetadata,
  postManuellHenvising,
} from 'features/deltaker/state/henvisning/thunks';
import { fetchEnheter } from 'features/enhet/enheterThunk';
import { FC, useEffect, useState } from 'react';

import { DialogContentWrapper, DialogFormButtons } from '../components';
import { ManuellHenvisningDialogFormProps } from './interface';
import { manuellHenvisningDialogSelector } from './selectors';
import { initialFormState, initialFormStateInvalid } from './utils';

const ManuellHenvisningDialogForm: FC<ManuellHenvisningDialogFormProps> = ({
  close,
}) => {
  const dispatch = useAppDispatch();
  const {
    deltakerId,
    enheter,
    fetchingEnheter,
    metadataStatus,
    metadata,
    postStatus,
    closeForm,
    inDialogErrorMessage,
  } = useAppSelector(manuellHenvisningDialogSelector);

  const [formState, setFormState] = useState(initialFormState);
  const [formStateInvalid, setFormStateInvalid] = useState(
    initialFormStateInvalid
  );
  const [henvisningstekstLock, setHenvisningstekstLock] = useState(true);

  useEffect(() => {
    return () => {
      dispatch(resetManuellHenvisningSending());
    };
  }, [dispatch]);

  useEffect(() => {
    deltakerId && dispatch(fetchManuellHenvisningMetadata(deltakerId));
    deltakerId && dispatch(fetchEnheter());
  }, [dispatch, deltakerId]);

  useEffect(() => {
    if (closeForm) close();
  }, [closeForm, close]);

  useEffect(() => {
    if (metadata) {
      setFormState((prevState) => ({ ...prevState, ...metadata }));
    }
  }, [metadata]);

  const isAllLoaded = !fetchingEnheter && metadataStatus;

  if (!isAllLoaded) return null;

  const handleChange = (
    event:
      | React.ChangeEvent<{ name?: string; value: unknown }>
      | SelectChangeEvent<string | number | number[]>
  ) => {
    const { name, value } = event.target;
    if (name) {
      setFormState({ ...formState, [name]: value });
      setFormStateInvalid({ ...formStateInvalid, [name]: false });
    }
  };

  const handleInvalid = (event: React.FormEvent) => {
    event.preventDefault();
    const { name } = event.target as HTMLInputElement;
    setFormStateInvalid({ ...formStateInvalid, [name]: true });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    dispatch(
      postManuellHenvising({
        henvisning: formState,
      })
    );
  };

  const unlockHenvisningstekst = () => {
    setHenvisningstekstLock(false);
  };

  const errorMessage = inDialogErrorMessage
    ? {
        title: 'Noe gikk galt ved lagring',
        message: inDialogErrorMessage,
      }
    : undefined;

  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <DialogContentWrapper
        showDeltakerInfo
        tekstOverskrift="Ny manuell henvisning"
        errorMessage={errorMessage}>
        <FormControl variant="filled" fullWidth sx={{ mb: 2 }}>
          <LoadingInputComponent showLoading={fetchingEnheter}>
            <InputLabel id="screeningsenter-select">
              Velg screeningsenter
            </InputLabel>
            <Select
              labelId="screeningsenter-select"
              name="screeningsenterId"
              value={formState.screeningsenterId ?? ''}
              onChange={handleChange}>
              <MenuItem value="">
                <em>Valgt basert på person adresse</em>
              </MenuItem>
              {enheter
                ?.filter(
                  (enhet) => enhet.aktiv && enhet.enhetType?.kanHenvisesTil
                )
                .map((enhet) => (
                  <MenuItem key={enhet.id} value={enhet.id}>
                    {enhet.navn}
                  </MenuItem>
                ))}
            </Select>
          </LoadingInputComponent>
        </FormControl>
        <TextField
          id="henvisning-tekst"
          label="Henvisningstekst..."
          name="henvisningstekst"
          required
          fullWidth
          multiline
          spellCheck={false}
          rows={10}
          value={formState.henvisningstekst ?? ''}
          InputLabelProps={{ shrink: true }}
          error={formStateInvalid.henvisningstekst}
          helperText={
            formStateInvalid.henvisningstekst
              ? 'Vennligst fyll ut henvisningstekst'
              : ''
          }
          onChange={handleChange}
          onInvalid={handleInvalid}
          disabled={henvisningstekstLock}
        />
        <Button
          startIcon={
            <ScrIcon iconId={henvisningstekstLock ? 57 : 58} size="small" />
          }
          type="button"
          disabled={!henvisningstekstLock}
          onClick={unlockHenvisningstekst}>
          Åpne henvisningstekst for redigering
        </Button>
      </DialogContentWrapper>

      <DialogFormButtons
        disabled={postStatus.loading}
        isLoading={fetchingEnheter}
        close={close}
        tekstHandling="Send henvisning"
      />
    </form>
  );
};

export default ManuellHenvisningDialogForm;
