import { SendStatus } from 'features/utils';

import { HenvendelseSendingState } from './interface';

export const getHenvendelseSendStatus = (
  state: HenvendelseSendingState
): SendStatus => state.postHenvendelseStatus;

export const getFritekstbrevSendStatus = (
  state: HenvendelseSendingState
): SendStatus => state.postFritekstbrevStatus;

const selectors = {
  getHenvendelseSendStatus,
  getFritekstbrevSendStatus,
};

export default selectors;
