import { useAppSelector } from 'app/hooks';
import { RootState } from 'app/rootReducer';
import { memoize } from 'proxy-memoize';

export const useParamSelector = <T>(
  selector: Function,
  ...params: any[]
): T => {
  return useAppSelector((state: RootState) => selector(state, params));
};
export const useParamSelectorWithMemoize = <T>(
  selector: Function,
  ...params: any[]
): T => {
  return useAppSelector(memoize((state: RootState) => selector(state, params)));
};
