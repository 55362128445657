import { Paper, PaperProps } from '@mui/material';
import Draggable from 'react-draggable';

type Props = {
  paperProps: PaperProps;
  handle: string;
  cancel: string;
};

const DraggablePaper = ({ paperProps, handle, cancel }: Props) => (
  <Draggable handle={handle} cancel={cancel}>
    <Paper {...paperProps} />
  </Draggable>
);

export default DraggablePaper;
