import { Box, Typography } from '@mui/material';
import Status from 'components/Status/Status';
import React from 'react';

const StatusExample: React.FC = () => {
  return (
    <>
      <Box>
        <Typography variant="h6">Standard</Typography>
        <Status type="aktiv" />
        <Status type="inaktiv" />
        <Status />
      </Box>
      <Box>
        <Typography variant="h6">Custom</Typography>
        <Status type="aktiv" overrideText="Ja" />
        <Status type="inaktiv" overrideText="Nei" />
        <Status overrideText="Vet ikke" />
      </Box>
      <Box>
        <Typography variant="h6">Uten tekst</Typography>
        <Status type="aktiv" showText={false} />
        <Status type="inaktiv" showText={false} />
      </Box>
    </>
  );
};

export default StatusExample;
