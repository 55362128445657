import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet } from 'utils/http';
import { redirectToLogin } from 'utils/redirect';

import { GetLoggedInBruker, LoggedInBrukerService } from './interface';

const basePath = 'logged-in-bruker';

const getLoggedInBruker: GetLoggedInBruker = async (signal?) => {
  // @ts-ignore // TODO: ts2339. find solution and remove
  const isCypressTest = window.Cypress && window.authBrukerData;

  if (isCypressTest) {
    // @ts-ignore
    return window.authBrukerData;
  }
  const fullPath = buildPath(basePath);
  try {
    return await httpGet(fullPath, signal);
  } catch (error) {
    redirectToLogin();
    throw addMessageToExsistingFetchError(
      error as FetchResponseError,
      `En feil oppstod i henting av innlogget bruker:  ${
        (error as FetchResponseError).message
      }`
    );
  }
};

export default {
  getLoggedInBruker,
} as LoggedInBrukerService;
