import Reestimer from 'features/invitasjon/components/Reestimer';
import { DateTime } from 'luxon';
import { useState } from 'react';

const ReestimerExample: React.FC = () => {
  const [, setDate] = useState<DateTime>();

  const handleDateChange = (newDate: DateTime) => setDate(newDate);

  return <Reestimer changeDate={handleDateChange} isLoading={false} />;
};

export default ReestimerExample;
