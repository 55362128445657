import { Alert, AlertTitle } from '@mui/material';

const BekreftelseAlert = () => {
  return (
    <Alert severity="error">
      <AlertTitle>Noe gikk galt ved sending</AlertTitle>Vennligst prøv igjen
    </Alert>
  );
};

export default BekreftelseAlert;
