export enum Size {
  Small,
  Medium,
  Large,
}

export const defaultDrawerWidth = 220;
export const minDrawerWidth = 80;
export const maxDrawerWidth = 320;
export const shouldShrinkToSmallSize = 150;

export const SIDEBAR_WIDTH = 'SIDEBAR_WIDTH';
