import { OppfølgingCreateDto } from 'services/deltaker/dto';
import { SysProperty } from 'services/sys/dto';

export type HenvendelseDto = {
  deltakerId: number;
  tekst: string;
  henvendelseKanalId: number;
  henvendelseTypeId: number;
  mottattTidspunkt: string;
  henvendelseKategorier?: number[];
  bekreftelsesBrevØnsket: boolean;
};

export type HenvendelseUpdateDto = {
  tekst: string;
  henvendelseKanalId: number;
  mottattTidspunkt: string;
  henvendelseKategorier?: number[];
};
export type GetHenvendelseDto = {
  deltakerId: number;
  henvendelseKanalId: number;
  henvendelseKategoriIder?: number[];
  henvendelseTypeId: number;
  id: number;
  mottattTidspunkt: string;
  mottattTidspunktOgHenvendelseKanalKanEndres: boolean;
  bekreftelsesBrevØnsket?: boolean;
  tekst?: string;
  harTekst: boolean;
  versjon: number;
};
export type HenvendelseNøkkeldataDto = {
  id: number;
  tidspunkt: string;
  henvendelseTypeId: number;
  henvendelseKanalId: number;
};

export type HenvendelseTypeDto = SysProperty & {
  tekstHandling: string;
  tekstOverskrift: string;
  tekstVeiledning: string;
  tekstErDuSikker: string;
  tekstUtførKnapp: string;
  bekreftelsesbrevTilgjengelig: boolean;
};

export type HenvendelseDeltakerDto = {
  henvendelseTypeId: number;
  disabled: boolean;
  ikonId: number;
};

export type HenvendelseMedOppfølgingOgFritekstbrevCreateDto = {
  henvendelse: HenvendelseDto;
  oppfølging?: Partial<OppfølgingCreateDto>;
  fritekstbrev?: FritekstbrevCreateDto;
};

export type FritekstbrevCreateDto = {
  hovedoverskrift?: string;
  fritekst: string;
};

export type HenvendelseOpplistingDto = {
  tidspunkt: string;
  deltakerId: number;
  deltakerStatus: string;
  henvendelseKanalId: number;
  henvendelseKategorier: [number];
  henvendelseTypeId: number;
};

export type HenvendelseOpplistingIntervallDto = {
  dateFrom: string;
  dateTo: string;
  reservasjonVedNegativtFunn: number;
  opphevelseAvReservasjonVedNegativtFunn: number;
  invitasjonsstopp: number;
  opphevelseAvInvitasjonsstopp: number;
  nyttPrøvesett: number;
  nyttPrøvesettEtterPrøvesvar: number;
  stoppAvPåminnelse: number;
  neiTilPrimærKoloskopi: number;
  jaTilPrimærKoloskopi: number;
  annenHenvendelse: number;
  fritekstbrev: number;
  oppheveReturFraPosten: number;
  settSomDødFørFolkeregisteroppdatering: number;
  opphevDødFørFolkeregisteroppdatering: number;
  annenHenvendelseMedBehovForManOppfølging: number;
  stoppPrøvesettRunde1: number;
  gjenopptaPrøvesettRunde1: number;
  total: number;
};

export enum HenvendelseTypeKey {
  RESERVASJON_VED_NEGATIVT_FUNN = 1,
  OPPHEVELSE_AV_RESERVASJON_VED_NEGATIVT_FUNN = 2,
  INVITASJONSSTOPP = 3,
  OPPHEVELSE_AV_INVITASJONSSTOPP = 4,
  NYTT_PRØVESETT = 5,
  STOPP_AV_PÅMINNELSE = 6,
  NEI_TIL_PRIMÆR_KOLOSKOPI = 7,
  JA_TIL_PRIMÆR_KOLOSKOPI = 8,
  ANNEN_HENVENDELSE = 9,
  FRITEKSTBREV = 10,
  OPPHEVE_RETUR_FRA_POSTEN = 11,
  SETT_SOM_DØD_FØR_FOLKEREGISTEROPPDATERING = 12,
  OPPHEV_DØD_FØR_FOLKEREGISTEROPPDATERING = 13,
  ANNEN_HENVENDELSE_MED_BEHOV_FOR_MAN_OPPFØLGING = 14,
  NYTT_PRØVESETT_ETTER_PRØVESVAR = 15,
  STOPP_PRØVESETT_RUNDE_1 = 16,
  GJENOPPTA_PRØVESETT_RUNDE_1 = 17,
}
