import { addError } from 'features/errorHandling/errorHandlingSlice';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { FetchStatus } from 'features/utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchResponseError } from 'utils/errors/customError';

import { FetchReturn } from './interface';

export const useFetchData = <T>(
  fetchFunc: (signal?: AbortSignal) => Promise<T>,
  condition = true
): FetchReturn<T> => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [status, setStatus] = useState<FetchStatus>({
    loading: false,
  });

  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (controller: AbortController) => {
      setStatus({
        loading: true,
        loadingComplete: false,
      });

      try {
        const res = await fetchFunc(controller.signal);
        setData(res);
        setStatus({
          loading: false,
          loadingComplete: true,
        });
      } catch (error) {
        if ((error as FetchResponseError).isAbortError) {
          setStatus({
            loading: false,
          });
        } else {
          setStatus({
            loading: false,
            errorMessage: (error as FetchResponseError).message,
            loadingComplete: true,
          });
          dispatchError(dispatch, error as FetchResponseError, addError.type);
        }
      }
    },
    [dispatch, fetchFunc]
  );

  const refetch = useCallback(() => {
    const controller = new AbortController();
    if (condition) fetchData(controller);
  }, [fetchData, condition]);

  useEffect(() => {
    const controller = new AbortController();
    if (condition) fetchData(controller);
    return () => {
      controller.abort();
    };
  }, [dispatch, fetchData, condition]);

  return { data, status, refetch };
};

export default useFetchData;
