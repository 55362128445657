import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost, httpPut } from 'utils/http';

import {
  GetAvailableHenvendelseTyperByDeltaker,
  GetHenvendelse,
  GetHenvendelseKanalIdsByHenvendelseType,
  HenvendelserService,
  SaveHenvendelse,
  UpdateHenvendelse,
} from './interface';

const basePath = 'henvendelser';

export const getAvailableHenvendelseTyperByDeltaker: GetAvailableHenvendelseTyperByDeltaker =
  async (deltakerId, signal?) => {
    const path = buildPath(
      `${basePath}/typer/tilgjengelig-for-deltaker/${deltakerId}`
    );
    return httpGet(path, signal).catch((error) => {
      throw addMessageToExsistingFetchError(
        error,
        `En feil oppsto ved henting av tilgjengelige henvendelseTyper for deltaker med id ${deltakerId}`
      );
    });
  };

export const getHenvendelse: GetHenvendelse = async (id, signal?) => {
  const path = buildPath(`${basePath}/${id}`);
  return httpGet(path, signal).catch((error) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av henvendelse med id ${id}`
    );
  });
};

export const saveHenvendelse: SaveHenvendelse = async (henvendelse) => {
  const path = buildPath(basePath);
  return httpPost(path, henvendelse).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      'En feil oppsto ved ved lagring av henvendelse'
    );
  });
};

export const updateHenvendelse: UpdateHenvendelse = async (
  henvendelseId,
  henvendelseUpdate
) => {
  const path = buildPath(`${basePath}/${henvendelseId}`);
  return httpPut(path, henvendelseUpdate).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      'En feil oppsto ved ved lagring av henvendelse'
    );
  });
};

export const getHenvendelseKanalIdsByHenvendelseType: GetHenvendelseKanalIdsByHenvendelseType =
  async (henvendelseTypeId, signal?) => {
    const path = buildPath(
      `${basePath}/typer/${henvendelseTypeId}/manuell-registrering-kanaler`
    );
    return httpGet(path, signal)
      .then((response) => response.henvendelseKanaler)
      .catch((error) => {
        throw addMessageToExsistingFetchError(
          error,
          `En feil oppsto ved henting av henvendelseKanalIder for henvendelseTypeId ${henvendelseTypeId}`
        );
      });
  };

export default {
  getAvailableHenvendelseTyperByDeltaker,
  saveHenvendelse,
  updateHenvendelse,
  getHenvendelseKanalIdsByHenvendelseType,
} as HenvendelserService;
