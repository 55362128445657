export type VariantType = 'error' | 'warning' | 'info' | 'success';

type Timeout = 4000 | 5000 | 6000 | 7000 | 8000 | 9000 | 10000;

export type NotificationMessage = { title?: string; text: string };

export enum ActionEnum {
  Redirect,
  Refresh,
}

export type ClickAction = {
  action: ActionEnum;
  value?: string;
};

export type NotificationAction = {
  text: string;
  clickAction: ClickAction;
};

export interface Notification {
  message: NotificationMessage;
  variant: VariantType;
  timeout?: Timeout;
  action?: NotificationAction;
}

export type DisplayNotification = {
  message: NotificationMessage;
  options: NotificationOptions;
  dismissed: boolean;
  action?: NotificationAction;
};

export type NotificationOptions = {
  key: string;
  variant: VariantType;
  timeout: Timeout;
};
