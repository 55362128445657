import { createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus, loading, loadingComplete } from 'features/utils';
import { ErrorNamesEnum } from 'utils/errors/interface';

import { initialState, sysAdapter } from './interface';
import { fetchSysProperties } from './sysPropertiesThunk';

const slice = createSlice({
  name: 'sys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSysProperties.pending, (state, { meta }) => {
        state[meta.arg].status = loading;
      })
      .addCase(
        fetchSysProperties.fulfilled,
        (state, { payload: { key, data } }) => {
          sysAdapter.setAll(state[key], data);
          state[key].status = loadingComplete;
        }
      )
      .addCase(
        fetchSysProperties.rejected,
        (state, { meta: { arg }, payload, error }) => {
          if ((error.name = ErrorNamesEnum.AbortError)) {
            state[arg].status = {
              ...initialFetchStatus,
            };
          } else {
            state[arg].status = {
              ...initialFetchStatus,
              errorMessage: payload,
            };
          }
        }
      );
  },
});

export default slice.reducer;
