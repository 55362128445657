import { getHideIdIn } from 'features/senteradmin/state/selectors';
import {
  FetchResponseError,
  addMessageToExsistingFetchError,
} from 'utils/errors/customError';
import { buildPath, httpGet, httpPost, httpPut } from 'utils/http';

import { SystemTabell } from './dto';
import {
  AddSysProperty,
  GetSysInfo,
  GetSysMetaData,
  GetSysProperties,
  GetSysTable,
  SysService,
  UpdateSysProperty,
} from './interface';
import { paths } from './utils';

const hideIdIn = getHideIdIn();

export const getSysProperties: GetSysProperties = async (
  sysKey,
  signal,
  detaljer
) => {
  const fullPath = detaljer
    ? buildPath(paths[sysKey], { hvemogtidspunkt: 'detaljer' })
    : buildPath(paths[sysKey]);
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av ${sysKey}`
    );
  });
};

export const getSysMetaData: GetSysMetaData = async (signal?) => {
  const fullPath = buildPath('sysmetadata', { hvemogtidspunkt: 'detaljer' });
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw error;
  });
};

export const getSysTable: GetSysTable = async (path, signal?) => {
  const fullPath = buildPath(path, { hvemogtidspunkt: 'detaljer' });
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw addMessageToExsistingFetchError(
      error,
      `En feil oppsto ved henting av ${path}`
    );
  });
};

export const getSysInfo: GetSysInfo = async (signal?) => {
  const fullPath = buildPath('system/info');
  return httpGet(fullPath, signal).catch((error: FetchResponseError) => {
    throw error;
  });
};

export const updateSysProperty: UpdateSysProperty = async (
  path: string,
  dto: SystemTabell
) => {
  if (hideIdIn.includes(path)) {
    return httpPut(
      buildPath(`${path}/${dto.nr ? dto.nr : dto.kode}`),
      dto
    ).catch((error: FetchResponseError) => {
      throw error;
    });
  } else {
    return httpPut(buildPath(`${path}/${dto.id}`), dto).catch(
      (error: FetchResponseError) => {
        throw error;
      }
    );
  }
};

export const addSysProperty: AddSysProperty = async (path, dto) => {
  if (hideIdIn.includes(path)) {
    return httpPost(buildPath(`${path}`), dto).catch(
      (error: FetchResponseError) => {
        throw error;
      }
    );
  } else {
    return httpPost(buildPath(`${path}/${dto.id}`), dto).catch(
      (error: FetchResponseError) => {
        throw error;
      }
    );
  }
};

export default {
  getSysProperties,
  getSysMetaData,
  addSysProperty,
  updateSysProperty,
  getSysTable,
  getSysInfo,
} as SysService;
