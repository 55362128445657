import { lazy } from 'react';

import {
  UTSENDINGSPAUSE_OPPRETT_PATH,
  UTSENDINGSPAUSE_REDIGER_PATH,
} from './routeConstants';

const AuthRoute = lazy(() => import('./AuthRoute'));
const GoBack = lazy(() => import('app/routes/GoBack'));
const OpprettUtsendingspause = lazy(
  () => import('features/utsendingspause/opprett/OpprettUtsendingspause')
);

const RedigerUtsendingspause = lazy(
  () => import('features/utsendingspause/rediger/RedigerUtsendingspause')
);
const UtsendingspausePage = lazy(
  () => import('features/utsendingspause/UtsendingspausePage')
);

export const utsendingspauseRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <UtsendingspausePage />
      </AuthRoute>
    ),
  },
  {
    path: UTSENDINGSPAUSE_OPPRETT_PATH,
    element: (
      <AuthRoute>
        <GoBack />
        <OpprettUtsendingspause />
      </AuthRoute>
    ),
  },
  {
    path: `${UTSENDINGSPAUSE_REDIGER_PATH}/:id`,
    element: (
      <AuthRoute>
        <GoBack />
        <RedigerUtsendingspause />
      </AuthRoute>
    ),
  },
];
