import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Utsending, UtsendingTypeDokumentType } from 'services/utsending/dto';

import { UtsendingState } from './interface';
import * as thunks from './utsendingThunk';

export const utsendingAdapter = createEntityAdapter<Utsending, number>({
  selectId: ({ id }) => id,
});
export const utsendingtypedokumenttypeAdapter =
  createEntityAdapter<UtsendingTypeDokumentType>({});

export const initialState: UtsendingState = {
  loadingUtsendinger: false,
  loadingUtsendingTypeDokumentTyper: false,
  errorMessage: null,
  utsendinger: utsendingAdapter.getInitialState(),
  utsendingtypedokumenttyper:
    utsendingtypedokumenttypeAdapter.getInitialState(),
};

const slice = createSlice({
  name: 'utsending',
  initialState,
  reducers: {
    reset: (state) => {
      state.utsendinger = utsendingAdapter.getInitialState();
      state.utsendingtypedokumenttyper =
        utsendingtypedokumenttypeAdapter.getInitialState();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.fetchUtsendinger.pending, (state) => {
        state.loadingUtsendinger = true;
      })
      .addCase(
        thunks.fetchUtsendinger.fulfilled,
        (state, { payload: utsendinger }) => {
          state.loadingUtsendinger = false;
          utsendingAdapter.setAll(state.utsendinger, utsendinger);
        }
      )
      .addCase(
        thunks.fetchUtsendinger.rejected,
        (state, { error: { message } }) => {
          return {
            ...initialState,
            errorMessage: message || null,
          };
        }
      )
      .addCase(thunks.fetchUtsendingtypeDokumenttyper.pending, (state) => {
        state.loadingUtsendingTypeDokumentTyper = true;
      })
      .addCase(
        thunks.fetchUtsendingtypeDokumenttyper.fulfilled,
        (state, { payload: utsendingtypedokumenttyper }) => {
          state.loadingUtsendingTypeDokumentTyper = false;
          utsendingtypedokumenttypeAdapter.setAll(
            state.utsendingtypedokumenttyper,
            utsendingtypedokumenttyper
          );
        }
      )
      .addCase(
        thunks.fetchUtsendingtypeDokumenttyper.rejected,
        (state, { error: { message } }) => {
          return {
            ...initialState,
            errorMessage: message || null,
          };
        }
      );
  },
});

export const { reset } = slice.actions;
export default slice.reducer;
