import { combineReducers } from '@reduxjs/toolkit';

import henvendelseKanaler from './kanaler/slice';
import henvendelseSending from './sending/slice';
import henvendelseTyper from './typer/slice';

const henvendelseReducer = combineReducers({
  typer: henvendelseTyper,
  sending: henvendelseSending,
  kanaler: henvendelseKanaler,
});

export default henvendelseReducer;
export type HenvendelseState = ReturnType<typeof henvendelseReducer>;
