import { DateTimePicker, DateTimeValidationError } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { updateForm } from 'features/deltaker/state/oppfølgingspunkt/slice';
import { autoFillUtførtTidspunkt } from 'features/deltaker/state/oppfølgingspunkt/thunks';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import {
  PickerFormat,
  PickerProps,
  fromISOToDateTime,
  isValidDateTime,
} from 'utils/date';

import { OppfølgingUtførtFormFieldsProps } from './interface';
import { oppfølgingspunkt } from './selectors';
import { OppfølgingspunktUsername } from '.';

const OppfølgingUtførtFormFields: React.FC<OppfølgingUtførtFormFieldsProps> = ({
  onInvalid,
}) => {
  const dispatch = useAppDispatch();
  const { formValues, invalidFields } = useAppSelector(oppfølgingspunkt);
  const [picker, setPicker] = useState<PickerProps>({
    value: fromISOToDateTime(formValues.utførtTidspunkt) || null,
    format: PickerFormat.DATE_TIME,
  });

  useEffect(() => {
    const autoFillTimeOnce =
      !formValues.utførtTidspunkt && !invalidFields.utførtTidspunkt;

    if (autoFillTimeOnce) dispatch(autoFillUtførtTidspunkt());
  }, [dispatch, formValues.utførtTidspunkt, invalidFields.utførtTidspunkt]);

  const handleDateTimeChange = (value: DateTime | null) => {
    setPicker({ ...picker, value: value, displayError: false });

    if (isValidDateTime(value)) {
      dispatch(
        updateForm({
          key: 'utførtTidspunkt',
          value: value?.toISO({ includeOffset: false }),
        })
      );
    }
  };

  const handleDateTimeError = (error: DateTimeValidationError) =>
    setPicker({ ...picker, error: error ? 'Ugyldig dato' : '' });

  const handleDateTimeBlur = () =>
    setPicker({
      ...picker,
      displayError: !!picker.error,
    });

  return (
    <>
      <OppfølgingspunktUsername onInvalid={onInvalid} />
      <DateTimePicker
        label="Utført"
        aria-label="Utført tidspunkt"
        ampm={false}
        format={picker.format.inputFormat}
        value={picker.value}
        onChange={handleDateTimeChange}
        onError={handleDateTimeError}
        slotProps={{
          textField: {
            name: 'utførtTidspunkt',
            required: true,
            error: !!picker.displayError || !!invalidFields.utførtTidspunkt,
            helperText: !!picker.displayError && picker.error,
            onBlur: handleDateTimeBlur,
            onInvalid: onInvalid,
          },
        }}
      />
    </>
  );
};

export default OppfølgingUtførtFormFields;
