import { combineReducers } from '@reduxjs/toolkit';

import allowedRoutes from './allowedRoutes/slice';
import auth from './auth/slice';
import brukeradminEnheter from './brukeradminEnheter/slice';
import enheter from './enheter/slice';
import rettigheter from './rettigheter/slice';

const currentBrukerReducer = combineReducers({
  allowedRoutes,
  auth,
  brukeradminEnheter,
  enheter,
  rettigheter,
});

export default currentBrukerReducer;
