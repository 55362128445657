import { TableCell } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

export const ColumnTableCell = styled(TableCell)(
  ({ theme: { palette, typography } }) => ({
    fontSize: 14,
    fontWeight: typography.fontWeightMedium,
    color: palette.primary.main,
  })
);
