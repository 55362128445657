import { useTheme } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';

const GlobalCss: React.FC = () => {
  const { palette, spacing, breakpoints } = useTheme();

  return (
    <GlobalStyles
      styles={{
        textarea: {
          borderColor: palette.scr.grey300,
        },
        '.MuiAlert-standardInfo': {
          border: `1px solid ${palette.info.main}`,
        },
        '.MuiAlert-standardError': {
          border: `1px solid ${palette.error.main}`,
        },
        '.MuiAlert-standardWarning': {
          border: `1px solid ${palette.warning.main}`,
        },
        '.MuiAlert-standardSuccess': {
          border: `1px solid ${palette.success.main}`,
        },
        '.MuiInputBase-root.MuiFilledInput-root': {
          backgroundColor: palette.common.white,
          '&.Mui-focused': {
            backgroundColor: palette.common.white,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.scr.grey300,
          },
          '&:hover': {
            '&:not(.Mui-disabled)': {
              backgroundColor: palette.common.white,
            },
          },
          '.MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: palette.scr.grey800,
          },
          '.MuiSelect-filled:focus': {
            backgroundColor: palette.common.white,
          },
        },
        '.MuiInputLabel-root.MuiInputLabel-filled': {
          color: palette.common.black,
          '&.Mui-disabled': {
            color: palette.scr.grey800,
          },
        },
        '.MuiButtonBase-root.MuiCheckbox-root': {
          color: palette.primary.main,
          [breakpoints.down('lg')]: {
            transform: 'scale(1.5)',
          },
        },
        '.MuiDialog-root': {
          '.MuiDialogTitle-root': {
            color: palette.primary.main,
            padding: spacing(4, 4, 1),
          },
          '.MuiDialogContent-root, .MuiDialogTitle-root + .MuiDialogContent-root':
            {
              padding: spacing(1, 4),
            },
          '.MuiDialogContentText-root': {
            color: palette.text.primary,
            lineHeight: 1.25,
            marginBottom: spacing(1.5),
          },
          '.MuiDialogActions-root': {
            padding: spacing(2, 4, 4),
          },
        },
        '.MuiDivider-root': { fontStyle: 'italic', fontSize: 14 },
      }}
    />
  );
};

export default GlobalCss;
