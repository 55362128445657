import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from 'app/hooks';
import { ScrIcon } from 'components';
import { useSysProperties } from 'features/sys';
import { FC } from 'react';

import DataRow from '../details/components/DeltakerCommunicationLog/DataRow';
import { DeltakerInformationProps } from './interface';
import { deltakerInformasjonSelector } from './selectors';

const DeltakerInformation: FC<DeltakerInformationProps> = ({
  hideFødselsnummer,
  hideDeltakerAdresse,
}) => {
  const { fødselsnummer, bostedsadresse, navn, adresser } = useAppSelector(
    deltakerInformasjonSelector
  );
  const { mapBeskrivelse } = useSysProperties('adresseTyper');
  return (
    <Grid container>
      {!hideFødselsnummer && (
        <DataRow left={4} right={8} title="Fnr" value={fødselsnummer} />
      )}
      <DataRow left={4} right={8} title="Navn" value={navn} />

      {adresser && adresser.length === 1 && !hideDeltakerAdresse && (
        <DataRow left={4} right={8} title="Adresse" value={bostedsadresse} />
      )}
      {adresser && adresser.length > 1 && !hideDeltakerAdresse && (
        <>
          {adresser?.map(({ adresse, adresseType, effektivPostAdresse }) => (
            <>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {mapBeskrivelse(adresseType)}:
                  {effektivPostAdresse && (
                    <Box component={'span'} sx={{ pl: 1 }}>
                      <ScrIcon iconId={74} size="small" />
                    </Box>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">
                  {adresse}
                  {effektivPostAdresse && (
                    <Typography
                      sx={{
                        display: 'inline',
                        fontStyle: 'italic',
                        fontSize: 13,
                        color: (theme) => theme.palette.scr.lightBlue900,
                      }}>
                      &nbsp;(Effektiv postadresse)
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </>
          ))}
        </>
      )}
    </Grid>
  );
};

export default DeltakerInformation;
