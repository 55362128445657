import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSnutenKryptertFødselsnummerByDeltakerId,
} from 'services/deltaker/deltakerService';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { FetchResponseError } from 'utils/errors/customError';

const typeName = 'deltaker/snuten';

export const fetchSnutenFnr = createAsyncThunk<
    {
      snutenKryptertFødselsnr: string;
    } | undefined,
    number,
    { rejectValue: string }
    >(
    `${typeName}`,
    async (deltakerId, { rejectWithValue, dispatch }) => {
      try {
        return getSnutenKryptertFødselsnummerByDeltakerId(deltakerId);
      } catch (error) {
        const err = error as FetchResponseError;
        dispatchError(dispatch, err, AddError);
        return rejectWithValue(err.message);
      }
    }
);
