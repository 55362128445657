import { Button, Grid, Typography } from '@mui/material';
import { ScrWrapper } from 'components';
import React, { useEffect, useState } from 'react';

import BekreftelseDialog from '../BekreftelseDialog';
import { AntallTable, Antallsbegrensning } from './';
import { AntallProps } from './interface';

const Antall: React.FC<AntallProps> = ({
  antallsbegrensningProps,
  inklusjonProps,
  tableHeader,
  submitButtonText,
  submit,
  bekreftDialogContent,
  data,
  dataFunctions,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const [bekreftOpen, setBekreftOpen] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const isAnyDataSelected = data.find((item) => item.selected);
  const isAnyDataErrors = !!data.find(
    (item) => (item.korrigertAntall ?? 0) > item.antall
  );
  const isAllKorrigertZero = data.every((item) => item.korrigertAntall === 0);
  useEffect(() => {
    if (invalid && isAnyDataSelected) {
      setInvalid(false);
    }
  }, [invalid, isAnyDataSelected]);

  const openBekreft = () => {
    if (isAnyDataErrors) {
      //Do nothing
    } else if (isAnyDataSelected) {
      setBekreftOpen(true);
    } else {
      setInvalid(true);
    }
  };
  const closeBekreft = () => setBekreftOpen(false);

  return (
    <>
      <ScrWrapper component="section" variant="secondary" sx={{ p: 2 }}>
        <Grid container>
          {antallsbegrensningProps && (
            <Antallsbegrensning
              {...antallsbegrensningProps}
              invalid={isAnyDataErrors}
            />
          )}
          <Grid item xs={12} sx={{ pt: 2 }}>
            <AntallTable
              header={tableHeader}
              antallsbegrensningActive={
                antallsbegrensningProps?.active || false
              }
              invalid={invalid}
              data={data}
              dataFunctions={dataFunctions}
              inklusjonProps={inklusjonProps}
              setDisableButton={setDisableButton}
            />
            <Typography variant="body2" sx={{ mt: 1 }}>
              Tallet er basert på estimater. Avvik vil forekomme.
            </Typography>
            {antallsbegrensningProps && antallsbegrensningProps?.active && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                * Totale antallet inklusjoner er listet under "Korrigert" i
                tabellen
              </Typography>
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" sx={{ pt: 1 }}>
            <Button
              variant="contained"
              onClick={openBekreft}
              disabled={disableButton || isAllKorrigertZero}>
              {submitButtonText}
            </Button>
          </Grid>
        </Grid>
      </ScrWrapper>

      {/* TODO: SCREENIT-1983 Denne må vurderes om trengs når mer er på plass backend og vi bedre ser fordeler/ulemper med ved å ha den her, eller på page-nivå */}
      <BekreftelseDialog
        open={bekreftOpen}
        close={closeBekreft}
        bekreftText={`Ja, ${submitButtonText}`}
        bekreft={submit}>
        {bekreftDialogContent}
      </BekreftelseDialog>
    </>
  );
};

export default Antall;
