import {
  CategoryOption,
  CategoryProps,
  Data,
  FilterMenuCategory,
} from './interfaces';

export const createFilterMenu = (
  data: Data<string>[],
  categoryMap?: Map<string, CategoryProps>
) => {
  return categoryMap
    ? createMappedFilter(data, categoryMap)
    : createAllOptionsFilterMenu(data);
};

export const noFilterValuesSelected = (filterMenu: FilterMenuCategory[]) =>
  filterMenu.some((fMenu) => !isSomeCategoryOptionsSelected(fMenu.options));

export const filterRows = (
  dataRows: Data<string>[],
  filterMenu: FilterMenuCategory[]
) => {
  const dataRowsToDisplay: Data<string>[] = [];
  if (noFilterValuesSelected(filterMenu)) return dataRows;
  dataRows.forEach((dataRow) => {
    let hideRow = false;
    for (const [columnKey, columnValue] of Object.entries(dataRow)) {
      const isFilterOptionAndNotSelected =
        isColumnOptionInFilter(columnKey, filterMenu) &&
        !isCategoryOptionSelected(filterMenu, columnKey, columnValue);

      if (isFilterOptionAndNotSelected) {
        hideRow = true;
        break;
      }
    }
    if (!hideRow) dataRowsToDisplay.push(dataRow);
  });

  return dataRowsToDisplay;
};

export const selectCategoryOption = (
  filterMenu: FilterMenuCategory[],
  categoryKey: string,
  optionKey: string
) => {
  const newCategories = [...filterMenu];

  const category = findCategory(newCategories, categoryKey);

  if (category) {
    const optionFoundIndex = findOptionIndex(category, optionKey);

    if (optionFoundIndex !== -1) {
      const option = category.options[optionFoundIndex];
      option.isSelected = !option.isSelected;
    }
  }

  return newCategories;
};

export const isCategoryOptionSelected = (
  filterMenu: FilterMenuCategory[],
  categoryKey: string,
  optionKey: string
): boolean => {
  const category = findCategory(filterMenu, categoryKey);

  if (category) {
    const optionFoundIndex = findOptionIndex(category, optionKey);

    if (optionFoundIndex !== -1)
      return category.options[optionFoundIndex].isSelected;
  }

  return false;
};

export const isAllCategoryOptionsSelected = (
  categoryOptions: CategoryOption[]
) => categoryOptions.every((option) => option.isSelected);

export const isSomeCategoryOptionsSelected = (
  categoryOptions: CategoryOption[]
) => categoryOptions.some((option) => option.isSelected);

export const toggleSelectAllCategoryOptions = (
  filterMenu: FilterMenuCategory[],
  categoryKey: string
) => {
  const newFilterMenu = [...filterMenu];
  const category = findCategory(newFilterMenu, categoryKey);
  category && setCategorySelection(category);

  return newFilterMenu;
};

export const forceSetAllCategoryOptions = (
  filterMenu: FilterMenuCategory[],
  selectedValue: boolean
) => {
  const newFilterMenu = [...filterMenu];

  return newFilterMenu.map((category) =>
    setCategorySelection(category, selectedValue)
  );
};

export const setCategorySelection = (
  category: FilterMenuCategory,
  forcedValue?: boolean
) => {
  const newCategory = { ...category };
  if (forcedValue !== undefined)
    newCategory.options.forEach((option) => (option.isSelected = forcedValue));
  else {
    const selected = isAllCategoryOptionsSelected(newCategory.options);
    newCategory.options.forEach((option) => (option.isSelected = !selected));
  }

  return newCategory;
};

export const buildCategoryOption = (
  key: string,
  label?: string
): CategoryOption => {
  return {
    key: key,
    label: label || key,
    isSelected: false,
  };
};

export const getUniqueCategoryOptions = (
  dataRows: Data<string>[],
  columnKey: string
) => {
  const allCategoryOptions: string[] = dataRows.map(
    (data: { [index: string]: string }) => data[columnKey]
  );
  const exclusiveOptions = new Set(allCategoryOptions);
  return [...exclusiveOptions];
};

export const buildCategory = (
  dataRows: Data<string>[],
  categoryKey: string,
  props?: CategoryProps
) => {
  dataRows = props?.excludeEmptyOption
    ? dataRows.filter((row) => row[categoryKey])
    : dataRows;
  const uniqueOptions = getUniqueCategoryOptions(dataRows, categoryKey);
  const categoryOptions = uniqueOptions.map((option) =>
    buildCategoryOption(option, props?.translations?.get(option))
  );

  const filterMenuCategory: FilterMenuCategory = {
    key: categoryKey,
    label: !props?.excludeLabelOption ? props?.label || categoryKey : undefined,
    options: categoryOptions,
  };

  return filterMenuCategory;
};

export const createAllOptionsFilterMenu = (
  data: Data<string>[]
): FilterMenuCategory[] =>
  Object.keys(data[0]).map((columnKey) => buildCategory(data, columnKey));

export const createMappedFilter = (
  data: Data<string>[],
  overrideMap: Map<string, CategoryProps>
) => {
  const filterMenu: FilterMenuCategory[] = [];
  overrideMap.forEach((overrideCategory, categoryKey) => {
    filterMenu.push(buildCategory(data, categoryKey, overrideCategory));
  });

  return filterMenu;
};

export const findCategory = (
  filtermenuCategories: FilterMenuCategory[],
  categoryKey: string
) => {
  return filtermenuCategories.find(
    (filterMenuCategory) => filterMenuCategory.key === categoryKey
  );
};

export const findOptionIndex = (
  filterMenuCategory: FilterMenuCategory,
  optionKey: string
) => {
  return filterMenuCategory.options.findIndex(
    (option) => option.key === optionKey
  );
};

export const isColumnOptionInFilter = (
  columnName: string,
  filter: FilterMenuCategory[]
) => {
  return !!findCategory(filter, columnName);
};
