import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { initialFetchStatus, loading, loadingComplete } from 'features/utils';
import { BrukerRolleDto } from 'services/brukerRolle/dto';
import { ReduxPickerProps } from 'utils/date';

import {
  fetchBruker,
  fetchBrukerRollerByBrukerAndEnhet,
  fetchBrukernavn,
  fetchRollerByEnhet,
  saveBruker,
} from './brukerEditorThunk';
import {
  BlockingErrors,
  BrukerEditorState,
  BrukerInput,
  BrukerTempValues,
  BrukerValidationMessages,
  GyldigPeriodePickers,
  RolleInput,
} from './interface';
import {
  mapInitialRolleInputs,
  mapUpdateRolleInput,
  mergeExistingBrukerInput,
} from './utils';

export const rolleInputAdapter = createEntityAdapter<RolleInput, number>({
  selectId: (rolleInput) => rolleInput.rolleId,
});

export const initialBrukerInput: BrukerInput = {
  fødselsnr: '',
  fornavnOgMellomnavn: '',
  etternavn: '',
  brukernavn: '',
  epost: '',
  telefonNr: '',
};

export const initialTempValues: BrukerTempValues = {
  fødselsnr: '',
  fornavnOgMellomnavn: '',
  etternavn: '',
  brukernavn: '',
};

const initialBlockingErrors: BlockingErrors = {
  roller: false,
  bruker: false,
  brukerRoller: false,
};

export const initialState: BrukerEditorState = {
  brukerInput: initialBrukerInput,
  initialBrukerInput: initialBrukerInput,
  tempValues: initialTempValues,
  brukernavnGeneratorDisabled: false,
  availableRoller: [],
  rolleInputs: rolleInputAdapter.getInitialState(),
  initialRolleInputs: rolleInputAdapter.getInitialState(),
  rolleStatus: initialFetchStatus,
  brukerStatus: initialFetchStatus,
  brukerRolleStatus: initialFetchStatus,
  brukernavnStatus: initialFetchStatus,
  saveStatus: initialFetchStatus,
  validationMessages: {},
  blockingErrors: initialBlockingErrors,
  resetFødselsnr: false,
  fødselsNrValid: true,
};

const slice = createSlice({
  name: 'brukerEditor',
  initialState: initialState,
  reducers: {
    setFødselsnummerValid: (state, action: PayloadAction<boolean>) => {
      state.fødselsNrValid = action.payload;
    },
    setSelectedEnhetId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedEnhetId = action.payload;
      state.rolleStatus = initialFetchStatus;
      state.saveStatus = initialFetchStatus;
    },
    updateBrukerInput: (
      state,
      action: PayloadAction<{ key: keyof BrukerInput; value: string }>
    ) => {
      const { key, value } = action.payload;

      state.brukerInput[key] = value;

      if (key === 'brukernavn') {
        state.brukernavnGeneratorDisabled = !!value;
      }
    },
    setRolleInputSelected: (
      state,
      action: PayloadAction<{
        rolleId: number;
        selected: boolean;
        gyldigFra: number | null;
      }>
    ) => {
      const { rolleId, selected, gyldigFra } = action.payload;

      rolleInputAdapter.updateOne(state.rolleInputs, {
        id: rolleId,
        changes: {
          selected,
          gyldigFra: { value: gyldigFra },
          gyldigTil: { value: null },
        },
      });

      state.validationMessages.roller = undefined;
    },
    updateGyldigPeriodePicker: (
      state,
      action: PayloadAction<{
        key: keyof GyldigPeriodePickers;
        rolleId: number;
        picker: ReduxPickerProps;
      }>
    ) => {
      const { key, rolleId, picker } = action.payload;

      rolleInputAdapter.updateOne(state.rolleInputs, {
        id: rolleId,
        changes: {
          [key]: picker,
        },
      });
    },
    setValidationMessage: (
      state,
      action: PayloadAction<{
        key: keyof BrukerValidationMessages;
        value?: string;
      }>
    ) => {
      const { key, value } = action.payload;
      state.validationMessages[key] = value;
    },
    setTempValue: (
      state,
      action: PayloadAction<{ key: keyof BrukerTempValues; value: string }>
    ) => {
      const { key, value } = action.payload;
      state.tempValues[key] = value;
    },
    setResetFødselsnrFalse: (state) => {
      state.resetFødselsnr = false;
    },
    resetBrukerEditorState: (
      state,
      action: PayloadAction<{ excludeEnhetAndRoller?: boolean }>
    ) => {
      if (action.payload.excludeEnhetAndRoller) {
        setInitialRolleInputs(state, state.availableRoller);
      } else {
        state.selectedEnhetId = undefined;
        state.availableRoller = [];
        state.rolleInputs = rolleInputAdapter.getInitialState();
        state.initialRolleInputs = rolleInputAdapter.getInitialState();
        state.rolleStatus = initialFetchStatus;
      }
      state.brukerInput = initialBrukerInput;
      state.initialBrukerInput = initialBrukerInput;
      state.tempValues = initialTempValues;
      state.brukernavnGeneratorDisabled = false;
      state.brukerStatus = initialFetchStatus;
      state.brukerRolleStatus = initialFetchStatus;
      state.brukernavnStatus = initialFetchStatus;
      state.saveStatus = initialFetchStatus;
      state.validationMessages = {};
      state.blockingErrors = initialBlockingErrors;
      state.resetFødselsnr = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRollerByEnhet.pending, (state) => {
        state.rolleStatus = loading;
      })
      .addCase(fetchRollerByEnhet.fulfilled, (state, { payload: roller }) => {
        setInitialRolleInputs(state, roller);
        state.availableRoller = roller;
        state.rolleStatus = loadingComplete;
        state.blockingErrors.roller = false;
      })
      .addCase(
        fetchRollerByEnhet.rejected,
        (state, { payload: errorMessage }) => {
          state.rolleStatus = {
            ...initialFetchStatus,
            errorMessage,
          };
          state.blockingErrors.roller = true;
        }
      )
      .addCase(fetchBruker.pending, (state) => {
        state.brukerStatus = loading;
        state.saveStatus = initialFetchStatus;

        if (state.brukerInput.id) {
          setInitialRolleInputs(state, state.availableRoller);
          state.brukernavnGeneratorDisabled = false;
        }
      })
      .addCase(fetchBruker.fulfilled, (state, { payload: bruker }) => {
        if (bruker) {
          state.brukerInput = state.initialBrukerInput =
            mergeExistingBrukerInput(
              state.brukerInput.id ? initialBrukerInput : state.brukerInput,
              bruker
            );
          state.tempValues.fødselsnr = bruker.fødselsnr || '';
          state.brukernavnGeneratorDisabled = true;
        } else if (state.brukerInput.id) {
          // Nullstill feltene for eksisterende bruker ved søk som ikke gir treff
          state.brukerInput = state.initialBrukerInput = {
            ...initialBrukerInput,
            fødselsnr: state.brukerInput.fødselsnr,
          };
        }
        state.validationMessages = {};
        state.brukerStatus = loadingComplete;
        state.blockingErrors.bruker = false;
      })
      .addCase(fetchBruker.rejected, (state, { payload: errorMessage }) => {
        state.brukerStatus = {
          ...initialFetchStatus,
          errorMessage,
        };
        state.blockingErrors.bruker = true;
      })
      .addCase(fetchBrukerRollerByBrukerAndEnhet.pending, (state) => {
        state.brukerRolleStatus = loading;
      })
      .addCase(
        fetchBrukerRollerByBrukerAndEnhet.fulfilled,
        (state, { payload: brukerRoller }) => {
          updateRolleInputs(state, brukerRoller);
          state.brukerRolleStatus = loadingComplete;
          state.blockingErrors.brukerRoller = false;
        }
      )
      .addCase(
        fetchBrukerRollerByBrukerAndEnhet.rejected,
        (state, { payload: errorMessage }) => {
          state.brukerRolleStatus = {
            ...initialFetchStatus,
            errorMessage,
          };
          state.blockingErrors.brukerRoller = true;
        }
      )
      .addCase(fetchBrukernavn.pending, (state) => {
        state.brukernavnStatus = loading;
      })
      .addCase(fetchBrukernavn.fulfilled, (state, { payload: brukernavn }) => {
        state.brukerInput.brukernavn = brukernavn;
        state.brukernavnStatus = loadingComplete;
      })
      .addCase(fetchBrukernavn.rejected, (state, { payload: errorMessage }) => {
        state.brukernavnStatus = {
          ...initialFetchStatus,
          errorMessage,
        };
      })
      .addCase(saveBruker.pending, (state) => {
        state.saveStatus = loading;
      })
      .addCase(
        saveBruker.fulfilled,
        (state, { payload: { id, versjon, roller: brukerRoller } }) => {
          state.brukerInput.id = String(id);
          state.brukerInput.versjon = String(versjon);

          brukerRoller && updateRolleInputs(state, brukerRoller);
          state.saveStatus = loadingComplete;
          state.initialBrukerInput = state.brukerInput;
        }
      )
      .addCase(saveBruker.rejected, (state, { payload: errorMessage }) => {
        state.saveStatus = {
          ...initialFetchStatus,
          errorMessage,
        };
      });
  },
});

const setInitialRolleInputs = (state: BrukerEditorState, roller: number[]) => {
  const rolleInputs = mapInitialRolleInputs(roller);
  rolleInputAdapter.setAll(state.rolleInputs, rolleInputs);
  rolleInputAdapter.setAll(state.initialRolleInputs, rolleInputs);
};

const updateRolleInputs = (
  state: BrukerEditorState,
  brukerRoller: BrukerRolleDto[]
) => {
  const updateRolleInput = mapUpdateRolleInput(brukerRoller);
  rolleInputAdapter.updateMany(state.rolleInputs, updateRolleInput);
  rolleInputAdapter.updateMany(state.initialRolleInputs, updateRolleInput);
};

export const {
  setSelectedEnhetId,
  updateBrukerInput,
  setRolleInputSelected,
  updateGyldigPeriodePicker,
  setValidationMessage,
  setTempValue,
  setResetFødselsnrFalse,
  resetBrukerEditorState,
  setFødselsnummerValid,
} = slice.actions;

export default slice.reducer;
