import { Grid, Typography, useTheme } from '@mui/material';
import { ScrIcon } from 'components';

const ScrIconExample = () => {
  const theme = useTheme();

  const { scr, common } = theme.palette;

  return (
    <Grid container>
      <Grid item xs={3}>
        <Typography>MUI ikon uten forhåndssatt farge</Typography>
        <ScrIcon iconId={13} size="small" />
        <ScrIcon iconId={13} />
        <ScrIcon iconId={13} size="large" />
        <ScrIcon iconId={13} size="xl" />
      </Grid>
      <Grid item xs={3}>
        <Typography>FA ikon uten forhåndssatt farge</Typography>
        <ScrIcon iconId={11} size="small" />
        <ScrIcon iconId={11} />
        <ScrIcon iconId={11} size="large" />
        <ScrIcon iconId={11} size="xl" />
      </Grid>
      <Grid item xs={3}>
        <Typography>Grønn</Typography>
        <ScrIcon iconId={4} size="small" />
        <ScrIcon iconId={4} />
        <ScrIcon iconId={4} size="large" />
        <ScrIcon iconId={4} size="xl" />
      </Grid>
      <Grid item xs={3}>
        <Typography>Rød</Typography>
        <ScrIcon iconId={6} size="small" />
        <ScrIcon iconId={6} />
        <ScrIcon iconId={6} size="large" />
        <ScrIcon iconId={6} size="xl" />
      </Grid>
      <Grid item xs={3}>
        <Typography>Avatar small</Typography>
        <ScrIcon iconId={42} size="small" />
        <ScrIcon iconId={42} />
        <ScrIcon iconId={42} size="large" />
        <ScrIcon iconId={42} size="xl" />
      </Grid>
      <Grid item xs={3}>
        <Typography>Ikon med bakgrunn</Typography>
        <ScrIcon
          iconId={3}
          size="small"
          color={common.black}
          bgcolor={scr.yellow900}
        />
        <ScrIcon iconId={1} color={common.white} bgcolor={common.black} />
        <ScrIcon
          iconId={6}
          size="large"
          color={common.white}
          bgcolor={common.black}
        />
        <ScrIcon
          iconId={11}
          color={scr.yellow900}
          bgcolor={scr.red900}
          size="xl"
        />
      </Grid>
      <Grid item xs={3}>
        <Typography>Ikon med ramme</Typography>
        <ScrIcon iconId={5} size="small" borderColor={scr.red900} />
        <ScrIcon iconId={8} borderColor={scr.green900} />
        <ScrIcon iconId={22} size="large" borderColor={scr.red900} />
        <ScrIcon iconId={23} borderColor={scr.yellow900} size="xl" />
      </Grid>
      <Grid item xs={3}>
        <Typography>Ikon med bakgrunn og ramme</Typography>
        <ScrIcon
          iconId={5}
          size="small"
          borderColor={scr.red900}
          bgcolor={scr.yellow900}
        />
        <ScrIcon
          iconId={8}
          borderColor={scr.green900}
          color={scr.yellow900}
          bgcolor={scr.red900}
        />
        <ScrIcon
          iconId={22}
          size="large"
          borderColor={scr.green900}
          color={scr.yellow900}
          bgcolor={scr.red900}
        />
        <ScrIcon
          iconId={23}
          borderColor={scr.green900}
          color={scr.yellow900}
          bgcolor={scr.red900}
          size="xl"
        />
      </Grid>
    </Grid>
  );
};

export default ScrIconExample;
