import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'app/rootReducer';
import { AppDispatch } from 'app/store';
import dispatchError from 'features/errorHandling/errorHandlingUtils';
import { AddError } from 'features/errorHandling/interface';
import { SysProperties, SysProperty } from 'services/sys/dto';
import { getSysProperties } from 'services/sys/sysService';
import { FetchResponseError } from 'utils/errors/customError';
import { ErrorNamesEnum } from 'utils/errors/interface';

export type SysReturnValue = {
  key: keyof SysProperties;
  data: SysProperty[];
};

export const fetchSysProperties = createAsyncThunk<
  SysReturnValue,
  keyof SysProperties,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: string;
    signal: AbortSignal;
  }
>(
  'sys/fetchSysProperties',
  async (sysKey, { dispatch, rejectWithValue, signal }) => {
    try {
      const sysProperties = await getSysProperties(sysKey, signal);
      return {
        key: sysKey,
        data: sysProperties,
      } as SysReturnValue;
    } catch (e) {
      const error = e as FetchResponseError;
      if (error.name !== ErrorNamesEnum.AbortError) {
        dispatchError(dispatch, error, AddError);
      }

      return rejectWithValue(error.message);
    }
  },
  {
    condition: (sysKey, { getState }) => {
      const { sys } = getState();
      if (
        sys[sysKey].ids.length > 0 ||
        sys[sysKey].status.loading ||
        sys[sysKey].status.loadingComplete
      ) {
        return false;
      }
    },
  }
);
