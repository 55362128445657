import { Button, Popover, Toolbar, Tooltip } from '@mui/material';
import { ScrIcon } from 'components';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { ReactNode, cloneElement, isValidElement } from 'react';

import Heading from '../Heading';

type FilterMenuComponentProps = {
  FilterMenuComponent?: React.ReactElement<{
    closeFilter?: VoidFunction;
    buttons?: boolean;
  }> | null;
  buttons?: boolean;
  filterDescription?: string;
  categoriesSelected?: number;
};
interface EnhancedTableToolbarProps {
  title?: string;
  filterMenuComponentProps?: FilterMenuComponentProps;
}

const EnhancedTableToolbar = ({
  title,
  filterMenuComponentProps,
}: EnhancedTableToolbarProps) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filterPopover',
  });

  let NewFilterMenu: ReactNode;
  const buttons = filterMenuComponentProps?.buttons;
  const closeFilter = () => popupState.close();
  const filterDescription = filterMenuComponentProps?.filterDescription;
  const FilterMenuComponent = filterMenuComponentProps?.FilterMenuComponent;
  if (isValidElement(FilterMenuComponent)) {
    NewFilterMenu = cloneElement(FilterMenuComponent, {
      closeFilter,
      buttons,
    });
  }

  return (
    <Toolbar variant="dense" sx={{ pl: 3, justifyContent: 'space-between' }}>
      <Heading
        id="tableTitle"
        componentVariant="h2"
        text={title || ''}
        noMargin
      />
      {FilterMenuComponent && (
        <>
          <Tooltip title={filterDescription || 'Filtrer tabell'}>
            <Button
              color="inherit"
              startIcon={<ScrIcon iconId={67} size="small" />}
              {...bindTrigger(popupState)}
              aria-label={filterDescription || 'Filtrer tabell'}>
              Filter{' '}
              {filterMenuComponentProps?.categoriesSelected
                ? `(${filterMenuComponentProps?.categoriesSelected || 0})`
                : ''}
            </Button>
          </Tooltip>
          <Popover
            {...bindPopover(popupState)}
            keepMounted
            elevation={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              sx: {
                bgcolor: 'scr.grey200',
                borderColor: 'scr.grey400',
                borderWidth: 1,
                py: 2,
                px: 3,
                maxWidth: 250,
              },
            }}>
            {NewFilterMenu || FilterMenuComponent}
          </Popover>
        </>
      )}
    </Toolbar>
  );
};

export default EnhancedTableToolbar;
