import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { initialFetchStatus, rejectedStatus } from 'features/utils';
import { EnhetDto } from 'services/enheter/dto';

import { EnheterState } from './interface';
import { fetchCurrentBrukerEnheter } from './thunks';

export const enheterAdapter = createEntityAdapter<EnhetDto, number>({
  selectId: (enhet) => enhet.id,
  sortComparer: (a, b) => a.navn.localeCompare(b.navn),
});

export const initialState: EnheterState = enheterAdapter.getInitialState({
  status: initialFetchStatus,
});

export const slice = createSlice({
  name: 'enheter',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentBrukerEnheter.fulfilled, (state, { payload }) => {
        state = enheterAdapter.setAll(state, payload);
        state.status.loading = false;
        state.status.loadingComplete = true;
      })
      .addCase(fetchCurrentBrukerEnheter.pending, (state) => {
        state.status.loading = true;
      })
      .addCase(
        fetchCurrentBrukerEnheter.rejected,
        (state, { error: { message } }) => {
          state.entities = {};
          state.ids = [];
          state.status = rejectedStatus(message);
          state.status.loadingComplete = true;
        }
      );
  },
});

export const { reset } = slice.actions;

export default slice.reducer;
