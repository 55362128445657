import { LoadingComponent } from 'components';

type Props = {
  height: number;
  text?: string;
  isLoading: boolean;
};

const LoadingTextComponent = ({ height, text, isLoading }: Props) => (
  <LoadingComponent
    showLoading={isLoading}
    variant="text"
    height={height}
    numberOfSkeletons={1}>
    {text}
  </LoadingComponent>
);

export default LoadingTextComponent;
