import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { FC } from 'react';
import { overlayDialogWidth } from 'utils/styles';

import { BekfreftDialogProps } from './interface';

const BekreftDialog: FC<BekfreftDialogProps> = ({
  handleClose,
  handleBekreft,
  open,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="fritekst-bekreft-dialog-title"
      PaperProps={{ sx: overlayDialogWidth }}>
      <DialogTitle id="fritekst-bekreft-dialog-title">
        Er du sikker på at du ønsker å sende brevet?
      </DialogTitle>

      <DialogActions>
        <Button onClick={() => handleClose(false)}>avbryt</Button>
        <Button variant="contained" onClick={handleBekreft}>
          Ja, send brev
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BekreftDialog;
