import { FritekstFormState } from './interface';

export const initialFormState: FritekstFormState = Object.freeze({
  hovedoverskrift: '',
  fritekst: '',
  adresseIdKryptert: undefined,
  fysiskPost: false,
});

export const initialMinimalFormState: FritekstFormState = Object.freeze({
  hovedoverskrift: '',
  fritekst: '',
});