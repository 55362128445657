import { DateTime } from 'luxon';

import { OutputType, formatToOutput } from './date';

export const convertFødselsnummerInput = (
  fødselsnummer: string,
  possibleDNumber: boolean
) => {
  const washedString = fødselsnummer.replace(/\s/g, '');
  if (washedString.length !== 11)
    throw new Error(
      `Fødselsnummer must be 11 digits, provided value ${fødselsnummer}`
    );
  if (isNaN(Number(washedString)))
    throw new Error(
      `Fødselsnummer must be 11 digits, did you enter some characters? Provided value ${fødselsnummer}`
    );
  if (isSyntetiskeData(washedString)) {
    return true;
  }

  if (!isValidFødselsnummerBirthdate(washedString, possibleDNumber))
    throw new Error(
      possibleDNumber
        ? `Even with D-number the first 6 characters in ${fødselsnummer} is not a date`
        : `The first 6 characters in ${fødselsnummer} is not a date`
    );

  const washedFødselsnummer = washedString.split('').map(Number);
  const k1Sequence = [3, 7, 6, 1, 8, 9, 4, 5, 2] as const;
  const k2Sequence = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2] as const;

  return (
    validateFNrChecksum(washedFødselsnummer, k1Sequence) &&
    validateFNrChecksum(washedFødselsnummer, k2Sequence)
  );
};

const isSyntetiskeData = (fødselsnummer: string) => {
  const mm = Number(fødselsnummer.substring(2, 4));
  return (
      Number(mm) > 12 &&
      ((Number(mm) - 80 <= 12 && Number(mm) - 80 >= 1) ||
          (Number(mm) - 65 <= 12 && Number(mm) - 65 >= 1))
  );
};

const isValidFødselsnummerBirthdate = (
  fødselsnummer: string,
  possibleDNumber: boolean
) => {
  const mm = Number(fødselsnummer.substring(2, 4));
  if (Number(mm) > 12) return false;

  let dd: number;
  let yyyy: number;
  try {
    dd = getCorrectDayOfMonth(fødselsnummer.substring(0, 2), possibleDNumber);
    yyyy = getFødselsnummerYear(
      fødselsnummer.substring(4, 6),
      fødselsnummer.substring(6, 9)
    );
  } catch (error) {
    return false;
  }
  const dateToCheck = DateTime.fromObject({ year: yyyy, month: mm, day: dd });
  return dateToCheck.isValid;
};

const getCorrectDayOfMonth = (dd: string, possibleDNumber: boolean) => {
  const ddNum: number = Number(dd);
  if (ddNum < 1) throw new Error(`Invalid day of month ${ddNum}`);

  if (ddNum <= 31) return ddNum;
  if (possibleDNumber) return ddNum - 40;

  throw new Error(
    `Day of month can not be ${dd} when it's not classified as a D-nummer`
  );
};

const getFødselsnummerYear = (
  lastTwoYearDigits: string,
  individnummer: string
) => {
  const indNum = Number(individnummer);
  const birthYear = Number(lastTwoYearDigits);

  if (0 <= indNum && indNum <= 499) {
    return Number('19' + lastTwoYearDigits);
  } else if (500 <= indNum && indNum <= 749 && birthYear >= 54)
    return Number('18' + lastTwoYearDigits);
  else if (900 <= indNum && indNum <= 999 && birthYear > 39)
    return Number('19' + lastTwoYearDigits);
  else if (500 <= indNum && indNum <= 999 && birthYear < 40)
    return Number('20' + lastTwoYearDigits);

  throw new Error('Unable to get correct century');
};

const validateFNrChecksum = (
  fødselsnummer: number[],
  sequence: readonly number[]
) => {
  const sum = sequence.reduce(
    (acc, curr, idx) => acc + curr * fødselsnummer[idx],
    0
  );
  const mod11 = sum % 11;
  const checksum = mod11 === 0 ? 0 : 11 - mod11;

  return fødselsnummer[sequence.length] === checksum;
};

const urlSearchParams = () => {
  if (window.location.search)
    return new URLSearchParams(window.location.search);

  return new URLSearchParams();
};

export const getUrlSearchParam = (param: string) => {
  return urlSearchParams().get(param);
};

export const getAllUrlSearchParams = () => {
  const params = urlSearchParams();
  if (params.toString() === '') return;

  return params.entries();
};

export const hasUrlSearchParamWithValue = (param: string) => {
  const searchParams = urlSearchParams();
  return searchParams.has(param) && searchParams.get(param) !== '';
};

export const deleteUrlSearchParam = (param: string) => {
  const searchParams = urlSearchParams();
  if (searchParams.has(param)) {
    searchParams.delete(param);
    window.history.pushState(
      {},
      '',
      window.location.pathname + '?' + searchParams.toString()
    );
  }
};

export const deleteAllUrlSearchParams = () => {
  window.history.pushState({}, '', window.location.pathname);
};

export const pushParamsToBrowserUrl = (params: string) => {
  window.history.pushState({}, '', window.location.pathname + '?' + params);
};

export const createSearchParamsFromObject = (
  obj: any,
  props?: { dateTimeOutputType?: OutputType }
) => {
  const params = Object.keys(obj)
    .map((key) => {
      let value = obj[key];

      if (value instanceof DateTime && props?.dateTimeOutputType) {
        value = formatToOutput(value, props.dateTimeOutputType);
      }

      if (value) return `${key}=${value}&`;
      return undefined;
    })
    .join('')
    .slice(0, -1); // fjern siste '&'

  return params;
};
