import { lazy } from 'react';

const AuthRoute = lazy(() => import('./AuthRoute'));

const InvitasjonerStartPage = lazy(
  () => import('features/invitasjon/InvitasjonerStartPage')
);

const InklusjonPage = lazy(
  () => import('features/invitasjon/inklusjon/InklusjonPage')
);
const KvitteringPage = lazy(
  () => import('features/invitasjon/kvitering/KvitteringPage')
);

const RedigerPage = lazy(
  () => import('features/invitasjon/rediger/RedigerPage')
);

export const invitasjonOgInklusjonRoutes = [
  {
    index: true,
    element: (
      <AuthRoute>
        <InvitasjonerStartPage />
      </AuthRoute>
    ),
  },
  {
    path: 'rediger/:utsendingstypeid',
    element: (
      <AuthRoute>
        <RedigerPage />
      </AuthRoute>
    ),
  },
  {
    path: 'inklusjon',
    element: (
      <AuthRoute>
        <InklusjonPage />
      </AuthRoute>
    ),
  },
  {
    path: 'kvittering',
    element: (
      <AuthRoute>
        <KvitteringPage />
      </AuthRoute>
    ),
  },
];
