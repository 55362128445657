import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch } from 'app/store';
import { addNotification } from 'features/notification/notificationSlice';
import { getAktivRettighetEnheter } from 'services/bruker/brukerService';
import { EnhetDto } from 'services/enheter/dto';
import { SELECTED_ENHET_ID } from 'utils';
import { TraceableError } from 'utils/errors/customError';

import { setCurrentEnhetId } from '../auth/slice';
import { fetchRettigheter } from '../rettigheter/thunks';

export const fetchCurrentBrukerEnheter = createAsyncThunk<
  EnhetDto[],
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string;
  }
>(
  'currentBruker/fetchCurrentBrukerEnheter',
  async (brukerId: number, { dispatch, rejectWithValue }) => {
    try {
      const enheter = await (
        await getAktivRettighetEnheter(brukerId)
      ).sort((a, b) => a.navn.localeCompare(b.navn));
      const selectedEnhetIdFromStorage = Number(
        sessionStorage.getItem(SELECTED_ENHET_ID)
      );
      const selectedEnhetId = !!selectedEnhetIdFromStorage
        ? selectedEnhetIdFromStorage
        : !!enheter.length
        ? enheter[0].id
        : 0;

      dispatch(setCurrentEnhetId(selectedEnhetId));
      dispatch(fetchRettigheter({ enhetId: selectedEnhetId, brukerId }));

      return enheter;
    } catch (e) {
      const err = e as TraceableError;
      dispatch(
        addNotification({
          message: {
            text: 'En feil skjedde ved henting av enhetene brukeren har tilgang til',
          },
          variant: 'error',
        })
      );
      return rejectWithValue(err.message);
    }
  }
);
