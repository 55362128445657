import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ScrIcon, ScrWrapper } from 'components';
import React from 'react';

const liIcon = (
  <ListItemIcon>
    <ScrIcon iconId={52} />
  </ListItemIcon>
);

const Kvittering: React.FC<{ messages: string[] }> = ({ messages }) => {
  return (
    <ScrWrapper sx={{ pt: 7, pb: 6, px: 9 }}>
      <List dense disablePadding>
        {messages.map((message, index) => (
          <ListItem key={index}>
            {liIcon}
            <ListItemText
              disableTypography
              sx={{ fontSize: 16, fontWeight: 'bold' }}>
              {message}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </ScrWrapper>
  );
};

export default Kvittering;
