import { Box } from '@mui/material';
import React from 'react';
import { screenReaderOnly } from 'utils/styles';

import PageHeaderHiddenProps from './interface';

const PageHeaderHidden: React.FC<PageHeaderHiddenProps> = ({
  level,
  description,
}: PageHeaderHiddenProps) => {
  return description ? (
    <Box component={level} sx={screenReaderOnly}>
      {description}
    </Box>
  ) : null;
};

export default PageHeaderHidden;
